import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import Cookies from 'universal-cookie'
import $ from 'jquery'
import ContentLoader from 'react-content-loader'
import { Helmet } from "react-helmet"

function Admindashboardheader() {

    const  REACT_APP_LIVE_BACKEND_BASE_URL  = process.env.REACT_APP_LIVE_BACKEND_BASE_URL;
    const [adminData, setAdminData] = useState([])
    const [userName, setUserName] = useState()
    const [isLoader, setIsLoader] = useState(true)
    const LAWYERURL = REACT_APP_LIVE_BACKEND_BASE_URL+'api/get-admin-data/'
    const cookies = new Cookies() 
    var tokenid = cookies.get('ADMIN')

    useEffect(() => {
        fetchlawyerdata()
        $('#sidebarCollapse').on('click', function () {
            $('#sidebar').toggleClass('active')
        })
    }, [])

    const MyLoader = () => (
        <ContentLoader speed={3} width={200} height={80}>
            <rect x="80" y="20" rx="3" ry="3" width="88" height="10" />
            <rect x="80" y="40" rx="3" ry="3" width="88" height="10" />
            <circle cx="30" cy="30" r="30" />
        </ContentLoader>
    )

    const fetchlawyerdata = () => {
        fetch(LAWYERURL+tokenid).then((res) => res.json()).then((response) => {
            setAdminData(response.userdata)
            if (response.username.length > 10) {
                const shortenedName = response.username.slice(0, 10) + "..."
                setUserName(shortenedName)
            } else {
                setUserName(response.username)
            }
            setIsLoader(false)
        })
    }

    const toggleMenu = (e) => {
        e.preventDefault()
        $(".show-admin-head-list").toggle(200)
    }

    return (
        <header className="header-main lawyer-header-main">
            <Helmet>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <div className="container-fluid">
                <div className="inner-header-main">
                    <div className="row align-items-center">
                        <div className="col-6 col-sm-6 col-md-3 col-lg-2">
                            {
                                isLoader ? <MyLoader /> : 
                                (
                                    <div className="brand-logo">
                                        <a href={`${process.env.REACT_APP_BASE_URL}/`}>
                                            <img src={adminData.website_logo !== undefined ?  adminData.website_logo : ''} alt="logo" />
                                        </a>
                                    </div>
                                )
                            }
                        </div>
                        <div className="col-6 col-sm-6 col-md-9 col-lg-10">
                            <nav>
                                <ul className="nav-list">
                                    <li className="nav-item dropdown icon-list profile-img">
                                        <button className="nav-link dropdown-toggle" onClick={toggleMenu} id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <img src={adminData.logo_image} alt={userName} />{userName !== '' ? userName : ''}
                                        </button>
                                        <span className="dropdown-menu top-dropdown-menu show-admin-head-list" aria-labelledby="navbarDropdown">
                                            <Link className="dropdown-item setting-bk-icon" to="/change-admin-password/">Change Password</Link>
                                            <Link className="dropdown-item logout-bk-icon" to="/logout/">Logout</Link>
                                        </span>
                                    </li>
                                    <li className="mobile-sidemenu-btn">
                                        <button type="button" id="sidebarCollapse" class="btn btn-primary">
                                            <i class="fa fa-bars"></i> <span class="sr-only">Toggle Menu</span>
                                        </button>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Admindashboardheader
