import React from 'react';

function Lawyerfooter(){

    return(
      <footer className="dashboard-foot">
        <hr className="custom"/>
        <div className="row">
          <div className="col-12 col-sm-6 col-md-6 col-lg-6">
            <div className="dashmin-foot-inner">
              <h5>ServeTheInjured</h5>
              <p>© {new Date().getFullYear()} </p>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-6"></div>
        </div>
      </footer>
    )
}

export default Lawyerfooter