import React from 'react';
import { Link } from 'react-router-dom';

function Lawyersidebar(){

    return (
        <nav id="sidebar">
      <div className="inner-sidebar">
        <ul className="list-unstyled components mb-5">

          <li>
            <Link to="/dashboard/">Dashboard</Link>
          </li>

          <li>
            <Link to="/edit-profile/">Edit Profile</Link>
          </li>

          <li>
            <Link to="/view-profile/">View Profile</Link>
          </li>

          <li>
            <Link to="/get-your-badge/">Get Your Badge</Link>
          </li>

          <li>
            <a href={`${process.env.REACT_APP_BASE_URL}/injury-lawyer-seo/course/`}>Injury Law SEO Course</a>
          </li>

          <li>
            <Link to="/get-published/">Get Published</Link>
          </li>

          <li>
            <Link to="/contact-us/">Contact Us</Link>
          </li>

          <li>
            <Link to="/logout/">Logout</Link>
          </li>
        </ul>
      </div>
    </nav>
    )
}

export default Lawyersidebar

