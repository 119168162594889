import React, {useEffect, useState} from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import $ from 'jquery';
import { Link } from 'react-router-dom';

function Flawyers() {
    const DvInnerHTML = function (target, newText) {
        document.querySelectorAll(target).forEach(function (element) {
            element.textContent = newText;
        });
    }
    const  REACT_APP_LIVE_BACKEND_BASE_URL  = process.env.REACT_APP_LIVE_BACKEND_BASE_URL;
    const StateURL = REACT_APP_LIVE_BACKEND_BASE_URL + 'api/get-all-states-list';
    const CityUrl = REACT_APP_LIVE_BACKEND_BASE_URL + 'api/get-cities';
    const [PracticeArea, setPracticeArea] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [state, setState] = useState([]);
    const [city, setCity] = useState([]);

    useEffect(() => {
        document.title = 'Find A Lawyers';
        fetchPracticeArea();
        fetchState();
        fetchstateData();
    }, [])

    const fetchPracticeArea = () => {
        fetch(REACT_APP_LIVE_BACKEND_BASE_URL+"api/get-practise-area-list").then((res) => res.json()).then((response) => {
            setPracticeArea(response);
        })
    }

    const fetchstateData = () => {
        fetch(StateURL).then((res) => res.json()).then((response) => {
            setStateList(response);
        })
    }

    const fetchState = () => {
        fetch(REACT_APP_LIVE_BACKEND_BASE_URL+"api/get-front-state-list") .then((res) => res.json()) .then((response) => { 
            setState(response); 
        })
    }

    const stateChange = (event) => {
        if (event.target.value !== "") {
            fetch(CityUrl + '/' + event.target.value).then((res) => res.json()).then((response) => {
                setCity(response);
            })
        }else{
            setCity([]);
        }
    }

    const SearchData = async (e) => {
        e.preventDefault();
        DvInnerHTML('.text-danger', '');
        var lawyer_state = $("#lawyer_state").val();
        var lawyer_city = $("#lawyer_city").val();
        var practice_area = $("#practice_area").val();
        if(practice_area === ""){
            $("#practice_area-error").html('Please select practice area.');
        }else if(lawyer_state === ""){
            $("#lawyer_state-error").html('Please select state.');
        }else if(lawyer_city === ""){
            $("#lawyer_city-error").html('Please select city.');
        }else{
            window.location.assign('/'+practice_area+'/'+lawyer_state+"/"+lawyer_city);
        }
    }

    return (
        <div>
            
            <Header />
            <section className="hero-sec">
                <div className="container">
                    <div className="title text-center">
                        <h2>The Right Personal Injury Lawyer</h2>
                    </div>

                    <div className="hero-sec-form">
                        <form onSubmit={(e) => SearchData(e)}>
                            <div className="row">

                                <div className="col-md-3">
                                    <div className="form-group">
                                        <select name="practice_area" id="practice_area">
                                            {PracticeArea !== '' ? (<>{PracticeArea.map((item, i) => {

                                                return(
                                                    <option value={item.slug} key={`practice-${i}`}>{item.name}</option>
                                                )
                                            })}</>) : ''}
                                            
                                        </select>
                                        <p className="text-danger mt-1 mb-0" id="practice_area-error"></p>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="form-group">
                                        <select name="state" id="lawyer_state" className="form-control" onChange={(edit) => stateChange(edit)}>
                                            <option value="">Select State</option>
                                            {
                                                stateList.map((item, key) => <option key={`state-${key}`} value={item.slug}>{item.name}</option>)
                                            }
                                        </select>
                                        <p className="text-danger mt-1 mb-0" id="lawyer_state-error"></p>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="form-group">
                                        <select name="city" id="lawyer_city" className="form-control">
                                            <option value="">Select City</option>
                                            {
                                                city.map((item, key) => <option key={`city-${key}`} value={item.slug}>{item.name}</option>)
                                            }
                                        </select>
                                        <p className="text-danger mt-1 mb-0" id="lawyer_city-error"></p>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="form-btn">
                                        <button type="submit">Search Attorneys</button>
                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </section>

            <section className="f-lawyer-state">
                <div className="container">
                    <div className="inner-f-lawyer-state">
                        <div className="title mb-3">
                            <h1 className="text-start">Choose Your State</h1>
                        </div>
                        <div className="text">
                            <p>Find Lawyer is the only legal directory where every Lawyer is linked to their firm and every firm to its Lawyer. Our directory features local results for over 100 practice areas across every city and state in the United States. Every lawyer and law firm has a detailed profile, with reviews from past clients just like you. From Agriculture to Workers Compensation, and every legal issue in between, Find Lawyer is here to help you find the right Lawyer today.</p>
                            <p>Most Lawyer focus their practice on a specific area of the law, and any directly related
                                practice areas. For example, divorce Lawyer will typically also handle general family law matters and estate planning lawyers may also practice tax law. However, a business Lawyer will generally not handle unrelated cases such as personal injury. Use our site to narrow down your search to the right practice area in your local area.</p>
                        </div>

                        <div className="state-list">
                            <ul className="lawyer-state-list">
                            {state.map((item, i) => {
                                return(
                                    item.map((items, j) => {
                                        return(
                                            <li key={`state-city-${j}`}> <Link to={"/practice-areas/"+items.slug}> {items.name} </Link> </li>
                                            // <li key={`state-city-${j}`}> <Link to={"/c-city/"+items.slug}> {items.name} </Link> </li>
                                        )
                                    })        
                                )
                            })}
                            </ul>
                        </div>

                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export { Flawyers } 