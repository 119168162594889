import React, {useEffect, useState} from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useParams, Link } from "react-router-dom";

function CCity() {

    let {id} = useParams();
    const  REACT_APP_LIVE_BACKEND_BASE_URL  = process.env.REACT_APP_LIVE_BACKEND_BASE_URL;
    const [city, setCity] = useState([]);
    const [state, setState] = useState(null);

    useEffect(() => {
        document.title = 'Cities';
        fetchState();
        fetchCity();
    }, [])

    const fetchCity = () => {
        fetch(REACT_APP_LIVE_BACKEND_BASE_URL+"api/get-front-ccity/"+id)
            .then((res) => res.json())
            .then((response) => {
                setCity(response);
            })
    }

    const fetchState = () => {
        fetch(REACT_APP_LIVE_BACKEND_BASE_URL+"api/get-state/"+id)
            .then((res) => res.json())
            .then((response) => {
                setState(response);
            })
    }

    return (
        <div>
            <Header />

            <section className="pages-breadcrumb mt-3">
                <div className="container">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href={process.env.REACT_APP_BASE_URL} rel="noopener noreferrer">Home</a></li>
                            <li className="breadcrumb-item" aria-current="page">
                                {state ? state.name : ""}
                                {/* <Link to={`/find-a-lawyer`}>{state ? state.name : ""}</Link> */}
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">City</li>
                        </ol>
                    </nav>
                </div>
            </section>

            <section className="f-lawyer-city pb-5">
                <div className="container">
                    <div className="inner-f-lawyer-city">
                        <div className="title mb-3">
                            <h1 className="text-start mb-4">{state ? state.name : ""} Personal Injury Lawyers: Choose Your City</h1>
                        </div>
                       
                            {Object.keys(city).map((item, i) => {
                                return (
                                    <>
                                    <div className="city-list-content mb-4">
                                        <div className="alpha-content">
                                            <h5>{item}</h5>
                                        </div>
                                        <ul className="city-list">
                                            {city[item].map((items,j) => {
                                                return(
                                                    <>
                                                    <li><a href={process.env.REACT_APP_LIVE_BASE_URL+"practice-areas/"+id+"/"+items.slug} rel="noopener noreferrer">{items.name}</a></li>
                                                    </>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                    </>
                                )
                            })}
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    )
}

export { CCity } 