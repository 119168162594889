import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {Link} from 'react-router-dom';

function SingleClaimProfile() {

    const REACT_APP_LIVE_BACKEND_BASE_URL = process.env.REACT_APP_LIVE_BACKEND_BASE_URL;
    const [agreement, setAgreement] = useState(false);
    let {id} = useParams();
    const LAWYERURL = REACT_APP_LIVE_BACKEND_BASE_URL + 'api/get-lawyer-data/' + id;
    const [lawyerdata, setLawyerdata] = useState({
        biography: "",
        city: "",
        created_at: "",
        email: "",
        firm_description: "",
        firmname: "",
        id: 0,
        location_url: "",
        map_url: "",
        mobile: "",
        name: "",
        office_phone: "",
        practice_area: "",
        profile_photo: "",
        state: "",
        street_address: "",
        updated_at: "",
        website: "",
        zipcode: ""
    })
    const [state, setState] = useState([])
    const [city, setCity] = useState([])

    useEffect(() => {
        fetchlawyerdata();
    }, [])

    const fetchlawyerdata = () => {
        fetch(LAWYERURL).then((res) => res.json()).then((response) => {
            if (response.lawyerprofile !== '') {
                setLawyerdata(response.lawyerprofile);

                if (response.lawyerprofile.state != null) {
                    fetch(REACT_APP_LIVE_BACKEND_BASE_URL + 'api/get-lawyer-state/' + response.lawyerprofile.state).then((res) => res.json()).then((response) => {
                        setState(response.data);
                    })
                }

                if (response.lawyerprofile.city != null) {
                    fetch(REACT_APP_LIVE_BACKEND_BASE_URL + 'api/get-lawyer-city/' + response.lawyerprofile.city).then((res) => res.json()).then((response) => {
                        setCity(response.data);
                    })
                }
            }
        })
    }

    const handleChange = (event) => {
        setAgreement(event.target.checked);
    }

    return (
        <section className="single-claim-profile">
            <div className="container">
                <div className="inner-single-claim-profile">

                    <div className="title text-center">
                        <h1>Claim your free Serve The Injured profile</h1>
                    </div>

                    <div className="single-profile-card">
                        <div className="row">

                            <div className="col-md-2">
                                <div className="s-profile-img">
                                    <img src={lawyerdata.profile_photo} className='img-fluid' alt={lawyerdata.name} />
                                </div>
                            </div>
                            <div className="col-md-10">
                                <div className="s-profile-content">
                                    <div className="s-profile-name">
                                        <h5>{lawyerdata.name}</h5>
                                        <p>
                                            <i className="fa-solid fa-location-dot"></i>
                                            {
                                            lawyerdata.street_address
                                        }, {
                                            city.name
                                        }, {
                                            state.name
                                        }</p>
                                        {lawyerdata.email && lawyerdata.email !== "" ? (
                                            <p><i className="fa-solid fa-envelope"></i> {lawyerdata.email}</p>
                                        ) : ""}
                                        
                                    </div>

                                    <div className="back-search">
                                        <p>Not you? <Link to="/claim-profile-search/">Back to Search.</Link></p>
                                    </div>

                                </div>
                            </div>

                        </div>

                        <div className="check-form mt-4">
                            <form>
                                <div className="form-group">
                                    <label> <input type="checkbox" id="privacy_check" onChange={(e) => handleChange(e)}/> I confirm that I am {lawyerdata.name} and I accept Serve The Injured <a target="_blank" href={`${process.env.REACT_APP_BASE_URL}/terms-of-service/`}>Terms of Use</a> and <a target="_blank" href={`${process.env.REACT_APP_BASE_URL}/privacy-policy/`}>Privacy Policy.</a> </label>
                                </div>
                                <div className="continue-btn mt-3">
                                    {
                                        agreement ? (
                                            <Link id="continue-next" to={`/choose-method/${lawyerdata.id}/`} className="button">Continue</Link>
                                        ):(
                                            <Link onClick={(e) => e.preventDefault()} to="/" className="button disabled">Continue</Link>
                                        )
                                    }                                       
                                    <p>It’s free and takes less than a minute!</p>
                                </div>
                            </form>
                        </div>

                    </div>

                    <div className="one-third-points my-5">
                        <div className="row">

                            <div className="col-md-6">
                                <div className="points">
                                    <div className="tick-icon">
                                        <i className="fa-solid fa-check"></i>
                                    </div>
                                    <div className="point-content">
                                        <div className="p-title">
                                            <h5>Control your online reputation.</h5>
                                        </div>
                                        <div className="p-text">Claiming and updating your profile and publishing articles on STI are excellent ways to promote positive content about your law firm.</div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="points">
                                    <div className="tick-icon">
                                        <i className="fa-solid fa-check"></i>
                                    </div>
                                    <div className="point-content">
                                        <div className="p-title">
                                            <h5>Grow your business.</h5>
                                        </div>
                                        <div className="p-text">Link to your website from the high DR profiles to increase the power of your website and connect with the millions of people searching for legal help.</div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="works">
                        <div className="title">
                            <h5>How it works</h5>
                        </div>
                        <ol>
                            <li>
                                <p>Choose how you want to claim.</p>
                                <span>You can use your email id to claim your profile.</span>
                            </li>

                            <li>
                                <p>Verify your identity.</p>
                                <span>Follow a few easy steps to confirm your identity.</span>
                            </li>

                            <li>
                                <p>Sign in to STI.</p>
                                <span>Sign in to your STI account using your email and password.</span>
                            </li>

                            <li>
                                <p>Build your profile.</p>
                                <span>Use our user-friendly profile builder to make it great, then pay one low fee to make it live.</span>
                            </li>
                        </ol>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default SingleClaimProfile;
