import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import Lawyerheader from '../component/Lawyerheader';
import Lawyerfooter from '../component/Lawyerfooter';
import Lawyersidebar from '../component/Lawyersidebar';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { useParams } from 'react-router-dom';

function AccountManagement() {

    const cookies = new Cookies();
    var lawyertocken = cookies.get('LAWYER');
    const  REACT_APP_LIVE_BACKEND_BASE_URL  = process.env.REACT_APP_LIVE_BACKEND_BASE_URL;
    const [packageList, setPackageList] = useState([]);
    const DvInnerHTML = function (target, newText) {
        document.querySelectorAll(target).forEach(function (element) {
            element.textContent = newText;
        });
    }
    const [Alert, setAlert] = useState(null);
    const [payment, setPayment] = useState([]);
    const [Package, setPackage] = useState([]);
    const [PackageId, setPackageId] = useState([]);
    const [lawyerId, setLawyerId] = useState(null);

    let {thanks} = useParams();
    useEffect(() => {
        document.title = 'Account Management';
        if(thanks == 'thanks'){
            setAlert((<SweetAlert success title="" onConfirm={() => {
                setAlert(null);
            }} >{'Thank you ! Package Successfully purchased.'}</SweetAlert>))
        }else if(thanks == 'fails'){
            setAlert((<SweetAlert danger title="" onConfirm={() => {
                setAlert(null);
            }} >{'Error ! Package Not purchased.'}</SweetAlert>))
        }
    }, []);

    useEffect(() => {
        fetchPackageList();
        LawyerPackage();
    }, [])

    const fetchPackageList = () => {
        fetch(REACT_APP_LIVE_BACKEND_BASE_URL+"api/get-lawyer-package-list")
            .then((res) =>
                res.json())

            .then((response) => {
                setPackageList(response.packagelist);
            })
    }

    const LawyerPackage = () => {
        fetch(REACT_APP_LIVE_BACKEND_BASE_URL+"api/get-lawyer-package-detail/"+lawyertocken)
            .then((res) =>
                res.json())

            .then((response) => {
                setPayment(response.paymentdetail);
                setPackage(response.lawyerpackage);
                setPackageId(response.packageid);
                setLawyerId(response.lawyerid);
            })
    }

    const BuyPackage = async (e) => {
        var packageid = e.target.getAttribute("packageid");
        if(packageid !== null){
            try {
                // make axios post request
                const response = await axios({
                    method: "post",
                    url: REACT_APP_LIVE_BACKEND_BASE_URL+"api/buy-package",
                    data: {
                        packageid:packageid,
                        lawyerid:lawyerId,
                    },
                    headers: { "Content-Type": "multipart/form-data" },
                }).then((response) => {
                    const errors = response.errors;
                    if (response.status) {
                        window.location.href = response.data.redirecturl;
                    } else {
                        DvInnerHTML('.text-danger', '');
                        if (parseInt(Object.keys(errors).length) > 0) {
                            for (var key in errors) {
                                if (errors.hasOwnProperty(key)) {
                                    if (document.getElementById(key + '-error') === null) {
                                    } else {
                                        document.getElementById(key + '-error').innerHTML = errors[key];
                                    }
                                }
                            }
                        } else {
                            setAlert((<SweetAlert danger title="" onConfirm={() => {
                                setAlert(null)
                            }} >{response.msg}</SweetAlert>))
                        }
                    }
                });
            } catch (response) {
                console.log(response)
            }
    
        }
    }

    return (
        <>
            <Lawyerheader />
            <div className="wrapper d-flex align-items-stretch">
                <Lawyersidebar />
                <div id="content" className="main-content">

                    <div className="bg-custom">

                        <div className="guest-posts">
                            <div className="inner-guest-posts c-spacing-s">
                                <div className="row">

                                    <div className="col-md-6">
                                        <div className="posts-title">
                                            <span>Account Management</span>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group posts-title text-right">
                                            <input type="text" name="search" placeholder="Search…" className="" id=""/>
                                        </div>
                                    </div>

                                </div>

                                <div className="inner-account-content px-3 pb-4">
                                    <div className="row">

                                        <div className="col-md-9">

                                            <div className="left-account-content-box">
                                                <div className="row">
                                                    {packageList !== null ? (<>{packageList.map((item, i) => {
                                                        return(
                                                            <>
                                                            <div className="col-md-4" key={i}>
                                                                <div className="card mb-4">
                                                                    <div className="card-body text-center">
                                                                        <h5 className="card-title">{item.title}</h5>
                                                                        <h5>${item.price}</h5>
                                                                        <p className="card-text">{item.description}</p>
                                                                        <div className="buy-btn">
                                                                            {PackageId !== null ? (<>{PackageId.includes(item.id) ? <button  packageid={item.id} className="package-button">Purchased</button> : <button onClick={BuyPackage} packageid={item.id} className="package-button">Buy</button>}</>) : <button onClick={BuyPackage} packageid={item.id} className="package-button">Buy</button>}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            </>
                                                        )
                                                    })}</>) : 'No Package'}

                                                    <div className="col-md-4">
                                                        <div className="card mb-4">
                                                            <div className="card-body text-center">
                                                                <h5 className="card-title">Publish an article</h5>
                                                                <p className="card-text">this goes to a page with a contact form. I’ll send the required fields
                                                                    later.</p>
                                                                <div className="buy-btn">
                                                                    <Link to="#" className="">Buy</Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <div className="card mb-4">
                                                            <div className="card-body text-center">
                                                                <h5 className="card-title">Add your badge</h5>
                                                                <p className="card-text">page like this <Link to="#"
                                                                    className="text-link">https://www.expertise.com/nj/camden/personal-injury-attorney/award?id=31tz6bd</Link>
                                                                </p>
                                                                <div className="buy-btn">
                                                                    <Link to="#" className="">Buy</Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <div className="card mb-4">
                                                            <div className="card-body text-center">
                                                                <h5 className="card-title">Change your password</h5>
                                                                <p className="card-text">classNameic change password function</p>
                                                                <div className="buy-btn">
                                                                    <Link to="#" className="">Buy</Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <div className="card mb-4">
                                                            <div className="card-body text-center">
                                                                <h5 className="card-title">Injury Law SEO</h5>
                                                                <p className="card-text">this is a standard webpage described below</p>
                                                                <div className="buy-btn">
                                                                    <Link to="#" className="">Buy</Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <div className="card mb-4">
                                                            <div className="card-body text-center">
                                                                <h5 className="card-title">Injury Website Link Building</h5>
                                                                <p className="card-text">this is a standard webpage described below</p>
                                                                <div className="buy-btn">
                                                                    <Link to="#" className="">Buy</Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <div className="card mb-4">
                                                            <div className="card-body text-center">
                                                                <h5 className="card-title">Speak with An Injury Law SEO Expert</h5>
                                                                <p className="card-text">this is a standard webpage described below</p>
                                                                <div className="buy-btn">
                                                                    <Link to="#" className="">Buy</Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>

                                        <div className="col-md-3">
                                            <div className="right-link-sec">
                                                <ol>
                                                    <li> <Link to="#">Lorem Ipsum is simply dummy text</Link> </li>
                                                    <li> <Link to="#">Lorem Ipsum is simply dummy text</Link> </li>
                                                    <li> <Link to="#">Lorem Ipsum is simply dummy text</Link> </li>
                                                    <li> <Link to="#">Lorem Ipsum is simply dummy text</Link> </li>
                                                    <li> <Link to="#">Lorem Ipsum is simply dummy text</Link> </li>
                                                    <li> <Link to="#">Lorem Ipsum is simply dummy text</Link> </li>
                                                </ol>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>

                            <div className="inner-guest-posts">

                                <div className="inner-profile-sec">
                                    <div className="heading-title c-text-small">
                                        <p>My Packages</p>
                                    </div>
                                    {Package !== null ? (<>{

                                    <div className="data-table-sec table-responsive">

                                    <table id="example" className="display" cellSpacing="0" width="100%">
                                        <thead>
                                            <tr>
                                                <th>Title</th>
                                                <th>Description</th>
                                                <th>purchase date</th>
                                                <th>Valid</th>
                                                <th>Price</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {Package !== null ? (<>{ Package.map((item, i) => {
                                            return(

                                            <tr>
                                                <td>{item.title}</td>
                                                <td>{item.description}</td>
                                                {payment.map((items, j) => {
                                                    return(
                                                        <>
                                                        {item.id == items.package_id ? (<><td>{items.purchase_date}</td><td>{items.valid_date}</td></>) : ''}
                                                        </>
                                                    )
                                                })}
                                                
                                                
                                                <td>${item.price}</td>
                                            </tr>
                                            )
                                        })
                                                                                
                                                                            }</>) : 'hello'}
                                            
                                        </tbody>
                                    </table>
                                    </div>
                                    }</>) : <></>}
                                    

                                </div>

                            </div>

                        </div>

                    </div>
                    {Alert}
                    <Lawyerfooter />

                </div>
            </div>
        </>
    )

}

export default AccountManagement