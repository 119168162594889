import React, {useState, useEffect} from 'react';

const Loader = ({loader, children}) => {
    return loader ?  (
        <div className='loader-main'>
            <img src={`${process.env.PUBLIC_URL}/images/loader.gif`} alt='loader' className='loader-image' />
        </div>
    ) : (
        children
    );
}

const Pagination = ({page, lastPage, currentPage, handleClick}) => {
    const beforepage = parseInt(currentPage) - 1;
    const afterpage = parseInt(currentPage) + 1;
    
    return (
        <>
        {
            page.map((item, i) => {
                let button = "";
                if(item.label === '&laquo; Previous' && item.url){
                    button = (<button type="button" onClick={(e) => handleClick(e)} nextpage={item.url} className={item.url != null ? "pagination-show prev active" : "pagination-show prev"} key={i}>Previous</button>)
                }else if(item.label === 'Next &raquo;' && item.url){
                    button = (<button type="button" onClick={(e) => handleClick(e)} nextpage={item.url} className={item.url != null ? "pagination-show next active" : "pagination-show next"} key={i}>Next</button>)
                }else if(item.label !== '&laquo; Previous' && item.label !== 'Next &raquo;'){
                    if(parseInt(beforepage) === parseInt(item.label)){
                        button = (<button type="button" onClick={(e) => handleClick(e)} nextpage={item.url} className={item.active === true ? "pagination-show count active" : "pagination-show count"} key={i}>{item.label}</button>)
                    }else if(parseInt(currentPage) === parseInt(item.label)){
                        button = (<button type="button" onClick={(e) => handleClick(e)} nextpage={item.url} className={item.active === true ? "pagination-show count active" : "pagination-show count"} key={i}>{item.label}</button>)
                    }else if(parseInt(afterpage) === parseInt(item.label)){
                        button = (<>
                        <button type="button" onClick={(e) => handleClick(e)} nextpage={item.url} className={item.active === true ? "pagination-show count active" : "pagination-show count"} key={i}>{item.label}</button>
                        <div className='mobile-sidemenu-btn'>...</div>
                        </>)
                    }else if(parseInt(lastPage) === parseInt(item.label)){
                        button = (<button type="button" onClick={(e) => handleClick(e)} nextpage={item.url} className={item.active === true ? "pagination-show count active" : "pagination-show count"} key={i}>{item.label}</button>)
                    }else{
                        button = (<button type="button" onClick={(e) => handleClick(e)} nextpage={item.url} className={item.active === true ? "pagination-show count active" : "count"} key={i}>{item.label}</button>)
                    }
                }

                return button
            })
        }
        
        </>
    );     
}

export {Loader, Pagination}
