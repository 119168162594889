import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import DropdownButton from 'react-bootstrap/DropdownButton'
import ContentLoader from 'react-content-loader'
import ScrollToTop from './ScrollToTop'
import LowercaseRedirect from './LowercaseRedirect'

function Header({isLogin, loginType}){

    const [adminData, setAdminData] = useState(null)
    const [headerMenu, setHeaderMenu] = useState([])
    const [isLoader, setIsLoader] = useState(true)
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)
    const [isMenuLoader, setIsMenuLoader] = useState(true)
    const [isShowMenu, setIsShowMenu] = useState(false)
    const LAWYERURL = process.env.REACT_APP_WORDPRESS_API_URL+'/site-data';
    const MENUURL = process.env.REACT_APP_WORDPRESS_API_URL+'/menu-locations/primary_menu';

    const MyLoader = () => (
        <ContentLoader speed={3} width={200} height={48}>
            <rect x="48" y="10" rx="3" ry="3" width="80" height="10" /> 
            <rect x="48" y="30" rx="3" ry="3" width="80" height="10" /> 
            <circle cx="22" cy="22" r="22" />
        </ContentLoader>
    )

    const MyMobileLoader = () => (
        <ContentLoader speed={3} width={148} height={30}>
            <rect x="35" y="5" rx="3" ry="3" width="80" height="5" /> 
            <rect x="35" y="20" rx="3" ry="3" width="80" height="5" /> 
            <circle cx="15" cy="15" r="15" />
        </ContentLoader>
    )

    const MenuLoader = () => (
        <ContentLoader speed={3} width={400} height={48} viewBox="0 0 476 124" backgroundColor="#f3f3f3" foregroundColor="#ecebeb">
            <rect x="48" y="10" rx="3" ry="3" width="400" height="20" /> 
            <rect x="48" y="40" rx="3" ry="3" width="380" height="20" /> 
            <rect x="48" y="70" rx="3" ry="3" width="380" height="20" /> 
        </ContentLoader>
    );

    useEffect(() => {
        fetchlawyerdata()
        fetchHeaderMenu()
        window.addEventListener('resize', handleResize())
        return () => {
            window.removeEventListener('resize', handleResize());
        }
    }, [])

    const fetchlawyerdata = () => {
        fetch(LAWYERURL).then((res) => res.json()).then((response) => {
            setAdminData(response.logo);
            setIsLoader(false);
        })
    }

    const fetchHeaderMenu = () => {
        fetch(MENUURL).then((res) => res.json()).then((response) => {
            setHeaderMenu(response.menus)
            setIsMenuLoader(false);
        })
    }

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 768)
    }

    return (
        <header className="header">
            <LowercaseRedirect />
            <ScrollToTop />
            <nav className="navbar navbar-expand-lg navbar-light bg-white">
                <div className="container">
                    {
                        isLoader ? (isMobile ? <MyMobileLoader /> : <MyLoader />) : 
                        (
                            <div className="logo">
                                <a className="navbar-brand" href={`${process.env.REACT_APP_BASE_URL}/`}>
                                    <img src={adminData ?  adminData : ''} alt="Server The Injured" />
                                </a>
                            </div>
                        )
                    }

                    <button className="navbar-toggler" type="button" onClick={() => setIsShowMenu(!isShowMenu)}>
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    {
                        isMenuLoader ? (isMobile ? "" : <MenuLoader />): 
                        (
                            <div className={isShowMenu ? `collapse navbar-collapse navbar-collapse-menu show` : `collapse navbar-collapse navbar-collapse-menu`} id="navbarSupportedContent">
                                <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                                    {
                                        headerMenu.length >= 1 ? headerMenu.map((menu, index) => {
                                            return (
                                                menu.children.length >= 1 ?
                                                (<li className='nav-item dropdown' key={`menu-${index}`}>
                                                    <DropdownButton title={menu.title} className="nav-dropdown-links">
                                                    { 
                                                        menu.children.map((submenu, subindex) => {
                                                            return (<a className='dropdown-item' href={submenu.url} key={`menu-${subindex}`}>{submenu.title}</a>)
                                                        })
                                                    } 
                                                    </DropdownButton>
                                                </li>) : (
                                                    <li className="nav-item" key={`menu-${index}`}>
                                                        <a href={menu.url} className="nav-link active">{menu.title}</a>
                                                    </li>
                                                )
                                            )
                                        }): '' 
                                    }                          
                                </ul>
                                <div className="mobile-head-top-btn">
                                    {
                                        isLogin ? (loginType === "Lawyer" ? <a href="/dashboard/" rel="nofollow">Dashboard</a> : <a href="/admin-dashboard/" rel="nofollow">Dashboard</a>) : (<>
                                            <Link to="/sign-in/" rel="nofollow">Attorney login</Link>
                                            <Link to="/signup/" rel="nofollow">Get listed</Link>
                                        </>)
                                    }
                                </div>
                            </div>
                        )
                    }
                    
                    <div className="head-top-btn">
                        {isLogin ? (loginType === "Lawyer" ? <a href="/dashboard/" rel="nofollow">Dashboard</a> : <a href="/admin-dashboard/" rel="nofollow">Dashboard</a>) : (<><Link to="/sign-in/" rel="nofollow">Attorney login</Link><Link to="/signup/" rel="nofollow">Get listed</Link></>)}
                    </div>
                </div>
            </nav>
        </header>
    )
}

export default Header