import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import SweetAlert from 'react-bootstrap-sweetalert';
import moment from "moment";
import axios from 'axios';
import Admindashboardfooter from "../components/Admindashboardfooter";
import $ from 'jquery';
import { Pagination } from "../../helper/commonHelper"
import DataTable from "../../helper/DataTable"

function Lawyerlist() {
    const [modalShow, setModalShow] = React.useState(false);
    const  REACT_APP_LIVE_BACKEND_BASE_URL  = process.env.REACT_APP_LIVE_BACKEND_BASE_URL;
    const [data, getData ] = useState([]);
    const [srNo, setSrNo ] = useState(1);
    const [pageLinks, setPageLinks] = useState([]);
    const [currentPageNumber, setCurrentPage] = useState(1);
    const [lastPageNumber, setLastPage] = useState("");
    const [sortBy, getSortBy ] = useState('');
    const [search, getSearch ] = useState('');
    const [checkedBoxes, setCheckedBoxes] =  useState([]);
    const [Alert, setAlert] = useState(null);
    const [isLoader, setLoader] = useState(true)
        
    useEffect(() => {
        document.title = 'Lawyer List'
        fetchData()
    }, [])

    const fetchData = (sortKey='', searchKey='') => {
        if(sortKey == ''){
            sortKey = sortBy
        }

        if(searchKey == ''){
            searchKey = search
        }

        const URL = REACT_APP_LIVE_BACKEND_BASE_URL+'api/get-lawyer-list?sort_by='+sortKey+'&search='+searchKey;
        fetch(URL).then((res) =>res.json()).then((response) => {
            setSrNo(response.from)
            setLastPage(response.last_page)
            setCurrentPage(response.current_page)
            getData(response.data)
            setPageLinks(response.links)
            setLoader(false)
        })
    }

    const getSearchFilter = (e) => {
        const searchVal = e.target.value;
        getSearch(searchVal)
        fetchData('', searchVal)
    }

    const getSortByFilter = (e) => {
        const sortVal = e.target.value;
        getSortBy(sortVal)
        fetchData(sortVal)
    }

    const allCheck = (e) => {
        const allCheckItems = document.getElementsByClassName('check-item');
        if(e.target.checked){
            let arr = checkedBoxes;
            for(let i = 0; i < allCheckItems.length; i++){
                arr.push(allCheckItems[i].value);
                allCheckItems[i].checked = true;
            }

            setCheckedBoxes(arr);
        }else{
            for(let i = 0; i < allCheckItems.length; i++){
                allCheckItems[i].checked = false;
            }
            setCheckedBoxes([]);
        }
    }

    const toggleCheckbox = (e, item) => {		
        if(e.target.checked) {
            let arr = checkedBoxes;
            arr.push(item.id);
            setCheckedBoxes(arr);
        } else {			
            let items = checkedBoxes.splice(checkedBoxes.indexOf(item.id), 1);
            setCheckedBoxes(items);
        }
    }		

    const applyBulkAction = async () => {
        const action = document.getElementById("bulk-actions").value;

        if(action === "Export"){
            if(checkedBoxes.length >= 1){
                handleExportData();
            }else{
                setAlert((
                    <SweetAlert danger title="Oops!" onConfirm={() => {
                        setAlert(null)
                    }}>
                        Please select atlest one lawyer
                    </SweetAlert>
                ));
            }
            
        }else if(action === "Delete"){
            setAlert((
                <SweetAlert title="Are you sure?" type="warning" showCancel={true} confirmBtnText="Yes" cancelBtnText="Cancel" cancelBtnBsStyle="danger" onConfirm={() => {
                    handleMultipleDelete()
                    setAlert(null)
                }} onCancel={()=> setAlert(null)}>You want to delete!</SweetAlert>
            ))
        }else{
            setAlert((
                <SweetAlert danger title="Oops!" onConfirm={() => { setAlert(null)}}>Please select action</SweetAlert>
            ));
        }
    }

    const handleMultipleDelete = () => {
        axios({
            method: "post",
            url: REACT_APP_LIVE_BACKEND_BASE_URL+"api/delete-lawyers",
            data: {id: checkedBoxes}
        }).then((response)=>{
            const data = response.data;
            setAlert((
                <SweetAlert success title="Deleted!" onConfirm={() => {
                    window.location.reload()
                }}>
                    {data.message}
                </SweetAlert>
            ));
        }).catch((error) => {
            const errors = error.response.data;
            setAlert((
                <SweetAlert danger title="Oops!" onConfirm={() => { setAlert(null) }}>
                    {errors.message}
                </SweetAlert>
            ))
        });
    }

    const handleExportData = () => {
        const url = REACT_APP_LIVE_BACKEND_BASE_URL+"lawyer/export-data?id="+checkedBoxes.toString();
        window.location.href = url;
    }

    const handleClick = (e) => {
        e.preventDefault();
        var next = e.currentTarget.getAttribute('nextpage');
        if(next != null){
            next = `${next}&sort_by=${sortBy}&search=${search}`;
            fetch(next).then((res) =>res.json()).then((response) => {
                setSrNo(response.from);
                setLastPage(response.last_page);
                setCurrentPage(response.current_page);
                getData(response.data);
                setPageLinks(response.links);
            });

            $(window).scrollTop(0)
        }
    }

    const lawyerAccountPaidUnpaid = (e, id) => {
        e.preventDefault();
        const buttonText = e.target.innerHTML;
        e.target.innerHTML = "<i class='fas fa-spinner fa-spin'></i>";
        axios({
            method: "get",
            url: REACT_APP_LIVE_BACKEND_BASE_URL+"api/lawyer-account-paid-unpaid/"+id,
            data: {id: checkedBoxes}
        }).then((response)=>{
            e.target.innerHTML = buttonText;
            const data = response.data;
            setAlert((
                <SweetAlert 
                success 
                title="Good Job!"
                onConfirm={window.location.reload()}
                >
                    {data.message}
                </SweetAlert>
            ));
        }).catch((error) => {
            e.target.innerHTML = buttonText;
            const errors = error.response.data;
            setAlert((
                <SweetAlert
                danger 
                title="Oops!"
                onConfirm={() => { setAlert(null)}}
                >
                    {errors.message}
                </SweetAlert>
            ))
        });
    }

    const deleteAccount = (e, id) => {
        e.preventDefault();
        const url = REACT_APP_LIVE_BACKEND_BASE_URL+"api/delete-lawyer/"+id;
        setAlert(
            <SweetAlert
                warning
                showCancel
                confirmBtnText="Yes, delete it!"
                confirmBtnBsStyle="danger"
                cancelBtnText="No, cancel"
                cancelBtnBsStyle="success"
                title="Are you sure?"
                onConfirm={() => deleteItem(url)}
                onCancel={() => setAlert(null)}
            >
                You won't be able to revert this!
            </SweetAlert>
        );
    }

    const deleteItem = async (url) => {
        setAlert(null)
        await axios({ method: "delete", url: url}).then((response) => {   
            fetchData()
            setAlert((
                <SweetAlert success title="Success" onConfirm={() => { setAlert(null)}}>{response.message}</SweetAlert>
            ))
        }).catch((error) => {
            const errors = error.response.data
            setAlert((
                <SweetAlert danger title="Oops!" onConfirm={() => {setAlert(null)}}>{errors.message}</SweetAlert>
            ))
        });
    }

    return (
        <>
            <div id="content" className="main-content">
                <div className="bg-custom">
                    <div className="main-profile-sec">
                        <div className="profile-sec">
                            <div className="inner-guest-posts">
                                <div className="inner-profile-sec">
                                    <div className="row align-items-center">
                                        <div className="col-md-12">
                                            <div className="heading-title c-text-small">
                                                <p>Lawyer Profile List</p>
                                            </div>
                                        </div>

                                        <div className="col-sm-12 col-md-12 col-xl-10">
                                            <div className="row">
                                                <div className="col-8 col-sm-10 col-md-3">
                                                    <div className="form-group posts-title text-left mb-0 p-0">
                                                        <select className="form-select" id="bulk-actions">
                                                            <option value="">Bulk actions</option>
                                                            <option value="Delete">Delete</option>
                                                            <option value="Export">Export</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-4 col-sm-2 col-md-2">
                                                    <div className="add-more-btn mt-0">
                                                        <button type="button" onClick={() => applyBulkAction()} className="btn btn-custom">Apply</button>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-3">
                                                    <div className="form-group posts-title text-left mb-0 p-0 m-spacing">
                                                        <select onChange={(e) => getSortByFilter(e)} className="form-select" aria-label="Default select example">
                                                            <option value="" selected>Sort By</option>
                                                            <option value="city">City</option>
                                                            <option value="state">State</option>
                                                            <option value="live">Live</option>
                                                            <option value="inactive">Inactive</option>
                                                            <option value="show_badge">Badge</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-4">
                                                    <div className="form-group posts-title text-right mb-0 p-0">
                                                        <input type="text" onKeyUp={getSearchFilter} name="search" placeholder="Search..."  />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-sm-12 col-md-12 col-xl-2">
                                            <div className="add-more-btn text-right">
                                                <Link to="/add-lawyer/"> <i className="fa-solid fa-plus"></i> Add lawyer Profile </Link>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="data-table-sec table-responsive admin-data-table">

                                        <table id="example" className="display" cellspacing="0" width="100%">
                                            <thead>
                                                <tr>
                                                    <th><input type="checkbox" className="all-check" onClick={(e) => allCheck(e)} /></th>
                                                    <th>#</th>
                                                    <th>Name</th>
                                                    <th>Law Firm Name</th>
                                                    <th>City</th>
                                                    <th>State</th>
                                                    <th>Phone</th>
                                                    <th>Email</th>
                                                    <th>Website</th>
                                                    <th>Status</th>
                                                    <th>Badge</th>
                                                    <th>Renew</th>
                                                    <th className="text-center">Actions</th>                                                     
                                                </tr>
                                            </thead>

                                            <tbody>
                                            {isLoader ? <DataTable /> : data.map((item, i) => (
                                                <tr key={`row-list-${i}`}>
                                                    <td>
                                                        <input type="checkbox" value={item.id} className="check-item" checked={checkedBoxes.find((p) => p.id === item.id)} onClick={(e) => toggleCheckbox(e, item)} />
                                                    </td>
                                                    <td>{srNo + i}</td>
                                                    <td>{item.name} <span className="add-on-text">LoremIpsumissimply</span></td>
                                                    <td>{item.firmname} <span className="add-on-text">LoremIpsumissimplydummytextofthe</span></td>
                                                    <td>{item.cname}</td>
                                                    <td>{item.sname}</td>
                                                    <td>{item.office_phone}</td>
                                                    <td>{item.email}</td>
                                                    <td>{item.website}</td>
                                                    <td> {item.subscription_status === 'Active' ? (item.subscription_by == 2 ? "Live Paid" : "Live Admin" ) : 'Inactive'} </td>
                                                    <td>{item.show_badge ? <span class='badge-show'></span> : <span>N/A</span> }</td>
                                                    <td>{item.sub_end_date ? moment(item.sub_end_date).format("DD-MM-YYYY") : "N/A"} <span className="add-on-text">adddatehere</span></td>
                                                    <td className="action-btn both-action-btn text-right d-flex justify-content-end">
                                                        <Link to={"/admin-lawyer-profile/"+item.id+"/"} className="edit-btn" title="View Profile"> <i className="fa-solid fa-eye"></i></Link>
                                                        <Link to="#" onClick={(e) => {
                                                            e.preventDefault();
                                                            window.open('lawyer/'+item.slug+"/", '_blank')
                                                        }} className="edit-btn" title="Live Profile"><i className="fa-solid fa-arrow-right"></i></Link>
                                                        <Link to={"/edit-lawyer-profile/"+item.id+"/"} className="edit-btn" title="Edit Profile"> <i className="fas fa-edit"></i></Link>
                                                        <Link className="delete-btn" to="/" onClick={(e) => deleteAccount(e, item.id)} title="Delete Lawyer"><i className="fa-solid fa-trash"></i></Link>
                                                        <Link className="edit-btn" to="/" onClick={(e) => lawyerAccountPaidUnpaid(e, item.id)} title={item.subscription_status === 'Active' ? "Cancel Paid Account" : "Make Paid Account"}>
                                                        {item.subscription_status === 'Active' ? <i className="fa-solid fa-toggle-on"></i> : <i className="fa-solid fa-toggle-off"></i>}</Link>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>

                                        </table>
                                    </div>

                                    <div className="lawyer-profile-pagination pagination-custom my-4">
                                        <Pagination page={pageLinks} lastPage={lastPageNumber} currentPage={currentPageNumber} handleClick={handleClick} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Admindashboardfooter />
                {Alert}
            </div>
            <ApprovedModal show={modalShow} onHide={() => setModalShow(false)} />
        </>
    )
}

export default Lawyerlist

function ApprovedModal(props) {
    return (
        <div className="profile-list-approve-modal">
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centeredl
            >
                <Modal.Header className="modal-header" closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h5 className="modal-title" id="exampleModalLabel">Approve</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="approve-list-content">
                        <div className="row">

                            <div className="col-md-6">
                                <div className="al-content">
                                    <p> <span>Name :</span> Adam Rosengard </p>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="al-content">
                                    <p> <span>Email :</span> abc@gmail.com </p>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="al-content">
                                    <p> <span>Payment Status :</span> Paid </p>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="al-content">
                                    <p> <span>Payment ID :</span> 123456 </p>
                                </div>
                            </div>

                        </div>

                        <div className="approve-switch-brn">
                            <Button type="button" className="btn btn-toggle" data-toggle="button" aria-pressed="false"
                                autocomplete="off">
                                <div className="handle"></div>
                            </Button>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button>Save</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}