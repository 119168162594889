import React, {useState, useEffect} from 'react'
import { useParams, Link, useHistory } from 'react-router-dom'
import { Helmet } from "react-helmet"
import { Instagram, Code } from 'react-content-loader'

function LawyerCities() {
    let { injury, state } = useParams()
    const  REACT_APP_LIVE_BACKEND_BASE_URL  = process.env.REACT_APP_LIVE_BACKEND_BASE_URL;
    const [cities, setCities] = useState([])
    const [stateData, setStateData] = useState([])
    const [metaTitle, setMetaTitle] = useState("Best Lawyers In USA")
    const [metaDescription, setMetaDescription] = useState("Top Rated Lawyers in USA! Consult Now for free")
    const [practiceArea, setPracticeArea] = useState([])
    const [isLoader, setLoader] = useState(true)
    const [isTextLoader, setTextLoader ] = useState(true)
    let history = useHistory()

    useEffect(() => {
        fetchPracticeArea()
        fetchCities()
    }, [])

    const fetchPracticeArea = () => {
        fetch(REACT_APP_LIVE_BACKEND_BASE_URL+"api/lawyer-practice-area-slug/"+injury).then((res) => res.json()).then((response) => {
            if(Object.keys(response).length == 0) {
                history.push(`/`)
            }
            fetchState(response)
            setPracticeArea(response)
        })
    }
    
    const fetchCities = () => {
        fetch(REACT_APP_LIVE_BACKEND_BASE_URL+"api/get-front-ccity/"+state).then((res) => res.json()).then((response) => {
            setCities(response)
        })
    }

    const fetchState = (practiceArea) => {
        fetch(REACT_APP_LIVE_BACKEND_BASE_URL+"api/get-state/"+state).then((res) => res.json()).then((response) => {
            if(Object.keys(response).length == 0) {
                history.push(`/`)
            }

            let title = response.meta_title || "Best {practice_area} Lawyers in {state_name} | "+process.env.REACT_APP_NAME
            let description = response.meta_description || "Connect with experienced {practice_area} attorneys in {state_name} based on peer & client reviews. Secure expert legal assistance and the compensation you need."
            title = title.replace("{practice_area}", practiceArea.name).replace("{state_name}", response.name)
            description = description.replace("{practice_area}", practiceArea.name).replace("{state_name}", response.name)
            setStateData(response)
            setMetaTitle(title)
            setMetaDescription(description)
            setLoader(false)
            setTextLoader(false)
        })
    }

    return (
        <div>
            <Helmet>
                <title>{metaTitle}</title>
                <meta name="description" content={metaDescription} />
                <link rel="canonical" href={window.location.href.toLowerCase()} />
            </Helmet>      
            <section className="pages-breadcrumb mt-3">
                <div className="container">
                    {
                        isTextLoader ? <Code /> : (
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href={process.env.REACT_APP_BASE_URL} rel="noopener noreferrer">Home</a></li>
                                    <li className="breadcrumb-item"><Link to="/">Find a Lawyer</Link></li>
                                    <li className="breadcrumb-item" aria-current="page"><Link to={`/../${practiceArea?.slug}/`}>{ practiceArea?.name }</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">{ stateData?.name }</li>
                                </ol>
                            </nav>
                        )
                    }
                    
                </div>
            </section>
            <section className="f-lawyer-city pb-5">
                <div className="container">
                    {
                        isLoader ? (<Instagram />) : (
                            <div className="inner-f-lawyer-city">
                                <h1 className="page-title" style={{"text-transform": "capitalize"}}>Best {practiceArea?.name} Lawyers in {stateData?.name}</h1>
                                <p className="small-title">Please click the city below to find local {stateData?.name} { practiceArea?.name } lawyers.</p>
                                <div className="title mb-3">
                                    <h2 className="small-title">Popular Cities In <span style={{"text-transform": "capitalize"}}>{stateData?.name}</span></h2>
                                </div>
                                {Object.keys(cities).map((item, i) => {
                                    return (<div className="city-list-content mb-4">
                                        <div className="alpha-content">
                                            <h5>{item}</h5>
                                        </div>
                                        <ul className="city-list">
                                            {cities[item].map((items,j) => {
                                                return(<li><Link to={`${items.slug}/`} rel="noopener noreferrer">{items.name}</Link> </li>)
                                            })}
                                        </ul>
                                    </div>)
                                })}
                            </div>
                        )
                    }
                </div>
            </section>
        </div>  
    )
}
export { LawyerCities } 