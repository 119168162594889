import React, {useEffect} from "react";
import { Link } from "react-router-dom";
import Admindashboardheader from "../components/Admindashboardheader"
import Admindashboardsidebar from "../components/Admindashboardsidebar"
import Admindashboardfooter from "../components/Admindashboardfooter"

function Accountmanagementlist() {
    useEffect(() => {
        document.title = 'Account management list';
    }, [])
    return (
        <>
            <Admindashboardheader />
            <div className="wrapper d-flex align-items-stretch">
                <Admindashboardsidebar />
                <div id="content" className="main-content">

                    <div className="bg-custom">

                        <div className="main-profile-sec">
                            <div className="profile-sec">
                                <div className="inner-guest-posts">

                                    <div className="inner-profile-sec">

                                        <div className="row align-items-center">

                                            <div className="col-md-6">
                                                <div className="heading-title c-text-small">
                                                    <p>Package List</p>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group posts-title text-right p-0">
                                                    <input type="text" name="search" placeholder="Search..." className="" id="" />
                                                </div>
                                            </div>

                                        </div>

                                        <div className="add-more-btn text-right">
                                            <Link to="/add-package"> <i className="fa-solid fa-plus"></i> Add Packages </Link>
                                        </div>

                                        <div className="data-table-sec table-responsive admin-data-table">

                                            <table id="example" className="display" cellspacing="0" width="100%">
                                                <thead>
                                                    <tr>
                                                        <th>Package Name</th>
                                                        <th style={{ width: "45%" }}>Package Description</th>
                                                        <th>Package Price</th>
                                                        <th className="text-right" style={{ width: "16%" }}>Actions</th>

                                                    </tr>
                                                </thead>

                                                <tbody>

                                                    <tr>
                                                        <td> Upgrade to a premium listing </td>
                                                        <td> they can pay an additional $100 to be at the top of the page </td>
                                                        <td> $ 100 </td>
                                                        <td className="c-edit-btn text-right">
                                                            <Link to="#" className="edit-btn"> <i className="fa-regular fa-pen-to-square"></i> edit </Link>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td> Add an office </td>
                                                        <td> they can add another location and pay $100 to make it live Connect with other attorneys in your firm </td>
                                                        <td> $ 100 </td>
                                                        <td className="c-edit-btn text-right">
                                                            <Link to="#" className="edit-btn"> <i className="fa-regular fa-pen-to-square"></i> edit </Link>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td> Update billing </td>
                                                        <td> they can change credit card, or cancel the subscription </td>
                                                        <td> $ 100 </td>
                                                        <td className="c-edit-btn text-right">
                                                            <Link to="#" className="edit-btn"> <i className="fa-regular fa-pen-to-square"></i> edit </Link>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td> Publish an article </td>
                                                        <td> this goes to a page with a contact form. I’ll send the required fields later. </td>
                                                        <td> $ 100 </td>
                                                        <td className="c-edit-btn text-right">
                                                            <Link to="#" className="edit-btn"> <i className="fa-regular fa-pen-to-square"></i> edit </Link>
                                                        </td>
                                                    </tr>

                                                </tbody>

                                            </table>
                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>
                    <Admindashboardfooter />

                </div>
            </div>

            {/* <!-- Modal --> */}
            <div className="profile-list-approve-modal">
                <div className="modal fade" id="profileListApprove" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                    aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Approve</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="approve-list-content">
                                    <div className="row">

                                        <div className="col-md-6">
                                            <div className="al-content">
                                                <p> <span>Name :</span> Adam Rosengard </p>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="al-content">
                                                <p> <span>Email :</span> abc@gmail.com </p>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="al-content">
                                                <p> <span>Payment Status :</span> Paid </p>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="al-content">
                                                <p> <span>Payment ID :</span> 123456 </p>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="approve-switch-brn">
                                        <button type="button" className="btn btn-toggle" data-toggle="button" aria-pressed="false"
                                            autocomplete="off">
                                            <div className="handle"></div>
                                        </button>
                                    </div>

                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn">Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Accountmanagementlist