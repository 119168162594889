import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import Cookies from 'universal-cookie'
import $ from 'jquery'
import axios from 'axios'
import ContentLoader from 'react-content-loader'
import LowercaseRedirect from '../../components/LowercaseRedirect'
import { Helmet } from "react-helmet"

function Lawyerheader() {

    const  REACT_APP_LIVE_BACKEND_BASE_URL  = process.env.REACT_APP_LIVE_BACKEND_BASE_URL;
    const [userName, setUserName] = useState('');
    const [userLogo, setUserLogo] = useState('');
    const [profilePhoto, setProfilePhoto] = useState('');
    const [isLoader, setIsLoader] = useState(true);
    const [lawyerId, setLawyerId] = useState();
    const [lawyerSlug, setLawyerSlug] = useState('#');
    const [subscriptionStatus, setSubscriptionStatus] = useState(false);
    const cookies = new Cookies(); 
    var lawyertocken = cookies.get('LAWYER');
    const LAWYERURL = REACT_APP_LIVE_BACKEND_BASE_URL+'api/get-admin-data-lawyer/'+lawyertocken;
    
    const fetchlawyerdata = () => {
        fetch(LAWYERURL)
            .then((res) => res.json())
            .then((response) => {
                if(response.username){
                    if (response.username.length > 10) {
                        const shortenedName = response.username.slice(0, 10) + "...";
                        setUserName(shortenedName);
                    } else {
                        setUserName(response.username);
                    }
                    
                    setUserLogo(response.userdata.website_logo);
                    setSubscriptionStatus(response.subscription_status);
                    setLawyerId(response.lawyer_id);
                    setLawyerSlug(response.slug);
                    setProfilePhoto(response.profile_photo);
                    setIsLoader(false);
                }else{
                    window.location.assign('/logout/');
                }
            })
    }

    const MyLoader = () => (
        <ContentLoader speed={3} width={200} height={80}>
            <rect x="80" y="20" rx="3" ry="3" width="88" height="10" /> 
            <rect x="80" y="40" rx="3" ry="3" width="88" height="10" /> 
            <circle cx="30" cy="30" r="30" />
        </ContentLoader>
    );

    const profileLivePlan = async (e) => {
        e.preventDefault();
        await axios({
            method: "GET",
            url: `${REACT_APP_LIVE_BACKEND_BASE_URL}api/profile-live-plan/${lawyerId}`,
            headers: { 'Content-Type' : 'application/json' },
        }).then((response) => {
            window.location.href = response.data.redirecturl;
        }).catch((error) => {
            console.log(error)
        });
    }

    useEffect(() => {
        fetchlawyerdata()
        $('#sidebarCollapse').on('click', function () {
            $('#sidebar').toggleClass('active')
        })
    }, [])

    const toggleMenu = (e) => {
        e.preventDefault()
        $(".show-head-top-menu").toggle(200)
    }

    return (
        <header className="header-main lawyer-header-main">
            <Helmet>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <LowercaseRedirect />
            <div className="container-fluid">
                <div className="inner-header-main">
                    <div className="row align-items-center">
                        <div className="col-4 col-sm-6 col-md-3 col-lg-2">
                            {
                                isLoader ? <MyLoader /> : 
                                (
                                    <div className="brand-logo ">
                                        <a href={`${process.env.REACT_APP_BASE_URL}/`}>
                                            <img src={userLogo} alt="Server The Injured" />
                                        </a>
                                    </div>
                                )
                            }
                        </div>
                        <div className="col-8 col-sm-6 col-md-9 col-lg-10">
                            <nav>
                                <ul className="nav-list">
                                    <li className="acc-btn acc-web-btn">
                                        {
                                            subscriptionStatus ? (
                                                <a href={'/lawyer/'+lawyerSlug+'/'}>See Live Profile</a>
                                            ) : (
                                                <a href='#' onClick={(e) => profileLivePlan(e)}>Your profile is currently hidden. Click here to Go Live</a>
                                            )
                                        }
                                        
                                    </li>

                                    <li className="nav-item dropdown icon-list profile-img">
                                        <button className="nav-link dropdown-toggle" onClick={toggleMenu} id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <img src={profilePhoto} alt={userName} /> <span className="profile-name">{userName}</span>
                                        </button>
                                        <span className="dropdown-menu top-dropdown-menu show-head-top-menu" aria-labelledby="navbarDropdown">
                                            <Link className="dropdown-item dashboard-bk-icon" to="/dashboard/">Dashboard</Link>
                                            <Link className="dropdown-item editprofile-bk-icon" to="/edit-profile/">Edit Profile</Link>
                                            <Link className="dropdown-item viewprofile-bk-icon" to="/view-profile/">View Profile</Link>
                                            <Link className="dropdown-item getbadge-bk-icon" to="/get-your-badge/">Get Your Badge</Link>
                                            <a className="dropdown-item seocourse-bk-icon" href={`${process.env.REACT_APP_BASE_URL}/injury-lawyer-seo/course/`}>Injury Law SEO Course</a>
                                            <Link className="dropdown-item contactus-bk-icon" to="/contact-us/">Contact Us</Link>
                                            <Link className="dropdown-item logout-bk-icon" to="/logout/">Logout</Link>
                                        </span>
                                    </li>

                                    <li className="mobile-sidemenu-btn">
                                        <button type="button" id="sidebarCollapse" class="btn btn-primary">
                                            <i class="fa fa-bars"></i>
                                            <span class="sr-only">Toggle Menu</span>
                                        </button>
                                    </li>
                                </ul>
                            </nav>
                        </div>

                        <div className="col-12 col-sm-12 col-md-9 col-lg-10 acc-mob-btn">
                            <nav>
                                <ul className="nav-list inner-acc-mob-btn">
                                    <li className="acc-btn">
                                        {
                                            subscriptionStatus ? (
                                                <a href={'/lawyer/'+lawyerSlug+'/'}>See Live Profile</a>
                                            ) : (
                                                <a href='#' onClick={(e) => profileLivePlan(e)}>Your profile is currently hidden. Click here to Go Live</a>
                                            )
                                        }
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Lawyerheader