import React, {useEffect} from "react";
import Admindashboardheader from "../components/Admindashboardheader"
import Admindashboardsidebar from "../components/Admindashboardsidebar"
import Admindashboardfooter from "../components/Admindashboardfooter"

function Addblog() {
    useEffect(() => {
        document.title = 'Add Blog';
    }, [])

    return (
        <>
            <Admindashboardheader />
            <div className="wrapper d-flex align-items-stretch">
                <Admindashboardsidebar />
                <div id="content" className="main-content">

                    <div className="bg-custom">

                        <div className="main-profile-sec">
                            <form>
                                <div className="profile-sec">
                                    <div className="inner-guest-posts">

                                        <div className="inner-profile-sec">
                                            <div className="heading-title c-text-small">
                                                <p>Add Blogs</p>
                                            </div>

                                            <div className="profile-edit-sec">
                                                <div className="row">

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Title</label>
                                                            <input type="text" name="" id="" className="form-control"/>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Description</label>
                                                            <textarea name="" id=""></textarea>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <div className="form-check-categories">
                                                            <label> Select Categories </label>
                                                            <div className="row">

                                                                <div className="col-md-3">
                                                                    <div className="form-categories-list">
                                                                        <label> <input type="checkbox" name="" id=""/> Personal Injury </label>
                                                                    </div>

                                                                    <div className="form-categories-list">
                                                                        <label> <input type="checkbox" name="" id=""/> Car Accidents </label>
                                                                    </div>

                                                                    <div className="form-categories-list">
                                                                        <label> <input type="checkbox" name="" id=""/> Mesothelioma and Asbestos </label>
                                                                    </div>

                                                                    <div className="form-categories-list">
                                                                        <label> <input type="checkbox" name="" id=""/> Slip and Fall Injuries </label>
                                                                    </div>

                                                                    <div className="form-categories-list">
                                                                        <label> <input type="checkbox" name="" id=""/> Wrongful Death </label>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-3">
                                                                    <div className="form-categories-list">
                                                                        <label> <input type="checkbox" name="" id=""/> Dog Bites </label>
                                                                    </div>

                                                                    <div className="form-categories-list">
                                                                        <label> <input type="checkbox" name="" id=""/> Construction Accidents </label>
                                                                    </div>

                                                                    <div className="form-categories-list">
                                                                        <label> <input type="checkbox" name="" id=""/> Motorcycle Accidents </label>
                                                                    </div>

                                                                    <div className="form-categories-list">
                                                                        <label> <input type="checkbox" name="" id=""/> Spinal Cord Injury </label>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-3">
                                                                    <div className="form-categories-list">
                                                                        <label> <input type="checkbox" name="" id=""/> Birth Injury </label>
                                                                    </div>

                                                                    <div className="form-categories-list">
                                                                        <label> <input type="checkbox" name="" id=""/> Defective Products </label>
                                                                    </div>

                                                                    <div className="form-categories-list">
                                                                        <label> <input type="checkbox" name="" id=""/> Nursing Home Abuse </label>
                                                                    </div>

                                                                    <div className="form-categories-list">
                                                                        <label> <input type="checkbox" name="" id=""/> Truck Accidents </label>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-3">
                                                                    <div className="form-categories-list">
                                                                        <label> <input type="checkbox" name="" id=""/> Brain injury </label>
                                                                    </div>

                                                                    <div className="form-categories-list">
                                                                        <label> <input type="checkbox" name="" id=""/> Medical Malpractice </label>
                                                                    </div>

                                                                    <div className="form-categories-list">
                                                                        <label> <input type="checkbox" name="" id=""/> Premises Liability </label>
                                                                    </div>

                                                                    <div className="form-categories-list">
                                                                        <label> <input type="checkbox" name="" id=""/> Work Accidents </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="profile-img-sec">
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/dummy-image.jpg`} />
                                                                <div className="upload-img-btn">
                                                                    <input type="file"/>
                                                                        <span>Blog Image</span>
                                                                </div>
                                                                <div className="delete-img-btn">
                                                                    <button type="button">Delete Image</button>
                                                                </div>
                                                        </div>
                                                    </div>

                                                </div>

                                                <div className="save-btn text-right p-0">
                                                    <button type="button" className="mt-2">Save</button>
                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </form>

                        </div>

                    </div>
                    <Admindashboardfooter />

                </div>
            </div>
        </>
    )
}

export default Addblog