import React, {useState, useEffect} from "react";
import Admindashboardfooter from "../components/Admindashboardfooter"
import axios from 'axios';
import SweetAlert from 'react-bootstrap-sweetalert'

function HomePage() {
    const [Alert, setAlert] = useState(null);
    const [homeData, setHomeData] = useState([]);
    const  REACT_APP_LIVE_BACKEND_BASE_URL  = `${process.env.REACT_APP_LIVE_BACKEND_BASE_URL}api/home-page-discription`;

    useEffect(() => {        
        document.title = 'Home Page'
        fetchHomePage()
    }, [])

    const DvInnerHTML = function (target, newText) {
        document.querySelectorAll(target).forEach(function (element) {
            element.textContent = newText;
        });
    }

    const fetchHomePage = () => {
        fetch(REACT_APP_LIVE_BACKEND_BASE_URL).then((res) => res.json()).then((response) => {
            setHomeData(response.data)
        })
    }

    const HomePageSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios({
                method: "post",
                url: REACT_APP_LIVE_BACKEND_BASE_URL,
                data: new FormData(e.target),
                headers: { "Content-Type": "multipart/form-data" },
            }).then((response) => {
                const data = response.data;
                DvInnerHTML('.text-danger', '');
                setAlert((<SweetAlert success title="" onConfirm={() => {
                    setAlert(null)
                }} >{data.message}</SweetAlert>))
            }).catch((response) => {
                const data = response.response.data
                const errors = data.errors
                DvInnerHTML('.text-danger', '');
                if (parseInt(Object.keys(errors).length) > 0) {
                    for (var key in errors) {
                        if (errors.hasOwnProperty(key)) {
                            if (document.getElementById(key + '-error') === null) {
                            } else {
                                document.getElementById(key + '-error').innerHTML = errors[key];
                            }
                        }
                    }
                } else {
                    setAlert((<SweetAlert danger title="" onConfirm={() => {
                        setAlert(null)
                    }} >{data.message}</SweetAlert>))
                }
            });
        } catch (response) {
            console.log(response)
        }
    }

    return (
        <div id="content" className="main-content">
            <div className="bg-custom">
                <div className="main-profile-sec">
                    <form onSubmit={HomePageSubmit} encType="multipart/form-data">
                        <div className="profile-sec">
                            <div className="inner-guest-posts">
                                <div className="inner-profile-sec">
                                    <div className="heading-title c-text-small">
                                        <p>Home Page</p>
                                    </div>

                                    <div className="profile-edit-sec">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <div className="row">
                                                        <label className="col-2">Title</label>
                                                        <div className="col-10">
                                                            <input type="text" name="title" id="title" className="form-control" defaultValue={homeData.title} />
                                                            <p class="text-danger" id="title-error"></p>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label className="col-2">Meta Description</label>
                                                        <div className="col-10">
                                                            <textarea name="meta_description" rows="5" cols="10" id="meta_description" className="form-control" defaultValue={homeData.meta_description}></textarea>
                                                            <p class="text-danger" id="meta_description-error"></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="save-btn text-right p-0">
                                            <button type="submit" className="mt-2">Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {Alert}
            <Admindashboardfooter />
        </div>
    )
}

export default HomePage