import React, {useEffect, useState} from "react";
import Admindashboardfooter from "../components/Admindashboardfooter"
import { useParams, useHistory } from "react-router-dom"
import axios from 'axios'
import SweetAlert from 'react-bootstrap-sweetalert'

function EditStateCity() {
    const DvInnerHTML = function (target, newText) {
        document.querySelectorAll(target).forEach(function (element) {
            element.textContent = newText;
        });
    }
    let {id} = useParams()
    const [Alert, setAlert] = useState(null)
    const  REACT_APP_LIVE_BACKEND_BASE_URL  = process.env.REACT_APP_LIVE_BACKEND_BASE_URL
    const spanText = "Use {practice_area} for add Practice Area dynamically"

    const [tags, setTags] = useState([])
    const [cityValue, setCityValue] = useState([])
    const [state, setState] = useState(null);
    const [city, setCity] = useState([]);
    let history = useHistory()
    
    useEffect(() => {
        document.title = 'Edit City'; 
        EditStateCity();
    },[])

    const EditStateCity = () => {
        fetch(REACT_APP_LIVE_BACKEND_BASE_URL+"api/edit-state-city/"+id).then((res) => res.json()).then((response) => {
            setState(response.state);
            setCity(response.city);
            {response.city.map((item, i) => {
                setCityValue([...cityValue, item.name]);
            })}
        })
    }

    function handleKeyDown(e){
        if(e.key !== 'Enter') return
        const value = e.target.value
        if(!value.trim()) return
        setTags([...tags, value])
        e.target.value = ''
    }

    function  removeTag (index) {
        setTags(tags.filter((el, i) => i !== index))
    }

    async function removeTagCity(index, e){
        var removeId = e.target.getAttribute("deleteid");
        if(removeId !== ''){
            try {
                await axios({
                    method: "post",
                    url: REACT_APP_LIVE_BACKEND_BASE_URL+"api/delete-selected-city",
                    data: {cityid:removeId},
                    headers: { "Content-Type": "multipart/form-data" },
                }).then((response) => {
                    const errors = response.data.errors;
                    if (response.data.status) {
                        setCity(city.filter((el, i) => i !== index))
                    } else {
                        DvInnerHTML('.text-danger', '');
                        if (parseInt(Object.keys(errors).length) > 0) {
                            for (var key in errors) {
                                if (errors.hasOwnProperty(key)) {
                                    if (document.getElementById(key + '-error') === null) {
                                    } else {
                                        document.getElementById(key + '-error').innerHTML = errors[key];
                                    }
                                }
                            }
                        } else {
                            setAlert((<SweetAlert danger title="" onConfirm={() => {
                                setAlert(null)
                            }} >{response.data.msg}</SweetAlert>))
                        }
                    }
                });
            } catch (response) {
                console.log(response)
            }
        }
    }


    const StateCityUpdate = async (e) => {
        e.preventDefault();
        
        try {
            await axios({
                method: "post",
                url: REACT_APP_LIVE_BACKEND_BASE_URL+"api/update-state-city/"+id,
                data: new FormData(document.getElementById('update-state-city')),
                headers: { "Content-Type": "multipart/form-data" },
            }).then((response) => {
                const errors = response.data.errors;
                if (response.data.status) {
                    DvInnerHTML('.text-danger', '');
                    
                    setAlert((<SweetAlert success title="" onConfirm={() => {
                        setAlert(null)
                        history.push('./../../state-city-list/')
                    }} >{response.data.msg}</SweetAlert>))
                } else {
                    DvInnerHTML('.text-danger', '');
                    if (parseInt(Object.keys(errors).length) > 0) {
                        for (var key in errors) {
                            if (errors.hasOwnProperty(key)) {
                                if (document.getElementById(key + '-error') === null) {
                                } else {
                                    document.getElementById(key + '-error').innerHTML = errors[key];
                                }
                            }
                        }
                    } else {
                        setAlert((<SweetAlert danger title="" onConfirm={() => {
                            setAlert(null)
                        }} >{response.data.msg}</SweetAlert>))
                    }
                }
            });
        } catch (response) {
            console.log(response)
        }
    }
        

    return (
        <div id="content" className="main-content">
            <div className="bg-custom">
                {state !== null ? (
                    <div className="main-profile-sec">
                        <form id="update-state-city">
                            <div className="profile-sec">
                                <div className="inner-guest-posts">
                                    <div className="inner-profile-sec">
                                        <div className="heading-title c-text-small">
                                            <p>Add State and their Cities</p>
                                        </div>
                                        <input type="hidden" name="cities" defaultValue={tags} />
                                        <input type="hidden" name="state_id" defaultValue={state !== null ? state.id : ''} />
                                        <div className="profile-edit-sec">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <div className="row">
                                                            <label className="col-2">State Name</label>
                                                            <div className="col-10">
                                                                <input type="text" defaultValue={state !== null ? state.name : '' } name="state_name" id="state_name" className="form-control" />
                                                            </div>
                                                            <p class="text-danger" id="state_name-error"></p>
                                                        </div>
                                                        <div className="row">
                                                            <label className="col-2">Meta Title</label>
                                                            <div className="col-10">
                                                                <input type="text" name="meta_title" id="meta_title" className="form-control mb-0" defaultValue={state.meta_title} />
                                                                <span className="small-text mb-2">{spanText}</span>
                                                                <p class="text-danger" id="meta_title-error"></p>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <label className="col-2">Meta Description</label>
                                                            <div className="col-10">
                                                                <textarea name="meta_description" rows="5" cols="10" id="meta_description" className="form-control mb-0" defaultValue={state.meta_description}></textarea>
                                                                <span className="small-text mb-2">{spanText}</span>
                                                                <p class="text-danger" id="meta_description-error"></p>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <label className="col-2">Description</label>
                                                            <div className="col-10">
                                                                <textarea name="state_description" defaultValue={state !== null ? state.description : ''} id="state_description"></textarea>
                                                            </div>
                                                            <p class="text-danger" id="state_description-error"></p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-checkbox-field">
                                                        <div className="categories-fileds">
                                                                <div className="row">
                                                                    <label htmlFor="input" className="col-2 col-form-label">Add Cities</label>
                                                                    <div className="col-10">
                                                                        {city.map((item, index) => {
                                                                            return(
                                                                                <>
                                                                            <div className="tag-item" key={index}>
                                                                                <span className="text">{item.name}</span>
                                                                                <span className="close" deleteid={item.id} onClick={ async (e) => removeTagCity(index, e)}>&times;</span>
                                                                            </div>
                                                                                </>
                                                                            )
                                                                        })}
                                                                    { tags.map((tag, index) => (
                                                                        <div className="tag-item" key={index}>
                                                                            <span className="text">{tag}</span>
                                                                            <span className="close"  onClick={() => removeTag(index)}>&times;</span>
                                                                        </div>
                                                                    )) }
                                                                        <input type="text" onKeyDown={handleKeyDown} name="input" className="form-control tags-input" placeholder="" />
                                                                        <small className="form-text text-muted">For new city press enter</small>
                                                                    </div>
                                                                    <p class="text-danger" id="cities-error"></p>
                                                                </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="save-btn text-right p-0">
                                                <button type="button" onClick={StateCityUpdate} className="mt-2">Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                ) : (<p>Something Went Wrong</p>)}
            </div>
            {Alert}
            <Admindashboardfooter />
        </div>
    )
}

export default EditStateCity