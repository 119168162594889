import React, {useState, useEffect} from 'react'
import axios from 'axios'
import Cookies from 'universal-cookie'
import Lawyerfooter from '../component/Lawyerfooter'
import Lawyersidebar from '../component/Lawyersidebar'
import SweetAlert from 'react-bootstrap-sweetalert'
import {useHistory} from 'react-router-dom'

function LawyerChangePassword() {
    useEffect(() => {
        document.title = 'Lawyer Change Password';
    }, []);
    const REACT_APP_LIVE_BACKEND_BASE_URL = process.env.REACT_APP_LIVE_BACKEND_BASE_URL;
    const URL = REACT_APP_LIVE_BACKEND_BASE_URL + 'api/lawyer/change-password';
    const cookies = new Cookies()
    let history = useHistory()
    const [Alert, setAlert] = useState(null);
    const [isDisabled, setIsDisabled] = useState(false);
    const [lawyerToken, setLawyerToken] = useState(cookies.get('LAWYER'));
    
    const DvInnerHTML = function (target, newText) {
        document.querySelectorAll(target).forEach(function (element) {
            element.textContent = newText
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsDisabled(true);
        DvInnerHTML('.text-danger', '');
        await axios({
            method: "post",
            url: URL,
            data: new FormData(e.target),
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            setIsDisabled(false)
            e.target.reset()
            setAlert((
                <SweetAlert success title="Success" onConfirm={() => {
                    setAlert(null)
                    history.push('./../logout/')                  
                }}>
                    {response.data.message}
                </SweetAlert>
            ))
        }).catch((error) => {
            setIsDisabled(false);
            const errors = error.response.data
            if(errors.message === undefined){
                Object.entries(errors.errors).forEach(([key, value]) => {
                    const elementId = key+"-error";
                    document.getElementById(elementId).innerHTML = value;
                })
            }else{
                setAlert((
                    <SweetAlert danger title="Oops!" onConfirm={() => {setAlert(null)}}>
                        {errors.message}
                    </SweetAlert>
                ))
            }
        });
    }
    
    return (
        <div className="wrapper d-flex align-items-stretch">
            <Lawyersidebar/>
            <div id="content" className="main-content">
                <div className="bg-custom py-4">
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <h3 className='mb-3'>Change Password</h3>
                                <form className='mt-3' onSubmit={(e) => handleSubmit(e)}>
                                    <div className='row'>
                                        <div className="col-md-12">
                                            <div className="form-group mb-0">
                                                <label>Old Password</label>
                                                <input type="password" name="old-password" placeholder='Old Password' className="form-control" />
                                                <input type="hidden" name="token" value={lawyerToken} />
                                                <p className="text-danger py-2 px-2" id='old-password-error'></p>
                                            </div>

                                            <div className="form-group mb-0">
                                                <label>New Password</label>
                                                <input type="password" name="new-password" placeholder='New Password' className="form-control" />
                                                <p className="text-danger py-2 px-2" id='new-password-error'></p>
                                            </div>

                                            <div className="form-group mb-0">
                                                <label>Confirm Password</label>
                                                <input type="password" name="confirm-password" placeholder='Confirm Password' className="form-control" />
                                                <p className="text-danger py-2 px-2" id='confirm-password-error'></p>
                                            </div>

                                            <div className="save-btn py-0 mb-2 text-start">
                                                <button type="submit" className='btn-submit' disabled={isDisabled}>{isDisabled ? "Please wait ..."  : "Submit"}</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                {Alert}
                <Lawyerfooter/>
            </div>
        </div>
    )

}

export default LawyerChangePassword
