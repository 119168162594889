import React, {useState, useEffect} from "react"
import Admindashboardfooter from "../components/Admindashboardfooter"
import axios from 'axios'
import SweetAlert from 'react-bootstrap-sweetalert'
import { useParams, useHistory } from "react-router-dom"

function UpdateSidebarBanner() {
    useEffect(() => {
        document.title = 'Sidebar Banner'; 
    }, [])
    const [Alert, setAlert] = useState(null);
    const [isDisabled, setDisabled] = useState(false)
    const [allBanner, setAllBanner] = useState([])
    let {id} = useParams()
    let history = useHistory()
    const REACT_APP_LIVE_BACKEND_BASE_URL = process.env.REACT_APP_LIVE_BACKEND_BASE_URL

    const DvInnerHTML = function (target, newText) {
        document.querySelectorAll(target).forEach(function (element) {
            element.textContent = newText;
        })
    }

    const bannerUpdate = async (event) => {
        event.preventDefault();
        setDisabled(true);
        const form = document.getElementById('update-banner');
        const data = new FormData(form);
        const URL = REACT_APP_LIVE_BACKEND_BASE_URL + 'api/sidebar-banner/'+id;
        await axios({
            method: "post",
            url: URL,
            data: data,
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            setDisabled(false);
            const message = response.message;
            DvInnerHTML('.text-danger', '');
            setAlert((
                <SweetAlert success title="Done!" onConfirm={() => {
                    setAlert(null)
                    history.push('./../../sidebar-banner/')
                }}>{message}</SweetAlert>
            ))
        }).catch((error) => {
            setDisabled(false)
            const errors = error.response.data.message
            DvInnerHTML('.text-danger', '')
            if (parseInt(Object.keys(errors).length) > 0) {
                for (var key in errors) {
                    if (errors.hasOwnProperty(key)) {
                        if (document.getElementById(key + '_error') === null) {} else {
                            document.getElementById(key + '_error').innerHTML = errors[key]
                        }
                    }
                }
            } else {
                setAlert((
                    <SweetAlert danger title="Oops!" onConfirm={() => {setAlert(null)}}>{error.message}</SweetAlert>
                ))
            }
        });
    }

    const getBadgeData = async () => {
        URL = REACT_APP_LIVE_BACKEND_BASE_URL+'api/sidebar-banner/'+id;
        await axios({
            method: "get",
            url: URL,
            headers: {
                "Content-Type": "application/json"
            }
        }).then((response) => {
            const data = response.data
            setAllBanner(data.data)
        }).catch((error) => {
            console.log(error)
        });
    }

    React.useEffect(() => {
        getBadgeData();
    }, []);

    return (
        <div id="content" className="main-content">
            <div className="bg-custom">
                <div className="main-profile-sec">
                    <form id="update-banner" onSubmit={bannerUpdate}>
                        <div className="profile-sec">
                            <div className="inner-guest-posts">
                                <div className="inner-profile-sec">
                                    <div className="heading-title c-text-small">
                                        <p>Update Banner</p>
                                    </div>
                                    <div className="profile-edit-sec">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Banner Image</label>
                                                    <input type="file" name="banner_image" className="form-control"/>
                                                    <span className="text-danger" id="banner_image_error"></span>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Status</label>
                                                    <select className="form-control" name="status" defaultValue={allBanner.status}>
                                                    <option value="1" selected={allBanner.status === 1 ? true : false}>Active</option>
                                                    <option value="0" selected={allBanner.status === 0 ? true : false}>Inactive</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Start Date</label>
                                                    <input type="date" name="start_date" defaultValue={allBanner.start_date} className="form-control"/>
                                                    <span className="text-danger" id="start_date_error"></span>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>End Date</label>
                                                    <input type="date" name="end_date" defaultValue={allBanner.end_date} className="form-control"/>
                                                    <span className="text-danger" id="end_date_error"></span>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>Url</label>
                                                    <input type="text" name="url" defaultValue={allBanner.url} className="form-control"/>
                                                    <span className="text-danger" id="url_error"></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="save-btn text-right p-0">
                                            <button disabled={isDisabled} type="submit" className="mt-2">Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {Alert}
            <Admindashboardfooter/>
        </div>
    )
}

export default UpdateSidebarBanner
