import React,{useEffect} from "react";
import Admindashboardheader from "../components/Admindashboardheader"
import Admindashboardsidebar from "../components/Admindashboardsidebar"
import Admindashboardfooter from "../components/Admindashboardfooter"

function Addcategories() {

    useEffect(() => {
        document.title = 'Add Categories';
    }, [])
    return (
        <>
            <Admindashboardheader />
            <div className="wrapper d-flex align-items-stretch">
                <Admindashboardsidebar />
                <div id="content" className="main-content">

                    <div className="bg-custom">

                        <div className="main-profile-sec">
                            <form>
                                <div className="profile-sec">
                                    <div className="inner-guest-posts">

                                        <div className="inner-profile-sec">
                                            <div className="heading-title c-text-small">
                                                <p>Add Categories</p>
                                            </div>

                                            <div className="profile-edit-sec">
                                                <div className="row">

                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <div className="row">
                                                                <label className="col-2">Name</label>
                                                                <div className="col-10">
                                                                    <input type="text" name="" id="" className="form-control"/>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <div className="row">
                                                                <label className="col-2">Slug</label>
                                                                <div className="col-10">
                                                                    <input type="text" name="" id="" className="form-control"/>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <div className="row">
                                                                <label className="col-2">Description</label>
                                                                <div className="col-10">
                                                                    <textarea name="" id=""></textarea>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                                <div className="save-btn text-right p-0">
                                                    <button type="button" className="mt-2">Save</button>
                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </form>

                        </div>

                    </div>
                    <Admindashboardfooter />

                </div>
            </div>
        </>
    )
}

export default Addcategories