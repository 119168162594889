import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom"
import Admindashboardfooter from "../components/Admindashboardfooter"
import SweetAlert from 'react-bootstrap-sweetalert'
import axios from 'axios'
import moment from "moment"
import DataTable from "../../helper/DataTable"

function SidebarBanner() {
    useEffect(() => {
        document.title = 'Sidebar Banner'; 
    }, [])
    const [allImages, setAllImages] = useState([])
    const [Alert, setAlert] = useState(null)
    const [isLoader, setLoader] = useState(true)
    const REACT_APP_LIVE_BACKEND_BASE_URL = process.env.REACT_APP_LIVE_BACKEND_BASE_URL;

    const getSidebarData = async () => {       
        const URL = REACT_APP_LIVE_BACKEND_BASE_URL + 'api/get-sidebar-banner?param_search=';
        setLoader(true)
        await axios({
            method: "get",
            url: URL,
            headers: {
                "Content-Type": "application/json"
            }
        }).then((response) => {
            const data = response.data
            setAllImages(data.data)
            setLoader(false)
        }).catch((error) => {
            setLoader(false)
            console.log(error)
        });
    }

    React.useEffect(() => {
        getSidebarData();
    }, []);

    const deleteBanner = async (id) => {
        const url = REACT_APP_LIVE_BACKEND_BASE_URL + 'api/sidebar-banner/' + id;
        setAlert(
            <SweetAlert
                warning
                showCancel
                confirmBtnText="Yes, delete it!"
                confirmBtnBsStyle="danger"
                cancelBtnText="No, cancel"
                cancelBtnBsStyle="success"
                title="Are you sure?"
                onConfirm={() => deleteItem(url)}
                onCancel={() => setAlert(null)}
            >
                You won't be able to revert this!
            </SweetAlert>
        );
    }

    const deleteItem = async (url) => {
        await axios({
            method: "delete",
            url: url,
            headers: {
                "Content-Type": "application/json"
            }
        }).then((response) => {
            getSidebarData()
        }).catch((error) => {
            console.log(error)
        });
        setAlert(null);
    }

    return (
        <div id="content" className="main-content">
            <div className="bg-custom">
                <div className="main-profile-sec">
                    <div className="profile-sec">
                        <div className="inner-guest-posts">
                            <div className="inner-profile-sec">
                                <div className="add-more-btn text-right">
                                    <Link to="/add-sidebar-banner/"><i className="fa-solid fa-plus"></i> Add Banner </Link>
                                </div>

                                <div className="data-table-sec table-responsive admin-data-table">
                                    <table id="example" className="display t-custom-width" cellspacing="0" width="100%">
                                        <thead>
                                            <tr>
                                                <th>Image</th>
                                                <th>Start Date</th>
                                                <th>End Date</th>
                                                <th>Url</th>
                                                <th>Status</th>
                                                <th className="text-right" style={{width: "27%"}}>Actions</th>
                                            </tr>
                                        </thead>

                                        <tbody> 
                                        {isLoader ? <DataTable /> : allImages.map((images, key) => {
                                                return (
                                                    <tr key={key}>
                                                        <td className="t-logo-img">
                                                            <img src={images.image} alt={images.title} style={{ maxWidth: "200px" }} />
                                                        </td>
                                                        <td> {images.start_date ? moment(images.start_date).format("DD-MM-YYYY") : "N/A"} </td>
                                                        <td> {images.end_date ? moment(images.end_date).format("DD-MM-YYYY") : "N/A"} </td>
                                                        <td> {images.url ? images.url : "#"} </td>
                                                        <td> {images.status === 1 ? (<span className="badge badge-success">Active</span>) : ( <span className="badge badge-danger">Inactive</span>)} </td>
                                                        <td className="action-btn text-right">
                                                            <button type="button" onClick={() => deleteBanner(images.id)} className="edit-btn c-dlt-btn"> <i className="fa-solid fa-eye"></i> Delete</button>
                                                            <Link to={"/sidebar-banner/" + images.id+"/"} className="edit-btn"> <i className="fa-regular fa-pen-to-square"></i> Edit</Link>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {Alert}
            <Admindashboardfooter/>
        </div>
    )
}

export default SidebarBanner
