import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import Lawyerfooter from '../component/Lawyerfooter';
import Lawyersidebar from '../component/Lawyersidebar';
import SweetAlert from 'react-bootstrap-sweetalert';
import Cookies from 'universal-cookie';
import { Loader } from '../../helper/commonHelper';

function GetPublished() {
    const cookies = new Cookies();
    var lawyertocken = cookies.get('LAWYER');
    const REACT_APP_LIVE_BACKEND_BASE_URL = process.env.REACT_APP_LIVE_BACKEND_BASE_URL;
    const url = REACT_APP_LIVE_BACKEND_BASE_URL + 'api/buy-package';
    const [Alert, setAlert] = useState(null);
    const [isPurchased, setIsPurchased] = useState(false);
    const [packageId, setPackageId] = useState(null);
    const [lawyerId, setLawyerId] = useState(null);
    const [isLoader, setLoader] = useState(false)

    useEffect(() => {
        if (!lawyertocken) {
            window.location.href = process.env.REACT_APP_BASE_URL+"/";
        }
        document.title = 'Get Published';
        fetch(REACT_APP_LIVE_BACKEND_BASE_URL + "api/get-published-package/" + lawyertocken).then((res) => res.json()).then((response) => {
            setLoader(false);
            setIsPurchased(response.purchased)
            setPackageId(response.packageid)
            setLawyerId(response.lawyerid)
        })
    }, [])

    const submitHandler = async (e) => {
        e.preventDefault();
        setLoader(false);
        try {
            await axios({
                method: "post",
                url: url,
                data: {
                    packageid: packageId,
                    lawyerid: lawyerId
                },
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            }).then((response) => {
                setLoader(true);
                if (response.status) {
                    window.location.href = response.data.redirecturl;
                } else {
                    setAlert((
                        <SweetAlert danger title="Oops!" onConfirm={() => {setAlert(null)}}>
                            {response.msg}
                        </SweetAlert>
                    ))
                }
            });
        } catch (response) {
            setLoader(false);
            console.log(response)
        }
    }

    return (
        <Loader loader={isLoader}>
            <div className="wrapper d-flex align-items-stretch">
                <Lawyersidebar/>
                <div id="content" className="main-content">
                    <div className="get-published-sec">
                        <div className="title get-published-title">
                            <h2>A guest post on ServeTheInjured.com provides a very powerful do-follow link to your website.</h2>
                        </div>

                        <div className="text get-published-text">
                            <p>At ServeTheInjured.com, we're committed to providing our readers with high-quality, insightful, and actionable content related to injury law. We welcome your submissions if you're passionate about this subject and believe you have a unique perspective or valuable information to share. </p>
                            <p>But before making your payment and sending us your article, please adhere to our editorial guidelines.</p>
                        </div>

                        <div className="published-get-now-btn">
                            {
                                isPurchased ? "" : <Link to="/" onClick={(e) => submitHandler(e)}>BUY NOW</Link>
                            }
                        </div>

                        <div className="get-published-points">
                            <h4><span>1.</span> Originality and Authenticity:</h4>
                        </div>

                        <div className="text get-published-text">
                            <p>Your piece must be 100% original. This means we won't accept content published elsewhere, whether on another blog, magazine, or any other online platform. Before publication, we will conduct a Copyscape check to ensure the content hasn't been duplicated from any other source. Repurposing, spinning, or using AI-generated content will result in immediate rejection.</p>
                        </div>

                        <div className="get-published-points">
                            <h4><span>2.</span> Quality and Depth:</h4>
                        </div>

                        <div className="text get-published-text">
                            <p>We emphasize quality over quantity. While we require articles to be a minimum of 1000 words, the content's depth and value matter most. Ensure your article is well-researched, properly structured, and offers genuine value to our readers. Fluff, redundant information, or mere opinions without supporting data won't make the cut.</p>
                        </div>

                        <div className="get-published-points">
                            <h4><span>3.</span> Educational Focus:</h4>
                        </div>

                        <div className="text get-published-text">
                            <p>Our primary goal is to educate our readers about injury law. Ensure your article is informative and not a sales pitch. We're interested in articles that provide insights into injury law, recent developments, tips, etc. Please avoid overly promotional content or anything not directly related to injury law.</p>
                        </div>

                        <div className="get-published-points">
                            <h4><span>4.</span> One Do-Follow Link:</h4>
                        </div>

                        <div className="text get-published-text">
                            <p>We understand the value of backlinks for our contributors. Therefore, you're allowed one do-follow link in your article. This link can direct our readers to any page on your website. However, ensure the linked page is relevant to your article and offers added value. Links to spammy or questionable websites will lead to immediate rejection.</p>
                        </div>

                        <div className="get-published-points">
                            <h4><span>5.</span> Formatting and Style:</h4>
                        </div>

                        <div className="text get-published-text">
                            <p>We appreciate clean, easy-to-read content. Please use headings, subheadings, bullet points, and short paragraphs to make your article reader-friendly. The tone should be professional yet accessible. Steer clear of jargon unless necessary, and always explain any complex terms or concepts.</p>
                        </div>

                        <div className="get-published-points">
                            <h4><span>6.</span> Proofreading:</h4>
                        </div>

                        <div className="text get-published-text">
                            <p>Mistakes can undercut your credibility. Please proofread your article carefully before submitting. Check for grammatical and spelling mistakes, and ensure consistency in style and tone.</p>
                        </div>

                        <div className="get-published-lists">
                            <ol>
                                <li><span>Pitch First</span>: Before submitting a full article, send us a brief pitch outlining your proposed topic and key points. This helps us gauge if the topic aligns with our editorial goals.</li>
                                <li><span>Feedback</span>: If your pitch is accepted, you may be asked to make certain modifications in line with our editorial vision. Please be open to feedback and revisions.</li>
                                <li><span>Final Submission</span>: Once your article meets our standards and adheres to the guidelines mentioned above, we'll proceed with the publication.</li>
                            </ol>
                        </div>

                        <div className="text get-published-text">
                            <p>In conclusion, we at ServeTheInjured.com pride ourselves on offering top-notch content to our readers. By ensuring your submissions align with our guidelines, you can showcase your injury law expertise and help us maintain the quality and trustworthiness our readers expect. We look forward to your contributions!</p>
                        </div>

                        <div className="published-get-now-btn">
                            {
                                isPurchased ? "" : <Link to="/" onClick={(e) => submitHandler(e)}>BUY NOW</Link>
                            }
                        </div>
                    </div>
                    {Alert}
                    <Lawyerfooter/>
                </div>
            </div>
        </Loader>
    )

}

export default GetPublished
