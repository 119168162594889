import React, {useState} from 'react';
import Routes from './Routes/FrontendRoutes';
import './App.css';
import { ThemeContext } from "./ThemeContext";
import { Helmet } from "react-helmet";

function App() {
  const [isLoader, setLoader] = useState(true);
  const toggleLoader = (status=null) => {
    status ? setLoader(status) : setLoader(!isLoader)
  }

  return (
    <div className="App">
      <Helmet>
        <title>{process.env.REACT_APP_NAME}</title>
        <meta name="description" content="Serve The Injured" />
        <link rel="canonical" href={process.env.REACT_APP_LIVE_BASE_URL} />
      </Helmet>
      <ThemeContext.Provider value={{ isLoader, toggleLoader }}>
        <Routes/>
      </ThemeContext.Provider>
    </div>
  );
}

export default App;