import React, {useEffect, useState} from 'react'
import {useParams, Link, useHistory} from "react-router-dom"
import { Facebook, Code } from 'react-content-loader'
import { Helmet } from "react-helmet"

function FrontLawyerProfile() {
    const REACT_APP_LIVE_BACKEND_BASE_URL = process.env.REACT_APP_LIVE_BACKEND_BASE_URL;
    let {id} = useParams();
    const LAWYERURL = REACT_APP_LIVE_BACKEND_BASE_URL + 'api/get-lawyer-data/' + id;
    const [badge, getBadge] = useState(null)
    const [isLoader, setLoader] = useState(true)
    const [isTextLoader, setTextLoader ] = useState(true)
    const [practiceArea, getPracticeArea] = useState([])
    const [license, getLicense] = useState([])
    const [award, getAward] = useState([])
    const [experience, getExperience] = useState([])
    const [association, getAssociation] = useState([])
    const [education, getEducation] = useState([])
    const [reading, getReading] = useState([])
    const [engagement, getEngagement] = useState([])
    const [settlement, getSettlement] = useState([])
    const [attorney, getAttorney] = useState([])
    const [social, getSocial] = useState([])
    const [photoVideo, getPhotoVideo] = useState([])
    const [lawyerdata, setLawyerdata] = useState({
        biography: "",
        city: "",
        created_at: "",
        email: "",
        firm_description: "",
        firmname: "",
        id: 0,
        location_url: "",
        map_url: "",
        mobile: "",
        name: "",
        office_phone: "",
        practice_area: "",
        profile_photo: "",
        state: "",
        street_address: "",
        updated_at: "",
        website: "",
        zipcode: "",
        google_rating: "",
        cities: {
            id: "",
            name: ""
        },
        states: {
            id: "",
            name: ""
        },
    })
    let history = useHistory()

    useEffect(() => {
        document.title = 'Lawyer profile';
        fetchlawyerdata();
    }, [])

    const fetchlawyerdata = () => {
        fetch(LAWYERURL).then((res) => res.json()).then((response) => {
            if (response.lawyerprofile !== '' && response.lawyerprofile) {
                setLoader(false)
                setLawyerdata(response.lawyerprofile)
                setTextLoader(false)
                if (response.licensedata !== '') {
                    getLicense(response.licensedata)
                }

                if (response.awardsdata !== '') {
                    getAward(response.awardsdata)
                }
                if (response.Profexperience !== '') {
                    getExperience(response.Profexperience)
                }

                if (response.associationdata !== null) {
                    getAssociation(response.associationdata)
                }
                if (response.educationdata !== '') {
                    getEducation(response.educationdata)
                }

                if (response.readingdata !== '') {
                    getReading(response.readingdata);
                }

                if (response.engagementdata !== '') {
                    getEngagement(response.engagementdata)
                }

                if (response.settlementdata !== '') {
                    getSettlement(response.settlementdata)
                }

                if (response.attorneydata !== '') {
                    getAttorney(response.attorneydata)
                }

                if (response.sociallinks !== null) {
                    getSocial(response.sociallinks)
                }

                if (response.photovideodata !== null) {
                    getPhotoVideo(response.photovideodata)
                }
                
                if (response.lawyerprofile.practice_area !== '') {
                    fetch(REACT_APP_LIVE_BACKEND_BASE_URL + 'api/get-lawyer-practice-area/' + response.lawyerprofile.id).then((res) => res.json()).then((response) => {
                        getPracticeArea(response)
                    })
                }

                getBadge(response.badge)
            }else{
                history.push(`/`)
            }
        })
    }
    
    return (
        <div>
            <Helmet>
                <title>{`${lawyerdata.name} | Lawyer in ${lawyerdata.cities.name}, ${lawyerdata?.states?.short_name}`}</title>
                <meta name="description" content={`${lawyerdata.name} is a highly experienced lawyer in ${lawyerdata?.cities?.name} city, ${lawyerdata?.states?.short_name} with a ${lawyerdata.google_rating || "4.3"}-star rating. Get expert legal help from a trusted professional for your legal needs.`} />
                <link rel="canonical" href={window.location.href.toLowerCase()} />
            </Helmet>
            <section class="pages-breadcrumb mt-3">
                <div class="container">
                    {
                        isTextLoader ? <Code /> : (
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <a href={process.env.REACT_APP_BASE_URL} rel="noopener noreferrer">Home</a>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="/">Find a Lawyer</Link>
                                    </li>
                                    <li class="breadcrumb-item active" aria-current="page">{lawyerdata.name}</li>
                                </ol>
                            </nav>
                        )
                    }
                </div>
            </section>
            <section class="single-lawyer-profile mt-5">
                <div class="container">
                    <div className="inner-lawyer-profile">
                        {
                            isLoader ? (<Facebook />) : (<div className="row">
                                    <div className="col-md-2">
                                        <div className="lawyer-profile-img">
                                            <img src={lawyerdata.profile_photo} className='img-fluid' alt={lawyerdata.name} loading='lazy' />
                                        </div>
                                    </div>

                                    <div className="col-md-7">
                                        <div className="lawyer-details">
                                            <div className="lawyer-name">
                                                <h5>{lawyerdata.name}</h5>
                                            </div>

                                            <div className="lawyer-type">
                                                <span>{lawyerdata.firmname}</span>
                                            </div>

                                            <div className="lawyer-address">
                                                <p className="text-muted">
                                                    <i className="fa-solid fa-map-location-dot"></i>
                                                    {lawyerdata.street_address}{lawyerdata.states?`, ${lawyerdata.states.name}`:""}{lawyerdata.cities ?`, ${lawyerdata.cities.name}`:""}{lawyerdata.zipcode ? `, ${lawyerdata.zipcode}`: ""}
                                                </p>
                                            </div>

                                            {
                                                lawyerdata.slogan ? (
                                                    <div className="slogan-text">
                                                        <p>{lawyerdata.slogan}</p>
                                                    </div>
                                                ) : ""
                                            }
                                            
                                            <div className="lawyer-review-year-info">
                                                {
                                                    lawyerdata.year_in_business ? (
                                                        <div className="yeared-licence">
                                                            <p className="text-muted"> <span><i className="fa-solid fa-building-columns"></i></span> <small>{lawyerdata.year_in_business} years in business</small> </p>
                                                        </div>
                                                    ) : ""
                                                }
                                            </div>

                                            <div className="lawyer-discription">
                                                <p className="text-muted">{lawyerdata.firm_description}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        { lawyerdata.subscription_status === 'Active' ? (
                                            <div className="lawyer-contant">
                                                {
                                                    badge && lawyerdata.show_badge ? (
                                                        <div className="lawyer-badge-img">
                                                            <img src={badge.regular_image} alt={badge.title} className='img-fluid' loading='lazy' />
                                                        </div>
                                                    ) : ''
                                                }

                                                {
                                                    lawyerdata.office_phone ? (
                                                        <div className="lawyer-contact-number">
                                                            <a href={"tel:" + lawyerdata.office_phone} rel="nofollow"><i className="fa-solid fa-phone"></i>{`(${String(lawyerdata.office_phone).substring(0, 3)}) ${String(lawyerdata.office_phone).substring(3, 6)}-${String(lawyerdata.office_phone).substring(6, 10)}`}</a>
                                                        </div>
                                                    ) : ''
                                                }

                                                {
                                                    lawyerdata.email ? (
                                                        <div className="lawyer-email">
                                                            <a href={`mailto:${lawyerdata.email}`} rel="nofollow">
                                                                <i className="fa-solid fa-envelope"></i> Email Us
                                                            </a>
                                                        </div>
                                                    ) : ''
                                                }

                                                {
                                                    lawyerdata.website ? (
                                                        <div className="lawyer-website">
                                                            <a href={lawyerdata.website} target="_blank" rel="nofollow">
                                                                <i className="fa-solid fa-arrow-up-right-from-square"></i> Website
                                                            </a>
                                                        </div>
                                                    ) : ''
                                                }
                                            </div>
                                        ) : '' }
                                    </div>
                                </div>
                            )
                        }
                    </div>

                    <div className="lawyer-profile-content">
                        <div className="lawyer-p-area">
                            <div className="title">
                                <h4 className="text-left mb-4"> <span className="title-border"></span> Practice Areas</h4>
                            </div>
                            <div className="text">
                                <ul>
                                    {practiceArea.map((item, i) => (<li> {item}</li>))}
                                </ul> 
                            </div>
                        </div>

                        <div className="lawyer-license">
                            <div className="title">
                                <h4 className="text-left mb-4"> <span class="title-border"></span> License</h4>
                            </div>
                            {license.map((item, i) => (
                                <div className="row" key={i}>
                                    <div className="col-md-4">
                                        <div className="l-state s-both">
                                            <p>
                                                <span className="under-mark">State:</span>
                                                <span>{item?.states?.name}</span>
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="l-status s-both">
                                            <p>
                                                <span className="under-mark">Status:</span>
                                                <span>{item.status}</span>
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="l-year s-both">
                                            <p>
                                                <span className="under-mark">Year:</span>
                                                <span>{item.year}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ))
                        } </div>

                        {
                            photoVideo.length >= 1 ? (<div className="lawyer-pv">
                                <div className="title">
                                    <h4 className="text-left mb-4"> <span class="title-border"></span> Photos</h4>
                                </div>
                                <div className="pv-content">
                                    <div className="row">
                                        {photoVideo.map((item, i) => {
                                            return item.photo != null ? (
                                                <div className="col-md-2" key={i}>
                                                    <div className="lawyer-photo">
                                                        <img src={REACT_APP_LIVE_BACKEND_BASE_URL + "public" + item.photo} loading='lazy' />
                                                    </div>
                                                </div>
                                            ) : ''
                                        })}
                                    </div>
                                </div>
                            </div>) : ""
                        }
                        
                        {
                            photoVideo.length >= 1 ? (<div className="lawyer-pv">
                                <div className="title">
                                    <h4 className="text-left mb-4"> <span class="title-border"></span> Videos</h4>
                                </div>
                                <div className="pv-content">
                                    <div className="row">
                                        {photoVideo.map((item, i) => {
                                            return (
                                                <> {
                                                    item.video != null ? <div className="col-md-4"
                                                        key={i}>
                                                        <div className="lawyer-video-default">
                                                            <video controls
                                                                src={
                                                                    REACT_APP_LIVE_BACKEND_BASE_URL + "public" + item.video
                                                            }></video>
                                                        </div>
                                                    </div> : ''
                                                }
                                                    {
                                                    item.youtube_videos != null ? <div className="col-md-4"
                                                        key={i}>
                                                        <div className="lawyer-video">
                                                            <div dangerouslySetInnerHTML={{ __html: item.youtube_videos }} />
                                                        </div>
                                                    </div> : ''
                                                } </>
                                            )
                                        })} 
                                    </div>
                                </div>
                            </div>) : ""
                        }

                        {
                            award.length >= 1 ? (<div className="lawyer-awards">
                                <div className="title">
                                    <h4 className="text-left mb-4"> <span class="title-border"></span> Awards</h4>
                                </div>
                                {award.map((item, i) => (
                                    <div className="row" key={i}>
                                        <div className="col-md-12">
                                            <div className="l-awards s-both">
                                                <p><span>{item.name}</span></p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>) : ""
                        }

                        {
                            experience.length >= 1 ? (<div className="lawyer-experience">
                                <div className="title">
                                    <h4 className="text-left mb-4"> <span class="title-border"></span> Professional Experience</h4>
                                </div>
                                {experience.map((item, i) => (
                                    <div className="row" key={i}>
                                        <div className="col-md-3">
                                            <div className="l-title s-both">
                                                <p><span className="under-mark">Title:</span> <span>{item.title}</span></p>
                                            </div>
                                        </div>
    
                                        <div className="col-md-3">
                                            <div className="l-company s-both">
                                                <p><span className="under-mark">Company:</span> <span>{item.law_firm}</span></p>
                                            </div>
                                        </div>
    
                                        <div className="col-md-3">
                                            <div className="l-date s-both">
                                                <p><span className="under-mark">From Date:</span> <span>{item.from_date}</span></p>
                                            </div>
                                        </div>
    
                                        <div className="col-md-3">
                                            <div className="l-date s-both">
                                                <p><span className="under-mark">To Date:</span> <span>{item.to_date}</span></p>
                                            </div>
                                        </div>
                                    </div>
                                ))} 
                            </div>) : ""
                        }

                        {association.length >= 1 ? (
                            <div className="lawyer-professional-associations">
                                <div className="title">
                                    <h4 className="text-left mb-4"> <span class="title-border"></span> Professional Associations</h4>
                                </div>

                                {
                                    association.map((item, i) => {
                                        return (
                                            <div className="row" key={`association-${i}`}>
                                                <div className="col-md-6">
                                                    <div className="l-association s-both">
                                                        <p>
                                                            <span className="under-mark">Association Name:</span>
                                                            <span>{item.name}</span>
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="l-position s-both">
                                                        <p>
                                                            <span className="under-mark">Position:</span>
                                                            <span>{item.position}</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                            ) : ""
                        }

                        {
                            education.length >= 1 ? (<div className="lawyer-education">
                                <div className="title">
                                    <h4 className="text-left mb-4"> <span class="title-border"></span> Education</h4>
                                </div>
                                {education.map((item, i) => (
                                    <div className="row" key={i}>
                                        <div className="col-md-4">
                                            <div className="l-school s-both">
                                                <p><span className="under-mark">School Name:</span> <span>{item.school_name}</span></p>
                                            </div>
                                        </div>
    
                                        <div className="col-md-4">
                                            <div className="l-degree s-both">
                                                <p><span className="under-mark">Degree:</span> <span>{item.degree}</span></p>
                                            </div>
                                        </div>
    
                                        <div className="col-md-4">
                                            <div className="l-graduated s-both">
                                                <p><span className="under-mark">Graduated:</span> <span>{item.graduated}</span></p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>) : ""
                        }

                        {
                            reading.length >= 1 ? (<div className="lawyer-further">
                                <div className="title">
                                    <h4 className="text-left mb-4"> <span class="title-border"></span> Further Reading</h4>
                                </div>
    
                                <div className="further-reading">
                                    {reading.map((item, i) => {
                                        return (<p key={i}><a target="_blank" href={item.url}>{item.text} <i className="fa-solid fa-up-right-from-square"></i></a></p>)
                                    })}
                                </div>
                            </div>) : ""
                        }

                        {
                            engagement.length >= 1 ? (<div className="lawyer-speaking-engagements">
                                <div className="title">
                                    <h4 className="text-left mb-4"> <span class="title-border"></span> Speaking Engagements</h4>
                                </div>
                                {engagement.map((item, i) => {
                                    return (
                                        <div className="row" key={i}>
                                            <div className="col-md-4">
                                                <div className="l-school s-both">
                                                    <p><span>{item.conference}</span></p>
                                                </div>
                                            </div>
    
                                            <div className="col-md-4">
                                                <div className="l-degree s-both">
                                                    <p><span className="under-mark">Topic:</span> <span>{item.topic}</span></p>
                                                </div>
                                            </div>
    
                                            <div className="col-md-4">
                                                <div className="l-graduated s-both">
                                                    <p><span className="under-mark">Date:</span> <span>{item.date}</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>) : ""
                        }

                        {
                            settlement.length >= 1 ? (<div className="lawyer-notable-settlements">
                                <div className="title">
                                    <h4 className="text-left mb-4"> <span class="title-border"></span> Notable Settlements</h4>
                                </div>
                                {settlement.map((item, i) => {
                                    return (
                                        <div className="row" key={i}>
                                            <div className="col-md-4">
                                                <div className="l-school s-both">
                                                    <p><span className="under-mark">Amount:</span> <span>{item.amount}</span></p>
                                                </div>
                                            </div>
    
                                            <div className="col-md-4">
                                                <div className="l-degree s-both">
                                                    <p><span className="under-mark">Injury:</span> <span>{item.injury}</span></p>
                                                </div>
                                            </div>
    
                                            <div className="col-md-4">
                                                <div className="l-graduated s-both">
                                                    <p><span className="under-mark">Date:</span> <span>{item.date}</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>) : ""
                        }

                        {
                            attorney.length >= 1 ? (<div className="lawer-attroney">
                                <div className="title">
                                    <h4 className="text-left mb-4"> <span class="title-border"></span> Attorneys at This Firm</h4>
                                </div>
    
                                <div className="attroney-list">
                                    <div className="row">
                                        {attorney.map((item, i) => {
                                            return (
                                                <div className="col-md-3 mb-2" key={i}>
                                                    <div className="row">
                                                        <div className="attroney-img col-md-4">
                                                            <img src={REACT_APP_LIVE_BACKEND_BASE_URL + "public" + item.image} loading='lazy' />
                                                        </div>
                                                        <div className="col-md-8">
                                                            <Link to="#">{item.name}</Link> 
                                                            <p>{item.category}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>) : ""
                        }

                        <div className="lawyer-social-media">
                            <div className="title">
                                <h4 className="text-left mb-4"> <span class="title-border"></span> Social Media</h4>
                            </div>

                            <ul className="p-0">
                                {
                                    social.facebook ? (<li className="d-inline-block">
                                        <a href={social.facebook} target='_blank'>
                                            <i className="fa-brands fa-facebook-f"></i>
                                        </a>
                                    </li>) : ""
                                }
                                
                                {
                                    social.twitter ? (<li className="d-inline-block">
                                        <a href={social.twitter} target='_blank'>
                                            <i className="fa-brands fa-twitter"></i>
                                        </a>
                                    </li>) : ""
                                }
                                
                                {
                                    social.linkedin ? (<li className="d-inline-block">
                                        <a href={social.linkedin} target='_blank'>
                                            <i className="fa-brands fa-linkedin-in"></i>
                                        </a>
                                    </li>) : ""
                                }
                                
                                {
                                    social.youtube ? (<li className="d-inline-block">
                                        <a href={social.youtube} target='_blank'>
                                            <i className="fa-brands fa-youtube"></i>
                                        </a>
                                    </li>) : ""
                                }
                                
                                {
                                    social.instagram ? (<li className="d-inline-block">
                                        <a href={social.instagram || '#'} target='_blank'>
                                            <i className="fa-brands fa-instagram"></i>
                                        </a>
                                    </li>) : ""
                                }
                            </ul>
                        </div>

                        {
                            lawyerdata.map_url ? (<div className="lawyer-map my-5">
                                <div className="title">
                                    <h4 className="text-left mb-4"> <span class="title-border"></span> Location (address)</h4>
                                </div>
    
                                <div className="loucation-map">
                                    <div dangerouslySetInnerHTML={{ __html: lawyerdata.map_url }} />
                                </div>
                            </div>) : ""
                        }
                    </div>
                </div>
            </section>
        </div>
    )
}

export default FrontLawyerProfile
