import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import Admindashboardfooter from "../components/Admindashboardfooter"
import DataTable from "../../helper/DataTable";

function LawyerDashboardContent() {
    const  REACT_APP_LIVE_BACKEND_BASE_URL  = process.env.REACT_APP_LIVE_BACKEND_BASE_URL;
    const [data, getData ] = useState([]);
    const [Alert, setAlert] = useState(null);
    const [isLoader, setLoader] = useState(true)
        
    useEffect(() => {
        document.title = 'Lawyer Dashboard Content'; 
        fetchData();
    }, [])

    const fetchData = () => {
        const URL = REACT_APP_LIVE_BACKEND_BASE_URL+'api/lawyer-profile-content';
        fetch(URL).then((res) =>res.json()).then((response) => {
            getData(response.data);
            setLoader(false);
        })
    }
       
    return (
        <div id="content" className="main-content">
            <div className="bg-custom">
                <div className="main-profile-sec">
                    <div className="profile-sec">
                        <div className="inner-guest-posts">
                            <div className="inner-profile-sec">
                                <div className="row align-items-center">
                                    <div className="col-md-10">
                                        <div className="heading-title c-text-small">
                                            <p>Lawyer Dashboard Content</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="data-table-sec table-responsive admin-data-table">
                                    <table id="example" className="display" cellspacing="0" width="100%">
                                        <thead>
                                            <tr>
                                                <th>Sr. No.</th>
                                                <th>Title</th>
                                                <th>Youtube Video</th>
                                                <th>Page</th>
                                                <th>Actions</th>                                                     
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {isLoader ? <DataTable /> : data.map((item, i) => (
                                                <tr key={`row-list-${i}`}>
                                                    <td>{item.id}</td>
                                                    <td>{item.title || "N/A"}</td>
                                                    <td><iframe width="150" height="80" src={item.youtube_video} title={item.title !== undefined ? item.title : "YouTube video player"} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe></td>
                                                    <td>{item.type}</td>                                                     
                                                    <td className="action-btn both-action-btn">
                                                        <Link title="Edit" to={"/edit-dashboard-content/"+item.id+"/"} className="edit-btn"> <i className="fas fa-edit"></i></Link>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Admindashboardfooter />
            {Alert}
        </div>
    )
}

export default LawyerDashboardContent