import React, {useEffect, useState} from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import axios from 'axios';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import SweetAlert from 'react-bootstrap-sweetalert';
import Admindashboardheader from "../components/Admindashboardheader"
import Admindashboardsidebar from "../components/Admindashboardsidebar"
import Admindashboardfooter from "../components/Admindashboardfooter"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

function Copyright() {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [errorText, setErrorText] = useState('');
    const [Alert, setAlert] = useState(null);
    const [isDisabled, setDisabled] = useState(false);
    const  REACT_APP_LIVE_BACKEND_BASE_URL  = process.env.REACT_APP_LIVE_BACKEND_BASE_URL;

    
    const loadData = async () => {
        const url = REACT_APP_LIVE_BACKEND_BASE_URL+"api/copyright";
        await axios({
            method: 'get',
            url: url,
            headers: {"Content-Type": "application/json"},
        }).then((response) => {
            const data = response.data.data;
            const contentBlock = htmlToDraft(data.copyright_text);
            const contentStateData = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorStateData = EditorState.createWithContent(contentStateData);
            setEditorState(editorStateData);
        }).catch((error) => {
            const errors = error.response.data.message;
            setAlert((<SweetAlert danger title="" onConfirm={() => {
                setAlert(null)
            }} >{errors}</SweetAlert>))
        });
    }
    
    const copyrightFormSubmit = async (event) => {
        event.preventDefault();
        setErrorText('');
        const url = event.target.getAttribute('action');
        const method = event.target.getAttribute('method');
        const formData = new FormData(event.target);
        setDisabled(true);

        await axios({
            method: method,
            url: url,
            data: formData,
            headers: {"Content-Type": "multipart/form-data" },
        }).then((response) => {
            setDisabled(false);
            const data = response.data;
            setAlert((<SweetAlert success title="" onConfirm={() => {
                setAlert(null)
            }} >{data.message}</SweetAlert>))
        }).catch((error) => {
            setDisabled(false);
            const errors = error.response.data;
            setErrorText(errors.message);
        });

    }

    const onEditorStateChange = (editorState) => {
        setErrorText('');
        setEditorState(editorState);
    }
    useEffect(loadData, []);

    return (
        <>
            <Admindashboardheader/>
            <div className="wrapper d-flex align-items-stretch">
                <Admindashboardsidebar/>
                <div id="content" className="main-content">
                    <div className="bg-custom">
                        <div className="main-profile-sec">
                            <form action={`${REACT_APP_LIVE_BACKEND_BASE_URL}api/copyright/add`} method="post" onSubmit={copyrightFormSubmit}>
                                <div className="profile-sec">
                                    <div className="inner-guest-posts">

                                        <div className="inner-profile-sec">
                                            <div className="heading-title c-text-small">
                                                <p>Copyright</p>
                                            </div>

                                            <div className="profile-edit-sec">
                                                <div className="row">

                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <Editor
                                                                        editorState={editorState}
                                                                        toolbarClassName="toolbarClassName"
                                                                        wrapperClassName="wrapperClassName"
                                                                        editorClassName="inner-guest-posts"
                                                                        onEditorStateChange={onEditorStateChange}
                                                                        toolbar={{
                                                                            options: ['inline', 'textAlign', 'colorPicker', 'link', 'remove', 'history']
                                                                        }}
                                                                    />
                                                                    <input type="hidden" name="copyright_text" value={draftToHtml(convertToRaw(editorState.getCurrentContent()))} />
                                                                    <p class="text-danger">{errorText}</p>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                                <div className="save-btn text-right p-0">
                                                    <button disabled={isDisabled} type="submit" className="mt-2">Save</button>
                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </form>

                        </div>
                    </div>
                    {Alert}
                    <Admindashboardfooter/>
                </div>
            </div>
        </>
    )
}

export default Copyright