import React, {useEffect, useState} from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import $ from 'jquery';

function PracticeAreasLawyerList() {
    let {stateid} = useParams();
    let {id} = useParams();
    let {practice} = useParams();
    const [lawyerList, setLawyerList] = useState([]);
    const [pageLinks, setPageLinks] = useState([]);
    const  REACT_APP_LIVE_BACKEND_BASE_URL  = process.env.REACT_APP_LIVE_BACKEND_BASE_URL;
    const LAWYERLISTURL = REACT_APP_LIVE_BACKEND_BASE_URL+'api/get-front-lawyer-list/';
    const [state, setState] = useState(null);
    const [city, setCity] = useState(null);
    const [practiceArea, setPracticeArea] = useState(null);

    useEffect(() => {
        fetchlawyerlist();
        fetchState();
        fetchCity();
        fetchPracticeArea();
    }, [])

    const fetchlawyerlist = async () => {
        fetch(LAWYERLISTURL+stateid+'/'+id+'/'+practice).then((res) => res.json()).then((response) => {
            setLawyerList(response.data)
            setPageLinks(response.links)
        })
    }

    const fetchState = () => {
        fetch(REACT_APP_LIVE_BACKEND_BASE_URL+"api/get-state/"+stateid).then((res) => res.json()).then((response) => {
            setState(response)
        })
    }

    const fetchCity = () => {
        fetch(REACT_APP_LIVE_BACKEND_BASE_URL+"api/get-city/"+id).then((res) => res.json()).then((response) => {
            setCity(response)
        })
    }

    const fetchPracticeArea = () => {
        fetch(REACT_APP_LIVE_BACKEND_BASE_URL+"api/get-practice-area/"+practice).then((res) => res.json()).then((response) => {
            setPracticeArea(response)
        })
    }
    
    const handleClick = (e) => {
        e.preventDefault();
        var next = e.currentTarget.getAttribute('nextpage');
        if(next != null){
            fetch(next).then((res) => res.json()).then((response) => {
                setLawyerList(response.data);
                setPageLinks(response.links);
            })
            $(window).scrollTop(0)
        }
    }    

    return (
        <div>
            <Header />
            <section className="pages-breadcrumb mt-3">
                <div className="container">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href={process.env.REACT_APP_BASE_URL} rel="noopener noreferrer">Home</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Lawyer Profile</li>
                        </ol>
                    </nav>
                </div>
            </section>

            <section className="lawyer-profile">
                <div className="container">
                    <div className="top-title">
                        <h1>{state ? state.name+",": ""} {city ? city.name+",":""} {practiceArea ? practiceArea.name: ""}</h1>
                    </div>

                    <div className="top-text mb-md-3 mb-lg-5">
                        <p>Compare Car Accident attorneys near you. Read reviews and contact them directly.</p>
                    </div>

                    <div className="inner-lawyer-profile-sec">
                        <div className="row">
                            <div className="col-md-9">
                                <div className="left-lawyer-profile-sec">
                                    {lawyerList.length  ? (<>
                                        {lawyerList.map((item, i) => {
                                            return(<div className="inner-lawyer-profile" key={i}>
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <div className="lawyer-profile-img">
                                                            <img src={item.profile_photo} className='img-fluid' alt={item.name} />
                                                        </div>
                                                        {item.subscription_status == 'Active' ? (<div className="visit-btn"><Link to={"/view-lawyer-profile/"+item.id}><i className="fa-solid fa-user"></i> View Profile </Link></div>) : ''}
                                                    </div>

                                                    <div className="col-md-5">
                                                        <div className="lawyer-details">
                                                            <div className="lawyer-name">
                                                                <h5>{item.name}</h5>
                                                            </div>

                                                            <div className="lawyer-type">
                                                                <span>Rosengard Law Group</span>
                                                            </div>

                                                            <div className="lawyer-address">
                                                                <p><i className="fa-solid fa-map-location-dot"></i> {state ? state.name : ""} </p>
                                                            </div>

                                                            <div className="lawyer-discription">
                                                                <p>{item.biography != null ? item.biography.substring(0,92) : ''}..</p>
                                                            </div>
                                                            <div className="read-more-btn">
                                                                {item.subscription_status == 'Active' ? (<>
                                                                    <Link to={"/view-lawyer-profile/"+item.id}>Read More<i className="fa-solid fa-angles-right"></i> </Link>
                                                                </>) : ''}
                                                                
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <div className="lawyer-contant">
                                                            <div className="lawyer-badge-img">
                                                                <img src="/images/Lawyer-Image.png" />
                                                            </div>

                                                            <div className="lawyer-contact-number">
                                                                {item.subscription_status == 'Active' ? (
                                                                <a href={"tel:91"+item.office_phone}><i className="fa-solid fa-phone"></i>{item.office_phone}</a>
                                                                ) : ''}
                                                                
                                                            </div>

                                                            <div className="lawyer-email">
                                                                {item.subscription_status == 'Active' ? (
                                                                <a href={"mailto:"+item.email}><i className="fa-solid fa-envelope"></i> Email Us</a>
                                                                ) : ''}
                                                                
                                                            </div>
                                                            {item.subscription_status == 'Active' ? (<>
                                                                {item.website != null ? <><div className="lawyer-website">
                                                                <a href={item.website} target="_blank"><i className="fa-solid fa-arrow-up-right-from-square"></i> Website</a>
                                                            </div></> : <></>} 
                                                            </>) : ''}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>)
                                        })}
                                        <div className="lawyer-profile-pagination mb-5">
                                            {pageLinks.map((item, i) => {
                                                return(<>
                                                {item.label == '&laquo; Previous' ?
                                                (<button type="button" onClick={handleClick} nextpage={item.url} className={item.url != null ? "prev active" : "prev"}>Previous</button>) : (<button type="button" onClick={handleClick} nextpage={item.url} className={item.active == true ? "count active" : "count"}>{item.label}</button>) &&
                                                item.label == 'Next &raquo;' ? (<button type="button" onClick={handleClick} nextpage={item.url} className={item.url != null ? "next active" : "next"}>Next</button>) : (<button type="button" onClick={handleClick} nextpage={item.url} className={item.active == true ? "count active" : "count"}>{item.label}</button>)}
                                                </>
                                                )
                                            })}
                                        </div>
                                    </>) : <div>No Lawyer Found For This Information <Link to="/">Back to search</Link></div>}
                                </div>
                            </div>

                        <div className="col-md-3">
                            <div className="right-add-links">
                                <div className="lawyer-add">
                                    <img src="/images/add-img.webp" />
                                        <div className="s-text text-center mt-2">
                                            <span>The Avvo Rating explained <i className="fa-solid fa-circle-info"></i> </span>
                                        </div>
                                </div>

                                <div className="add-map-img mt-4">
                                    <img src="/images/map.webp" />
                                        <div className="map-overlap-text">
                                            <p> <i className="fa-solid fa-location-dot"></i> View map</p>
                                        </div>
                                </div>

                                <div className="add-links mt-4">
                                    <div className="link-title">
                                        <h4>Nearby Courthouses</h4>
                                    </div>
                                    <div className="link-content mt-md-2 mt-lg-3">
                                        <div className="text-mute a-text">Heman Marion Sweatt Travis County Courthouse Travis County Courthouse</div>
                                        <p className="text-mute s-texts m-0 mt-1">Civil Court</p>
                                        <p className="text-mute s-texts m-0">1000 Guadalupe, Room 206</p>
                                        <p className="text-mute s-texts m-0">Austin, TX 78701</p>
                                        <p className="text-mute s-texts m-0"> <i className="fa-solid fa-phone"></i> 512-854-9241</p>
                                    </div>
                                    <div className="link-contact mt-2">
                                        <Link to="">Court Website</Link> <span> | </span> <Link to="">Get Directions</Link>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>



        </div>
    </section >

        <Footer />
        </div >
    )
}

export { PracticeAreasLawyerList }