import React, {useState,useEffect} from "react";
import Admindashboardfooter from "../components/Admindashboardfooter"
import { useParams, useHistory } from "react-router-dom"
import axios from 'axios'
import SweetAlert from 'react-bootstrap-sweetalert'

function UpdateCity() {
    const DvInnerHTML = function (target, newText) {
        document.querySelectorAll(target).forEach(function (element) {
            element.textContent = newText;
        });
    }

    const {id} = useParams();
    const [Alert, setAlert] = useState(null);
    const [city, setCity] = useState([])
    const [states, setStates] = useState([])
    let history = useHistory()
    const spanText = "Use {practice_area}, {state_name} for add Practice Area and State name dynamically"
    const  REACT_APP_LIVE_BACKEND_BASE_URL  = process.env.REACT_APP_LIVE_BACKEND_BASE_URL

    useEffect(() => {
        document.title = 'Update City'
        getStatesData()
        getCityData()
    }, [])

    const getCityData = async () => {
        const URL = REACT_APP_LIVE_BACKEND_BASE_URL+'api/get-lawyer-city/'+id
        await axios({
            method: "get",
            url: URL,
            headers: {
                "Content-Type": "application/json"
            }
        }).then((response) => {
            const data = response.data;
            setCity(data.data);
        }).catch((error) => {
            console.log(error)
        })
    }

    const getStatesData = async () => {
        const URL = REACT_APP_LIVE_BACKEND_BASE_URL+'api/get-states-list'
        await axios({
            method: "get",
            url: URL,
            headers: {
                "Content-Type": "application/json"
            }
        }).then((response) => {
            const data = response.data;
            setStates(data);
        }).catch((error) => {
            console.log(error)
        })
    }

    const handleSubmitForm = async (e) => {
        e.preventDefault();
        try {
            await axios({
                method: "post",
                url: REACT_APP_LIVE_BACKEND_BASE_URL+"api/update-city",
                data: new FormData(e.target),
                headers: { "Content-Type": "multipart/form-data" },
            }).then((response) => {
                const data = response.data
                if (data.status) {
                    DvInnerHTML('.text-danger', '');
                    setAlert((<SweetAlert success title="Done" onConfirm={() => {
                        setAlert(null)
                        history.push('./../../city-list/')
                    }} >{data.message}</SweetAlert>))
                }else{
                    const errors = data.errors
                    DvInnerHTML('.text-danger', '');
                    if (parseInt(Object.keys(errors).length) > 0) {
                        for (var key in errors) {
                            if (errors.hasOwnProperty(key)) {
                                if (document.getElementById(key + '-error') === null) {
                                } else {
                                    document.getElementById(key + '-error').innerHTML = errors[key];
                                }
                            }
                        }
                    } else {
                        setAlert((<SweetAlert danger title="Ooops!" onConfirm={() => {
                            setAlert(null)
                        }} >{data.message}</SweetAlert>))
                    }
                }
            })
        } catch (response) {
            console.log(response)
        }
    }

    return (
        <div id="content" className="main-content">
            <div className="bg-custom">
                <div className="main-profile-sec">
                    <form id="add-state-city" onSubmit={(e) => handleSubmitForm(e)}>
                        <div className="profile-sec">
                            <div className="inner-guest-posts">
                                <div className="inner-profile-sec">
                                    <div className="heading-title c-text-small">
                                        <p>Update City</p>
                                    </div>
                                    <input type="hidden" name="id" defaultValue={city.id} />
                                    <div className="profile-edit-sec">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <div className="row">
                                                        <label className="col-2">City Name</label>
                                                        <div className="col-10">
                                                            <input type="text"  name="name" id="name" className="form-control" defaultValue={city.name} />
                                                            <p class="text-danger" id="name-error"></p>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label className="col-2">State</label>
                                                        <div className="col-10">
                                                            <select name="state_id" id="state_id" className="form-control" defaultValue={city.state_id}>
                                                                <option value="">Please Select</option>
                                                                {
                                                                    states.map((state, i) => {
                                                                        return (<option key={`state_option_${i}`} value={state.id} selected={state.id == city.state_id ? true : false}>{state.name}</option>)
                                                                    })
                                                                }
                                                            </select>
                                                            <p class="text-danger" id="state_id-error"></p>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label className="col-2">Meta Title</label>
                                                        <div className="col-10">
                                                            <input type="text" name="meta_title" id="meta_title" className="form-control mb-0" defaultValue={city.meta_title} />
                                                            <span className="small-text mb-2">{spanText}</span>
                                                            <p class="text-danger" id="meta_title-error"></p>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label className="col-2">Meta Description</label>
                                                        <div className="col-10">
                                                            <textarea name="meta_description" rows="5" cols="10" id="meta_description" className="form-control mb-0" defaultValue={city.meta_description}></textarea>
                                                            <span className="small-text mb-2">{spanText}</span>
                                                            <p class="text-danger" id="meta_description-error"></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="save-btn text-right p-0">
                                            <button type="submit" className="mt-2">Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {Alert}
            <Admindashboardfooter />
        </div>
    )
}

export default UpdateCity