import React, {useEffect} from "react";
import { Link } from "react-router-dom";
import Admindashboardheader from "../components/Admindashboardheader"
import Admindashboardsidebar from "../components/Admindashboardsidebar"
import Admindashboardfooter from "../components/Admindashboardfooter"

function Webinarslist() {

    useEffect(() => {
        document.title = 'Webinar list';
    }, [])
    return (
        <>
            <Admindashboardheader />
            <div className="wrapper d-flex align-items-stretch">
                <Admindashboardsidebar />
                <div id="content" className="main-content">

                    <div className="bg-custom">

                        <div className="main-profile-sec">
                            <div className="profile-sec">
                                <div className="inner-guest-posts">

                                    <div className="inner-profile-sec">

                                        <div className="row align-items-center">

                                            <div className="col-md-6">
                                                <div className="heading-title c-text-small">
                                                    <p>Webinars List</p>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group posts-title text-right p-0">
                                                    <input type="text" name="search" placeholder="Search..." className="" id=""/>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="add-more-btn text-right">
                                            <Link to="/add-webinars"> <i className="fa-solid fa-plus"></i> Add Webinars </Link>
                                        </div>

                                        <div className="data-table-sec table-responsive admin-data-table">

                                            <table id="example" className="display" cellspacing="0" width="100%">
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Email</th>
                                                        <th>Company Name</th>
                                                        <th>session</th>
                                                        <th className="text-right" style={{width: "16%"}}>Actions</th>

                                                    </tr>
                                                </thead>

                                                <tbody>

                                                    <tr>
                                                        <td> abc </td>
                                                        <td> abc@gmail.com </td>
                                                        <td> abcdefg </td>
                                                        <td> Thursday, November 10th 2022 - 9:30 PM </td>
                                                        <td className="c-edit-btn text-right">
                                                            <Link to="#" className="edit-btn"> <i className="fa-regular fa-pen-to-square"></i> edit </Link>
                                                        </td>
                                                    </tr>

                                                </tbody>

                                            </table>
                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>
                    <Admindashboardfooter />

                </div>
            </div>
        </>
    )
}

export default Webinarslist