import React, {useEffect, useState} from 'react';
import $ from 'jquery';
import {Link, useHistory } from 'react-router-dom';

function ClaimProfileSearch(){
    const DvInnerHTML = function (target, newText) {
        document.querySelectorAll(target).forEach(function (element) {
            element.textContent = newText;
        });
    }

    const [state, setState] = useState([]);
    const [city, setCity] = useState([]); 
    const REACT_APP_LIVE_BACKEND_BASE_URL = process.env.REACT_APP_LIVE_BACKEND_BASE_URL;
    const StateURL = REACT_APP_LIVE_BACKEND_BASE_URL + 'api/get-all-states-list';
    const CityUrl = REACT_APP_LIVE_BACKEND_BASE_URL + 'api/get-cities';
    let history = useHistory()

    const fetchstateData = () => {
        fetch(StateURL).then((res) => res.json()).then((response) => {
            setState(response);
        })
    }

    const stateChange = (event) => {
        if (event.target.value !== "") {
            fetch(CityUrl + '/' + event.target.value).then((res) => res.json()).then((response) => {
                setCity(response);
            })
        }else{
            setCity([]);
        }
    }

    useEffect(() => {
        document.title = 'Claim profile';
        fetchstateData();
    }, []);

    const SearchData = async (e) => {
        e.preventDefault();
        DvInnerHTML('.text-danger', '')
        var lawyer_name = $("#lawyer_name").val();
        var lawyer_state = $("#lawyer_state").val();
        var lawyer_city = $("#lawyer_city").val();
        if(lawyer_name === ""){
            $("#lawyer_name-error").html('Please enter law firm name.');
        }else if(lawyer_state === ""){
            $("#lawyer_state-error").html('Please select state.');
        }else if(lawyer_city === ""){
            $("#lawyer_city-error").html('Please select city.');
        }else{
            history.push(`/claim-profile/${lawyer_name}/${lawyer_state}/${lawyer_city}`)
        }
    }

    return(
    <>
        <section class="pages-breadcrumb mt-3">
            <div class="container">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li class="breadcrumb-item"><a href="javascript:;">Claim Profile</a></li>
                    </ol>
                </nav>
            </div>
        </section>
        <section class="claim-profile">
            <div class="container">

                <div class="inner-claim-profile">
                    <div class="title">
                        <h1>Search for your profile</h1>
                    </div>

                    <div class="claim-profile-content">
                        <div class="row">

                            <div class="col-md-4">
                                <div class="claim-profile-form">
                                    <form onSubmit={(e) => SearchData(e)}>
                                        <div class="form-group">
                                            <label>Law firm name</label>
                                            <input type="text" name="lawyer_name" id="lawyer_name" class="form-control" />
                                            <p className="text-danger" id="lawyer_name-error"></p>
                                        </div>
                                        <div class="form-group">
                                            <label>State</label>
                                            <select name="state" id="lawyer_state" class="form-control" onChange={(edit) => stateChange(edit)}>
                                                <option value="">Select State</option>
                                                {
                                                    state.map((item, key) => <option key={`state-${key}`} value={item.slug}>{item.name}</option>)
                                                }
                                            </select>
                                            <p className="text-danger" id="lawyer_state-error"></p>
                                        </div>
                                        <div class="form-group">
                                            <label>City</label>
                                            <select name="city" id="lawyer_city" class="form-control">
                                                <option value="">Select City</option>
                                                {
                                                    city.map((item, key) => <option key={`city-${key}`} value={item.slug}>{item.name}</option>)
                                                }
                                            </select>
                                            <p className="text-danger" id="lawyer_city-error"></p>
                                        </div>
                                        
                                        <div class="search-btn">
                                            <button type="submit">Search</button>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div class="col-md-2"></div>
                            <div class="col-md-6">
                                <div class="claim-profile-form-content">
                                    <p>Claiming your ServeTheInjured.com profile lets you…</p>
                                    <ul>
                                        <li>Set yourself apart from your competition to attract new leads</li>
                                        <li>Get some powerful backlinks to your injury law firm website</li>
                                        <li>Update your profile with as much information as you want, as often as you want</li>
                                        <li>Publish articles to improve credibility and authority online</li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                    
                </div>

            </div>
        </section>
    </>
    )
}

export default ClaimProfileSearch