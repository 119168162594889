import React, {useState, useEffect} from 'react';
import Cookies from 'universal-cookie';
import { Link } from 'react-router-dom';
import $ from 'jquery';

function Admindashboardsidebar() {
    const  REACT_APP_LIVE_BACKEND_BASE_URL  = process.env.REACT_APP_LIVE_BACKEND_BASE_URL
    const [roles, setRoles] = useState([])
    const LAWYERURL = REACT_APP_LIVE_BACKEND_BASE_URL+'api/get-admin-data/'
    const cookies = new Cookies()
    var tokenid = cookies.get('ADMIN')

    const fetchlawyerdata = () => {
        fetch(LAWYERURL+tokenid).then((res) => res.json()).then((response) => {
            const data = response.userdata
            const roles = data.role.split(",")
            setRoles(roles)
        })
    }

    useEffect(() => {
        document.title = 'Injury Page Description';
        fetchlawyerdata();
    }, []);

    const toggleMenu = (e) => {
        e.preventDefault()
        $("#Submenu-one").toggle(200)
    }

    return (
        <nav id="sidebar">
            <div className="inner-sidebar">
                <ul className="list-unstyled components mb-5">
                    <li className="active">
                        <Link to="/admin-dashboard/">Dashboard</Link>
                    </li>
                    {
                        roles.includes('Lawyer Lists') ? (
                            <li>
                                <Link to="/lawyer-list/">Lawyer Lists</Link>
                            </li>
                        ): ""
                    }

                    {
                        roles.includes('Lawyer Dashboard Content') ? (
                            <li>
                                <Link to="/lawyer-dashboard-content/">Lawyer Dashboard Content</Link>
                            </li>
                        ): ""
                    }
                    
                    {
                        roles.includes('User Lists') ? (
                            <li>
                                <Link to="/user-list/">User Lists</Link>
                            </li>
                        ): ""
                    }
                    
                    {
                        roles.includes('Frontend') ? (
                            <li>
                                <Link onClick={toggleMenu} data-toggle="collapse" aria-expanded="false" className="dropdown-toggle"> Frontend </Link>
                                <ul className="collapse list-unstyled" id="Submenu-one">
                                    <li>
                                        <Link to="/practice-area-list/">Practice Areas</Link>
                                    </li>                            
                                    <li>
                                        <Link to="/sidebar-banner/">Sidebar Banner</Link>
                                    </li>
                                    <li>
                                        <Link to="/home-page/">Home Page</Link>
                                    </li>
                                </ul>
                            </li>
                        ): ""
                    }
                    
                    {
                        roles.includes('Badge') ? (
                            <li>
                                <Link to="/badge/">Badge</Link>
                            </li>
                        ): ""
                    }
                    
                    {
                        roles.includes('State City') ? (
                            <li>
                                <Link to="/state-city-list/">State City List</Link>
                            </li>
                        ): ""
                    }
                    
                    {
                        roles.includes('State City') ? (
                            <li>
                                <Link to="/city-list/">City List</Link>
                            </li>
                        ): ""
                    }
                    
                    {
                        roles.includes('Package') ? (
                            <li>
                                <Link to="/package-list/">Package List</Link>
                            </li>
                        ): ""
                    }
                    <li>
                        <Link to="/logout/">Logout</Link>
                    </li>
                </ul>
            </div>
        </nav>
    )
}

export default Admindashboardsidebar

