import React, { useState, useEffect } from "react";
import Admindashboardfooter from "../components/Admindashboardfooter"
import axios from 'axios'
import SweetAlert from 'react-bootstrap-sweetalert'
import { LawLicense, LawAward, ProfessionalExperience, ProfessionalAssociation, LawyerEducation, FurtherReading, SpeakingEngagement, NotableSettlement, LawyerAttorney, LawyerSocials, PhotoVideoLink } from "../components/LawyerFormData"
import $ from 'jquery'
import { useParams } from "react-router-dom"
import DevtoCard from "../../helper/DevtoCard"

function EditLawyerProfile() {
    let {id} = useParams();
    const [data, getData] = useState([])
    const  REACT_APP_LIVE_BACKEND_BASE_URL  = process.env.REACT_APP_LIVE_BACKEND_BASE_URL;
    const [lawyerdata, setLawyerdata] = useState({
        biography: "", city: "", created_at: "", email: "", firm_description: "", firmname: "", id: 0, location_url: "", map_url: "", mobile: "", name: "", office_phone: "", practice_area: "", profile_photo: "", state: "", street_address: "", updated_at: "", website: "", zipcode: ""
    })
    const URL = REACT_APP_LIVE_BACKEND_BASE_URL+'api/get-practise-area'
    const LAWYERURL = REACT_APP_LIVE_BACKEND_BASE_URL+'api/get-lawyer-data/' + id
    const StateURL = REACT_APP_LIVE_BACKEND_BASE_URL+'api/get-states-list'
    const CityUrl = REACT_APP_LIVE_BACKEND_BASE_URL+'api/get-city-list'
    const [state, setState]  = useState([])
    const [city, setCity]  = useState([])
    const [isLoader, setLoader] = useState(true)

    useEffect(() => {       
        document.title = 'Edit Lawyer Profile'; 
        const fetchDataAsync = async () => {
            try {
                await Promise.all([
                    fetchData(),
                    fetchlawyerdata(),
                    fetchstateData(),
                ])

                setLoader(false)
            } catch (error) {
                console.error("An error occurred:", error)
                setLoader(false)
            }
        }

        fetchDataAsync()
    }, [])

    const fetchData = () => {
        fetch(URL).then((res) => res.json()).then((response) => {
            getData(response);
        })
    }

    const DvInnerHTML = function (target, newText) {
        document.querySelectorAll(target).forEach(function (element) {
            element.textContent = newText;
        });
    }

    const [Alert, setAlert] = useState(null)

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await axios({
                method: "post",
                url: REACT_APP_LIVE_BACKEND_BASE_URL+"api/add-lawyer-profile",
                data: new FormData(e.target),
                headers: { "Content-Type": "multipart/form-data" },
            }).then((response) => {
                const errors = response.data.errors;
                if (response.data.status) {
                    DvInnerHTML('.text-danger', '');
                    setAlert((<SweetAlert success title="Done" onConfirm={() => {
                        setAlert(null)
                    }}>{response.data.msg}</SweetAlert>))
                } else {
                    DvInnerHTML('.text-danger', '');
                    if (parseInt(Object.keys(errors).length) > 0) {
                        for (var key in errors) {
                            if (errors.hasOwnProperty(key)) {
                                if (document.getElementById(key + '-error') === null) {
                                } else {
                                    document.getElementById(key + '-error').innerHTML = errors[key];
                                }
                            }
                        }
                    } else {
                        setAlert((<SweetAlert danger title="Ooops!" onConfirm={() => {
                            setAlert(null)
                        }} >{response.data.msg}</SweetAlert>))
                    }
                }
            });
        } catch (response) {
            console.log(response)
        }

    }

    const submitLawFirmDescription = async (event) => {
        event.preventDefault();
        axios({
            method: "POST",
            url: REACT_APP_LIVE_BACKEND_BASE_URL + "api/lawyer/update-law-firm-description",
            data: new FormData(event.target)
        }).then((response) => {
            const message = response.data.message;
            DvInnerHTML('.text-danger', '');
            setAlert((
                <SweetAlert success title="Done" onConfirm={() => { setAlert(null)}}>
                    {message}
                </SweetAlert>
            ))
        }).catch((error) => {
            const errors = error.response.data;
            if(errors.message === undefined){
                Object.entries(errors.errors).forEach(([key, value]) => {
                    const elementId = key+"-error";
                    document.getElementById(elementId).innerHTML = value;
                });
            }else{
                setAlert((
                    <SweetAlert danger title="Oops!" onConfirm={() => { setAlert(null)}}>
                        {errors.message}
                    </SweetAlert>
                ))
            }
        });
    }

    const fetchlawyerdata = () => {
        fetch(LAWYERURL).then((res) => res.json())
        .then((response) => {
            if(response.lawyerprofile !== ''){
                setLawyerdata(response.lawyerprofile);
                fetch(CityUrl+'/'+response.lawyerprofile.state).then((res) => res.json())
                .then((response) => {
                    setCity(response);
                })
            }
        })
    }

    const MapurlSubmit = async (e) => {
        e.preventDefault();
        try {
            // make axios post request
            const response = await axios({
                method: "post",
                url: REACT_APP_LIVE_BACKEND_BASE_URL+"api/add-lawyer-mapurl",
                data: new FormData(e.target),
                headers: { "Content-Type": "multipart/form-data" },
            }).then((response) => {
                const errors = response.data.errors;
                if (response.data.status) {
                    DvInnerHTML('.text-danger', '');
                    setAlert((<SweetAlert success title="" onConfirm={() => {
                        setAlert(null)
                    }} >{response.data.msg}</SweetAlert>))
                } else {
                    DvInnerHTML('.text-danger', '');
                    if (parseInt(Object.keys(errors).length) > 0) {
                        for (var key in errors) {
                            if (errors.hasOwnProperty(key)) {
                                if (document.getElementById(key + '-error') === null) {
                                } else {
                                    document.getElementById(key + '-error').innerHTML = errors[key];
                                }
                            }
                        }
                    } else {
                        setAlert((<SweetAlert danger title="" onConfirm={() => {
                            setAlert(null)
                        }} >{response.data.msg}</SweetAlert>))
                    }
                }
            });
        } catch (response) {
            console.log(response)
        }
    }

    const PracticeAreaSubmit = async (e) => {
        e.preventDefault();
        try {
            // make axios post request
            await axios({
                method: "post",
                url: REACT_APP_LIVE_BACKEND_BASE_URL+"api/add-lawyer-practicearea",
                data: new FormData(e.target),
                headers: { "Content-Type": "multipart/form-data" },
            }).then((response) => {
                const errors = response.data.errors;
                if (response.data.status) {
                    DvInnerHTML('.text-danger', '');
                    setAlert((<SweetAlert success title="" onConfirm={() => {
                        setAlert(null)
                    }} >{response.data.msg}</SweetAlert>))
                } else {
                    DvInnerHTML('.text-danger', '');
                    if (parseInt(Object.keys(errors).length) > 0) {
                        for (var key in errors) {
                            if (errors.hasOwnProperty(key)) {
                                if (document.getElementById(key + '-error') === null) {
                                } else {
                                    document.getElementById(key + '-error').innerHTML = errors[key];
                                }
                            }
                        }
                    } else {
                        setAlert((<SweetAlert danger title="" onConfirm={() => {
                            setAlert(null)
                        }} >{response.data.msg}</SweetAlert>))
                    }
                }
            });
        } catch (response) {
            console.log(response)
        }
    }

    $(document).on('change', "#profile_photo", function () {
        if (typeof (FileReader) != "undefined") {

            var image_holder = $(this).attr('previewimage');
            // image_holder.empty();

            var reader = new FileReader();
            reader.onload = function (e) {
                $("#"+image_holder).prop('src', e.target.result)
            }
            reader.readAsDataURL($(this)[0].files[0]);
        } else {
            alert("This browser does not support FileReader.");
        }
    });

    $(document).on('click', '#delete-photos', function(){
        var deleteattr = $(this).attr('deleteprev')
        $("."+deleteattr).val('')
        $("."+deleteattr).prop('src', '')
    });

    const fetchstateData = () => {
        fetch(StateURL).then((res) => res.json()).then((response) => {
            setState(response)
        })
    }

    const stateChange = (event) => {
        if(event.target.value != ''){
            fetch(CityUrl+'/'+event.target.value).then((res) => res.json()).then((response) => {
                setCity(response)
            })
        }
    }

    const handleUpdateSlogan = async (e) => {
        e.preventDefault();
        const form = e.target;
        const submitButton = form.querySelector('button[type="submit"]');
        submitButton.innerHTML = '<i class="fas fa-spinner fa-spin"></i>';
        submitButton.disabled = true;

        await axios({
            method: "post",
            url: REACT_APP_LIVE_BACKEND_BASE_URL + "api/lawyer-update-slogan",
            data: new FormData(e.target),
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            DvInnerHTML('.text-danger', '');
            submitButton.innerHTML = 'Submit';
            submitButton.disabled = false;
            setAlert((
                <SweetAlert 
                    success 
                    title="Success"
                    onConfirm={ () => { setAlert(null) } }
                >
                    {response.data.message}
                </SweetAlert>
            ));
        }).catch((error) => {
            DvInnerHTML('.text-danger', '');
            submitButton.innerHTML = 'Submit';
            submitButton.disabled = false;
            const errors = error.response.data;

            if(errors.message === undefined){
                Object.entries(errors.errors).forEach(([key, value]) => {
                    const elementId = `${key}_error`;
                    document.getElementById(elementId).innerHTML = value;
                });
            }else{
                setAlert((
                    <SweetAlert danger title="Oops!" onConfirm={ () => { setAlert(null) } }>
                        {errors.message}
                    </SweetAlert>
                ))
            }
        });
    }

    return (
        <div id="content" className="main-content">
            <div className="bg-custom">
                <div className="main-profile-sec">
                    <div className="profile-sec">
                        {
                            isLoader ? <DevtoCard /> : (
                                <form onSubmit={(e) => handleSubmit(e)}>
                                    <input type="hidden" name="update_side" value="1" />
                                    <div className="inner-guest-posts">
                                        <div className="inner-profile-sec">
                                            <div className="heading-title c-text-small">
                                                <p>My Account</p>
                                            </div>
                                            <div className="profile-edit-sec">
                                                <div className="row">
                                                    <input type="hidden" name="lawyer_id" id="lawyer_id" className="form-control" defaultValue={lawyerdata.id} />
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Name</label>
                                                            <input type="text" name="name" id="name" className="form-control" defaultValue={lawyerdata.name} />
                                                            <p className="text-danger" id="name-error"></p>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Office Phone</label>
                                                            <input type="text" name="office_phone" id="office_phone" defaultValue={lawyerdata.office_phone} className="form-control" />
                                                            <p className="text-danger" id="office_phone-error"></p>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Law Firm Name</label>
                                                            <input type="text" name="firmname" id="firmname" defaultValue={lawyerdata.firmname} className="form-control" />
                                                            <p className="text-danger" id="firmname-error"></p>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Mobile Phone</label>
                                                            <input type="text" name="mobile" id="mobile" defaultValue={lawyerdata.mobile} className="form-control" />
                                                            <p className="text-danger" id="mobile-error"></p>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Street Address</label>
                                                            <input type="text" name="street_address" id="street_address" defaultValue={lawyerdata.street_address} className="form-control" />
                                                            <p className="text-danger" id="street_address-error"></p>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Website</label>
                                                            <input type="text" name="website" id="website" defaultValue={lawyerdata.website} className="form-control" />
                                                            <p className="text-danger" id="website-error"></p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>State</label>
                                                            <select name="state" onChange={(e) => stateChange(e)} id="state" className="form-control get-city-bystate" defaultValue={lawyerdata.state}>
                                                                <option value="">Select State</option>
                                                                {
                                                                    state.map((item, i) => {
                                                                        return (<option value={ item.id } key={'state-key-'+i} selected={lawyerdata.state == item.id ? true : false}> { item.name }</option>)
                                                                    })
                                                                }
                                                            </select>
                                                            <p className="text-danger" id="state-error"></p>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>City</label>
                                                            <select name="city" id="city" className="form-control" defaultValue={lawyerdata.city}>
                                                                <option value="">Select City</option>
                                                                {
                                                                    city.map((item, i) => {
                                                                        return (<option value={ item.id } key={'city-key-'+i} selected={lawyerdata.city == item.id ? true : false}> { item.name }</option>)
                                                                    })
                                                                }
                                                            </select>
                                                            <p className="text-danger" id="city-error"></p>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Email</label>
                                                            <input type="text" name="email" id="email" defaultValue={lawyerdata.email} className="form-control" />
                                                            <p className="text-danger" id="email-error"></p>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Zip Code</label>
                                                            <input type="text" name="zipcode" id="zipcode" defaultValue={lawyerdata.zipcode}  className="form-control" />
                                                            <p className="text-danger" id="zipcode-error"></p>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="profile-img-sec">
                                                            <img src={lawyerdata.profile_photo} id="lawyerprofileimage" className="delete_prev_image"/>
                                                            <div className="action-button-profile">
                                                                <div className="upload-img-btn">
                                                                    <input type="file" name="profile_photo" id="profile_photo" previewimage="lawyerprofileimage" className="delete_prev_image"/>
                                                                    <span>Upload Profile Image</span>

                                                                </div>
                                                                <div className="delete-img-btn">
                                                                    <button type="button" deleteprev="delete_prev_image" id="delete-photos">Delete Image</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <p className="text-danger" id="profile_photo-error"></p>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Show Badge on profile</label>
                                                            <select name="show_badge" id="show_badge" className="form-control">
                                                                <option value="0" selected={lawyerdata.show_badge === 0 ? true : false}>No</option>
                                                                <option value="1" selected={lawyerdata.show_badge === 1 ? true : false}>Yes</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                </div>

                                                <div className="save-btn text-right p-0">
                                                    <button type="submit" className="mt-2">Save</button>
                                                </div>

                                            </div>

                                        </div>

                                    </div>
                                </form>
                            )
                        }
                        

                        <div className="profile-sec">
                            <div className="inner-guest-posts">

                                <div className="inner-profile-sec">
                                    <div className="heading-title c-text-small">
                                        <p>Law Firm Description</p>
                                    </div>
                                    <form onSubmit={(e) => submitLawFirmDescription(e)}>
                                        <div className="profile-edit-sec">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group height-80">
                                                        <input type="hidden" name="id" value={lawyerdata.id} />
                                                        <textarea name="firm_description" defaultValue={lawyerdata.firm_description} id="firm_description"></textarea>
                                                        <p className="text-danger" id="firm_description-error"></p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="save-btn text-right p-0">
                                                <button type="submit" className="mt-2">Save</button>
                                            </div>

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <div className='profile-sec' id="lawyer_firm_slogan">
                            <div className="inner-guest-posts">
                                <div className="inner-profile-sec">
                                    <div className="heading-title c-text-small">
                                        <p>Law Firm Slogan</p>
                                    </div>

                                    <form onSubmit={(e) => handleUpdateSlogan(e)}>
                                        <input type='hidden' name='lawyer_id' value={lawyerdata.id} />
                                        <div className="profile-edit-sec">
                                            <p>Please add your law firms slogan in 55 characrters or less</p>
                                            <div className='row'>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <input type="text" name="slogan" className="form-control" defaultValue={lawyerdata.slogan} />
                                                        <p className="text-danger" id="slogan_error"></p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="save-btn text-right p-0">
                                                <button type="submit" className="mt-2">Save</button>
                                            </div>
                                        </div>
                                    </form> 

                                </div>
                            </div>
                        </div>

                        <div className="profile-sec">
                            <div className="inner-guest-posts">

                                <div className="inner-profile-sec">
                                    <div className="heading-title c-text-small">
                                        <p>Practice Areas</p>
                                    </div>
                                    <form onSubmit={(e) => PracticeAreaSubmit(e)}>
                                        <input type="hidden" name="lawyer_id" id="lawyer_id" className="form-control" defaultValue={lawyerdata.id} />
                                        <div className="profile-edit-sec" id="">
                                            <div className="row">
                                                {data.map((item, i) => (
                                                    <div className="col-md-3" key={i}>
                                                        <ul className="unstyled p-0" key={i}>
                                                            {item.map((items, j) => (
                                                                <li key={j}>
                                                                    <span className="form-check p-0">
                                                                        <label>
                                                                        {lawyerdata.practice_area.includes(items.id) ? 
                                                                                <input type="checkbox" name="practice_area[]" defaultChecked  value={items.id} /> : 
                                                                                
                                                                                <input type="checkbox" name="practice_area[]"  value={items.id} />  
                                                                        } {items.name}
                                                            
                                                                                </label>
                                                                    </span>
                                                                </li>
                                                            ))}

                                                        </ul>
                                                    </div>
                                                ))}
                                            </div>
                                            <p className="text-danger" id="practice_area-error"></p>
                                            <div className="save-btn text-right p-0">
                                                <button type="submit" className="mt-2">Save</button>
                                            </div>

                                        </div>
                                    </form>

                                </div>

                            </div>

                        </div>

                        <LawLicense sates={state} />
                        <LawAward />
                        <ProfessionalExperience/>
                        <ProfessionalAssociation/>
                        <LawyerEducation/>
                        <FurtherReading/>
                        <SpeakingEngagement/>
                        <NotableSettlement/>
                        <LawyerAttorney/>
                        <LawyerSocials/>

                        <div className="profile-sec">
                            <div className="inner-guest-posts">

                                <div className="inner-profile-sec">
                                    <div className="heading-title c-text-small">
                                        <p>Google Map Location</p>
                                    </div>
                                    <form onSubmit={(e) => MapurlSubmit(e)}>
                                        <div className="profile-edit-sec">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <input type="hidden" name="lawyer_id" value={lawyerdata.id} />
                                                        <textarea defaultValue={lawyerdata.map_url} rows="8" name="lawyer_map" id="lawyer_map" className="form-control"></textarea>
                                                        <p className="text-danger" id="lawyer_map-error"></p>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="save-btn text-right p-0">
                                                <button type="submit" className="mt-2">Save</button>
                                            </div>

                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>

                        <PhotoVideoLink/>

                    </div>

                </div>
                <Admindashboardfooter />
                {Alert}
            </div>
        </div>
    )
}


export default EditLawyerProfile