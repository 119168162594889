import React, {useEffect, useState} from "react";
import Admindashboardheader from "../components/Admindashboardheader";
import Admindashboardsidebar from "../components/Admindashboardsidebar";
import Admindashboardfooter from "../components/Admindashboardfooter";
import { Link } from "react-router-dom";
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';

function PopularLocationList() {
    const  REACT_APP_LIVE_BACKEND_BASE_URL  = process.env.REACT_APP_LIVE_BACKEND_BASE_URL;
    const [popularLocationList, setPopularLocationList] = useState([]);
    const DvInnerHTML = function (target, newText) {
        document.querySelectorAll(target).forEach(function (element) {
            element.textContent = newText;
        });
    }
    const [Alert, setAlert] = useState(null);

    useEffect(() => {
        document.title = 'Popular Location List'; 
        fetchPopularLocationList();
    }, [])

    const fetchPopularLocationList = () => {
        fetch(REACT_APP_LIVE_BACKEND_BASE_URL+"api/get-popular-location-list")
            .then((res) =>
                res.json())

            .then((response) => {
                setPopularLocationList(response.popularlocationdata);
            })


    }

    const DeletePopularLocation = async (e) => {
        var removeid = e.target.getAttribute("locationid");
        if(removeid !== null){
                try {
                    // make axios post request
                    const response = await axios({
                        method: "post",
                        url: REACT_APP_LIVE_BACKEND_BASE_URL+"api/delete-popular-location",
                        data: {
                            locationid:removeid,
                        },
                        headers: { "Content-Type": "multipart/form-data" },
                    }).then((response) => {
                        const errors = response.data.errors;
                        if (response.data.status) {
                            DvInnerHTML('.text-danger', '');
                           
                            setAlert((<SweetAlert success title="" onConfirm={() => {
                                setAlert(null)
                                window.location.reload();
                            }} >{response.data.msg}</SweetAlert>))
                        } else {
                            DvInnerHTML('.text-danger', '');
                            if (parseInt(Object.keys(errors).length) > 0) {
                                for (var key in errors) {
                                    if (errors.hasOwnProperty(key)) {
                                        if (document.getElementById(key + '-error') === null) {
                                        } else {
                                            document.getElementById(key + '-error').innerHTML = errors[key];
                                        }
                                    }
                                }
                            } else {
                                setAlert((<SweetAlert danger title="" onConfirm={() => {
                                    setAlert(null)
                                }} >{response.data.msg}</SweetAlert>))
                            }
                        }
                    });
                } catch (response) {
                    console.log(response)
                }
        
        }
    }

    return (
        <>
            <Admindashboardheader />
            <div className="wrapper d-flex align-items-stretch">
                <Admindashboardsidebar />
                <div id="content" className="main-content">

                    <div className="bg-custom">

                        <div className="main-profile-sec">
                            <div className="profile-sec">
                                <div className="inner-guest-posts">

                                    <div className="inner-profile-sec">

                                        <div className="row align-items-center">

                                            <div className="col-md-6">
                                                <div className="heading-title c-text-small">
                                                    <p>Popular Location List</p>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group posts-title text-right p-0">
                                                    <input type="text" name="search" placeholder="Search..." className="" id="" />
                                                </div>
                                            </div>

                                        </div>

                                        <div className="add-more-btn text-right">
                                            <Link to="/add-popular-location"> <i className="fa-solid fa-plus"></i> Add Popular Location</Link>
                                        </div>

                                        <div className="data-table-sec table-responsive admin-data-table">

                                            <table id="example" className="display" cellspacing="0" width="100%">
                                                <thead>
                                                    <tr>
                                                        <th>S No.</th>
                                                        <th>Title Name</th>
                                                        <th>Link</th>
                                                        <th className="text-right" style={{ width: "22%" }}>Actions</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                {popularLocationList !== null ? (<>{popularLocationList.map((item, i) => {

                                                return (
                                                <tr>
                                                    <td> {i+1}. </td>
                                                    <td> {item.name} </td>
                                                    <td> {item.link} </td>
                                                    <td className="action-btn text-right">
                                                        <Link to={"/edit-popular-location/"+item.id} className="edit-btn" > <i className="fa-solid fa-edit"></i> Edit
                                                        </Link>
                                                        <button className="edit-btn" locationid={item.id} onClick={DeletePopularLocation}> <i className="fa-solid fa-trash"></i> Delete
                                                        </button>
                                                    </td>
                                                </tr>
                                                )
                                                })}</>) : <>No Data Found</>} 
                                                    

                                                </tbody>

                                            </table>
                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>
                    {Alert}
                    <Admindashboardfooter />

                </div>
            </div>
        </>
    )
}

export default PopularLocationList
