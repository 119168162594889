import React, {useEffect, useState} from "react";
import Admindashboardfooter from "../components/Admindashboardfooter"
import axios from 'axios'
import SweetAlert from 'react-bootstrap-sweetalert'
import { useHistory } from "react-router-dom"

function Addbadge() {
    useEffect(() => {
        document.title = 'Add Badge'
    }, [])
    const REACT_APP_LIVE_BACKEND_BASE_URL = process.env.REACT_APP_LIVE_BACKEND_BASE_URL;
    const URL = REACT_APP_LIVE_BACKEND_BASE_URL + 'api/add-badge';
    const [Alert, setAlert] = useState(null);
    const [isDisabled, setDisabled] = useState(false)
    let history = useHistory()

    const DvInnerHTML = function (target, newText) {
        document.querySelectorAll(target).forEach(function (element) {
            element.textContent = newText;
        });
    }

    const badgeAdd = async (event) => {
        event.preventDefault();
        setDisabled(true);
        const form = document.getElementById('add-badge');
        const data = new FormData(form);
        await axios({
            method: "post",
            url: URL,
            data: data,
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            setDisabled(false)
            const message = response.data.message
            DvInnerHTML('.text-danger', '')
            setAlert((
                <SweetAlert success title="Done" onConfirm={() => {
                    setAlert(null)
                    history.push('./../badge/')
                }}>{message}</SweetAlert>
            ))
        }).catch((error) => {
            setDisabled(false);
            const errors = error.response.data.message
            DvInnerHTML('.text-danger', '')
            if (parseInt(Object.keys(errors).length) > 0) {
                for (var key in errors) {
                    if (errors.hasOwnProperty(key)) {
                        if (document.getElementById(key + '_error') === null) {} else {
                            document.getElementById(key + '_error').innerHTML = errors[key]
                        }
                    }
                }
            } else {
                setAlert((
                    <SweetAlert danger title="Ooops!" onConfirm={() => { setAlert(null) }}>
                        {error.message}
                    </SweetAlert>
                ))
            }
        })
    }

    return (
        <div id="content" className="main-content">
            <div className="bg-custom">
                <div className="main-profile-sec">
                    <form id="add-badge" onSubmit={badgeAdd}>
                        <div className="profile-sec">
                            <div className="inner-guest-posts">
                                <div className="inner-profile-sec">
                                    <div className="heading-title c-text-small">
                                        <p>Add Badge</p>
                                    </div>

                                    <div className="profile-edit-sec">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Title</label>
                                                    <input type="text" name="title" className="form-control"/>
                                                    <span className="text-danger" id="title_error"></span>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Title Image</label>
                                                    <input type="file" name="title_image" className="form-control"/>
                                                    <span className="text-danger" id="title_image_error"></span>
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>Description</label>
                                                    <textarea name="description" rows={4}></textarea>
                                                    <span className="text-danger" id="description_error"></span>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Website Link</label>
                                                    <input type="text" name="link_text" className="form-control" />
                                                    <span className="text-danger" id="link_text_error"></span>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Alt Text</label>
                                                    <input type="text" name="alt_text" className="form-control"  />
                                                    <span className="text-danger" id="alt_text_error"></span>
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>Regular Image</label>
                                                    <input type="file" name="regular_image" className="form-control"/>
                                                    <span className="text-danger" id="regular_image_error"></span>
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>Transparent Image</label>
                                                    <input type="file" name="transparent_image" className="form-control"/>
                                                    <span className="text-danger" id="transparent_image_error"></span>
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>Inverse Image</label>
                                                    <input type="file" name="inverse_image" className="form-control"/>
                                                    <span className="text-danger" id="inverse_image_error"></span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="save-btn text-right p-0">
                                            <button disabled={isDisabled} type="submit" className="mt-2">Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {Alert}
            <Admindashboardfooter/>
        </div>
    )
}

export default Addbadge
