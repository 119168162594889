import React, {useEffect} from "react";
import Admindashboardheader from "../components/Admindashboardheader"
import Admindashboardsidebar from "../components/Admindashboardsidebar"
import Admindashboardfooter from "../components/Admindashboardfooter"

function Addwebinars() {

    useEffect(() => {
        document.title = 'Add Webinars';
    }, [])
    return (
        <>
            <Admindashboardheader />
            <div className="wrapper d-flex align-items-stretch">
                <Admindashboardsidebar />
                <div id="content" className="main-content">

                    <div className="bg-custom">

                        <div className="main-profile-sec">
                            <form>
                                <div className="profile-sec">
                                    <div className="inner-guest-posts">

                                        <div className="inner-profile-sec">
                                            <div className="heading-title c-text-small">
                                                <p>Add Webinars</p>
                                            </div>

                                            <div className="profile-edit-sec">
                                                <div className="row">

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Name</label>
                                                            <input type="text" name="" id="" className="form-control"/>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Email</label>
                                                            <input type="text" name="" id="" className="form-control"/>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Company Name</label>
                                                            <input type="text" name="" id="" className="form-control"/>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Session</label>
                                                            <input type="text" name="" id="" className="form-control"/>
                                                        </div>
                                                    </div>

                                                </div>

                                                <div className="save-btn text-right p-0">
                                                    <button type="button" className="mt-2">Save</button>
                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </form>

                        </div>

                        <div className="main-profile-sec">
                            <form>
                                <div className="profile-sec">
                                    <div className="inner-guest-posts">

                                        <div className="inner-profile-sec">
                                            <div className="heading-title c-text-small">
                                                <p>Add Webinars videos</p>
                                            </div>

                                            <div className="profile-edit-sec">
                                                <div className="row">

                                                    <div className="col-md-12">
                                                        <div className="form-group row">
                                                            <label className="col-2">Video URL</label>
                                                            <div className="col-10">
                                                                <input type="text" name="" id="" className="form-control"/>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <div className="form-group row">
                                                            <label className="col-2">Title</label>
                                                            <div className="col-10">
                                                                <input type="text" name="" id="" className="form-control"/>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <div className="form-group row">
                                                            <label className="col-2">Description</label>
                                                            <div className="col-10">
                                                                <textarea name="" id=""></textarea>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="profile-img-sec thumbnail-img">
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/dummy-image.jpg`} />
                                                                <div className="upload-img-btn">
                                                                    <input type="file"/>
                                                                        <span>Thumbnail Image</span>
                                                                </div>
                                                                <div className="delete-img-btn">
                                                                    <button type="button">Delete Image</button>
                                                                </div>
                                                        </div>
                                                    </div>

                                                </div>

                                                <div className="save-btn text-right p-0">
                                                    <button type="button" className="mt-2">Save</button>
                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </form>

                        </div>

                    </div>
                    <Admindashboardfooter />

                </div>
            </div>
        </>
    )
}

export default Addwebinars