import React, {useState} from "react"
import { Link } from "react-router-dom"
import Admindashboardfooter from "../components/Admindashboardfooter"
import axios from 'axios'
import SweetAlert from 'react-bootstrap-sweetalert'
import DataTable from "../../helper/DataTable"

function Badge() {
    const [pageLinks, setPageLinks] = useState([]);
    const [allBadegs, setAllBadegs] = useState([]);
    const [Alert, setAlert] = useState(null);
    const [isLoader, setLoader] = useState(true)
    const  REACT_APP_LIVE_BACKEND_BASE_URL  = process.env.REACT_APP_LIVE_BACKEND_BASE_URL
    const getBadgeData = async (URL='', search='') => {
        if(URL === ''){
            URL = REACT_APP_LIVE_BACKEND_BASE_URL+'api/get-badges';
        }

        if(search !== ''){
            URL = `${URL}?param_search=${search}`
        }

        await axios({
            method: "get",
            url: URL,
            headers: {
                "Content-Type": "application/json"
            }
        }).then((response) => {
            const data = response.data;
            setPageLinks(data.data.links);
            setAllBadegs(data.data.data);
            setLoader(false)
        }).catch((error) => {
            console.log(error)
        });
    }

    React.useEffect(() => {
        document.title = 'Badge';
        getBadgeData()
    }, []);

    const handleClick = (e) => {
        var next = e.currentTarget.getAttribute('nextpage');
        if(next !== null){
            getBadgeData(next)
        }
    }
   
    const searchFilter = (event) => {
        const searchVal = event.target.value
        getBadgeData('', searchVal)
    }

    const deleteBadge = async (id) => {
        const url = REACT_APP_LIVE_BACKEND_BASE_URL+"api/badge/"+id;
        setAlert(
            <SweetAlert
                warning
                showCancel
                confirmBtnText="Yes, delete it!"
                confirmBtnBsStyle="danger"
                cancelBtnText="No, cancel"
                cancelBtnBsStyle="success"
                title="Are you sure?"
                onConfirm={() => deleteItem(url)}
                onCancel={() => setAlert(null)}
            >
                You won't be able to revert this!
            </SweetAlert>
        );  
    }

    const deleteItem = async (url) => {
        setAlert(null);
        await axios({
            method: "delete",
            url: url,
        }).then((response) => {   
            getBadgeData();         
            setAlert((
                <SweetAlert success title="Success" onConfirm={() => { setAlert(null)}}>
                    {response.message}
                </SweetAlert>
            ))
        }).catch((error) => {
            const errors = error.response.data;
            setAlert((
                <SweetAlert danger title="Oops!" onConfirm={() => {setAlert(null)}}>
                    {errors.message}
                </SweetAlert>
            ))
        });
    }
   
    return (
        <div id="content" className="main-content">
            <div className="bg-custom">
                <div className="main-profile-sec">
                    <div className="profile-sec">
                        <div className="inner-guest-posts">

                            <div className="inner-profile-sec">

                                <div className="row align-items-center">

                                    <div className="col-md-6">
                                        <div className="heading-title c-text-small">
                                            <p>Badge</p>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group posts-title text-right p-0 m-0">
                                            <input type="text" name="search" placeholder="Search..." onKeyUp={searchFilter} />
                                        </div>
                                    </div>

                                </div>

                                <div className="add-more-btn text-right custom-add-btn-space">
                                    <Link to="/add-badge/"> <i className="fa-solid fa-plus"></i> Add Badge </Link>
                                </div>

                                <div className="data-table-sec table-responsive admin-data-table">

                                    <table id="example" className="display custom-admin-data-table" cellspacing="0" width="100%">
                                        <thead>
                                            <tr>
                                                <th>Title Image</th>
                                                <th>Title</th>
                                                <th>Description</th>
                                                <th>Badge</th>
                                                <th>Link</th>
                                                <th>Alt Text</th>
                                                <th className="text-right" style={{width: "27%"}}>Actions</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {isLoader ? <DataTable /> : allBadegs.map((badeg, key) => {
                                                return (
                                                    <tr key={key}>
                                                        <td className="t-logo-img">
                                                            <img src={badeg.title_image} alt={`title image ${key}`} />
                                                        </td>
                                                        <td> {badeg.title} </td>
                                                        <td> {badeg.description} </td>
                                                        <td className="t-logo-img">
                                                            <img src={badeg.regular_image} alt={`regular image ${key}`} />
                                                        </td>
                                                        <td>{badeg.link_text}</td>
                                                        <td>{badeg.alt_text}</td>
                                                        <td className="action-btn text-right">
                                                            <button type="button" onClick={() => deleteBadge(badeg.id)} className="edit-btn"> <i className="fa-solid fa-eye"></i> Delete </button>
                                                            <Link to={"/badge/"+badeg.id+"/"} className="edit-btn"> <i className="fa-regular fa-pen-to-square"></i> Edit </Link>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="lawyer-profile-pagination mb-5">
                                    {
                                        pageLinks.map((item, i) => {
                                            return(
                                                <>
                                                    {item.label === '&laquo; Previous' && item.url ? (<button type="button" onClick={(e) => handleClick(e)} nextpage={item.url} className={item.url != null ? "prev active" : "prev"} key={i}>Previous</button>) : ""}

                                                    {item.label !== '&laquo; Previous' && item.label !== 'Next &raquo;' ? (<button type="button" onClick={(e) => handleClick(e)} nextpage={item.url} className={item.active === true ? "count active" : "count"} key={i}>{item.label}</button>) : ""}

                                                    {item.label === 'Next &raquo;' && item.url ? (<button type="button" onClick={(e) => handleClick(e)} nextpage={item.url} className={item.url != null ? "next active" : "next"} key={i}>Next</button>) : ""}
                                                </>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {Alert}
            <Admindashboardfooter />
        </div>
    )
}

export default Badge