import React, {useEffect, useState} from 'react';
import { useParams, Link } from 'react-router-dom';
import $ from 'jquery';


function ClaimProfile(){

    let {name, lawyerstate, lawyercity} = useParams();
    const  REACT_APP_LIVE_BACKEND_BASE_URL  = process.env.REACT_APP_LIVE_BACKEND_BASE_URL;
    const [lawyerList, setLawyerList] = useState([]);
    const [pageLinks, setPageLinks] = useState([]);
    const [totalEntry, setTotalEntry] = useState('');
    const StateURL = REACT_APP_LIVE_BACKEND_BASE_URL+'api/get-states-list';
    const [state, setState]  = useState([]);

    useEffect(() => {
        document.title = 'Claim profile';
        SubmitSearch();
        fetchstateData();
    }, []);

    const SubmitSearch = async (e) => {
        if(name != '' && lawyerstate != ''){
            fetch(REACT_APP_LIVE_BACKEND_BASE_URL+"api/search-lawyer-profile/"+name+"/"+lawyerstate+"/"+lawyercity)
            .then((res) =>res.json())
            .then((response) => {
                setLawyerList(response.data);
                setPageLinks(response.links);
                setTotalEntry(response.total);
            })
        }else{
            return false;
        }
    }

    const handleClick = (e) => {
        e.preventDefault();
        var next = e.currentTarget.getAttribute('nextpage');
        if(next != null){
        fetch(next)
            .then((res) => res.json())
            .then((response) => {
                setLawyerList(response.data);
                setPageLinks(response.links);
                setTotalEntry(response.total);
            })
            $(window).scrollTop(0)
        }
      }

      const fetchstateData = () => {
        fetch(StateURL).then((res) => res.json())
        .then((response) => {
            setState(response);
        })
    }

    return(
        <>        
        <section class="pages-breadcrumb mt-3">
            <div class="container">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><Link to="/">Claim Your Profile</Link></li>
                        <li class="breadcrumb-item active" aria-current="page">Search Results</li>
                    </ol>
                </nav>
            </div>
        </section>

        <section class="claim-profile-search">
            <div class="container">
                <div class="inner-claim-profile-search">
                    <div class="title">
                        <h1>Lawyer's <span class="result-found">— {totalEntry} found</span></h1>
                    </div>

                    <div class="row">
                        <div class="col-md-8">
                            <div class="left-profile-search-list">
                                <div class="pagination-sec">
                                    <div class="pagination">
                                    {pageLinks.map((item, i) => {
                                            return(
                                                <>
                                                {item.label == '&laquo; Previous' ?
                                                ('') : (<a href="#" key={i} onClick={handleClick} nextpage={item.url} className={item.active == true ? "active" : ""} >{item.label}</a>) &&
                                                
                                                item.label == 'Next &raquo;' ? (<a href="#" key={i} onClick={handleClick} nextpage={item.url} className={item.url != null ? "active" : ""} ><i class="fa-solid fa-arrow-right"></i></a>) : (<a href="#" 
                                                key={i} onClick={handleClick} nextpage={item.url} className={item.active == true ? "active" : ""}  >{item.label}</a>)}
                                                </>
                                            )
                                        })}
                                    </div>
                                </div>

                                <div class="shorting">
                                    <form>
                                        <div class="form-group">
                                            <label> Sort by:
                                                <select name="" id="">
                                                    <option value="">Best Match</option>
                                                    <option value="">Last Name</option>
                                                </select>
                                            </label>
                                        </div>
                                    </form>
                                </div>

                                <div class="search-profile-lists-content">
                                    <ul>
                                        {lawyerList.length > 0 ? lawyerList.map((item, i) => {
                                            return(<li key={i}>
                                                <span class="row" key={i}>
                                                    <span class="col-3 col-sm-3 col-md-2 col-lg-2">
                                                        <span class="profile-list-img">
                                                            <img src={item.profile_photo}/>
                                                        </span>
                                                    </span>

                                                    <span class="col-9 col-sm-9 col-md-6 col-lg-7">
                                                        <span class="profile-list-content">
                                                            <a href="#" class="l-name">{item.name}</a>
                                                            <p class="profile-address"> {item.street_address}, {state.map((istate, j) => {
                                                                return(item.state != null ? (item.state.includes(istate.id) ? istate.name : '') : "")
                                                            })} </p>
                                                            <span class="licence-valid">{item.email !== undefined && item.email !== ""  ? item.email : ""}</span>
                                                        </span>
                                                    </span>

                                                    <span class="col-12 col-sm-12 col-md-4 col-lg-3">
                                                        <span class="claim-btn">
                                                            <Link to={"/single-claim-profile/"+item.id+"/"}>Claim profile</Link>
                                                        </span>
                                                    </span>

                                                </span>
                                            </li>)
                                        }) : (<p>No Data Found For This Information. <Link to="/signup/" rel="nofollow">Sign up</Link></p>)}
                                    </ul>
                                </div>

                                <div class="pagination-sec border-0 m-0">
                                    <div class="pagination">
                                        {pageLinks.map((item, i) => {
                                            return(
                                                <>
                                                {item.label == '&laquo; Previous' ?
                                                ('') : (<a href="#" onClick={handleClick} nextpage={item.url} className={item.active == true ? "active" : ""} key={i}>{item.label}</a>) &&
                                                
                                                item.label == 'Next &raquo;' ? (<a href="#" onClick={handleClick} nextpage={item.url} className={item.url != null ? "active" : ""}  key={i}><i class="fa-solid fa-arrow-right"></i></a>) : (<a href="#" onClick={handleClick} nextpage={item.url} className={item.active == true ? "active" : ""}  key={i}>{item.label}</a>)}
                                                </>
                                            )
                                        })}
                                    </div>
                                </div>

                                <div class="list-profile-note">
                                    <p>If you do not see your law firm profile, click the <strong><Link to="/signup/" rel="nofollow">Get Listed</Link></strong> button to create one quickly and easily.</p>
                                </div>

                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="claim-profile-form-content right-cpf-content">
                                <p>Claiming your free STI Profile lets you...</p>
                                <ul>
                                    <li>Set yourself apart from your colleagues to attract new business</li>
                                    <li>Update your profile with as much information as you want, as often as you want</li>
                                    <li>Request reviews and peer endorsements</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>)
}

export default ClaimProfile