import React, {useState, useEffect} from "react";
import Admindashboardheader from "../components/Admindashboardheader"
import Admindashboardsidebar from "../components/Admindashboardsidebar"
import Admindashboardfooter from "../components/Admindashboardfooter"
import axios from 'axios';
import $ from 'jquery';
import SweetAlert from 'react-bootstrap-sweetalert';
import Cookies from 'universal-cookie';

function AddLogo() {

    const  REACT_APP_LIVE_BACKEND_BASE_URL  = process.env.REACT_APP_LIVE_BACKEND_BASE_URL;
    const DvInnerHTML = function (target, newText) {
        document.querySelectorAll(target).forEach(function (element) {
            element.textContent = newText;
        });
    }
    const [adminData, setAdminData] = useState([])
    const LAWYERURL = REACT_APP_LIVE_BACKEND_BASE_URL+'api/get-admin-data/';
    const cookies = new Cookies(); 
    var tokenid = cookies.get('ADMIN');

    useEffect(() => {
        document.title = 'Add Logo';
        fetchlawyerdata();
    }, [])

    const fetchlawyerdata = () => {
        fetch(LAWYERURL+tokenid)
            .then((res) =>
                res.json())

            .then((response) => {
                setAdminData(response.userdata);
            })


    }
    const [Alert, setAlert] = useState(null);

    const LogoSubmit = async (e) => {
        e.preventDefault();
        try {
            // make axios post request
            const response = await axios({
                method: "post",
                url: REACT_APP_LIVE_BACKEND_BASE_URL+"api/add-logo/"+tokenid,
                data: new FormData(e.target),
                headers: { "Content-Type": "multipart/form-data" },
            }).then((response) => {
                const errors = response.data.errors;
                if (response.data.status) {
                    DvInnerHTML('.text-danger', '');
                   
                    setAlert((<SweetAlert success title="" onConfirm={() => {
                        setAlert(null)
                        window.location.reload();
                    }} >{response.data.msg}</SweetAlert>))
                } else {
                    DvInnerHTML('.text-danger', '');
                    if (parseInt(Object.keys(errors).length) > 0) {

                        for (var key in errors) {
                            if (errors.hasOwnProperty(key)) {
                                if (document.getElementById(key + '-error') === null) {
                                } else {
                                    document.getElementById(key + '-error').innerHTML = errors[key];
                                }
                            }
                        }
                    } else {
                        setAlert((<SweetAlert danger title="" onConfirm={() => {
                            setAlert(null)
                        }} >{response.data.msg}</SweetAlert>))
                    }
                }
            });
        } catch (response) {
            console.log(response)
        }
    }

    $(document).on('change', "#logo", function () {
        if (typeof (FileReader) != "undefined") {

            var image_holder = $(this).attr('preview');

            var reader = new FileReader();
            reader.onload = function (e) {
                $("#" + image_holder).prop('src', e.target.result)
            }
            reader.readAsDataURL($(this)[0].files[0]);
        } else {
            alert("This browser does not support FileReader.");
        }
    });


    return (
        <>
            <Admindashboardheader />
            <div className="wrapper d-flex align-items-stretch">
                <Admindashboardsidebar />
                <div id="content" className="main-content">

                    <div className="bg-custom">

                        <div className="main-profile-sec">
                            <form onSubmit={LogoSubmit} encType="multipart/form-data">
                                <div className="profile-sec">
                                    <div className="inner-guest-posts">

                                        <div className="inner-profile-sec">
                                            <div className="heading-title c-text-small">
                                                <p>Add Website Logo</p>
                                            </div>

                                            <div className="profile-edit-sec">
                                                <div className="row">

                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <div className="row">
                                                                <label className="col-2">Logo Image</label>
                                                                <div className="col-10">
                                                                    <input type="file" preview="preview-logo-image" name="logo" id="logo" className="form-control"/>
                                                                <p class="text-danger" id="logo-error"></p>
                                                                <div class="show-preview">
                                                                    <img src={adminData.logo_image !== null ?  REACT_APP_LIVE_BACKEND_BASE_URL+"public" + adminData.logo_image : ''} id="preview-logo-image" />
                                                                </div>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>

                                                <div className="save-btn text-right p-0">
                                                    <button type="submit" className="mt-2">Save</button>
                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </form>

                        </div>

                    </div>
                    {Alert}
                    <Admindashboardfooter />

                </div>
            </div>
        </>
    )
}

export default AddLogo