import React, {useEffect, useState, useContext} from 'react'
import { useParams, Link } from 'react-router-dom'
import $ from 'jquery'
import { Helmet } from "react-helmet"
import { Instagram, Code } from 'react-content-loader'
import SchemaComponent from './components/SchemaComponent'

function LawyerProfileByPractice() {
    const [lawyerList, setLawyerList] = useState([]);
    const [pageLinks, setPageLinks] = useState([]);
    const [lastPage, setLastPage] = useState(1);
    const [totalLawyers, setTotalLawyers] = useState(0)
    const [metaTitle, setMetaTitle] = useState("Best Lawyers In USA")
    const [metaDescription, setMetaDescription] = useState("Top Rated Lawyers in USA! Consult Now for free")
    const [badge, getBadge] = useState(0)
    const [isLoader, setLoader ] = useState(true)
    const [isTextLoader, setTextLoader ] = useState(true)
    let {lawyercity, lawyerstate, practice} = useParams();
    const  REACT_APP_LIVE_BACKEND_BASE_URL  = process.env.REACT_APP_LIVE_BACKEND_BASE_URL;
    const LAWYERLISTURL = REACT_APP_LIVE_BACKEND_BASE_URL+'api/get-front-lawyer-list/'+lawyerstate+'/'+lawyercity+'/'+practice;
    const BannerURL = REACT_APP_LIVE_BACKEND_BASE_URL+'api/get-sidebar-image';
    const [bannerImages, setBannerImages]  = useState([])
    const [practiceArea, setPracticeArea] = useState({})
    const [searchState, setSearchState] = useState(null)
    const [searchCity, setSearchCity] = useState(null)
        
    useEffect(() => {
        const fetchDataAsync = async () => {
            try {
                await Promise.all([
                    SubmitSearch(4),
                    fetchBadge(),
                    fetchBannerData(),
                    fetchPracticeArea()
                ]);
            } catch (error) {
                console.error("An error occurred:", error)
            }
        }

        fetchDataAsync();
    }, [])
    const SubmitSearch = async (e) => {
        if(practice != '' && lawyerstate != ''){
            fetch(LAWYERLISTURL).then((res) => res.json()).then((response) => {
                if(Object.keys(response).length > 0) {
                    setLawyerList(response.data)
                    setPageLinks(response.links)
                    setLastPage(response.last_page)
                    setTotalLawyers(response.total)
                }
            })
        }else{
            return false;
        }
    }

    const fetchState = (practiceArea) => {
        fetch(REACT_APP_LIVE_BACKEND_BASE_URL+"api/get-state/"+lawyerstate).then((res) => res.json()).then((response) => {
            fetchCity(practiceArea, response)
            setSearchState(response)
        })
    }

    const fetchCity = (practiceArea, state) => {
        fetch(REACT_APP_LIVE_BACKEND_BASE_URL+"api/get-city/"+lawyercity).then((res) => res.json()).then((response) => {
            let title = response.meta_title || "Best {practice_area} Lawyers in {city_name}, {state_name}"
            let description = response.meta_description || "Best {practice_area} lawyers in {city_name}. Connect with expert legal professionals based on peer & client reviews and get the help and compensation you deserve."
            title = title.replace("{practice_area}", practiceArea.name).replace("{state_name}", state.short_name).replace("{city_name}", response.name)
            description = description.replace("{practice_area}", practiceArea.name).replace("{state_name}", state.short_name).replace("{city_name}", response.name)
            setSearchCity(response)
            setMetaTitle(title)
            setMetaDescription(description)
            setLoader(false)
            setTextLoader(false)
        })
    }

    const fetchBannerData = () => {
        fetch(BannerURL).then((res) => res.json()).then((response) => {
            setBannerImages(response.data);
        })
    }
    
    const fetchPracticeArea = () => {
        fetch(REACT_APP_LIVE_BACKEND_BASE_URL+"api/lawyer-practice-area-slug/"+practice).then((res) => res.json()).then((response) => {
            if(Object.keys(response).length == 0) {
                window.location.assign(process.env.REACT_APP_BASE_URL)
            }

            fetchState(response)
            setPracticeArea(response)
        })
    }

    const fetchBadge = () => {
        fetch(REACT_APP_LIVE_BACKEND_BASE_URL+"api/get-badge").then((res) => res.json()).then((response) => {
            getBadge(response.data);
        })
    }

    const handleClick = (e) => {
        e.preventDefault();
        var next = e.currentTarget.getAttribute('nextpage');
        if(next != null){
            fetch(next).then((res) => res.json()).then((response) => {
                setLawyerList(response.data);
                setPageLinks(response.links);
            })
            $(window).scrollTop(0)
        }
    }

    return (
        <div>
            <Helmet>
                <title>{metaTitle}</title>
                <meta name="description" content={metaDescription} />
                <link rel="canonical" href={window.location.href.toLowerCase()} />
            </Helmet>
            
            <section className="pages-breadcrumb mt-3">
                <div className="container">
                    {
                        isTextLoader ? <Code /> : (
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <a href={process.env.REACT_APP_BASE_URL} rel="noopener noreferrer">Home</a>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="/">Find a Lawyer</Link>
                                    </li>
                                    <li className="breadcrumb-item" aria-current="page">
                                        <Link to={`/${practiceArea?.slug}/`}>{ practiceArea.name }</Link>
                                    </li>
                                    <li className="breadcrumb-item" aria-current="page">
                                        <Link to={`/${practiceArea?.slug}/${searchState?.slug}/`}>{ searchState?.name }</Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">{ searchCity?.name }</li>
                                </ol>
                            </nav>
                        )
                    }
                </div>
            </section>
            <section className="lawyer-profile">
                <div className="container">
                    <div className="inner-lawyer-profile-sec">
                        <div className="row">
                            <div className="col-md-9">
                                <div className="top-title">
                                    {
                                        isTextLoader ? <Code /> : (<>
                                                <h1>Best {searchCity ? searchCity.name+"," : ""} {searchState ? searchState.name+"," : ""} {practiceArea ? practiceArea.name+" Lawyers": "Lawyers"} Near You</h1>
                                                <p>{totalLawyers} {parseInt(totalLawyers) > 1 ? "Lawyers":"Lawyer"} in {searchCity?.name} who specialize in {practiceArea?.name} can be compared based on peer and client reviews. Evaluate their feedback to choose the most suitable lawyer for your legal needs.</p>
                                            </>)
                                    }
                                    
                                </div>

                                {
                                    isLoader ? <Instagram /> : (
                                        <div className="left-lawyer-profile-sec">
                                    {lawyerList.length > 0 ? (<> {lawyerList.map((item, i) => {
                                        return(<div className="inner-lawyer-profile outter-inner-lawyer-profile" key={i}>
                                                    <div className="row">
                                                        <div className="col-md-2">
                                                            <div className="lawyer-profile-img lawyer-profile-outer-img">
                                                                {
                                                                    item.subscription_status === 'Active' ? (
                                                                        <Link to={`/lawyer/${item.slug}/`}>
                                                                            <img src={item.profile_photo} className='img-fluid' alt={item.name} loading='lazy' />
                                                                        </Link>
                                                                    ) : (
                                                                        <img src={item.profile_photo} className='img-fluid' alt={item.name} loading='lazy' />
                                                                    )
                                                                }
                                                            </div>
                                                            {item.subscription_status === 'Active' ? (<>
                                                                <div className="visit-btn">
                                                                    <Link to={`/lawyer/${item.slug}/`} rel="nofollow"><i className="fa-solid fa-user"></i> View Profile </Link>
                                                                </div>
                                                            </>) : ''}
                                                        </div>

                                                        <div className="col-md-7">
                                                            <div className="lawyer-details">
                                                                <div className="lawyer-name">
                                                                    {
                                                                        item.subscription_status === 'Active' ? (
                                                                            <Link to={`/lawyer/${item.slug}/`}><h5>{item.name}</h5></Link>
                                                                        ) : (<h5>{item.name}</h5>)
                                                                    }
                                                                </div>

                                                                <div className="lawyer-type">
                                                                    <span>{item.firmname}</span>
                                                                </div>

                                                                <div className="lawyer-address">
                                                                    <p className="text-muted"><i className="fa-solid fa-map-location-dot"></i>{item.street_address}{item.states?`, ${item.states.name}`:""}{item.cities ?`, ${item.cities.name}`:""}{item.zipcode ? `, ${item.zipcode}`: ""}</p>
                                                                </div>

                                                                {
                                                                    item.slogan ? (
                                                                        <div className="slogan-text">
                                                                            <p>{item.slogan}</p>
                                                                        </div>
                                                                    ) : ""
                                                                }
                                                                    
                                                                <div className="lawyer-review-year-info">        
                                                                    {
                                                                        item.year_in_business ? (
                                                                            <div className="yeared-licence">
                                                                                <p className="text-muted"> <span><i className="fa-solid fa-building-columns"></i></span> <small>{item.year_in_business} years in business</small> </p>
                                                                            </div>
                                                                        ) : ""
                                                                    }
                                                                </div>
                                                                
                                                                <div className="lawyer-discription">
                                                                    <p className="text-muted">{item.firm_description != null ? item.firm_description.substring(0, 150) : ''}..</p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-3">
                                                            <div className="lawyer-contant">
                                                                {
                                                                    badge && item.show_badge ? (
                                                                        <div className="lawyer-badge-img">
                                                                            <img src={badge.regular_image} alt={badge.title} className='img-fluid' loading='lazy' />
                                                                        </div>
                                                                    ) : ""
                                                                }
                                                                
                                                                {item.office_phone ? (<div className="lawyer-contact-number">
                                                                    {item.subscription_status === 'Active' ? (<a href={"tel:" + item.office_phone} rel="nofollow"><i className="fa-solid fa-phone"></i>{`(${String(item.office_phone).substring(0, 3)}) ${String(item.office_phone).substring(3, 6)}-${String(item.office_phone).substring(6, 10)}`}</a>
                                                                    ) : ''}
                                                                </div>) : ''}

                                                                {item.email ? (<div className="lawyer-email">
                                                                    {item.subscription_status === 'Active' ? (<a href={"mailto:" + item.email} rel="nofollow"><i className="fa-solid fa-envelope"></i> Email Us</a>) : ''}
                                                                </div>) : ''}

                                                                {item.website != null ? <div className="lawyer-website">
                                                                    {item.subscription_status === 'Active' ? (<a href={item.website} target="_blank" rel="nofollow"><i className="fa-solid fa-arrow-up-right-from-square"></i> Website</a>) : ''}
                                                                </div> : ""}

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>)
                                        })}
                                        {lastPage > 1 ? (
                                            <div className="lawyer-profile-pagination mb-5">
                                                {pageLinks.map((item, i) => {
                                                    return(
                                                        <>
                                                            {item.label === '&laquo; Previous' && item.url ? (<button type="button" onClick={(e) => handleClick(e)} nextpage={item.url} className={item.url != null ? "prev active" : "prev"} key={i}>Previous</button>) : ""}

                                                            {item.label !== '&laquo; Previous' && item.label !== 'Next &raquo;' ? (<button type="button" onClick={(e) => handleClick(e)} nextpage={item.url} className={item.active === true ? "count active" : "count"} key={i}>{item.label}</button>) : ""}

                                                            {item.label === 'Next &raquo;' && item.url ? (<button type="button" onClick={(e) => handleClick(e)} nextpage={item.url} className={item.url != null ? "next active" : "next"} key={i}>Next</button>) : ""}
                                                        </>
                                                    )
                                                })}
                                            </div>
                                        ): ""}
                                        </>) : (<><p>No Data Found For This Information.</p></>)}
                                    </div>
                                    )
                                }
                            </div>
                            <div className="col-md-3">
                                <div className="right-add-links">
                                    {
                                        bannerImages.map((item) => {
                                            return (
                                                <div className="lawyer-add mb-3">
                                                    <a href={item.url} target='_blank'>
                                                        <img src={item.image} alt={item.title} />
                                                    </a>
                                                </div>
                                            )
                                        })
                                    }
                                    <div className="lawyer-add mb-3" dangerouslySetInnerHTML={{ __html: practiceArea?.description }}>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <SchemaComponent lawyers={lawyerList} />
        </div>
    )
}

export default LawyerProfileByPractice 