import React, {useEffect, useState} from 'react'
import axios from 'axios'
import SweetAlert from 'react-bootstrap-sweetalert'
import { Oval } from  'react-loader-spinner'
import { Link, useParams, useHistory } from 'react-router-dom'
import { Helmet } from "react-helmet"

function ResetPassword() {
    let {resettocken} = useParams();
    const  REACT_APP_LIVE_BACKEND_BASE_URL  = process.env.REACT_APP_LIVE_BACKEND_BASE_URL;
    const DvInnerHTML = function(target,newText) {
        document.querySelectorAll(target).forEach(function(element) {
          element.textContent = newText;
        });
    }  
    const [Alert, setAlert] = useState(null)
    const [valid, setValid] = useState(false)
    const [loading, setLoading] = useState(true)
    let history = useHistory()

    useEffect(() => {
        fetch(REACT_APP_LIVE_BACKEND_BASE_URL+"api/check-reset-tocken/"+resettocken)
        .then((res) =>
            res.json())
        .then((response) => {
            setValid(response.status);
            setLoading(false);
        })
        document.title = "Reset password";
    })

    const handleSubmit = async(e) => {
        e.preventDefault();
      
        try {
          await axios({
            method: "post",
            url: REACT_APP_LIVE_BACKEND_BASE_URL+"api/reset-lawyer-password/"+resettocken,
            data: new FormData(e.target),
            headers: { "Content-Type": "multipart/form-data" },
          }).then((response) =>{
            const errors = response.data.errors;
            if(response.data.status){
                DvInnerHTML('.text-danger', '');
                setAlert((<SweetAlert success title="" onConfirm={() => {
                    setAlert(null)
                    history.push('/sign-in/')
                }} >{response.data.msg}</SweetAlert>))
            }else{
                DvInnerHTML('.text-danger', '');
                if (parseInt(Object.keys(errors).length) > 0) {
                    for (var key in errors) {
                        if (errors.hasOwnProperty(key)) {
                            if(document.getElementById(key + '-error') === null)
                            {
                                console.log(key + '-error')
                            }else
                            {
                                document.getElementById(key + '-error').innerHTML = errors[key];
                            }
                        }
                    }
                }else{
                    setAlert((<SweetAlert danger title="" onConfirm={() => {
                        setAlert(null)
                    }} >{response.data.msg}</SweetAlert>))
                }
            }
          });
        } catch(response) {
          console.log(response)
        }
    }

    return (
        <div>
             <Helmet>
                <title>Reset Password</title>
                <link rel="canonical" href={window.location.href.toLowerCase()} />
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <section className="signup">
                <div className="container">
                    {
                        loading ? (
                            <Oval height={80} width={80} color="#FF705F" wrapperClass="custom-loader" visible={true} ariaLabel='oval-loading' secondaryColor="#FF705F" strokeWidth={2} strokeWidthSecondary={2}
                            />
                        ) : (
                            <>
                            <div className="inner-signup">
                                { valid ? (
                                <>
                                    <div className="top-s-content">
                                        <div className="s-title text-center">
                                            <h5>Are you a lawyer?</h5>
                                        </div>
                                    </div>

                                    <div className="signup-content">
                                        <div className="signup-title text-center">
                                            <h4>Reset Password</h4>
                                        </div>
                                    </div>

                                    <div className="signup-form-sec mt-4">
                                        <form onSubmit={(e) => handleSubmit(e)}>

                                            <div className="form-group mb-3 mt-3">
                                                <input type="password" name="password" id="password" className="form-control" placeholder="Password"/>
                                                    <span><i className="fa-solid fa-lock"></i></span>
                                            </div>
                                                    <p className="text-danger" id="password-error"></p>

                                            <div className="form-group mt-4 mb-4">
                                                <input type="password" name="cpassword" id="cpassword" className="form-control" placeholder="Confirm Password"/>
                                                    <span><i className="fa-solid fa-lock"></i></span>
                                            </div>
                                                    <p className="text-danger" id="cpassword-error"></p>

                                            <div className="signup-btn mt-4">
                                                <button type="submit">Submit</button>
                                            </div>

                                            <div className="condition-text text-center mt-4 text-muted">
                                                <p>By signing up, you agree to our <a href={`${process.env.REACT_APP_BASE_URL}/terms-of-service/`}>Terms of Use</a>, <a href={`${process.env.REACT_APP_BASE_URL}/community-guidelines/`}>Community
                                                    Guidelines</a> and <a href={`${process.env.REACT_APP_BASE_URL}/privacy-policy-2/`}>Privacy Policy</a>.</p>
                                            </div>

                                        </form>
                                    </div>
                                </>) : (<div class="wrong-tocken-info">Missing Information</div>)
                            }
                        </div>
                        <div className="already-account text-center mt-5">
                            <p className="text-muted">Don't want to change password</p>
                            <Link to="/sign-in/">Sign in</Link>
                        </div>
                    </>
                        )
                    }
                    
                </div>
            </section>
            {Alert}
        </div>
    )
}

export default ResetPassword