import React, {useState, useEffect} from "react";
import Admindashboardfooter from "../components/Admindashboardfooter"
import axios from 'axios'
import SweetAlert from 'react-bootstrap-sweetalert'
import { useHistory } from "react-router-dom"
import $ from 'jquery'

function AddLawyerProfile(){
    const [file, setFile] = useState()
    const [data, getData] = useState([])
    const  REACT_APP_LIVE_BACKEND_BASE_URL  = process.env.REACT_APP_LIVE_BACKEND_BASE_URL;
    const URL = REACT_APP_LIVE_BACKEND_BASE_URL+'api/get-practise-area';
    const StateURL = REACT_APP_LIVE_BACKEND_BASE_URL+'api/get-states-list';
    const CityUrl = REACT_APP_LIVE_BACKEND_BASE_URL+'api/get-city-list';
    const [state, setState]  = useState([])
    const [city, setCity]  = useState([])
    let history = useHistory()

    useEffect(() => {
        document.title = 'Add Lawyer Profile'
        fetchData()
        fetchstateData()
    }, [])

    const fetchData = () => {
        fetch(URL).then((res) => res.json()).then((response) => {
            getData(response);
        })
    }

    const fetchstateData = () => {
        fetch(StateURL).then((res) => res.json()).then((response) => {
            setState(response);
        })

    }

    const DvInnerHTML = function(target,newText) {
        document.querySelectorAll(target).forEach(function(element) {
          element.textContent = newText;
        });
    }

    const [Alert, setAlert] = useState(null)
    const handleSubmit = async(e) => {
        e.preventDefault();

        try {
          await axios({
            method: "post",
            url: REACT_APP_LIVE_BACKEND_BASE_URL+"api/add-lawyer-profile",
            data: new FormData(e.target),
            headers: { "Content-Type": "multipart/form-data" },
          }).then((response) =>{
            const errors = response.data.errors;
            if(response.data.status){
                setAlert((<SweetAlert success title="Done" onConfirm={() => {
                    setAlert(null)
                    history.push('./../lawyer-list/')
                }} >{response.data.msg}</SweetAlert>))
            }else{
                DvInnerHTML('.text-danger', '');
                if (parseInt(Object.keys(errors).length) > 0) {
                    for (var key in errors) {
                        if (errors.hasOwnProperty(key)) {
                            if(document.getElementById(key + '-error') === null)
                            {
                                console.log(key + '-error')
                            }else
                            {
                                document.getElementById(key + '-error').innerHTML = errors[key];
                            }
                        }
                    }
                }else{
                    setAlert((<SweetAlert danger title="Ooops!" onConfirm={() => {
                        setAlert(null)
                    }} >{response.data.msg}</SweetAlert>))
                }
            }
          });
        } catch(response) {
          console.log(response)
        }

      }

      $(document).on('change', "#profile_photo", function () {
        if (typeof (FileReader) != "undefined") {
            var image_holder = $(this).attr('previewimage');
            var reader = new FileReader();
            reader.onload = function (e) {
                $("#"+image_holder).prop('src', e.target.result)
            }
            reader.readAsDataURL($(this)[0].files[0]);
        } else {
            alert("This browser does not support FileReader.")
        }
    });

    $(document).on('click', '#delete-photos', function(){
        var deleteattr = $(this).attr('deleteprev')
        $("."+deleteattr).val('')
        $("."+deleteattr).prop('src', '')
    });

    const stateChange = (event) => {
        if(event.target.value != ''){
            fetch(CityUrl+'/'+event.target.value).then((res) => res.json()).then((response) => {
                setCity(response)
            })
        }
    }

    return(
        <div id="content" className="main-content">
            <div className="bg-custom">
                <div className="main-profile-sec">
                    <div className="profile-sec">
                        <form onSubmit={handleSubmit}>
                            <input type="hidden" name="lawyer_id" value="new" />
                            <div className="inner-guest-posts">

                                <div className="inner-profile-sec">
                                    <div className="heading-title c-text-small">
                                        <p>My Accounts</p>
                                    </div>

                                    <div className="profile-edit-sec">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Name</label>
                                                    <input type="text" name="name" id="name" className="form-control"  />
                                                    <p className="text-danger" id="name-error"></p>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Office Phone</label>
                                                    <input type="text" name="office_phone" id="office_phone"   className="form-control"/>
                                                    <p className="text-danger" id="office_phone-error"></p>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Law Firm Name</label>
                                                    <input type="text" name="firmname" id="firmname"  className="form-control"/>
                                                    <p className="text-danger" id="firmname-error"></p>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Mobile Phone</label>
                                                    <input type="text" name="mobile" id="mobile"   className="form-control"/>
                                                    <p className="text-danger" id="mobile-error"></p>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Street Address</label>
                                                    <input type="text" name="street_address" id="street_address"   className="form-control"/>
                                                    <p className="text-danger" id="street_address-error"></p>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Website</label>
                                                    <input type="text" name="website" id="website"   className="form-control"/>
                                                    <p className="text-danger" id="website-error"></p>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>State</label>
                                                    <select name="state" id="state" onChange={stateChange}  className="form-control get-city-bystate">
                                                    <option value="">Select State</option>
                                                    {state.map((item, i) => (
                                                        <option key={`state-key-${i}`} value={item.id}>{item.name}</option>
                                                    ))}
                                                    </select>
                                                    <p className="text-danger" id="state-error"></p>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>City</label>
                                                    <select name="city" id="city"  className="form-control">
                                                    <option value="">Select City</option>
                                                    {city.map((item, i) => (
                                                        <option key={`city-key-${i}`} value={item.id}>{item.name}</option>
                                                    ))}
                                                    </select>
                                                    <p className="text-danger" id="city-error"></p>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Email</label>
                                                    <input type="text" name="email" id="email" className="form-control" />
                                                    <p className="text-danger" id="email-error"></p>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Zip Code</label>
                                                    <input type="text" name="zipcode" id="zipcode" className="form-control" />
                                                    <p className="text-danger" id="zipcode-error"></p>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="profile-img-sec">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/dummy-image.jpg`} id="lawyerprofileimage" className="delete_prev_image"/>
                                                    <div className="action-button-profile">
                                                        <div className="upload-img-btn">
                                                            <input type="file" filename={file}  previewimage="lawyerprofileimage"  name="profile_photo" id="profile_photo"  className="delete_prev_image"/>
                                                            <span>Upload Profile Image</span>
                                                        </div>
                                                        <div className="delete-img-btn">
                                                            <button type="button" deleteprev="delete_prev_image" id="delete-photos">Delete Image</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <p className="text-danger" id="profile_photo-error"></p>
                                            </div>
                                        </div>
                                        <div className="save-btn text-right p-0">
                                            <button type="submit" className="mt-2">Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <Admindashboardfooter />
                {Alert}
            </div>
        </div>
    )
}


export default AddLawyerProfile
