import React, {useEffect, useState} from "react";
import Admindashboardheader from "../components/Admindashboardheader"
import Admindashboardsidebar from "../components/Admindashboardsidebar"
import Admindashboardfooter from "../components/Admindashboardfooter"
import axios from 'axios';
import SweetAlert from 'react-bootstrap-sweetalert';

function AddForTheInjured() {
    useEffect(() => {
        document.title = 'Add For The Injured';
    }, [])
    const  REACT_APP_LIVE_BACKEND_BASE_URL  = process.env.REACT_APP_LIVE_BACKEND_BASE_URL;
    const DvInnerHTML = function (target, newText) {
        document.querySelectorAll(target).forEach(function (element) {
            element.textContent = newText;
        });
    }

    const [Alert, setAlert] = useState(null);

    const ForTheInjuredSubmit = async (e) => {
        e.preventDefault();
        try {
            // make axios post request
            const response = await axios({
                method: "post",
                url: REACT_APP_LIVE_BACKEND_BASE_URL+"api/add-for-the-injured",
                data: new FormData(e.target),
                headers: { "Content-Type": "multipart/form-data" },
            }).then((response) => {
                const errors = response.data.errors;
                if (response.data.status) {
                    DvInnerHTML('.text-danger', '');
                   
                    setAlert((<SweetAlert success title="" onConfirm={() => {
                        setAlert(null)
                        window.location.assign('/for-the-injured-list');
                        // window.location.assign('/lawyer/for-the-injured-list');
                        // window.location.reload();
                    }} >{response.data.msg}</SweetAlert>))
                } else {
                    DvInnerHTML('.text-danger', '');
                    if (parseInt(Object.keys(errors).length) > 0) {

                        for (var key in errors) {
                            if (errors.hasOwnProperty(key)) {
                                if (document.getElementById(key + '-error') === null) {
                                } else {
                                    document.getElementById(key + '-error').innerHTML = errors[key];
                                }
                            }
                        }
                    } else {
                        setAlert((<SweetAlert danger title="" onConfirm={() => {
                            setAlert(null)
                        }} >{response.data.msg}</SweetAlert>))
                    }
                }
            });
        } catch (response) {
            console.log(response)
        }
    }


    return (
        <>
            <Admindashboardheader />
            <div className="wrapper d-flex align-items-stretch">
                <Admindashboardsidebar />
                <div id="content" className="main-content">

                    <div className="bg-custom">

                        <div className="main-profile-sec">
                            <form onSubmit={ForTheInjuredSubmit}>
                                <div className="profile-sec">
                                    <div className="inner-guest-posts">

                                        <div className="inner-profile-sec">
                                            <div className="heading-title c-text-small">
                                                <p>Add Website Logo</p>
                                            </div>

                                            <div className="profile-edit-sec">
                                                <div className="row">

                                                    
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <div className="row">
                                                                <label className="col-2">Name</label>
                                                                <div className="col-10">
                                                                    <input type="text" name="name" id="name" className="form-control"/>
                                                                <p class="text-danger" id="name-error"></p>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div><div className="col-md-12">
                                                        <div className="form-group">
                                                            <div className="row">
                                                                <label className="col-2">Link</label>
                                                                <div className="col-10">
                                                                    <input type="text" name="link" id="link" className="form-control"/>
                                                                <p class="text-danger" id="link-error"></p>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>

                                                <div className="save-btn text-right p-0">
                                                    <button type="submit" className="mt-2">Save</button>
                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </form>

                        </div>

                    </div>
                    {Alert}
                    <Admindashboardfooter />

                </div>
            </div>
        </>
    )
}

export default AddForTheInjured