import React, {useState, useEffect} from 'react'
import Header from './Header'
import Lawyerheader from '../lawyer_dashboard/component/Lawyerheader'

export default function LawyerHeader(){
    const [isDashboard, setIsDashboard] = useState(true)
    const dashboardUrls = ['edit-profile', 'view-profile', 'get-your-badge', 'account-management', 'dashboard', 'contact-us', 'change-password', 'get-published', 'logout']
    
    const checkCurrentPage = () => {
        const liveUrl = window.location.href
        let isUrlExists = false
        for (let i = 0; i < dashboardUrls.length; i++) {
            if (liveUrl.includes(dashboardUrls[i])) {
                isUrlExists = true
            }
        }
        setIsDashboard(isUrlExists)
    }

    useEffect(() => {
        checkCurrentPage()
    }, [])

    return (
        isDashboard ? <Lawyerheader /> : <Header isLogin={true} loginType="Lawyer" />
    )
}