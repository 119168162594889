import React, {useEffect, useState} from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Admindashboardfooter from "../components/Admindashboardfooter"
import { Link } from "react-router-dom"
import axios from 'axios'
import SweetAlert from 'react-bootstrap-sweetalert'
import DataTable from "../../helper/DataTable";

function PracticeAreaList() {
    const [modalShow, setModalShow] = React.useState(false);
    const  REACT_APP_LIVE_BACKEND_BASE_URL  = process.env.REACT_APP_LIVE_BACKEND_BASE_URL;
    const [PracticeArea, setPracticeArea] = useState([]);
    const [Alert, setAlert] = useState(null);
    const [search, setSearch] = useState('');
    const [isLoader, setLoader] = useState(true)

    useEffect(() => {
        document.title = 'Practice Area List'; 
        fetchPracticeArea();
    }, [])

    const fetchPracticeArea = (searchQury='') => {
        if(searchQury === ''){
            searchQury = search
        }
        
        const URL = REACT_APP_LIVE_BACKEND_BASE_URL+"api/get-practise-area-list?param_search="+ searchQury;
        fetch(URL).then((res) => res.json()).then((response) => {
            setPracticeArea(response);
            setLoader(false);
        })
    }

    const handleFilter = (e) => {
        const searchVal = e.target.value
        setSearch(e.target.value)
        fetchPracticeArea(searchVal)
    }

    const handleDeletePracticeArea = (id) => {
        const url = REACT_APP_LIVE_BACKEND_BASE_URL+"api/delete-practise-area/"+id;
        setAlert(
            <SweetAlert
                warning
                showCancel
                confirmBtnText="Yes, delete it!"
                confirmBtnBsStyle="danger"
                cancelBtnText="No, cancel"
                cancelBtnBsStyle="success"
                title="Are you sure?"
                onConfirm={() => deleteItem(url)}
                onCancel={() => setAlert(null)}
            >
                You won't be able to revert this!
            </SweetAlert>
        );       
    }

    const deleteItem = async (url) => {
        setAlert(null);
        await axios({
            method: "delete",
            url: url,
        }).then((response) => {   
            fetchPracticeArea()
            setAlert((
                <SweetAlert success title="Success" onConfirm={() => { setAlert(null)}}>
                    {response.message}
                </SweetAlert>
            ))
        }).catch((error) => {
            const errors = error.response.data
            setAlert((
                <SweetAlert danger title="Oops!" onConfirm={() => {setAlert(null)}}>
                    {errors.message}
                </SweetAlert>
            ))
        });
    }

    return (
        <>
            <div id="content" className="main-content">
                <div className="bg-custom">
                    <div className="main-profile-sec">
                        <div className="profile-sec">
                            <div className="inner-guest-posts">
                                <div className="inner-profile-sec">
                                    <div className="row align-items-center">
                                        <div className="col-md-6">
                                            <div className="heading-title c-text-small">
                                                <p>Practice Areas List</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group posts-title text-right p-0 m-0">
                                                <input type="text" name="search" placeholder="Search..." onKeyUp={(e) => handleFilter(e)} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="add-more-btn text-right custom-add-btn-space">
                                        <Link to="/add-practice-area/"> <i className="fa-solid fa-plus"></i> Add Practice Areas</Link>
                                    </div>

                                    <div className="data-table-sec table-responsive admin-data-table">
                                        <table id="example" className="display t-custom-width" cellspacing="0" width="100%">
                                            <thead>
                                                <tr>
                                                    <th>S No.</th>
                                                    <th>Title Name</th>
                                                    <th className="text-right" style={{ width: "28%" }}>Actions</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {isLoader ? <DataTable /> : PracticeArea ? PracticeArea.map((item, i) => {
                                                    return (<tr>
                                                        <td> {i+1}. </td>
                                                        <td> {item.name} </td>
                                                        <td className="action-btn text-right">
                                                            <Link to={"/edit-practice-area/"+item.id+"/"} className="edit-btn" > <i className="fa-solid fa-edit"></i> Edit</Link>
                                                            <Button className="edit-btn" onClick={() => handleDeletePracticeArea(item.id)}> <i className="fa-solid fa-trash"></i> Delete</Button>
                                                        </td>
                                                    </tr>)
                                                }) : (<tr>
                                                    <td colSpan="3">No Data Found</td>
                                                </tr>)}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {Alert}
                <Admindashboardfooter />
            </div>
            <ApprovedModal show={modalShow} onHide={() => setModalShow(false)} />
        </>
    )
}

export default PracticeAreaList

function ApprovedModal(props) {
    return (
        <div className="profile-list-approve-modal">
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centeredl
            >
                <Modal.Header className="modal-header" closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h5 className="modal-title" id="exampleModalLabel">Approve</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="approve-list-content">
                        <div className="row">

                            <div className="col-md-6">
                                <div className="al-content">
                                    <p> <span>Name :</span> Adam Rosengard </p>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="al-content">
                                    <p> <span>Email :</span> abc@gmail.com </p>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="al-content">
                                    <p> <span>Payment Status :</span> Paid </p>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="al-content">
                                    <p> <span>Payment ID :</span> 123456 </p>
                                </div>
                            </div>

                        </div>

                        <div className="approve-switch-brn">
                            <Button type="button" className="btn btn-toggle" data-toggle="button" aria-pressed="false"
                                autocomplete="off">
                                <div className="handle"></div>
                            </Button>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button>Save</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
