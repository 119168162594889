import React from 'react'
import { Helmet } from 'react-helmet'

const SchemaComponent = ({lawyers}) => {
    const schemaData = {
        "@context": "http://schema.org",
        "@type": "ItemList",
        "itemListElement": []
    }

    lawyers.map((item, index) => {
        const lawyerData = {
            "@type": "ListItem",
            "position": index,
            "item": {
                "@type": "Person",
                "name": item.name,
                "url": `${process.env.REACT_APP_LIVE_BASE_URL}lawyer/${item.slug}/`,
                "image": item.profile_photo,
                "jobTitle": "Attorney",
                "worksFor": {
                    "@type": "Organization",
                    "name": item.firmname,
                    "url": item.website,
                    "address": {
                        "@type": "PostalAddress",
                        "streetAddress": item.street_address,
                        "addressLocality": item.cities.name || "",
                        "addressRegion": item.states.short_name || "",
                        "postalCode": item.zipcode || "",
                        "addressCountry": "USA"
                    },
                    "telephone": `(${String(item.office_phone).substring(0, 3)}) ${String(item.office_phone).substring(3, 6)}-${String(item.office_phone).substring(6, 10)}`
                },
                "description": item.firm_description,
                "contactPoint": {
                    "@type": "ContactPoint",
                    "contactType": "Customer Service",
                    "telephone": `(${String(item.office_phone).substring(0, 3)}) ${String(item.office_phone).substring(3, 6)}-${String(item.office_phone).substring(6, 10)}`,
                    "email": `mailto:${item.email}`,
                    "url": item.website
                }
            }
        }
        schemaData.itemListElement.push(lawyerData)
        return
    })  

    return (
        <Helmet>
            <script type="application/ld+json">
                {JSON.stringify(schemaData)}
            </script>
        </Helmet>
    );
};

export default SchemaComponent;
