import React from "react";

function MissingInformation(){
    return(
        <div>
            <section className="pages-breadcrumb mt-3">
                <div className="container">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href={process.env.REACT_APP_BASE_URL} rel="noopener noreferrer">Home</a></li>
                        </ol>
                    </nav>
                </div>
            </section>

            <section className="f-lawyer-practice-areas pb-5">
                <div className="container">
                    <div className="missing-information">
                        <div className="title mb-3">
                            <h1 className="missing-info-text">Missing Information!</h1>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default MissingInformation