import React, {useState, useEffect} from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

function ChooseClaimMethod(){
    const REACT_APP_LIVE_BACKEND_BASE_URL = process.env.REACT_APP_LIVE_BACKEND_BASE_URL;
    const { id } = useParams();
    const history = useHistory();
    const [claimUser, setClaimUSer] = useState([]);
    const [phoneError, setPhoneError] = useState('');
    const [otpError, setOtpError] = useState('');
    const [Alert, setAlert] = useState(null);
    
    useEffect(() => {
        document.title = 'Claim methods';
        fetchclaimUserData();
    }, []);

    const fetchclaimUserData = () => {
        const url = `${REACT_APP_LIVE_BACKEND_BASE_URL}api/get-lawyer-data/${id}`;
        fetch(url).then((res) => res.json()).then((response) => {
            setClaimUSer(response.lawyerprofile);
        });
    }

    const verifyWithPhone = (e, type, phone) => {
        e.preventDefault();
        setPhoneError('');
        let url = `${REACT_APP_LIVE_BACKEND_BASE_URL}api/user-verify-with-email/${phone}`;
        if(type === "phone"){
            url = `${REACT_APP_LIVE_BACKEND_BASE_URL}api/user-verify-with-phone/${phone}`;
        }

        fetch(url).then((res) => res.json()).then((response) => {
            if(response.status){
                setAlert((<SweetAlert success title="" onConfirm={() => {
                    setAlert(null);
                }} >{response.message}</SweetAlert>))
                processTabHandler('step-2');
            }else{
                setAlert((
                    <SweetAlert 
                    danger 
                    title="Oops!"
                    onConfirm={() => { setAlert(null)}}
                    >
                        {response.message}
                    </SweetAlert>
                ))
            }
        }).catch((error) => {
            setPhoneError(error.message);
        })   
    }

    const verifyWithOtpPhone = (e, type, phone) => {
        e.preventDefault();
        setOtpError('');
        const otp = document.getElementById('otpNumber').value;
        let url = `${REACT_APP_LIVE_BACKEND_BASE_URL}api/user-verify-with-email/${phone}/${otp}`;
        if(type === "phone"){
            url = `${REACT_APP_LIVE_BACKEND_BASE_URL}api/user-verify-with-phone/${phone}/${otp}`;
        }

        fetch(url).then((res) => res.json()).then((response) => {
            if(response.status){
                setAlert((
                    <SweetAlert success title="Good job"
                        onConfirm={
                            () => {
                                setAlert(null)
                                history.push('/sign-in/'+response.token);
                            }
                            }>
                        {
                        response.message
                    }</SweetAlert>
                ))
            }else{
                setAlert((
                    <SweetAlert danger title="Oops!"
                        onConfirm={
                            () => {
                                setAlert(null)
                            }
                            }>
                        {
                        response.message
                    }</SweetAlert>
                ))
            }
            
        }).catch((error) => {
            setOtpError(error.message);
        })   
    }

    const processTabHandler = (step) => {
        const targetElement = document.getElementById("progress-tab-"+step);
        const stepElement = document.querySelector("."+step);
        const elements = document.getElementsByClassName('claim-method-content');
        for(let i = 0; i < elements.length; i++){
            elements[i].classList.add('d-none');
        }
        targetElement.classList.remove('d-none');

        const steps = document.getElementsByClassName('step');
        for(let i = 0; i < steps.length; i++){
            steps[i].classList.remove('active');
        }
        stepElement.classList.add('active');
    }

    return(
        <>
        <section className="progress-tabs">
            <div className="inner-progress-tabs">
                <div className="container">
                    <div className="progress-tabs-sec">
                        <ol>
                            <li className="step step-1 active"> <a href='javascript:;'> Choose a claim method </a> </li>
                            <li className="step step-2"> <a href='javascript:;'> Verify your identity </a> </li>
                            <li className="step step-3"> <a href='javascript:;'> Sign in </a> </li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>

        <section className="claim-method-content" id='progress-tab-step-1'>
            <div className="container">
                <div className="title text-start">
                    <h1>Choose how you want to claim</h1>
                </div>
                <div className="inner-claim-method-content">
                    <div className="method-lists b-bottom">
                        {
                            claimUser.email === undefined || !claimUser.email || claimUser.email === "" ? (
                                <div className="row">
                                    <div className="col-md-9">
                                        <p>If you are certain this profile belongs to you, please email us, and we'll call you to sort out the issue: <strong>{process.env.REACT_APP_ADMIN_EMAIL_ID}</strong></p>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="m-btn call-btn">
                                            <a href={`mailto:${process.env.REACT_APP_ADMIN_EMAIL_ID}`}><i className="fa-solid fa-envelope"></i> Email Us</a>
                                        </div>
                                    </div>
                                </div>
                            ): claimUser.verify_token === undefined || !claimUser.verify_token || claimUser.verify_token === "" ? (
                                <div className="row">
                                    <div className="col-md-9">
                                        <p>If you are certain this profile belongs to you, please email us, and we'll call you to sort out the issue: <strong>{process.env.REACT_APP_ADMIN_EMAIL_ID}</strong>.</p>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="m-btn call-btn">
                                            <a href={`mailto:${process.env.REACT_APP_ADMIN_EMAIL_ID}`}><i className="fa-solid fa-envelope"></i> Email Us</a>
                                        </div>
                                    </div>
                                </div>
                            ) :(
                                <div className="row">
                                    <div className="col-md-9">
                                        <p>We will send you verification code in your registered email id: <strong>{claimUser.email}</strong></p>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="m-btn call-btn">
                                            <Link to="/" onClick={(e) => verifyWithPhone(e, 'email', claimUser.email)}><i className="fa-solid fa-envelope"></i> Verify Email </Link>
                                            <span className='text-danger'>{phoneError}</span>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </section>

        <section className="claim-method-content d-none" id='progress-tab-step-2'>
            <div className="container">
                <div className="title text-start">
                    <h1>Enter verification code to verify your email id</h1>
                </div>
                <div className="inner-claim-method-content">
                    <div className="method-lists b-bottom">
                        <div className="row align-items-center">
                            <div className="col-md-4">
                                <input type='text' id='otpNumber' className='form-control' />
                                <span>{otpError}</span>
                            </div>
                            <div className='col-md-3'>
                                <div className='m-btn'>
                                    <Link to="/" onClick={(e) => verifyWithOtpPhone(e, 'email', claimUser.email)} className='text-center'>Submit verification code</Link>
                                </div>
                            </div>
                            <div className='col-md-2'>
                                <div className='m-btn'>
                                    <Link to="/" onClick={(e) => verifyWithPhone(e, 'email', claimUser.email)} className='text-center'>Resend</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {Alert}
    </>
    )
}

export default ChooseClaimMethod