import React, {useEffect} from "react";
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Admindashboardheader from "../components/Admindashboardheader"
import Admindashboardsidebar from "../components/Admindashboardsidebar"
import Admindashboardfooter from "../components/Admindashboardfooter"

function Blogcategorieslist() {
    useEffect(() => {
        document.title = 'Blog Categories List';
    }, []);

    const [modalShow, setModalShow] = React.useState(false);

    return (
        <>
            <Admindashboardheader />
            <div className="wrapper d-flex align-items-stretch">
                <Admindashboardsidebar />
                <div id="content" className="main-content">

                    <div className="bg-custom">

                        <div className="main-profile-sec">
                            <div className="profile-sec">
                                <div className="inner-guest-posts">

                                    <div className="inner-profile-sec">

                                        <div className="row align-items-center">

                                            <div className="col-md-6">
                                                <div className="heading-title c-text-small">
                                                    <p>Categories</p>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group posts-title text-right p-0">
                                                    <input type="text" name="search" placeholder="Search..." className="" id="" />
                                                </div>
                                            </div>

                                        </div>

                                        <div className="add-more-btn text-right">
                                            <Link to="add-categories.html"> <i className="fa-solid fa-plus"></i> Add Categories </Link>
                                        </div>

                                        <div className="data-table-sec table-responsive admin-data-table">

                                            <table id="example" className="display" cellspacing="0" width="100%">
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Description</th>
                                                        <th>Slug</th>
                                                        <th className="text-right" style={{ width: "16%" }}>Actions</th>

                                                    </tr>
                                                </thead>

                                                <tbody>

                                                    <tr>
                                                        <td> The Ultimate Guide to Guest Posting in 2022 </td>
                                                        <td></td>
                                                        <td></td>
                                                        <td className="c-edit-btn text-right">
                                                            <Button className="edit-btn" data-toggle="modal" data-target="#editCategorieModal" onClick={() => setModalShow(true)}> <i className="fa-regular fa-pen-to-square"></i> edit
                                                            </Button>
                                                        </td>
                                                    </tr>

                                                </tbody>

                                            </table>
                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>
                    <Admindashboardfooter />

                </div>
            </div>
            <Editcategorie  show={modalShow} onHide={() => setModalShow(false)} />
        </>
    )
}

export default Blogcategorieslist



function Editcategorie(props) {
    return (
        <div className="edit-categorie-modal">
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centeredl
            >
                <Modal.Header className="modal-header" closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h5 className="modal-title" id="exampleModalLabel">Add Categories</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="profile-edit-sec">
                        <div className="row">

                            <div className="col-md-12">
                                <div className="form-group">
                                    <div className="row">
                                        <label className="col-3">Name</label>
                                        <div className="col-9">
                                            <input type="text" name="" id="" className="form-control" />
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="form-group">
                                    <div className="row">
                                        <label className="col-3">Slug</label>
                                        <div className="col-9">
                                            <input type="text" name="" id="" className="form-control" />
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="form-group m-0">
                                    <div className="row">
                                        <label className="col-3">Description</label>
                                        <div className="col-9">
                                            <textarea name="" id="" className="m-0"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button>Save</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}