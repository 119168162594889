import React, {useEffect, useState} from 'react'
import axios from 'axios'
import SweetAlert from 'react-bootstrap-sweetalert'
import { Link, useHistory } from 'react-router-dom'
import { Helmet } from "react-helmet"

function ForgetPassword(){
    const  REACT_APP_LIVE_BACKEND_BASE_URL  = process.env.REACT_APP_LIVE_BACKEND_BASE_URL;
    const DvInnerHTML = function(target,newText) {
        document.querySelectorAll(target).forEach(function(element) {
          element.textContent = newText;
        });
    }

    useEffect(() => {
        document.title = 'Forget password';
    }, [])

    const [Alert, setAlert] = useState(null)
    let history = useHistory()

    const handleSubmit = async(e) => {
        e.preventDefault();
      
        try {
            await axios({
                method: "post",
                url: REACT_APP_LIVE_BACKEND_BASE_URL+"api/forget-lawyer-password",
                data: new FormData(e.target),
                headers: { "Content-Type": "multipart/form-data" },
            }).then((response) =>{
                const errors = response.data.errors;
                if(response.data.status){
                    DvInnerHTML('.text-danger', '');
                    setAlert((<SweetAlert success title="" onConfirm={() => {
                        setAlert(null)
                        history.push('/sign-in/')
                    }} >{response.data.msg}</SweetAlert>))
                }else{
                    DvInnerHTML('.text-danger', '');
                    if (parseInt(Object.keys(errors).length) > 0) {
                        for (var key in errors) {
                            if (errors.hasOwnProperty(key)) {
                                if(document.getElementById(key + '-error') === null){
                                    console.log(key + '-error')
                                }else{
                                    document.getElementById(key + '-error').innerHTML = errors[key]
                                }
                            }
                        }
                    }else{
                        setAlert((<SweetAlert danger title="" onConfirm={() => {
                            setAlert(null)
                        }}>{response.data.msg}</SweetAlert>))
                    }
                }
            })
        } catch(response) {
            console.log(response)
        }
    }

    return (
        <div>
            <Helmet>
                <title>Forget Password</title>
                <link rel="canonical" href={window.location.href.toLowerCase()} />
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <section className="signin">
                <div className="container">
                    <div className="inner-signin">
                        <Link to="/sign-in/" className="previous-btn">
                            <i className="fa-solid fa-angle-left"></i>
                        </Link>
                        <div className="signin-content">
                            <div className="signin-title text-center">
                                <h4>Forgot Password</h4>
                            </div>
                        </div>
                        <div className="email signin-form-sec mt-4">
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label for="email" className="mb-1">Email <span className="red">*</span></label>
                                    <input type="text" name="email"  className="form-control" id="email_address"/>
                                </div>
                                <p className="text-danger" id="email-error"></p>

                                <div className="next-btn">
                                    <button type="submit" className="mt-4 mb-2">Submit</button>
                                </div>
                            </form>
                            <div className="account text-center mt-4">
                                <p className="m-0 text-muted"> Don't want to forget password ? <Link to="/sign-in/"> Sign in </Link> </p>
                            </div>
                        </div>
                        <div className="password signin-form-sec mt-4">
                            <div className="terms-text text-center mt-2">
                                <p className="m-0 text-muted"> By signing in, you agree to STI <a href={`${process.env.REACT_APP_BASE_URL}/terms-of-service/`}> terms of use </a> </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {Alert}
        </div>
    )
}

export default ForgetPassword