import React, { useState, useEffect, useContext } from "react";
import axios from 'axios';
import SweetAlert from 'react-bootstrap-sweetalert';
import Cookies from 'universal-cookie';
import { ThemeContext } from "../../ThemeContext";
import moment from "moment";
import $ from 'jquery';


function LawLicense({sates}) {

    const cookies = new Cookies();
    var lawyerid = cookies.get('LAWYERID');
    const  REACT_APP_LIVE_BACKEND_BASE_URL  = process.env.REACT_APP_LIVE_BACKEND_BASE_URL;
    const LAWYERURL = REACT_APP_LIVE_BACKEND_BASE_URL+'api/get-lawyer-data/' + lawyerid;
    const [lawyerLicense, setLawyerLicense] = useState([])
    const [deleteLicense, setDeleteLicense] = useState([])
    const { isLoader, toggleLoader } = useContext(ThemeContext);
    const DvInnerHTML = function (target, newText) {
        document.querySelectorAll(target).forEach(function (element) {
            element.textContent = newText;
        });
    }
    const [Alert, setAlert] = useState(null);

    useEffect(() => {
        toggleLoader(isLoader ? isLoader : true);
        fetchlawyerdata();
    }, [])

    const fetchlawyerdata = () => {
        fetch(LAWYERURL) .then((res) => res.json()) .then((response) => { 
            setLawyerLicense(response.licensedata); 
            toggleLoader(isLoader ? false : isLoader); 
        })
    }

    var licenselength = parseInt($(".total-license").length);
    let addLicense = () => {
        var html = '<div class="row total-license" id="law-license' + licenselength + '"><div class="col-md-4"><div class="form-group"><label>State</label><input type="hidden" name="licenseid[' + licenselength + ']" value="" /><select name="state[' + licenselength + ']" id="state' + licenselength + '" class="form-control"><option value="">Select State</option>';

        sates.map((val, key) => {
            html += "<option value='"+val.id+"'>"+ val.name +"</option>";
            return;
        });
        
        html += '</select><p class="text-danger" id="state.' + licenselength + '-error"></p></div></div><div class="col-md-4"><div class="form-group"><label>Status</label><select name="status[' + licenselength + ']" id="status' + licenselength + '" class="form-control"><option value="Active">Active</option> <option value="Inactive">Inactive</option></select><p class="text-danger" id="status.' + licenselength + '-error"></p></div></div><div class="col-md-4"><div class="form-group"><label>Year</label><input type="text" name="year[' + licenselength + ']" id="year' + licenselength + '"class="form-control"/><p class="text-danger" id="year.' + licenselength + '-error"></p></div></div><div class="save-btn text-right "><button type="button" class="remove-license" entity="law-license' + licenselength + '">Remove</button></div></div>';
        $("#add-more-license").append(html);
        licenselength = licenselength + parseInt(1);
    }

    $(document).on("click", ".remove-license", function (e) {
        e.preventDefault();
        var lastlicense = parseInt($(".total-license").length);
        if (lastlicense == 1) {
            return false;
        } else {
            var entity = $(this).attr("entity");
            $("#" + entity).remove();
            var deleteid = $(this).attr('deletelicenceid');
            if (deleteid != null) {

                setDeleteLicense([...deleteLicense, deleteid]);
            }
        }
    });

    const licenseSubmit = async (e) => {
        e.preventDefault();
        try {
            // make axios post request
            const response = await axios({
                method: "post",
                url: REACT_APP_LIVE_BACKEND_BASE_URL+"api/add-lawyer-profile-license",
                data: new FormData(e.target),
                headers: { "Content-Type": "multipart/form-data" },
            }).then((response) => {
                const errors = response.data.errors;
                if (response.data.status) {
                    DvInnerHTML('.text-danger', '');
                    
                    setAlert((<SweetAlert success title="" onConfirm={() => {
                        setAlert(null)
                       window.location.reload();
                    }} >{response.data.msg}</SweetAlert>))
                    
                } else {
                    DvInnerHTML('.text-danger', '');
                    if (parseInt(Object.keys(errors).length) > 0) {
                        for (var key in errors) {
                            if (errors.hasOwnProperty(key)) {
                                if (document.getElementById(key + '-error') === null) {
                                } else {
                                    document.getElementById(key + '-error').innerHTML = errors[key];
                                }
                            }
                        }
                    } else {
                        setAlert((<SweetAlert danger title="" onConfirm={() => {
                            setAlert(null)
                        }} >{response.data.msg}</SweetAlert>))
                    }
                }
            });
        } catch (response) {
            console.log(response)
        }
    }

    return (
        <>
            <div class="profile-sec" id="lawyer_personal_license">
                <div class="inner-guest-posts">

                    <div class="inner-profile-sec">

                        <div class="row align-items-center">

                            <div class="col-md-6">
                                <div class="heading-title c-text-small">
                                    <p>Law License</p>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="add-more-field text-right">
                                    <button type="button" onClick={() => addLicense()}>Add Another State</button>
                                </div>
                            </div>

                        </div>


                        <div class="profile-edit-sec">
                            <form id="licence-form" onSubmit={licenseSubmit}>

                                <div class="licence-form-fields" id="add-more-license">
                                    <input type="hidden" name="lawyer_id" id="lawyer_id" class="form-control" defaultValue={lawyerid} />
                                    <input type="hidden" id="deleting_fields" name="deleting_fields" defaultValue={JSON.stringify(deleteLicense)} />
                                    {lawyerLicense != "" ? lawyerLicense.map((item, i) => {
                                        return (<div class="row total-license" id={"law-license" + i} key={i}>
                                            
                                            <div class="col-md-4 col-lg-4 col-xl-4">
                                                <div class="form-group">
                                                    <label>State</label>
                                                    <input type="hidden" name={"licenseid[" + i + "]"} value={item.id} />
                                                    <select name={"state[" + i + "]"} defaultValue={item.state} id={"state" + i + ""} class="form-control">
                                                        <option value="">Select State</option>
                                                        {
                                                            sates.map((val, key) => {
                                                                if(val.id == item.state){
                                                                    return <option key={`licences-states-${key}`} selected value={val.id}>{val.name}</option>
                                                                }else{
                                                                    return <option key={`licences-states-${key}`} value={val.id}>{val.name}</option>
                                                                }
                                                            })
                                                        }
                                                    </select>
                                                    <p class="text-danger" id={"state." + i + "-error"}></p>
                                                </div>
                                            </div>

                                            <div class="col-md-4 col-lg-4 col-xl-4">
                                                <div class="form-group">
                                                    <label>Status</label>
                                                    <select name={"status[" + i + "]"} defaultValue={item.status} id={"status" + i + ""} class="form-control">
                                                        <option value="Active">Active</option>
                                                        <option value="Inactive">Inactive</option>
                                                    </select>
                                                    <p class="text-danger" id={"status." + i + "-error"}></p>
                                                </div>
                                            </div>

                                            <div class="col-md-4 col-lg-4 col-xl-4">
                                                <div class="form-group">
                                                    <label>Year</label>
                                                    <input type="text" name={"year[" + i + "]"} defaultValue={item.year} id={"year" + i + ""} class="form-control" />
                                                    <p class="text-danger" id={"year." + i + "-error"}></p>
                                                </div>
                                            </div>
                                            {
                                                i < 1 ? "" : (
                                                    <div class="save-btn text-right ">
                                                        <button type="button" class="remove-license" deletelicenceid={item.id} entity={"law-license" + i}>Remove</button>
                                                    </div>
                                                )
                                            }
                                            
                                        </div>)
                                    }) :
                                        <div class="row total-license" id="law-license0">
                                            <div class="col-md-4 col-lg-4 col-xl-4">
                                                <div class="form-group">
                                                    <label>State</label>
                                                    <input type="hidden" name="licenseid[0]" value="" />
                                                    <select name="state[0]" id="state0" class="form-control">
                                                        <option value="">Select State</option>
                                                        {
                                                            sates.map((val, key) => {
                                                                return <option key={`licences-states-${key}`} value={val.id}>{val.name}</option>
                                                            })
                                                        }
                                                    </select>
                                                    <p class="text-danger" id="state.0-error"></p>
                                                </div>
                                            </div>

                                            <div class="col-md-4 col-lg-4 col-xl-4">
                                                <div class="form-group">
                                                    <label>Status</label>
                                                    <select name="status[0]" id="status0" class="form-control">
                                                        <option value="Active">Active</option>
                                                        <option value="Inactive">Inactive</option>
                                                    </select>
                                                    <p class="text-danger" id="status.0-error"></p>
                                                </div>
                                            </div>

                                            <div class="col-md-4 col-lg-4 col-xl-4">
                                                <div class="form-group">
                                                    <label>Year</label>
                                                    <input type="text" name="year[0]" id="year0" class="form-control" />
                                                    <p class="text-danger" id="year.0-error"></p>
                                                </div>
                                            </div>


                                        </div>
                                    }
                                </div>



                                <div class="save-btn text-right pt-0">
                                    <button type="submit" class="mt-2">Save</button>
                                </div>
                            </form>
                        </div>

                    </div>

                </div>

            </div>
            {Alert}
        </>
    )
}

function LawAward() {

    const cookies = new Cookies();
    var lawyerid = cookies.get('LAWYERID');
    const  REACT_APP_LIVE_BACKEND_BASE_URL  = process.env.REACT_APP_LIVE_BACKEND_BASE_URL;
    const LAWYERURL = REACT_APP_LIVE_BACKEND_BASE_URL+'api/get-lawyer-data/' + lawyerid;
    const [lawyerAward, setLawyerAward] = useState([])
    const [deleteAward, setDeleteAward] = useState([])
    const DvInnerHTML = function (target, newText) {
        document.querySelectorAll(target).forEach(function (element) {
            element.textContent = newText;
        });
    }
    const [Alert, setAlert] = useState(null);
    const { isLoader, toggleLoader } = useContext(ThemeContext);

    const formatDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    useEffect(() => {
        toggleLoader(isLoader ? isLoader : true);
        fetchlawyerdata();
    }, [])

    const fetchlawyerdata = () => {
        fetch(LAWYERURL).then((res) =>res.json()).then((response) => {
            toggleLoader(isLoader ? false : isLoader);
            setLawyerAward(response.awardsdata);
        })
    }

    var awardlength = parseInt($(".add-multifields").length);
    let addAward = () => {
        var html = '<div class="row add-multifields" id="lawyer-awards' + awardlength + '"><div class="col-md-12"><div class="form-group"><input type="hidden" name="awardid[' + awardlength + ']" value="" /><label>Award Name</label><input type="text" name="award_name[' + awardlength + ']" id="award_name' + awardlength + '" class="form-control"/><p class="text-danger" id="award_name.' + awardlength + '-error"></p></div></div><div class="save-btn text-right "><button type="button" entity="lawyer-awards' + awardlength + '" class="remove-award">Remove</button></div></div>';
        $("#addawardss").append(html);
        awardlength = awardlength + parseInt(1);
    }

    $(document).on("click", ".remove-award", function (e) {
        e.preventDefault();
        var hideremove = parseInt($(".add-multifields").length);
        if (hideremove == 1) {
            return false;
        } else {

            var entity = $(this).attr("entity")
            $("#" + entity).remove()
            var deleteid = $(this).attr('deleteawardid');
            if (deleteid != null) {

                setDeleteAward([...deleteAward, deleteid]);
            }
        }
    });


    const AwardSubmit = async (e) => {
        e.preventDefault();
        try {
            // make axios post request
            const response = await axios({
                method: "post",
                url: REACT_APP_LIVE_BACKEND_BASE_URL+"api/add-lawyer-profile-award",
                data: new FormData(e.target),
                headers: { "Content-Type": "multipart/form-data" },
            }).then((response) => {
                const errors = response.data.errors;
                if (response.data.status) {
                    DvInnerHTML('.text-danger', '');
                    
                    setAlert((<SweetAlert success title="" onConfirm={() => {
                        setAlert(null)
                        window.location.reload();
                    }} >{response.data.msg}</SweetAlert>))
                } else {
                    DvInnerHTML('.text-danger', '');
                    if (parseInt(Object.keys(errors).length) > 0) {
                        for (var key in errors) {
                            if (errors.hasOwnProperty(key)) {
                                if (document.getElementById(key + '-error') === null) {
                                } else {
                                    document.getElementById(key + '-error').innerHTML = errors[key];
                                }
                            }
                        }
                    } else {
                        setAlert((<SweetAlert danger title="" onConfirm={() => {
                            setAlert(null)
                        }} >{response.data.msg}</SweetAlert>))
                    }
                }
            });
        } catch (response) {
            console.log(response)
        }
    }

    return (
        <>
            <div class="profile-sec" id="lawyer_personal_award">
                <div class="inner-guest-posts">
                    <div class="inner-profile-sec">
                        <div class="row align-items-center">
                            <div class="col-md-8">
                                <div class="heading-title c-text-small">
                                    <p>Awards</p>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <div class="add-more-field text-right">
                                    <button type="button" onClick={() => addAward()}>Add Award</button>
                                </div>
                            </div>

                        </div>
                        <form onSubmit={AwardSubmit}>
                            <div class="profile-edit-sec" >
                                <input type="hidden" name="lawyer_id" id="lawyer_id" class="form-control" defaultValue={lawyerid} />
                                <input type="hidden" id="delete_award" name="award_delete" defaultValue={JSON.stringify(deleteAward)} />
                                <div id="addawardss">
                                    {lawyerAward != "" ? lawyerAward.map((item, i) => {
                                        return (
                                            <div class="row add-multifields" id={"lawyer-awards" + i} key={i}>

                                                <div class="col-md-12" key={i}>
                                                    <div class="form-group" key={i}>
                                                        <input type="hidden" name={"awardid[" + i + "]"} value={item.id} />
                                                        <label>Award Name</label>
                                                        <input type="text" name={"award_name[" + i + "]"} defaultValue={item.name} id={"award_name" + i} class="form-control" />
                                                        <p class="text-danger" id={"award_name." + i + "-error"}></p>
                                                    </div>
                                                </div>
                                                {
                                                    i < 1 ? "" : (
                                                        <div class="save-btn text-right">
                                                            <button type="button" deleteawardid={item.id} entity={"lawyer-awards" + i} class="remove-award">Remove</button>
                                                        </div>
                                                    )
                                                }
                                            </div>

                                        )
                                    }) :
                                        <div class="row add-multifields" id="lawyer-awards0">

                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <input type="hidden" name="awardid[0]" value="" />
                                                    <label>Award Name</label>
                                                    <input type="text" name="award_name[0]" id="award_name0" class="form-control" />
                                                    <p class="text-danger" id="award_name.0-error"></p>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>

                                <div class="save-btn text-right pt-0">
                                    <button type="submit" class="mt-2">Save</button>
                                </div>

                            </div>
                        </form>
                    </div>



                </div>

            </div>
            {Alert}
        </>
    )
}

function ProfessionalExperience() {

    const cookies = new Cookies();
    var lawyerid = cookies.get('LAWYERID');
    const  REACT_APP_LIVE_BACKEND_BASE_URL  = process.env.REACT_APP_LIVE_BACKEND_BASE_URL;
    const LAWYERURL = REACT_APP_LIVE_BACKEND_BASE_URL+'api/get-lawyer-data/' + lawyerid;
    const [lawyerExperience, setLawyerExperience] = useState([])
    const [deleteExperience, setDeleteExperience] = useState([])
    const DvInnerHTML = function (target, newText) {
        document.querySelectorAll(target).forEach(function (element) {
            element.textContent = newText;
        });
    }

    const [Alert, setAlert] = useState(null);
    const [yearOfDate, setYearOfDate] = useState([])

    useEffect(() => {
        fetchlawyerdata();
        const dates = [];
        const currentYear = moment().year();
        const fiftyYearsAgoYear = currentYear - 50;
        for(let year = fiftyYearsAgoYear; year <= currentYear; year++){
            dates.push(year);
        }
        setYearOfDate(dates);
    }, [])

    const fetchlawyerdata = () => { 
        fetch(LAWYERURL).then((res) => res.json()).then((response) => {
            setLawyerExperience(response.Profexperience);
        })
    }


    var experiencelength = parseInt($(".total-experience").length);
    let addExperience = () => {


        var html = '<div class="row total-experience" id="lawyer-experience' + experiencelength + '" ><div class="col-md-3"><div class="form-group"><input type="hidden" name="experienceid[' + experiencelength + ']"  defaultValue="" /><label>Title</label><input type="text" name="experience_title[' + experiencelength + ']" id="experience_title' + experiencelength + '" class="form-control" /><p class="text-danger" id="experience_title.' + experiencelength + '-error"></p></div></div><div class="col-md-3"><div class="form-group"><label>Law Firm</label><input type="text" name="experience_firm[' + experiencelength + ']" id="experience_firm' + experiencelength + '" class="form-control" /><p class="text-danger" id="experience_firm.' + experiencelength + '-error"></p></div></div><div class="col-md-3"><div class="form-group"><label>From Date</label><select name="experience_from_date[' + experiencelength + ']" id="experience_from_date' + experiencelength + '" class="form-control">';

        yearOfDate.map((value) => {
            html += "<option>"+value+"</option>";
            return
        })
        
        html += '</select><p class="text-danger" id="experience_from_date.' + experiencelength + '-error"></p></div></div><div class="col-md-3"><div class="form-group"><label>To Date</label><select name="experience_to_date[' + experiencelength + ']" id="experience_to_date' + experiencelength + '" class="form-control">';

        yearOfDate.map((value) => {
            html += "<option>"+value+"</option>";
            return
        })

        html += "<option>Present</option>";
        
        html += '</select><p class="text-danger" id="experience_to_date.' + experiencelength + '-error"></p></div></div><div class="save-btn text-right "><button type="button" class="remove-experience" entity="lawyer-experience' + experiencelength + '">Remove</button></div></div>';
        $("#add-prof-experience").append(html);
        experiencelength = experiencelength + parseInt(1);
    }

    $(document).on("click", ".remove-experience", function (e) {
        e.preventDefault();
        var lastone = parseInt($(".total-experience").length);
        if (lastone == 1) {
            return false;
        } else {
            var entity = $(this).attr("entity")
            $("#" + entity).remove()
            var deleteid = $(this).attr("deleteexperience");
            setDeleteExperience([...deleteExperience, deleteid])
        }
    });

    const ExperienceSubmit = async (e) => {
        e.preventDefault();
        try {
            // make axios post request
            const response = await axios({
                method: "post",
                url: REACT_APP_LIVE_BACKEND_BASE_URL+"api/add-lawyer-profile-experience",
                data: new FormData(e.target),
                headers: { "Content-Type": "multipart/form-data" },
            }).then((response) => {
                const errors = response.data.errors;
                if (response.data.status) {
                    DvInnerHTML('.text-danger', '');
                    
                    setAlert((<SweetAlert success title="" onConfirm={() => {
                        setAlert(null)
                        window.location.reload();
                    }} >{response.data.msg}</SweetAlert>))
                } else {
                    DvInnerHTML('.text-danger', '');
                    if (parseInt(Object.keys(errors).length) > 0) {
                        for (var key in errors) {
                            if (errors.hasOwnProperty(key)) {
                                if (document.getElementById(key + '-error') === null) {
                                } else {
                                    document.getElementById(key + '-error').innerHTML = errors[key];
                                }
                            }
                        }
                    } else {
                        setAlert((<SweetAlert danger title="" onConfirm={() => {
                            setAlert(null)
                        }} >{response.data.msg}</SweetAlert>))
                    }
                }
            });
        } catch (response) {
            console.log(response)
        }
    }

    return (
        <>
            <div class="profile-sec" id="lawyer_personal_experience">
                <div class="inner-guest-posts">

                    <div class="inner-profile-sec">

                        <div class="row align-items-center">

                            <div class="col-md-8">
                                <div class="heading-title c-text-small">
                                    <p>Professional Experience</p>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <div class="add-more-field text-right">
                                    <button type="button" onClick={() => addExperience()}>Add <i
                                        class="fa-solid fa-plus ml-2"></i></button>
                                </div>
                            </div>

                        </div>
                        <form onSubmit={ExperienceSubmit}>
                            <div class="profile-edit-sec">
                                <input type="hidden" name="lawyer_id" defaultValue={lawyerid} />
                                <input type="hidden" id="delete_experience" name="experience_delete" defaultValue={JSON.stringify(deleteExperience)} />
                                <div id="add-prof-experience">
                                    {lawyerExperience != "" ? lawyerExperience.map((item, i) => {
                                        return (
                                            <div class="row total-experience" id={"lawyer-experience" + i} key={i}>
                                                <div class="col-md-6 col-lg-3 col-xl-3">
                                                    <div class="form-group">
                                                        <input type="hidden" name={"experienceid[" + i + "]"} defaultValue={item.id} />
                                                        <label>Title</label>
                                                        <input type="text" name={"experience_title[" + i + "]"} defaultValue={item.title} id={"experience_title" + i} class="form-control" />
                                                        <p class="text-danger" id={"experience_title." + i + "-error"}></p>
                                                    </div>
                                                </div>

                                                <div class="col-md-6 col-lg-3 col-xl-3">
                                                    <div class="form-group">
                                                        <label>Law Firm</label>
                                                        <input type="text" name={"experience_firm[" + i + "]"} defaultValue={item.law_firm} id={"experience_firm" + i} class="form-control" />
                                                        <p class="text-danger" id={"experience_firm." + i + "-error"}></p>
                                                    </div>
                                                </div>

                                                <div class="col-md-6 col-lg-3 col-xl-3">
                                                    <div class="form-group">
                                                        <label>From Date</label>
                                                        <select name={"experience_from_date[" + i + "]"} defaultValue={item.from_date} id={"experience_from_date" + i} class="form-control">
                                                            {
                                                                yearOfDate.map((value) => {
                                                                    return <option>{value}</option>
                                                                })
                                                            }
                                                        </select>
                                                        <p class="text-danger" id={"experience_from_date." + i + "-error"}></p>
                                                    </div>
                                                </div>

                                                <div class="col-md-6 col-lg-3 col-xl-3">
                                                    <div class="form-group">
                                                        <label>To Date</label>
                                                        <select name={"experience_to_date[" + i + "]"} defaultValue={item.to_date} id={"experience_to_date" + i} class="form-control">
                                                            {
                                                                yearOfDate.map((value) => {
                                                                    return <option>{value}</option>
                                                                })
                                                            }
                                                            <option>Present</option>
                                                        </select>
                                                        <p class="text-danger" id={"experience_to_date." + i + "-error"}></p>
                                                    </div>
                                                </div>

                                                {
                                                    i < 1 ? "" : (
                                                        <div class="save-btn text-right ">
                                                            <button type="button" deleteexperience={item.id} class="remove-experience" entity={"lawyer-experience" + i}>Remove</button>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        )
                                    }) :
                                        <div class="row total-experience" id="lawyer-experience0">
                                            <div class="col-md-6 col-lg-3 col-xl-3">
                                                <div class="form-group">
                                                    <input type="hidden" name="experienceid[0]" defaultValue="" />
                                                    <label>Title</label>
                                                    <input type="text" name="experience_title[0]" id="experience_title0" class="form-control" />
                                                    <p class="text-danger" id="experience_title.0-error"></p>
                                                </div>
                                            </div>

                                            <div class="col-md-6 col-lg-3 col-xl-3">
                                                <div class="form-group">
                                                    <label>Law Firm</label>
                                                    <input type="text" name="experience_firm[0]" id="experience_firm0" class="form-control" />
                                                    <p class="text-danger" id="experience_firm.0-error"></p>
                                                </div>
                                            </div>

                                            <div class="col-md-6 col-lg-3 col-xl-3">
                                                <div class="form-group">
                                                    <label>From Date</label>
                                                    <select name="experience_from_date[0]" id="experience_from_date0" class="form-control">
                                                        {
                                                            yearOfDate.map((value) => {
                                                                return <option>{value}</option>
                                                            })
                                                        }
                                                    </select>
                                                    <p class="text-danger" id="experience_from_date.0-error"></p>
                                                </div>
                                            </div>

                                            <div class="col-md-6 col-lg-3 col-xl-3">
                                                <div class="form-group">
                                                    <label>To Date</label>
                                                    <select name="experience_to_date[0]" id="experience_to_date0" class="form-control">
                                                        {
                                                            yearOfDate.map((value) => {
                                                                return <option>{value}</option>
                                                            })
                                                        }
                                                        <option>Present</option>
                                                    </select>
                                                    <p class="text-danger" id="experience_to_date.0-error"></p>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>


                                <div class="save-btn text-right pt-0">
                                    <button type="submit" class="mt-2">Save</button>
                                </div>


                            </div>
                        </form>

                    </div>

                </div>

            </div>
            {Alert}
        </>
    )
}

function ProfessionalAssociation() {
    const cookies = new Cookies();
    var lawyerid = cookies.get('LAWYERID');
    const  REACT_APP_LIVE_BACKEND_BASE_URL  = process.env.REACT_APP_LIVE_BACKEND_BASE_URL;
    const LAWYERURL = REACT_APP_LIVE_BACKEND_BASE_URL+'api/get-lawyer-data/' + lawyerid;
    const [lawyerAssociation, setLawyerAssociation] = useState([])
    const [deleteAssociation, setDeleteAssociation] = useState([])
    const DvInnerHTML = function (target, newText) {
        document.querySelectorAll(target).forEach(function (element) {
            element.textContent = newText;
        });
    }
    const [Alert, setAlert] = useState(null);

    useEffect(() => {
        fetchlawyerdata();
    }, [])

    const fetchlawyerdata = () => {
        fetch(LAWYERURL).then((res) => res.json()).then((response) => {
            setLawyerAssociation(response.associationdata);
        })
    }

    const AssociationSubmit = async (e) => {
        e.preventDefault();
        try {
            // make axios post request
            const response = await axios({
                method: "post",
                url: REACT_APP_LIVE_BACKEND_BASE_URL+"api/add-lawyer-profile-association",
                data: new FormData(e.target),
                headers: {"Content-Type": "multipart/form-data"},
            }).then((response) => {
                const errors = response.data.errors;
                if (response.data.status) {
                    DvInnerHTML('.text-danger', '');
                    
                    setAlert((<SweetAlert success title="" onConfirm={() => {
                        setAlert(null)
                        window.location.reload();
                    }} >{response.data.msg}</SweetAlert>))
                } else {
                    DvInnerHTML('.text-danger', '');
                    if (parseInt(Object.keys(errors).length) > 0) {
                        for (var key in errors) {
                            if (errors.hasOwnProperty(key)) {
                                if (document.getElementById(key + '-error') === null) {
                                } else {
                                    document.getElementById(key + '-error').innerHTML = errors[key];
                                }
                            }
                        }
                    } else {
                        setAlert((<SweetAlert danger title="" onConfirm={() => {
                            setAlert(null)
                        }} >{response.data.msg}</SweetAlert>))
                    }
                }
            });
        } catch (response) {
            console.log(response)
        }
    }

    var associationlength = parseInt($(".total-associations").length);
    let addAssociations = () => {
        var html = '<div class="row total-associations" id="lawyer-association-' + associationlength + '"><div class="col-md-5"><div class="form-group"><input type="hidden" name="associationid[' + associationlength + ']" defaultValue="" /><label>Association Name</label><input type="text" name="association_name[' + associationlength + ']" class="form-control" /><p class="text-danger" id="association_name.' + associationlength + '-error"></p></div></div><div class="col-md-5"><div class="form-group"><label>Position</label><input type="text" name="association_position[' + associationlength + ']" class="form-control" /><p class="text-danger" id="association_position.' + associationlength + '-error"></p></div></div><div class="col-md-2"><button type="button" class="remove-association" entity="lawyer-association-' + associationlength + '">Remove</button></div></div>';
        $("#add-lawyer-association").append(html);
        associationlength = associationlength + parseInt(1);
    }

    $(document).on("click", ".remove-association", function (e) {
        e.preventDefault();
        var entity = $(this).attr("entity");
        $("#" + entity).remove();
        var deleteid = $(this).attr('deleteAssociationId');
        if (deleteid != null) {
            setDeleteAssociation([...deleteAssociation, deleteid]);
        }
    });

    return (
        <>
            <div class="profile-sec" id="lawyer_personal_association">
                <div class="inner-guest-posts">
                    <div class="inner-profile-sec">
                        <div class="row">
                            <div class="col-md-8">
                                <div class="heading-title c-text-small">
                                    <p>Professional Associations</p>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="add-more-field text-right">
                                    <button type="button" onClick={() => addAssociations()}>Add <i class="fa-solid fa-plus ml-2"></i></button>
                                </div>
                            </div>
                        </div>
                        <form onSubmit={AssociationSubmit}>
                            <div class="profile-edit-sec">
                                <input type="hidden" name="lawyer_id" defaultValue={lawyerid} />
                                <input type="hidden" name="association_delete" defaultValue={JSON.stringify(deleteAssociation)} />
                                <div id="add-lawyer-association">
                                    {
                                        lawyerAssociation.length > 0 ? lawyerAssociation.map((item, i) => {
                                            return (<div class="row total-associations" key={`associations-key-${i}`} id={`lawyer-association-${i}`}>
                                                <div class="col-md-5">
                                                    <input type="hidden" name={`associationid[${i}]`} defaultValue={item.id} />
                                                    <div class="form-group">
                                                        <label>Association Name</label>
                                                        <input type="text" name={`association_name[${i}]`} defaultValue={item.name} class="form-control" />
                                                        <p class="text-danger" id={`association_name-${i}-error`}></p>
                                                    </div>
                                                </div>
                                                <div class="col-md-5">
                                                    <div class="form-group">
                                                        <label>Position</label>
                                                        <input type="text" name={`association_position[${i}]`} defaultValue={item.position} class="form-control" />
                                                        <p class="text-danger" id={`association_position-${i}-error`}></p>
                                                    </div>
                                                </div>
                                                <div class="col-md-2">
                                                    {
                                                        i > 0 ? (
                                                            <button type="button" class="remove-association" deleteAssociationId={item.id} entity={`lawyer-association-${i}`}>Remove</button>
                                                        ) : ("")
                                                    }
                                                </div>
                                            </div>);
                                        }) : (
                                            <div class="row total-associations" key={`associations-key-0`}>
                                                <div class="col-md-5">
                                                    <input type="hidden" name="associationid[]" value="" />
                                                    <div class="form-group">
                                                        <label>Association Name</label>
                                                        <input type="text" name="association_name[]" class="form-control" />
                                                        <p class="text-danger" id="association_name-0-error"></p>
                                                    </div>
                                                </div>
                                                <div class="col-md-5">
                                                    <div class="form-group">
                                                        <label>Position</label>
                                                        <input type="text" name="association_position[]" class="form-control" />
                                                        <p class="text-danger" id="association_position-0-error"></p>
                                                    </div>
                                                </div>
                                                <div class="col-md-2"></div>
                                            </div>
                                        )
                                    }
                                </div>

                                <div class="save-btn text-right pt-3">
                                    <button type="submit" class="mt-2">Save</button>
                                </div>
                            </div>

                        </form>

                    </div>
                </div>
            </div>
            {Alert}
        </>
    )
}

function LawyerEducation() {
    const cookies = new Cookies();
    var lawyerid = cookies.get('LAWYERID');
    const  REACT_APP_LIVE_BACKEND_BASE_URL  = process.env.REACT_APP_LIVE_BACKEND_BASE_URL;
    const LAWYERURL = REACT_APP_LIVE_BACKEND_BASE_URL+'api/get-lawyer-data/' + lawyerid;
    const [lawyerEducation, setLawyerEducation] = useState([])
    const [deleteEducation, setDeleteEducation] = useState([])
    const [yearOfDate, setYearOfDate] = useState([])
    const DvInnerHTML = function (target, newText) {
        document.querySelectorAll(target).forEach(function (element) {
            element.textContent = newText;
        });
    }
    const [Alert, setAlert] = useState(null);

    
    useEffect(() => {
        fetchlawyerdata();
        const dates = [];
        const currentYear = moment().year();
        const fiftyYearsAgoYear = currentYear - 50;
        for(let year = fiftyYearsAgoYear; year <= currentYear; year++){
            dates.push(year);
        }
        setYearOfDate(dates);
    }, [])

    const fetchlawyerdata = () => {
        fetch(LAWYERURL) .then((res) => res.json()) .then((response) => { setLawyerEducation(response.educationdata); })
    }

    var educationlength = parseInt($(".total-education").length);
    let addEducation = () => {

        var html = '<div class="row total-education" id="lawyer-education' + educationlength + '"><div class="col-md-4"><div class="form-group"><input type="hidden" name="educationid[' + educationlength + ']" defaultValue="" /><label>School Name</label><input type="text" name="education_school_name[' + educationlength + ']" id="school_name' + educationlength + '" class="form-control" /><p class="text-danger" id="education_school_name.' + educationlength + '-error"></p></div></div><div class="col-md-4"><div class="form-group"><label>Degree</label><input type="text" name="education_degree[' + educationlength + ']" id="education_degree' + educationlength + '" class="form-control" /><p class="text-danger" id="education_degree.' + educationlength + '-error"></p></div></div><div class="col-md-4"><div class="form-group"><label>Graduated</label><select name="education_graduated[' + educationlength + ']" id="education_graduated' + educationlength + '" class="form-control"><option value="">Select Year</option>';

        yearOfDate.map((value) => {
            html += "<option>"+value+"</option>";
            return
        })
        
        html += '</select><p class="text-danger" id="education_graduated.' + educationlength + '-error"></p></div></div><div class="save-btn text-right "><button type="button" class="remove-education" entity="lawyer-education' + educationlength + '">Remove</button></div></div>';
        $("#add-lawyer-eductaion").append(html);
        educationlength = educationlength + parseInt(1);
    }

    $(document).on("click", ".remove-education", function (e) {
        e.preventDefault();
        var lasteducation = parseInt($(".total-education").length);
        if (lasteducation == 1) {
            return false;
        } else {

            var entity = $(this).attr("entity")
            $("#" + entity).remove()
            var deleteid = $(this).attr('deleteeducationid');
            if (deleteid != null) {
                setDeleteEducation([...deleteEducation, deleteid]);
            }
        }
    });


    const EducationSubmit = async (e) => {
        e.preventDefault();
        try {
            // make axios post request
            const response = await axios({
                method: "post",
                url: REACT_APP_LIVE_BACKEND_BASE_URL+"api/add-lawyer-profile-education",
                data: new FormData(e.target),
                headers: { "Content-Type": "multipart/form-data" },
            }).then((response) => {
                const errors = response.data.errors;
                if (response.data.status) {
                    DvInnerHTML('.text-danger', '');
                    
                    setAlert((<SweetAlert success title="" onConfirm={() => {
                        setAlert(null)
                        window.location.reload();
                    }} >{response.data.msg}</SweetAlert>))
                } else {
                    DvInnerHTML('.text-danger', '');
                    if (parseInt(Object.keys(errors).length) > 0) {
                        for (var key in errors) {
                            if (errors.hasOwnProperty(key)) {
                                if (document.getElementById(key + '-error') === null) {
                                } else {
                                    document.getElementById(key + '-error').innerHTML = errors[key];
                                }
                            }
                        }
                    } else {
                        setAlert((<SweetAlert danger title="" onConfirm={() => {
                            setAlert(null)
                        }} >{response.data.msg}</SweetAlert>))
                    }
                }
            });
        } catch (response) {
            console.log(response)
        }
    }

    return (
        <>
            <div class="profile-sec" id="lawyer_personal_education">
                <div class="inner-guest-posts">

                    <div class="inner-profile-sec">

                        <div class="row align-items-center">

                            <div class="col-md-8">
                                <div class="heading-title c-text-small">
                                    <p>Education</p>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <div class="add-more-field text-right">
                                    <button type="button" onClick={() => addEducation()}>Add <i class="fa-solid fa-plus ml-2"></i></button>
                                </div>
                            </div>

                        </div>
                        <form onSubmit={EducationSubmit}>
                            <div class="profile-edit-sec">
                                <input type="hidden" name="lawyer_id" defaultValue={lawyerid} />
                                <input type="hidden" id="delete_education" name="education_delete" defaultValue={JSON.stringify(deleteEducation)} />
                                <div id="add-lawyer-eductaion">
                                    {lawyerEducation != "" ? lawyerEducation.map((item, i) => {
                                        return (
                                            <div class="row total-education" id={"lawyer-education" + i} key={i}>
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <input type="hidden" name={"educationid[" + i + "]"} defaultValue={item.id} />
                                                        <label>School Name</label>
                                                        <input type="text" name={"education_school_name[" + i + "]"} defaultValue={item.school_name} id={"school_name" + i} class="form-control" />
                                                        <p class="text-danger" id={"education_school_name." + i + "-error"}></p>
                                                    </div>
                                                </div>

                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label>Degree</label>
                                                        <input type="text" name={"education_degree[" + i + "]"} defaultValue={item.degree} id={"education_degree" + i} class="form-control" />
                                                        <p class="text-danger" id={"education_degree." + i + "-error"}></p>
                                                    </div>
                                                </div>

                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label>Graduated</label>
                                                        <select name={ "education_graduated[" + i + "]" } defaultValue={item.graduated} id={ "education_graduated" + i } class="form-control">
                                                            <option value="">Select Year</option>
                                                            {
                                                                yearOfDate.map((value) => {
                                                                    return <option>{value}</option>
                                                                })
                                                            }
                                                        </select>
                                                        <p class="text-danger" id={"education_graduated." + i + "-error"}></p>
                                                    </div>
                                                </div>
                                                {
                                                    i < 1 ? "" : (
                                                        <div class="save-btn text-right ">
                                                            <button type="button" class="remove-education" deleteeducationid={item.id} entity={"lawyer-education" + i}>Remove</button>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        )
                                    }) :
                                        <div class="row total-education" id="lawyer-education0">
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <input type="hidden" name="educationid[0]" defaultValue="" />
                                                    <label>School Name</label>
                                                    <input type="text" name="education_school_name[0]" id="school_name0" class="form-control" />
                                                    <p class="text-danger" id="education_school_name.0-error"></p>
                                                </div>
                                            </div>

                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Degree</label>
                                                    <input type="text" name="education_degree[0]" id="education_degree0" class="form-control" />
                                                    <p class="text-danger" id="education_degree.0-error"></p>
                                                </div>
                                            </div>

                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Graduated</label>
                                                    <select name="education_graduated[0]" id="education_graduated0" class="form-control">
                                                        <option value="">Select Year</option>
                                                        {
                                                            yearOfDate.map((value) => {
                                                                return <option>{value}</option>
                                                            })
                                                        }
                                                    </select>
                                                    <p class="text-danger" id="education_graduated.0-error"></p>
                                                </div>
                                            </div>

                                        </div>
                                    }
                                </div>

                                <div class="save-btn text-right pt-0">
                                    <button type="submit" class="mt-2">Save</button>
                                </div>

                            </div>
                        </form>

                    </div>

                </div>

            </div>
            {Alert}
        </>
    )
}

function FurtherReading() {

    const cookies = new Cookies();
    var lawyerid = cookies.get('LAWYERID');
    const  REACT_APP_LIVE_BACKEND_BASE_URL  = process.env.REACT_APP_LIVE_BACKEND_BASE_URL;
    const LAWYERURL = REACT_APP_LIVE_BACKEND_BASE_URL+'api/get-lawyer-data/' + lawyerid;
    const [lawyerReading, setLawyerReading] = useState([])
    const [deleteReading, setDeleteReading] = useState([])
    const DvInnerHTML = function (target, newText) {
        document.querySelectorAll(target).forEach(function (element) {
            element.textContent = newText;
        });
    }
    const [Alert, setAlert] = useState(null);


    useEffect(() => {
        fetchlawyerdata();
    }, [])

    const fetchlawyerdata = () => {
        fetch(LAWYERURL).then((res) => res.json()).then((response) => {
            setLawyerReading(response.readingdata);
        })
    }

    var readinglength = parseInt($(".total-lawyer-reading").length);
    let addReading = () => {
        var html = '<div class="row align-items-center total-lawyer-reading" id="lawyer-reading' + readinglength + '"><div class="col-md-4"><div class="form-group"><label>Anchor Text</label><input type="hidden" name="lawyerreadingid[' + readinglength + ']" defaultValue="" /><input type="text" name="reading_anchor_text[' + readinglength + ']" id="reading_anchor' + readinglength + '"><p class="text-danger" id="reading_anchor_text.' + readinglength + '-error"></p></div></div><div class="col-md-5"><div class="form-group"><label>URL</label><input type="text" name="reading_url[' + readinglength + ']" id="reading_url' + readinglength + '" class="form-control" /><p class="text-danger" id="reading_url.' + readinglength + '-error"></p></div></div><div class="col-md-3"><div class="form-group text-right mb-0"><button type="button" class="delete-btn remove-reading" entity="entity-container-1" removeid="lawyer-reading' + readinglength + '">Delete </button></div></div></div>';
        $(".add-lawyer-reading").append(html);
        readinglength = readinglength + parseInt(1);
    }

    $(document).on("click", ".remove-reading", function (e) {
        e.preventDefault();
        var entity = $(this).attr("removeid")
        var deleteid = $(this).attr('deletereadingid');
        $("#" + entity).remove()
        if (deleteid !== undefined) {
            setDeleteReading([...deleteReading, deleteid]);
        }
    });

    const ReadingSubmit = async (e) => {
        e.preventDefault();
        try {
            // make axios post request
            const response = await axios({
                method: "post",
                url: REACT_APP_LIVE_BACKEND_BASE_URL+"api/add-lawyer-profile-reading",
                data: new FormData(e.target),
                headers: { "Content-Type": "multipart/form-data" },
            }).then((response) => {
                const errors = response.data.errors;
                if (response.data.status) {
                    DvInnerHTML('.text-danger', '');
                    
                    setAlert((<SweetAlert success title="" onConfirm={() => {
                        setAlert(null)
                        window.location.reload();
                    }} >{response.data.msg}</SweetAlert>))
                } else {
                    DvInnerHTML('.text-danger', '');
                    if (parseInt(Object.keys(errors).length) > 0) {
                        for (var key in errors) {
                            if (errors.hasOwnProperty(key)) {
                                if (document.getElementById(key + '-error') === null) {
                                } else {
                                    document.getElementById(key + '-error').innerHTML = errors[key];
                                }
                            }
                        }
                    } else {
                        setAlert((<SweetAlert danger title="" onConfirm={() => {
                            setAlert(null)
                        }} >{response.data.msg}</SweetAlert>))
                    }
                }
            });
        } catch (response) {
            console.log(response)
        }
    }

    return (
        <>
            <div class="profile-sec" id="lawyer_personal_reading">
                <div class="inner-guest-posts">

                    <div class="inner-profile-sec">
                        <div class="heading-title c-text-small">
                            <p>Further Reading</p>
                        </div>

                        <div class="row">
                            <div class="col-md-8">
                                <div class="heading-text c-text-small">
                                    <p class="m-0">These links will be live do-follow links, so we recommend you
                                        link to some of the best pages on your law firm's website.</p>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="add-more-field text-right">
                                    <button type="button" onClick={() => addReading()}>Add <i
                                        class="fa-solid fa-plus ml-2"></i></button>
                                </div>
                            </div>
                        </div>

                        <form onSubmit={ReadingSubmit}>
                            <div class="profile-edit-sec">

                                <div class="multi-fields">
                                    <input type="hidden" name="lawyer_id" defaultValue={lawyerid} />
                                    <input type="hidden" id="reading_delete" name="reading_delete" defaultValue={JSON.stringify(deleteReading)} />
                                    <div class="e-url add-lawyer-reading" id="entity-container-1">
                                        {lawyerReading != "" ? lawyerReading.map((item, i) => {
                                            return (
                                                <div class="row align-items-center total-lawyer-reading" id={"lawyer-reading" + i} key={i}>
                                                    <div class="col-md-4">
                                                        <div class="form-group">
                                                            <label>Anchor Text</label>
                                                            <input type="hidden" name={"lawyerreadingid[" + i + "]"} defaultValue={item.id} />
                                                            <input type="text" defaultValue={item.text} name={"reading_anchor_text[" + i + "]"} id={"reading_anchor" + i} class="form-control" />
                                                            <p class="text-danger" id={"reading_anchor_text." + i + "-error"}></p>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-5">
                                                        <div class="form-group">
                                                            <label>URL</label>
                                                            <input type="text" defaultValue={item.url} name={"reading_url[" + i + "]"} id={"reading_url" + i} class="form-control" />
                                                            <p class="text-danger" id={"reading_url." + i + "-error"}></p>
                                                        </div>
                                                    </div>
                                                    {
                                                        i < 1 ? "" : (
                                                            <div class="col-md-3">
                                                                <div class="form-group mb-0 text-right">
                                                                    <button type="button" class="delete-btn remove-reading" entity="entity-container-1" deletereadingid={item.id} removeid={"lawyer-reading" + i}>Delete </button>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            )
                                        }) :
                                            <div class="row align-items-center total-lawyer-reading" id="lawyer-reading0">
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label>Anchor Text</label>
                                                        <input type="hidden" name="lawyerreadingid[0]" defaultValue="" />
                                                        <input type="text" name="reading_anchor_text[0]" id="reading_anchor0" class="form-control" />
                                                        <p class="text-danger" id="reading_anchor_text.0-error"></p>
                                                    </div>
                                                </div>

                                                <div class="col-md-5">
                                                    <div class="form-group">
                                                        <label>URL</label>
                                                        <input type="text" name="reading_url[0]" id="reading_url0" class="form-control" />
                                                        <p class="text-danger" id="reading_url.0-error"></p>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>

                                <div class="save-btn text-right pt-2">
                                    <button type="submit" class="mt-2">Save</button>
                                </div>

                            </div>
                        </form>

                    </div>

                </div>

            </div>
            {Alert}
        </>
    )
}

function SpeakingEngagement() {

    const cookies = new Cookies();
    var lawyerid = cookies.get('LAWYERID');
    const  REACT_APP_LIVE_BACKEND_BASE_URL  = process.env.REACT_APP_LIVE_BACKEND_BASE_URL;
    const LAWYERURL = REACT_APP_LIVE_BACKEND_BASE_URL+'api/get-lawyer-data/' + lawyerid;
    const [lawyerEngagement, setLawyerEngagement] = useState([])
    const [deleteEngagement, setDeleteEngagement] = useState([])
    const DvInnerHTML = function (target, newText) {
        document.querySelectorAll(target).forEach(function (element) {
            element.textContent = newText;
        });
    }
    const [Alert, setAlert] = useState(null);

    const formatDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    useEffect(() => {
        fetchlawyerdata();
    }, [])

    const fetchlawyerdata = () => {
        fetch(LAWYERURL)
            .then((res) =>
                res.json())

            .then((response) => {
                setLawyerEngagement(response.engagementdata);
            })


    }


    var engagementlength = parseInt($(".total-lawyer-engagement").length);
    let addEngagement = () => {


        var html = '<div class="row total-lawyer-engagement" id="lawyer-speaking_engagement' + engagementlength + '"><div class="col-md-4"><div class="form-group"><label>Conference</label><input type="hidden" name="lawyerspeakingid[' + engagementlength + ']" defaultValue="" /><input type="text" name="speaking_conference[' + engagementlength + ']" id="speaking_conference' + engagementlength + '" class="form-control" /><p class="text-danger" id="speaking_conference.' + engagementlength + '-error"></p></div></div><div class="col-md-4"><div class="form-group"><label>Topic</label><input type="text" name="speaking_topic[' + engagementlength + ']" id="speaking_topic' + engagementlength + '" class="form-control" /><p class="text-danger" id="speaking_topic.' + engagementlength + '-error"></p></div></div><div class="col-md-4"><div class="form-group"><label>Date</label><input type="date" max="' + formatDate(new Date()) + '" name="speaking_date[' + engagementlength + ']" id="speaking_date' + engagementlength + '" class="form-control" /><p class="text-danger" id="speaking_date.' + engagementlength + '-error"></p></div></div><div class="save-btn text-right "><button type="button" class="remove-speaking_engagemnet" entity="lawyer-speaking_engagement' + engagementlength + '">Remove</button></div></div>';
        $(".add-lawyer-speaking-engagemnet").append(html);
        engagementlength = engagementlength + parseInt(1);
    }


    $(document).on("click", ".remove-speaking_engagemnet", function (e) {
        e.preventDefault();
        var lastengagement = parseInt($(".total-lawyer-engagement").length);
        if (lastengagement == 1) {
            return false;
        } else {
            var entity = $(this).attr("entity")
            $("#" + entity).remove()
            var deleteid = $(this).attr('deleteengagementid');
            if (deleteid != null) {
                setDeleteEngagement([...deleteEngagement, deleteid]);
            }
        }
    });


    const EngagementSubmit = async (e) => {
        e.preventDefault();
        try {
            // make axios post request
            const response = await axios({
                method: "post",
                url: REACT_APP_LIVE_BACKEND_BASE_URL+"api/add-lawyer-profile-speaking",
                data: new FormData(e.target),
                headers: { "Content-Type": "multipart/form-data" },
            }).then((response) => {
                const errors = response.data.errors;
                if (response.data.status) {
                    // DvInnerHTML('.add-lawyer-speaking-engagemnet', '');
                    DvInnerHTML('.text-danger', '');
                    setAlert((<SweetAlert success title="" onConfirm={() => {
                        setAlert(null)
                        window.location.reload();
                    }} >{response.data.msg}</SweetAlert>))                                        
                } else {
                    DvInnerHTML('.text-danger', '');
                    if (parseInt(Object.keys(errors).length) > 0) {
                        for (var key in errors) {
                            if (errors.hasOwnProperty(key)) {
                                if (document.getElementById(key + '-error') === null) {
                                } else {
                                    document.getElementById(key + '-error').innerHTML = errors[key];
                                }
                            }
                        }
                    } else {
                        setAlert((<SweetAlert danger title="" onConfirm={() => {
                            setAlert(null)
                        }} >{response.data.msg}</SweetAlert>))
                    }
                }
            });
        } catch (response) {
            console.log(response)
        }
    }

    return (
        <>
            <div class="profile-sec" id="lawyer_personal_speaking">
                <div class="inner-guest-posts">

                    <div class="inner-profile-sec">

                        <div class="row align-items-center">

                            <div class="col-md-8">
                                <div class="heading-title c-text-small">
                                    <p>Speaking Engagements</p>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <div class="add-more-field text-right">
                                    <button type="button" onClick={() => addEngagement()}>Add <i
                                        class="fa-solid fa-plus ml-2"></i></button>
                                </div>
                            </div>

                        </div>
                        <form onSubmit={EngagementSubmit}>
                            <div class="profile-edit-sec">
                                <input type="hidden" name="lawyer_id" defaultValue={lawyerid} />
                                <input type="hidden" id="engagement_delete" name="engagement_delete" defaultValue={JSON.stringify(deleteEngagement)} />
                                <div class="add-lawyer-speaking-engagemnet">
                                    {lawyerEngagement != "" ? lawyerEngagement.map((item, i) => {
                                        return (
                                            <div class="row total-lawyer-engagement" id={"lawyer-speaking_engagement" + i} key={i}>

                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label>Conference</label>
                                                        <input type="hidden" name={"lawyerspeakingid[" + i + "]"} defaultValue={item.id} />
                                                        <input type="text" defaultValue={item.conference} name={"speaking_conference[" + i + "]"} id={"speaking_conference" + i} class="form-control" />
                                                        <p class="text-danger" id={"speaking_conference." + i + "-error"}></p>
                                                    </div>
                                                </div>

                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label>Topic</label>
                                                        <input type="text" defaultValue={item.topic} name={"speaking_topic[" + i + "]"} id={"speaking_topic" + i} class="form-control" />
                                                        <p class="text-danger" id={"speaking_topic." + i + "-error"}></p>
                                                    </div>
                                                </div>

                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label>Date</label>
                                                        <input type="date" max={formatDate(new Date())} defaultValue={formatDate(item.date)} name={"speaking_date[" + i + "]"} id={"speaking_date" + i} class="form-control" />
                                                        <p class="text-danger" id={"speaking_date." + i + "-error"}></p>
                                                    </div>
                                                </div>
                                                {
                                                    i < 1 ? "" : (
                                                        <div class="save-btn text-right ">
                                                            <button type="button" class="remove-speaking_engagemnet" deleteengagementid={item.id} entity={"lawyer-speaking_engagement" + i}>Remove</button>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        )
                                    }) :
                                        <div class="row total-lawyer-engagement" id="lawyer-speaking_engagement0">

                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Conference</label>
                                                    <input type="hidden" name="lawyerspeakingid[0]" defaultValue="" />
                                                    <input type="text" name="speaking_conference[0]" id="speaking_conference0" class="form-control" />
                                                    <p class="text-danger" id="speaking_conference.0-error"></p>
                                                </div>
                                            </div>

                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Topic</label>
                                                    <input type="text" name="speaking_topic[0]" id="speaking_topic0" class="form-control" />
                                                    <p class="text-danger" id="speaking_topic.0-error"></p>
                                                </div>
                                            </div>

                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Date</label>
                                                    <input type="date" max={formatDate(new Date())} name="speaking_date[0]" id="speaking_date0" class="form-control" />
                                                    <p class="text-danger" id="speaking_date.0-error"></p>
                                                </div>
                                            </div>


                                        </div>
                                    }
                                </div>

                                <div class="save-btn text-right pt-0">
                                    <button type="submit" class="mt-2">Save</button>
                                </div>

                            </div>
                        </form>
                    </div>

                </div>
            </div>
            {Alert}
        </>
    )
}

function NotableSettlement() {

    const cookies = new Cookies();
    var lawyerid = cookies.get('LAWYERID');
    const  REACT_APP_LIVE_BACKEND_BASE_URL  = process.env.REACT_APP_LIVE_BACKEND_BASE_URL;
    const LAWYERURL = REACT_APP_LIVE_BACKEND_BASE_URL+'api/get-lawyer-data/' + lawyerid;
    const [lawyerSettlement, setLawyerSettlement] = useState([])
    const [deleteSettlement, setDeleteSettlement] = useState([])
    const DvInnerHTML = function (target, newText) {
        document.querySelectorAll(target).forEach(function (element) {
            element.textContent = newText;
        });
    }
    const [Alert, setAlert] = useState(null);


    const formatDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    useEffect(() => {
        fetchlawyerdata();
    }, [])

    const fetchlawyerdata = () => {
        fetch(LAWYERURL) .then((res) => res.json()) .then((response) => { setLawyerSettlement(response.settlementdata); })
    }

    var settlementlength = parseInt($(".total-lawyer-settlement").length);
    let addSettlement = () => {


        var html = '<div class="row total-lawyer-settlement" id="lawyer_notable_settlement' + settlementlength + '" ><div class="col-md-4"><div class="form-group"><label>Amount</label><input type="hidden" name="lawyersettlementid[' + settlementlength + ']" defaultValue="" /><input type="text" name="settlement_amount[' + settlementlength + ']" id="settlement_amount' + settlementlength + '" class="form-control" /><p class="text-danger" id="settlement_amount.' + settlementlength + '-error"></p></div></div><div class="col-md-4"><div class="form-group"><label>Injury</label><input type="text" name="settlement_injury[' + settlementlength + ']" id="settlement_injury' + settlementlength + '" class="form-control" /><p class="text-danger" id="settlement_injury.' + settlementlength + '-error"></p></div></div><div class="col-md-4"><div class="form-group"><label>Date</label><input type="date" max="' + formatDate(new Date()) + '" name="settlement_date[' + settlementlength + ']" id="settlement_date' + settlementlength + '" class="form-control" /><p class="text-danger" id="settlement_date.' + settlementlength + '-error"></p></div></div><div class="save-btn text-right "><button type="button" class="remove-settlement" entity="lawyer_notable_settlement' + settlementlength + '">Remove</button></div></div>';
        $(".add-lawyer-settlement").append(html);
        settlementlength = settlementlength + parseInt(1);
    }


    $(document).on("click", ".remove-settlement", function (e) {
        e.preventDefault();
        var lastsettlement = parseInt($(".total-lawyer-settlement").length);
        if (lastsettlement == 1) {
            return false;
        } else {
            var entity = $(this).attr("entity")
            $("#" + entity).remove()
            var deleteid = $(this).attr('deletenotableid');
            if (deleteid != null) {
                setDeleteSettlement([...deleteSettlement, deleteid]);
            }
        }
    });


    const SettlementSubmit = async (e) => {
        e.preventDefault();
        try {
            // make axios post request
            const response = await axios({
                method: "post",
                url: REACT_APP_LIVE_BACKEND_BASE_URL+"api/add-lawyer-profile-settlement",
                data: new FormData(e.target),
                headers: { "Content-Type": "multipart/form-data" },
            }).then((response) => {
                const errors = response.data.errors;
                if (response.data.status) {
                    DvInnerHTML('.text-danger', '');
                    
                    setAlert((<SweetAlert success title="" onConfirm={() => {
                        setAlert(null)
                        window.location.reload();
                    }} >{response.data.msg}</SweetAlert>))
                } else {
                    DvInnerHTML('.text-danger', '');
                    if (parseInt(Object.keys(errors).length) > 0) {
                        for (var key in errors) {
                            if (errors.hasOwnProperty(key)) {
                                if (document.getElementById(key + '-error') === null) {
                                } else {
                                    document.getElementById(key + '-error').innerHTML = errors[key];
                                }
                            }
                        }
                    } else {
                        setAlert((<SweetAlert danger title="" onConfirm={() => {
                            setAlert(null)
                        }} >{response.data.msg}</SweetAlert>))
                    }
                }
            });
        } catch (response) {
            console.log(response)
        }
    }

    return (
        <>
            <div class="profile-sec" id="lawyer_personal_settlement">
                <div class="inner-guest-posts">

                    <div class="inner-profile-sec">

                        <div class="row align-items-center">

                            <div class="col-md-8">
                                <div class="heading-title c-text-small">
                                    <p>Notable Settlements</p>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <div class="add-more-field text-right">
                                    <button type="button" onClick={() => addSettlement()}>Add <i
                                        class="fa-solid fa-plus ml-2"></i></button>
                                </div>
                            </div>

                        </div>
                        <form onSubmit={SettlementSubmit}>
                            <div class="profile-edit-sec">
                                <input type="hidden" name="lawyer_id" defaultValue={lawyerid} />
                                <input type="hidden" id="notable_delete" name="notable_delete" defaultValue={JSON.stringify(deleteSettlement)} />
                                <div class="add-lawyer-settlement">
                                    {lawyerSettlement != "" ? lawyerSettlement.map((item, i) => {
                                        return (
                                            <div class="row total-lawyer-settlement" id={"lawyer_notable_settlement" + i} key={i}>

                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label>Amount</label>
                                                        <input type="hidden" defaultValue={item.id} name={"lawyersettlementid[" + i + "]"} />
                                                        <input type="text" defaultValue={item.amount} name={"settlement_amount[" + i + "]"} id={"settlement_amount" + i} class="form-control" />
                                                        <p class="text-danger" id={"settlement_amount." + i + "-error"}></p>
                                                    </div>
                                                </div>

                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label>Injury</label>
                                                        <input type="text" defaultValue={item.injury} name={"settlement_injury[" + i + "]"} id={"settlement_injury" + i} class="form-control" />
                                                        <p class="text-danger" id={"settlement_injury." + i + "-error"}></p>
                                                    </div>
                                                </div>

                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label>Date</label>
                                                        <input type="date" max={formatDate(new Date())} defaultValue={formatDate(item.date)} name={"settlement_date[" + i + "]"} id={"settlement_date" + i} class="form-control" />
                                                        <p class="text-danger" id={"settlement_date." + i + "-error"}></p>
                                                    </div>
                                                </div>
                                                {
                                                    i < 1 ? "" : (
                                                        <div class="save-btn text-right">
                                                            <button type="button" class="remove-settlement" deletenotableid={item.id} entity={"lawyer_notable_settlement" + i}>Remove</button>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        )
                                    }) :
                                        <div class="row total-lawyer-settlement" id="lawyer_notable_settlement0" >

                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Amount</label>
                                                    <input type="hidden" name="lawyersettlementid[0]" defaultValue="" />
                                                    <input type="text" name="settlement_amount[0]" id="settlement_amount0" class="form-control" />
                                                    <p class="text-danger" id="settlement_amount.0-error"></p>
                                                </div>
                                            </div>

                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Injury</label>
                                                    <input type="text" name="settlement_injury[0]" id="settlement_injury0" class="form-control" />
                                                    <p class="text-danger" id="settlement_injury.0-error"></p>
                                                </div>
                                            </div>

                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Date</label>
                                                    <input type="date" max={formatDate(new Date())} name="settlement_date[0]" id="settlement_date0" class="form-control" />
                                                    <p class="text-danger" id="settlement_date.0-error"></p>
                                                </div>
                                            </div>


                                        </div>
                                    }
                                </div>

                                <div class="save-btn text-right pt-0">
                                    <button type="submit" class="mt-2">Save</button>
                                </div>

                            </div>
                        </form>
                    </div>

                </div>
            </div>
            {Alert}
        </>
    )
}


function LawyerAttorney() {


    const cookies = new Cookies();
    var lawyerid = cookies.get('LAWYERID');
    const  REACT_APP_LIVE_BACKEND_BASE_URL  = process.env.REACT_APP_LIVE_BACKEND_BASE_URL;
    const LAWYERURL = REACT_APP_LIVE_BACKEND_BASE_URL+'api/get-lawyer-data/' + lawyerid;
    const dummyImage = `${process.env.PUBLIC_URL}/assets/images/dummy-image.jpg`;
    const [lawyerAttorney, setLawyerAttorney] = useState([])
    const [deleteAttorney, setDeleteAttorney] = useState([])
    const DvInnerHTML = function (target, newText) {
        document.querySelectorAll(target).forEach(function (element) {
            element.textContent = newText;
        });
    }
    const [Alert, setAlert] = useState(null);


    useEffect(() => {
        fetchlawyerdata();
    }, [])

    const fetchlawyerdata = () => {
        fetch(LAWYERURL)
            .then((res) =>
                res.json())

            .then((response) => {
                setLawyerAttorney(response.attorneydata);

            })
    }


    var attorneylength = parseInt($(".total-lawyer-attorney").length);
    let addAttorney = () => {
        var html = '<div class="row total-lawyer-attorney" id="lawyer_attorney' + attorneylength + '"><div class="col-md-6"><input type="hidden" name="lawyerattorneyid[' + attorneylength + ']" value="0" /><div class="lawyer-attorney-img"><img src="'+dummyImage+'" id="preview_image' + attorneylength + '" class="delete_preview-image' + attorneylength + '"/><div class="action-video-photo"><div class="upload-img-btn"><input type="file" name="attorney_image_' + attorneylength + '" id="attorney_image' + attorneylength + '" preimage="preview_image' + attorneylength + '" class="newpreview delete_preview-image' + attorneylength + '"/><span>Upload Image</span></div><div class="delete-img-btn"><button type="button" class="delete-prev-image" deletepreview="delete_preview-image' + attorneylength + '">Delete Image</button></div></div></div><p class="text-danger" id="attorney_image_' + attorneylength + '-error"></p></div><div class="col-md-3"><div class="form-group"><label>Name</label><input type="text" name="attorney_name[' + attorneylength + ']" id="attorney_name' + attorneylength + '" class="form-control" /><p class="text-danger" id="attorney_name.' + attorneylength + '-error"></p></div></div><div class="col-md-3"><div class="form-group"><label>Category</label><input type="text" name="attorney_category[' + attorneylength + ']" id="attorney_category' + attorneylength + '" class="form-control" /><p class="text-danger" id="attorney_category.' + attorneylength + '-error"></p></div></div><div class="save-btn text-right "><button type="button" class="remove-attorney" entity="lawyer_attorney' + attorneylength + '">Remove</button></div></div>';
        $(".add_lawyer_attorney").append(html);
        attorneylength = attorneylength + parseInt(1);
    }

    $(document).on("click", ".remove-attorney", function (e) {
        e.preventDefault();
        var entity = $(this).attr("entity")
        var deleteid = $(this).attr('deleteattorneyid');
        if (deleteid !== undefined) {
            $("#" + entity).remove();
            setDeleteAttorney([...deleteAttorney, deleteid]);
            axios({
                method: "delete",
                url: REACT_APP_LIVE_BACKEND_BASE_URL+"api/delete-attorney/"+deleteid,                
            }).then((response) => {
                const data = response.data;
            }).catch((error) => {
                const data = error.response.data;
            });
        }else{
            $("#" + entity).remove();
        }
    });

    const AttorneySubmit = async (e) => {
        e.preventDefault();
        $("#start-spin-attorney").html('<i class="fas fa-spinner fa-spin"></i>');
        $("#start-spin-attorney").prop('disabled', true);
        try {
            // make axios post request
            const response = await axios({
                method: "post",
                url: REACT_APP_LIVE_BACKEND_BASE_URL+"api/add-lawyer-profile-attorney",
                data: new FormData(e.target),
                headers: { "Content-Type": "multipart/form-data" },
            }).then((response) => {
                $("#start-spin-attorney").html('Submit');
                $("#start-spin-attorney").prop('disabled', false);
                const errors = response.data.errors;
                if (response.data.status) {
                    DvInnerHTML('.text-danger', '');
                    
                    setAlert((<SweetAlert success title="" onConfirm={() => {
                        setAlert(null)
                        window.location.reload();
                    }} >{response.data.msg}</SweetAlert>))
                } else {
                    DvInnerHTML('.text-danger', '');
                    if (parseInt(Object.keys(errors).length) > 0) {
                        for (var key in errors) {
                            if (errors.hasOwnProperty(key)) {
                                if (document.getElementById(key + '-error') === null) {
                                } else {
                                    document.getElementById(key + '-error').innerHTML = errors[key];
                                }
                            }
                        }
                    } else {
                        setAlert((<SweetAlert danger title="" onConfirm={() => {
                            setAlert(null)
                        }} >{response.data.msg}</SweetAlert>))
                    }
                }
            });
        } catch (response) {
            console.log(response)
        }
    }

    $(document).on('change', ".newpreview", function () {
        if (typeof (FileReader) != "undefined") {

            var image_holder = $(this).attr('preimage');
            // image_holder.empty();

            var reader = new FileReader();
            reader.onload = function (e) {
                $("#" + image_holder).prop('src', e.target.result)
            }
            reader.readAsDataURL($(this)[0].files[0]);
        } else {
            alert("This browser does not support FileReader.");
        }
    });

    $(document).on('click', '.delete-prev-image', function () {
        var deleteattr = $(this).attr('deletepreview');
        $("." + deleteattr).val('');
        $("." + deleteattr).prop('src', '');
    });

    return (

        <>
            <div class="profile-sec" id="lawyer_personal_attorney">
                <div class="inner-guest-posts">

                    <div class="inner-profile-sec">

                        <div class="row align-items-center">

                            <div class="col-md-8">
                                <div class="heading-title c-text-small">
                                    <p>Attorneys at This Firm</p>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <div class="add-more-field text-right">
                                    <button type="button" onClick={() => addAttorney()}>Add <i
                                        class="fa-solid fa-plus ml-2"></i></button>
                                </div>
                            </div>

                        </div>
                        <form onSubmit={AttorneySubmit} encType="multipart/form-data">
                            <div class="profile-edit-sec">
                                <input type="hidden" name="lawyer_id" value={lawyerid} />
                                <input type="hidden" id="attorney_delete" name="attorney_delete" value={JSON.stringify(deleteAttorney)} />
                                <div class="add_lawyer_attorney">
                                    {lawyerAttorney != "" ? lawyerAttorney.map((item, i) => {
                                        return (
                                            <div class="row total-lawyer-attorney" id={"lawyer_attorney" + i} key={i}>
                                                <div class="col-md-6">
                                                    <input type="hidden" name={"lawyerattorneyid[" + i + "]"} value={item.id} />
                                                    <div class="lawyer-attorney-img">
                                                        <img src={REACT_APP_LIVE_BACKEND_BASE_URL+"public" + item.image} id={"preview_image" + i} class={"delete_preview-image" + i} />
                                                        <div className="action-video-photo">
                                                            <div class="upload-img-btn">
                                                                <input type="file" preimage={"preview_image" + i} name={"attorney_image_" + i} id={"attorney_image" + i} class={"newpreview delete_preview-image" + i + ""} />
                                                                <span>Upload Image</span>
                                                            </div>
                                                            <div class="delete-img-btn">
                                                                <button type="button" class="delete-prev-image" deletepreview={"delete_preview-image" + i} >Delete Image</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <p class="text-danger" id={"attorney_image_" + i + "-error"}></p>
                                                </div>

                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label>Name</label>
                                                        <input type="text" defaultValue={item.name} name={"attorney_name[" + i + "]"} id={"attorney_name" + i} class="form-control" />
                                                        <p class="text-danger" id={"attorney_name." + i + "-error"}></p>
                                                    </div>
                                                </div>

                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label>Category</label>
                                                        <input type="text" defaultValue={item.category} name={"attorney_category[" + i + "]"} id={"attorney_category" + i} class="form-control" />
                                                        <p class="text-danger" id={"attorney_category." + i + "-error"}></p>
                                                    </div>
                                                </div>
                                                {
                                                    i < 1 ? "" : (
                                                        <div class="save-btn text-right ">
                                                            <button type="button" class="remove-attorney" deleteattorneyid={item.id} entity={"lawyer_attorney" + i}>Remove</button>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        )
                                    }) :
                                        <div class="row total-lawyer-attorney" id="lawyer_attorney0">
                                            <div class="col-md-6">
                                                <input type="hidden" name="lawyerattorneyid[0]" defaultValue="0" />
                                                <div class="lawyer-attorney-img">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/dummy-image.jpg`} id="preview_image0" class="delete_preview-image0" />
                                                    <div className="action-video-photo">
                                                        <div class="upload-img-btn">
                                                            <input type="file" preimage="preview_image0" name="attorney_image_0" id="attorney_image_0" class="newpreview delete_preview-image0" />
                                                            <span>Upload Image</span>
                                                        </div>
                                                        <div class="delete-img-btn">
                                                            <button type="button" class="delete-prev-image" deletepreview="delete_preview-image0">Delete Image</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <p class="text-danger" id="attorney_image_0-error"></p>
                                            </div>

                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <label>Name</label>
                                                    <input type="text" name="attorney_name[0]" id="attorney_name0" class="form-control" />
                                                    <p class="text-danger" id="attorney_name.0-error"></p>
                                                </div>
                                            </div>

                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <label>Category</label>
                                                    <input type="text" name="attorney_category[0]" id="attorney_category0" class="form-control" />
                                                    <p class="text-danger" id="attorney_category.0-error"></p>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>

                                <div class="save-btn text-right pt-0">
                                    <button type="submit" class="mt-2" id="start-spin-attorney">Save</button>
                                </div>

                            </div>
                        </form>
                    </div>

                </div>
            </div>
            {Alert}
        </>
    )
}

function LawyerSocials() {

    const cookies = new Cookies();
    var lawyerid = cookies.get('LAWYERID');
    const  REACT_APP_LIVE_BACKEND_BASE_URL  = process.env.REACT_APP_LIVE_BACKEND_BASE_URL;
    const LAWYERURL = REACT_APP_LIVE_BACKEND_BASE_URL+'api/get-lawyer-data/' + lawyerid;
    const [lawyerSocials, setLawyerSocials] = useState([])
    const DvInnerHTML = function (target, newText) {
        document.querySelectorAll(target).forEach(function (element) {
            element.textContent = newText;
        });
    }
    const [Alert, setAlert] = useState(null);

    useEffect(() => {
        fetchlawyerdata();
    }, [])

    const fetchlawyerdata = () => {
        fetch(LAWYERURL)
        .then((res) => res.json())
        .then((response) => {
            if (response.sociallinks !== null) {
                setLawyerSocials(response.sociallinks);
            }
        })
    }

    const SocialSubmit = async (e) => {
        e.preventDefault();
        try {
            // make axios post request
            const response = await axios({
                method: "post",
                url: REACT_APP_LIVE_BACKEND_BASE_URL+"api/add-lawyer-profile-socials",
                data: new FormData(e.target),
                headers: { "Content-Type": "multipart/form-data" },
            }).then((response) => {
                const errors = response.data.errors;
                if (response.data.status) {
                    DvInnerHTML('.text-danger', '');
                   
                    setAlert((<SweetAlert success title="" onConfirm={() => {
                        setAlert(null)
                        window.location.reload();
                    }} >{response.data.msg}</SweetAlert>))
                } else {
                    DvInnerHTML('.text-danger', '');
                    if (response.data.common_message.length) {
                        $("#blank_field-error").html(response.data.common_message);
                    }else if (parseInt(Object.keys(errors).length) > 0) {

                        for (var key in errors) {
                            if (errors.hasOwnProperty(key)) {
                                if (document.getElementById(key + '-error') === null) {
                                } else {
                                    document.getElementById(key + '-error').innerHTML = errors[key];
                                }
                            }
                        }
                    } else {
                        setAlert((<SweetAlert danger title="" onConfirm={() => {
                            setAlert(null)
                        }} >{response.data.msg}</SweetAlert>))
                    }
                }
            });
        } catch (response) {
            console.log(response)
        }
    }

    return (
        <>
            <div class="profile-sec" id="lawyer_personal_socials">
                <div class="inner-guest-posts">

                    <div class="inner-profile-sec">
                        <div class="heading-title c-text-small">
                            <p>Social Media</p>
                        </div>
                        <form onSubmit={SocialSubmit}>
                            <div class="profile-edit-sec">
                                <div class="row">

                                    <div class="col-md-4">
                                        <input type="hidden" name="lawyer_id" defaultValue={lawyerid} />
                                        <input type="hidden" name="lawyersocialid" defaultValue={lawyerSocials.id} />
                                        <div class="form-group">
                                            <label>Facebook Link</label>
                                            <input type="text" defaultValue={lawyerSocials.facebook} name="lawyer_facebook" id="lawyer_facebook" class="form-control" />
                                            <p class="text-danger" id="lawyer_facebook-error"></p>
                                        </div>
                                    </div>

                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label>Twitter Link</label>
                                            <input type="text" defaultValue={lawyerSocials.twitter} name="lawyer_twitter" id="lawyer_twitter" class="form-control" />
                                            <p class="text-danger" id="lawyer_twitter-error"></p>
                                        </div>
                                    </div>

                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label>Linkedin Link</label>
                                            <input type="text" defaultValue={lawyerSocials.linkedin} name="lawyer_linkedin" id="lawyer_linkedin" class="form-control" />
                                            <p class="text-danger" id="lawyer_linkedin-error"></p>
                                        </div>
                                    </div>

                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label>Youtube Link</label>
                                            <input type="text" defaultValue={lawyerSocials.youtube} name="lawyer_youtube" id="lawyer_youtube" class="form-control" />
                                            <p class="text-danger" id="lawyer_youtube-error"></p>
                                        </div>
                                    </div>

                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label>Instagram Link</label>
                                            <input type="text" defaultValue={lawyerSocials.instagram} name="lawyer_instagram" id="lawyer_instagram" class="form-control" />
                                            <p class="text-danger" id="lawyer_instagram-error"></p>
                                        </div>
                                    </div>

                                </div>
                                <p class="text-danger" id="blank_field-error"></p>

                                <div class="save-btn text-right pt-2">
                                    <button type="submit" class="mt-2">Save</button>
                                </div>

                            </div>
                        </form>
                    </div>

                </div>
            </div>
            {Alert}
        </>
    )
}


function PhotoVideoLink() {
    const cookies = new Cookies();
    var lawyerid = cookies.get('LAWYERID');
    const  REACT_APP_LIVE_BACKEND_BASE_URL  = process.env.REACT_APP_LIVE_BACKEND_BASE_URL;
    const LAWYERURL = REACT_APP_LIVE_BACKEND_BASE_URL+'api/get-lawyer-data/' + lawyerid;
    const [lawyerPhotoVideo, setLawyerPhotoVideo] = useState([])
    const [deletePhotoVideo, setDeletePhotoVideo] = useState([])
    const [Alert, setAlert] = useState(null);
    const DvInnerHTML = function (target, newText) {
        document.querySelectorAll(target).forEach(function (element) {
            element.textContent = newText;
        });
    }
    
    useEffect(() => {
        fetchlawyerdata();
    }, [])

    const fetchlawyerdata = () => {
        fetch(LAWYERURL).then((res) => res.json()).then((response) => {
            setLawyerPhotoVideo(response.photovideodata);
        })
    }

    var photovideolength = parseInt($(".total-photo-video").length);
    let addPhotoVideo = () => {
        const dummyImage = `${process.env.PUBLIC_URL}/assets/images/dummy-image.jpg`;

        var html = '<div class="row total-photo-video" id="lawyer_photo_video' + photovideolength + '"><div class="col-md-6"><input type="hidden" name="lawyerphotoid[' + photovideolength + ']" id="lawyerphotoid' + photovideolength + '" defaultValue="" /><div class="form-group"><label>Add Photos</label></div><div class="photo-gallery-sec"><img src='+dummyImage+' id="preview_lawyer_photos' + photovideolength + '" class="delete_lawyer_image-prev' + photovideolength + '"/><div class="action-video-photo"><div class="upload-img-btn"><input type="file" preimage="preview_lawyer_photos' + photovideolength + '" name="lawyer_photos_' + photovideolength + '" id="lawyer_photos_' + photovideolength + '" class="newpreview delete_lawyer_image-prev' + photovideolength + '"/><span>Upload Photos</span></div><div class="delete-img-btn"><button type="button" class="delete-prev-image" deletepreview="delete_lawyer_image-prev' + photovideolength + '">Delete Photos</button></div></div></div><p class="text-danger" id="lawyer_photos_' + photovideolength + '-error"></p></div><div class="col-md-6"><div class="form-group"><label>Add Youtube Videos (<small>Add youtube video link</small>)</label><textarea rows="3" class="form-control" name="lawyer_youtube_video[' + photovideolength + ']" id="lawyer_youtube_video' + photovideolength + '"></textarea><p class="text-danger" id="lawyer_youtube_video.' + photovideolength + '-error"></p></div><div class="save-btn text-right "><button type="button" class="remove-attorney" entity="lawyer_photo_video' + photovideolength + '">Remove</button></div></div></div>';
        $(".add_photo_video").append(html);
        photovideolength = photovideolength + parseInt(1);
    }

    $(document).on("click", ".remove-attorney", async function (e) {
        e.preventDefault();
        var entity = $(this).attr("entity")
        var deleteid = $(this).attr('deletephotoid');
        if (deleteid !== undefined) {
            $("#" + entity).remove();
            setDeletePhotoVideo([...deletePhotoVideo, deleteid]);
            axios({
                method: "delete",
                url: REACT_APP_LIVE_BACKEND_BASE_URL+"api/delete-photos-videos/"+deleteid,                
            }).then((response) => {
                const data = response.data;
            }).catch((error) => {
                const data = error.response.data;
            });
        }else{
            $("#" + entity).remove();
        }
    });

    $(document).on('click', '.delete_video_prev', function () {
        var prevthis = $(this).attr('prevdelete');
        $('.' + prevthis).val('');
        $('.' + prevthis).prop('src', '');
    })

    const PhotoVideoSubmit = async (e) => {
        e.preventDefault();

        $("#start-spin").html('<i class="fas fa-spinner fa-spin"></i>');
        $("#start-spin").prop('disabled', true);

        try {
            // make axios post request
            await axios({
                method: "post",
                url: REACT_APP_LIVE_BACKEND_BASE_URL+"api/add-lawyer-profile-photovideo",
                data: new FormData(e.target),
                headers: { "Content-Type": "multipart/form-data" },
            }).then((response) => {
                $("#start-spin").html('Submit');
                $("#start-spin").prop('disabled', false);
                const errors = response.data.errors;
                if (response.data.status) {
                    DvInnerHTML('.text-danger', '');
                    
                    setAlert((<SweetAlert success title="" onConfirm={() => {
                        setAlert(null)
                        window.location.reload();
                    }} >{response.data.msg}</SweetAlert>))
                } else {
                    DvInnerHTML('.text-danger', '');
                    if (parseInt(Object.keys(errors).length) > 0) {
                        for (var key in errors) {
                            if (errors.hasOwnProperty(key)) {
                                if (document.getElementById(key + '-error') === null) {
                                } else {
                                    document.getElementById(key + '-error').innerHTML = errors[key];
                                }
                            }
                        }
                    } else {
                        setAlert((<SweetAlert danger title="" onConfirm={() => {
                            setAlert(null)
                        }} >{response.data.msg}</SweetAlert>))
                    }
                }
            });
        } catch (response) {
            console.log(response)
        }
    }

    $(document).on('change', ".newpreview", function () {
        if (typeof (FileReader) != "undefined") {

            var image_holder = $(this).attr('preimage');
            // image_holder.empty();

            var reader = new FileReader();
            reader.onload = function (e) {
                $("#" + image_holder).prop('src', e.target.result)
            }
            reader.readAsDataURL($(this)[0].files[0]);
        } else {
            alert("This browser does not support FileReader.");
        }
    });

    $(document).on('click', '.delete-prev-image', function () {
        var deleteattr = $(this).attr('deletepreview');
        $("." + deleteattr).val('');
        $("." + deleteattr).prop('src', '');
    });

    $(document).on('change', '.upload-video', function () {
        var fileshow = $(this).attr('prev_video');
        var fileUrl = window.URL.createObjectURL(this.files[0]);
        $("#" + fileshow).attr("src", fileUrl);
    });

    return (
        <>
            <div class="profile-sec" id="lawyer_personal_photovideo">
                <div class="inner-guest-posts">

                    <div class="inner-profile-sec">
                        <div class="row align-items-center">
                                                            
                            <div class="col-md-8">
                                <div class="heading-title c-text-small">
                                    <p>Photos and Videos</p>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="add-more-field text-right">
                                    <button type="button" onClick={() => addPhotoVideo()}>Add <i class="fa-solid fa-plus ml-2"></i></button>
                                </div>
                            </div>
                        </div>
                        <form onSubmit={(e) => PhotoVideoSubmit(e)}>
                            <div class="profile-edit-sec">
                                <input type="hidden" name="lawyer_id" id="lawyer_id" defaultValue={lawyerid} />
                                <input type="hidden" id="photo_video_delete" name="photo_video_delete" defaultValue={JSON.stringify(deletePhotoVideo)} />
                                <div class="add_photo_video">
                                    {lawyerPhotoVideo != "" ? lawyerPhotoVideo.map((item, i) => {
                                        return (
                                            <div class="row total-photo-video" id={"lawyer_photo_video" + i} key={i}>
                                                <div class="col-md-6">
                                                    <input type="hidden" name={"lawyerphotoid[" + i + "]"} id={"lawyerphotoid" + i} defaultValue={item.id} />
                                                    <div class="form-group">
                                                        <label>Add Photos</label>
                                                    </div>
                                                    <div class="photo-gallery-sec">
                                                        <img src={REACT_APP_LIVE_BACKEND_BASE_URL+"public" + item.photo} id={"preview_lawyer_photos" + i} class={"delete_lawyer_image-prev" + i} />
                                                        <div className="action-video-photo">
                                                            <div class="upload-img-btn">
                                                                <input type="file" preimage={"preview_lawyer_photos" + i} name={"lawyer_photos_" + i} id={"lawyer_photos_" + i} class={"newpreview delete_lawyer_image-prev" + i} />
                                                                <span>Upload Photos</span>
                                                            </div>
                                                            <div class="delete-img-btn">
                                                                <button type="button" class="delete-prev-image" deletepreview={"delete_lawyer_image-prev" + i}>Delete Photos</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <p class="text-danger" id={"lawyer_photos_" + i + "-error"}></p>

                                                </div>

                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label>Add Youtube Videos (<small>Add youtube video link</small>)</label>
                                                    </div>
                                                    
                                                    <div class="form-group">
                                                        <textarea rows="3" className="form-control" name={"lawyer_youtube_video[" + i + "]"} id={"lawyer_youtube_video" + i} defaultValue={item.youtube_videos}></textarea>
                                                        <p class="text-danger" id={"lawyer_youtube_video." + i + "-error"}></p>
                                                    </div>

                                                    {
                                                        i < 1 ? "" : (
                                                            <div class="save-btn text-right ">
                                                                <button type="button" deletephotoid={item.id} class="remove-attorney" entity={"lawyer_photo_video" + i}>Remove</button>
                                                            </div>
                                                        )
                                                    }

                                                </div>

                                            </div>
                                        )
                                    }) :
                                        <div class="row total-photo-video" id="lawyer_photo_video0">
                                            <div class="col-md-6">
                                                <input type="hidden" name="lawyerphotoid[0]" id="lawyerphotoid0" defaultValue="" />
                                                <div class="form-group">
                                                    <label>Add Photos</label>
                                                </div>
                                                <div class="photo-gallery-sec">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/dummy-image.jpg`} class="delete_lawyer_image-prev0" id="preview_lawyer_photos0" />
                                                    <div className="action-video-photo">
                                                        <div class="upload-img-btn">
                                                            <input type="file" preimage="preview_lawyer_photos0" name="lawyer_photos_0" id="lawyer_photos_0" class="newpreview delete_lawyer_image-prev0" />
                                                            <span>Upload Photos</span>
                                                        </div>
                                                        <div class="delete-img-btn">
                                                            <button type="button" class="delete-prev-image" deletepreview="delete_lawyer_image-prev0">Delete Photos</button>
                                                        </div>
                                                    </div>
                                                        
                                                </div>
                                                <p class="text-danger" id="lawyer_photos_0-error"></p>

                                            </div>

                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label>Add Youtube Videos (<small>Add youtube video link</small>)</label>
                                                </div>
                                                
                                                <div class="form-group">
                                                    <textarea rows="3" className="form-control" name="lawyer_youtube_video[0]" id="lawyer_youtube_video0"></textarea>
                                                    <p class="text-danger" id="lawyer_youtube_video.0-error"></p>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                </div>
                                <div class="save-btn text-right pt-0">
                                    <button type="submit" class="mt-2" id="start-spin">Save</button>
                                </div>

                            </div>
                        </form>
                    </div>

                </div>
            </div>
            {Alert}
        </>
    )
}

export { LawLicense, LawAward, ProfessionalExperience, ProfessionalAssociation, LawyerEducation, FurtherReading, SpeakingEngagement, NotableSettlement, LawyerAttorney, LawyerSocials, PhotoVideoLink }