import React, {useEffect, useState} from 'react'
import axios from 'axios'
import SweetAlert from 'react-bootstrap-sweetalert'
import {Link} from 'react-router-dom'
import { Helmet } from "react-helmet"

function Signup() {

    const REACT_APP_LIVE_BACKEND_BASE_URL = process.env.REACT_APP_LIVE_BACKEND_BASE_URL;
    const DvInnerHTML = function (target, newText) {
        document.querySelectorAll(target).forEach(function (element) {
            element.textContent = newText;
        });
    }
    useEffect(() => {
        document.title = 'Signup';
    }, []);
    const [Alert, setAlert] = useState(null);
    const [isDisabled, setIsDisabled] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsDisabled(true);

        try { 
            await axios({
                method: "post",
                url: REACT_APP_LIVE_BACKEND_BASE_URL + "api/signup-lawyer-profile",
                data: new FormData(e.target),
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            }).then((response) => {
                setIsDisabled(false);
                const errors = response.data.errors;
                if (response.data.status) {
                    DvInnerHTML('.text-danger', '');
                    setAlert((
                        <SweetAlert success title="" onConfirm={() => {
                            setAlert(null)
                            window.location.assign('/sign-in/')
                        }}>{response.data.msg}</SweetAlert>
                    ))
                } else {
                    setIsDisabled(false);
                    DvInnerHTML('.text-danger', '');
                    if (parseInt(Object.keys(errors).length) > 0) {
                        for (var key in errors) {
                            if (errors.hasOwnProperty(key)) {
                                if (document.getElementById(key + '-error') === null) {
                                    console.log(key + '-error')
                                } else {
                                    document.getElementById(key + '-error').innerHTML = errors[key];
                                }
                            }
                        }
                    } else {
                        setAlert((
                            <SweetAlert danger title="" onConfirm={() => {
                                setAlert(null)
                            }}>{response.data.msg}</SweetAlert>
                        ))
                    }
                }
            });
        } catch (response) {
            setIsDisabled(false);
            console.log(response)
        }

    }

    return (
        <div>
            <Helmet>
                <title>Sign Up</title>
                <link rel="canonical" href={window.location.href.toLowerCase()} />
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <section className="signup">
                <div className="container">
                    <div className="inner-signup">

                        <div className="top-s-content">
                            <div className="s-title text-center">
                                <h5>Are you a lawyer?</h5>
                            </div>
                            <div className="s-text text-center">
                                <p>Before signing up, please run a quick search to ensure you don’t already have a live listing - <Link rel="nofollow" to="/claim-profile-search/" className="fw-semibold">click here.</Link></p>
                            </div>
                        </div>

                        <div className="signup-content">
                            <div className="signup-title text-center">
                                <h4>Sign up</h4>
                            </div>
                        </div>

                        <div className="signup-form-sec mt-4">
                            <form onSubmit={(e) => handleSubmit(e)}>
                                <div className="form-group">
                                    <input type="text" name="fname" id="fname" className="form-control" placeholder="First name"/>
                                    <span>
                                        <i className="fa-solid fa-user"></i>
                                    </span>
                                </div>
                                <p className="text-danger" id="fname-error"></p>

                                <div className="form-group">
                                    <input type="text" name="email" id="email" className="form-control" placeholder="Email"/>
                                    <span>
                                        <i className="fa-solid fa-envelope"></i>
                                    </span>
                                </div>
                                <p className="text-danger" id="email-error"></p>

                                <div className="form-group">
                                    <input type="password" name="password" id="password" className="form-control" placeholder="Password"/>
                                    <span>
                                        <i className="fa-solid fa-lock"></i>
                                    </span>
                                </div>
                                <p className="text-danger" id="password-error"></p>

                                <div className="form-group">
                                    <input type="password" name="cpassword" id="cpassword" className="form-control" placeholder="Confirm Password"/>
                                    <span>
                                        <i className="fa-solid fa-lock"></i>
                                    </span>
                                </div>
                                <p className="text-danger" id="cpassword-error"></p>

                                <div className="signup-btn mt-4 mb-4">
                                    <button type="submit" disabled={isDisabled}>Sign up</button>
                                </div>

                                <div className="condition-text text-center text-muted">
                                    <p>By signing up, you agree to our <a href={`${process.env.REACT_APP_BASE_URL}/terms-of-service/`}> Terms of Use </a> and <a href={`${process.env.REACT_APP_BASE_URL}/privacy-policy/`}>Privacy Policy</a>.</p>
                                </div>

                            </form>
                        </div>

                    </div>

                    <div className="already-account text-center mt-5">
                        <p className="text-muted">Already have an account?</p>
                        <Link to="/sign-in/" rel="nofollow">Sign in</Link>
                    </div>

                </div>
            </section>
            {Alert}
        </div>
    )
}

export {Signup}
