import React, {useEffect, useState} from "react"
import { Link } from "react-router-dom"
import axios from 'axios'
import SweetAlert from 'react-bootstrap-sweetalert'
import Admindashboardfooter from "../components/Admindashboardfooter"
import { Pagination } from "../../helper/commonHelper"
import DataTable from "../../helper/DataTable"
import $ from 'jquery';

function Userlist() {
    const  REACT_APP_LIVE_BACKEND_BASE_URL  = process.env.REACT_APP_LIVE_BACKEND_BASE_URL;
    const [data, getData ] = useState([]);
    const [pageLinks, setPageLinks] = useState([]);
    const [currentPageNumber, setCurrentPage] = useState(1);
    const [lastPageNumber, setLastPage] = useState("");
    const [Alert, setAlert] = useState(null);
    const [isLoader, setLoader] = useState(true)
        
    useEffect(() => {
        document.title = 'User list';
        fetchData();
    }, [])

    const fetchData = () => {
        const URL = REACT_APP_LIVE_BACKEND_BASE_URL+'api/get-users-list';
        fetch(URL).then((res) =>res.json()).then((response) => {
            getData(response.data);
            setPageLinks(response.links);
            setLastPage(response.last_page);
            setCurrentPage(response.current_page);
            setLoader(false)
        })
    }
    
    const handleClick = (e) => {
        e.preventDefault();
        var next = e.currentTarget.getAttribute('nextpage');
        if(next != null){
            fetch(next).then((res) =>res.json()).then((response) => {
                getData(response.data);
                setPageLinks(response.links);
                setLastPage(response.last_page);
                setCurrentPage(response.current_page);
            });

            $(window).scrollTop(0)
        }
    }

    const  DeleteUser = async (e, id) => {
        e.preventDefault();
        const url = REACT_APP_LIVE_BACKEND_BASE_URL+"api/delete-user/"+id;
        setAlert(
            <SweetAlert warning showCancel confirmBtnText="Yes, delete it!" confirmBtnBsStyle="danger" cancelBtnText="No, cancel" cancelBtnBsStyle="success" title="Are you sure?" onConfirm={() => deleteItem(url)} onCancel={() => setAlert(null)}>You won't be able to revert this!</SweetAlert>
        );
    }

    const deleteItem = async (url) => {
        setAlert(null);
        await axios({
            method: "delete",
            url: url,
        }).then((response) => {   
            fetchData()       
            setAlert((<SweetAlert success title="Success" onConfirm={() => { setAlert(null)}}>{response.message}</SweetAlert>))
        }).catch((error) => {
            const errors = error.response.data
            setAlert((
                <SweetAlert danger title="Oops!" onConfirm={() => {setAlert(null)}}>
                    {errors.message}
                </SweetAlert>
            ))
        });
    }


   
    return (
        <div id="content" className="main-content">
            <div className="bg-custom">
                <div className="main-profile-sec">
                    <div className="profile-sec">
                        <div className="inner-guest-posts">
                            <div className="inner-profile-sec">
                                <div className="row align-items-center">
                                    <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-10">
                                        <div className="heading-title c-text-small">
                                            <p>User List</p>
                                        </div>
                                    </div>
                                    <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-2">
                                        <div className="add-more-btn text-right margen-c-top">
                                            <Link to="/add-user/"> <i className="fa-solid fa-plus"></i> Add new user </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="data-table-sec table-responsive admin-data-table">
                                    <table id="example" className="display" cellspacing="0" width="100%">
                                        <thead>
                                            <tr>
                                                <th>Profile</th>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Permission</th>
                                                <th>Status</th>
                                                <th>Actions</th>                                                     
                                            </tr>
                                        </thead>

                                        <tbody>
                                        { isLoader ? <DataTable />: data.map((item, i) => (
                                            <tr key={`row-list-${i}`}>
                                                <td><img src={item.logo_image} className="user-profile" alt={item.name} /></td>
                                                <td>{item.name}</td>
                                                <td>{item.email}</td>
                                                <td>{item.role}</td>
                                                <td>{item.status ? "Active" : "Inactive"}</td>
                                                
                                                <td className="action-btn both-action-btn">
                                                    <Link title="Edit" to={"/edit-user-profile/"+item.id+"/"} className="edit-btn"> <i className="fas fa-edit"></i></Link>
                                                    <Link title="Delete" onClick={(e) => DeleteUser(e, item.id)} to="/" className="edit-btn"> <i className="fas fa-trash"></i></Link>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>

                                <div className="lawyer-profile-pagination pagination-custom my-4">
                                    <Pagination page={pageLinks} lastPage={lastPageNumber} currentPage={currentPageNumber} handleClick={handleClick} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Admindashboardfooter />
            {Alert}
        </div>
    )
}

export default Userlist