import React, {useEffect, useState} from 'react';
import axios from 'axios';
import SweetAlert from 'react-bootstrap-sweetalert';
import Cookies from 'universal-cookie';

function AdminSignIn() {

    const DvInnerHTML = function(target,newText) {
        document.querySelectorAll(target).forEach(function(element) {
          element.textContent = newText;
        });
    }  

    const [Alert, setAlert] = useState(null);
    const cookies = new Cookies();
    const [formValue, setformValue] = React.useState({
        email: '',
        password: ''
    });
    
    const  REACT_APP_LIVE_BACKEND_BASE_URL  = process.env.REACT_APP_LIVE_BACKEND_BASE_URL;
    const handleSubmit = async(e) => {
        e.preventDefault();

        const loginFormData = new FormData();
        loginFormData.append("email", formValue.email)
        loginFormData.append("password", formValue.password)
      
        try {
          // make axios post request
          const response = await axios({
            method: "post",
            url: REACT_APP_LIVE_BACKEND_BASE_URL+"api/login",
            data: loginFormData,
            headers: { "Content-Type": "multipart/form-data" },
          }).then((response) =>{
            const errors = response.data.errors;
            if(response.data.status){
                setAlert((<SweetAlert success title="" onConfirm={() => {
                    setAlert(null)
                    cookies.set('ADMIN', response.data.token, { path: '/', maxAge: 2592000, secure: true, sameSite: 'None'});
                    cookies.set('ADMIN', response.data.token, { path: '/', maxAge: 2592000, domain:'.servetheinjured.com', secure: true, sameSite: 'Lax'}); 
                    window.location.assign(`/admin-dashboard/`)
                }} >{response.data.msg}</SweetAlert>))
            }else{
                DvInnerHTML('.text-danger', '');
                if (parseInt(Object.keys(errors).length) > 0) {
                    for (var key in errors) {
                        if (errors.hasOwnProperty(key)) {
                            if(document.getElementById(key + '-error') === null)
                            {
                                console.log(key + '-error')
                            }else
                            {
                                document.getElementById(key + '-error').innerHTML = errors[key];
                            }
                        }
                    }
                }else{
                    setAlert((<SweetAlert danger title="" onConfirm={() => {
                        setAlert(null)
                    }} >{response.data.msg}</SweetAlert>))
                }
            }
          });
        } catch(response) {
          console.log(response)
        }
        
      }
    
      const handleChange = (event) => {
        setformValue({
          ...formValue,
          [event.target.name]: event.target.value
        });
      }

    useEffect(() => {
        document.title = 'Admin Signin';
        if(typeof cookies.get('LAWYER') !== "undefined"){
            window.location.href = process.env.REACT_APP_BASE_URL
        }
        
        if(typeof cookies.get('ADMIN') !== "undefined"){
            window.location.href = `${process.env.REACT_APP_LIVE_BASE_URL}admin-dashboard/`;
        }
    }, [])

    return (
        <>
            <section className="signin">
                <div className="container">
                    <div className="inner-signin">
                        <div className="signin-content">
                            <div className="signin-title text-center">
                                <h4>Sign in</h4>
                            </div>
                        </div>

                        <div className="email signin-form-sec mt-4">
                            <form onSubmit={(e) => handleSubmit(e)}>
                                <div className="form-group">
                                    <label for="email" className="mb-1">Email Address <span className="red">*</span></label>
                                    <input type="text" name="email" value={formValue.email} onChange={handleChange} className="form-control" id="email"/>
                                    <p className="text-danger" id="email-error"></p>
                                </div>

                                <div className="form-group">
                                    <label for="password" className="mb-1"> Password <span className="red">*</span></label>
                                    <input type="password" name="password" value={formValue.password} onChange={handleChange} className="form-control" id="password"/>
                                    <p className="text-danger" id="password-error"></p>
                                </div>

                                <div className="remember mt-4">
                                    <label><input type="checkbox" name="" id=""/>Remember me</label>
                                </div>

                                <div className="next-btn">
                                    <button type="submit" className="mt-4 mb-2">Sign in</button> 
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            {Alert}
        </>
    )
}

export default  AdminSignIn  