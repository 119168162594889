import React, {useState, useEffect} from "react";
import Admindashboardfooter from "../components/Admindashboardfooter"
import axios from 'axios';
import { useParams, useHistory } from "react-router-dom";
import SweetAlert from 'react-bootstrap-sweetalert';
import DevtoCard from "../../helper/DevtoCard";

function EditPackage() {

    const  REACT_APP_LIVE_BACKEND_BASE_URL  = process.env.REACT_APP_LIVE_BACKEND_BASE_URL;
    const DvInnerHTML = function (target, newText) {
        document.querySelectorAll(target).forEach(function (element) {
            element.textContent = newText;
        });
    }
    const [Alert, setAlert] = useState(null)
    let {id} = useParams()
    const [packageList, setPackageList] = useState([])
    const [isLoader, setLoader] = useState(false)
    let history = useHistory()

    useEffect(() => {
        document.title = 'Edit Package'; 
        fetchPackageList();
    }, [])

    const fetchPackageList = () => {
        fetch(REACT_APP_LIVE_BACKEND_BASE_URL+"api/edit-package/"+id).then((res) => res.json()).then((response) => {
            setPackageList(response.package);
            setLoader(false);
        })
    }

    const PackageSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios({
                method: "post",
                url: REACT_APP_LIVE_BACKEND_BASE_URL+"api/update-package/"+id,
                data: new FormData(e.target),
                headers: { "Content-Type": "multipart/form-data" },
            }).then((response) => {
                const errors = response.data.errors;
                if (response.data.status) {
                    DvInnerHTML('.text-danger', '');
                   
                    setAlert((<SweetAlert success title="Done" onConfirm={() => {
                        setAlert(null)
                        history.push('./../../package-list/')
                    }} >{response.data.msg}</SweetAlert>))
                } else {
                    DvInnerHTML('.text-danger', '');
                    if (parseInt(Object.keys(errors).length) > 0) {
                        for (var key in errors) {
                            if (errors.hasOwnProperty(key)) {
                                if (document.getElementById(key + '-error') === null) {
                                } else {
                                    document.getElementById(key + '-error').innerHTML = errors[key]
                                }
                            }
                        }
                    } else {
                        setAlert((<SweetAlert danger title="Ooops!" onConfirm={() => {
                            setAlert(null)
                        }} >{response.data.msg}</SweetAlert>))
                    }
                }
            });
        } catch (response) {
            console.log(response)
        }
    }


    return (
        <div id="content" className="main-content">
            <div className="bg-custom">
                <div className="main-profile-sec">
                    <form onSubmit={PackageSubmit}>
                        <div className="profile-sec">
                            <div className="inner-guest-posts">
                                <div className="inner-profile-sec">
                                    <div className="heading-title c-text-small">
                                        <p>Update Package</p>
                                    </div>
                                    {isLoader ? <DevtoCard /> : (<div className="profile-edit-sec">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <div className="row">
                                                        <label className="col-2">Package Title</label>
                                                        <div className="col-10">
                                                            <input type="text" defaultValue={packageList.title} name="title" id="title" className="form-control"/>
                                                            <p class="text-danger" id="title-error"></p>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <div className="row">
                                                        <label className="col-2">Package Price</label>
                                                        <div className="col-10">
                                                            <input type="text" defaultValue={packageList.price} name="price" id="price" className="form-control"/>
                                                            <p class="text-danger" id="price-error"></p>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <div className="row">
                                                        <label className="col-2">Button Text</label>
                                                        <div className="col-10">
                                                            <input type="text" defaultValue={packageList.button_text} name="button_text" id="button_text" className="form-control" />
                                                            <p class="text-danger" id="button_text-error"></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <div className="row">
                                                        <label className="col-2">Status</label>
                                                        <div className="col-10">
                                                            <select defaultValue={packageList.status} name="status" className="form-control">
                                                                <option value="0">Inactive</option>
                                                                <option value="1">Active</option>
                                                            </select>
                                                            <p class="text-danger" id="button_text-error"></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <div className="row">
                                                        <label className="col-2">Package Description</label>
                                                        <div className="col-10">
                                                            <textarea name="description" defaultValue={packageList.description} id="description"></textarea>
                                                            <p class="text-danger" id="description-error"></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="save-btn text-right p-0">
                                            <button type="submit" className="mt-2">Save</button>
                                        </div>
                                    </div>)}
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {Alert}
            <Admindashboardfooter />
        </div>
    )
}

export default EditPackage