import React, {useState, useEffect, lazy, Suspense} from 'react'
import { useParams, Link, useHistory } from 'react-router-dom'
import { Helmet } from "react-helmet"
import { Instagram, Code } from 'react-content-loader'
const CitiesComponent = lazy(() => import('./components/CitiesComponent'));

function LawyerStates() {
    let {injury} = useParams();
    const  REACT_APP_LIVE_BACKEND_BASE_URL  = process.env.REACT_APP_LIVE_BACKEND_BASE_URL
    const [state, setState] = useState([])
    const [practiceArea, setPracticeArea] = useState([])
    const [isLoader, setLoader] = useState(true)
    const [isTextLoader, setTextLoader ] = useState(true)
    let history = useHistory()

    useEffect(() => {
        if (injury == "lawyer" || injury == "signup") {
            history.push(`/`)
        }
        fetchState()
        fetchPracticeArea()
    }, [])


    const fetchState = () => {
        fetch(REACT_APP_LIVE_BACKEND_BASE_URL+"api/get-front-state-list").then((res) => res.json()).then((response) => {
            if(Object.keys(response).length == 0) {
                history.push(`/`)
            }
            setState(response)
        })
    }   

    const fetchPracticeArea = () => {
        fetch(REACT_APP_LIVE_BACKEND_BASE_URL+"api/lawyer-practice-area-slug/"+injury).then((res) => res.json()).then((response) => {
            if(Object.keys(response).length == 0) {
                history.push(`/`)
            }
            setPracticeArea(response)
            setLoader(false)
            setTextLoader(false)
        })
    }

    return (
        <div>
            <Helmet>
                <title>{practiceArea?.meta_title}</title>
                <meta name="description" content={practiceArea?.meta_description} />
                <link rel="canonical" href={window.location.href.toLowerCase()} />
            </Helmet>
            <section className="pages-breadcrumb mt-3">
                <div className="container">
                    {
                        isTextLoader ? <Code /> : (
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <a href={process.env.REACT_APP_BASE_URL} rel="noopener noreferrer">Home</a>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="/">Find a Lawyer</Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        { practiceArea?.name }
                                    </li>
                                </ol>
                            </nav>
                        )
                    }
                </div>
            </section>
            <section className="lawyer-states-section">
                <div className="container">
                    {
                        isLoader ? (<Instagram />) : Object.keys(practiceArea).length == 0 ? (
                            <div className="row">
                                <div className='col-md-12'>
                                    <h2>No Injury found.</h2>
                                </div>
                            </div>
                        ) : (
                                <div className="row">
                                    <h1 className="page-title" style={{"text-transform": "capitalize"}}>Best {practiceArea?.name} Lawyers Near You</h1>
                                    <p className="small-title">Please click the state below to find local { practiceArea?.name } lawyers.</p>
                                    <div className="inner-fl-state-content">
                                        {Object.keys(practiceArea).length > 0 ? (
                                            <>
                                            <div className="row fl-state">
                                                {state.map((item, i) => {
                                                    return(<div className="col-4 col-sm-4 col-md-4" key={i}>
                                                        <div className="states">
                                                            <ul>
                                                                {item.map((items, j) => {
                                                                    return(<li key={j}><Link to={items.slug+"/"} rel="noopener noreferrer">{items.name}</Link> </li>)
                                                                })}
                                                            </ul>
                                                        </div>
                                                    </div>)
                                                })}
    
                                                <Suspense fallback={<div>Loading...</div>}>
                                                    <CitiesComponent name={practiceArea ? practiceArea.name : ""} />
                                                </Suspense>
                                            </div>
                                            {practiceArea?.long_description ? (
                                                <div className="row">
                                                    <div className="description-area mb-3 mt-3" dangerouslySetInnerHTML={{ __html: practiceArea?.long_description }}></div>
                                                </div> 
                                            ) : ("")}
                                            </>
                                        ) : (<p>No States Found For This Injury.</p>)}
                                    </div>
                                </div> 
                        )
                    }
                </div>
            </section>
        </div>
    )

   
}
export { LawyerStates } 