import React, {useEffect, useState} from "react";
import Admindashboardfooter from "../components/Admindashboardfooter"
import DataTable from "../../helper/DataTable"
import { Link } from "react-router-dom"
import SweetAlert from 'react-bootstrap-sweetalert'
import axios from 'axios'

function PackageList() {
    const  REACT_APP_LIVE_BACKEND_BASE_URL  = process.env.REACT_APP_LIVE_BACKEND_BASE_URL;
    const [packageList, setPackageList] = useState([]);
    const [Alert, setAlert] = useState(null);
    const [search, setSearch] = useState('');
    const [isLoader, setLoader] = useState(false)

    useEffect(() => {
        document.title = 'Package List'; 
        fetchPackageList();
    }, [])

    const fetchPackageList = (searchQury='') => {
        if(searchQury === ''){
            searchQury = search
        }
        
        const URL = REACT_APP_LIVE_BACKEND_BASE_URL+"api/get-package-list?param_search="+ searchQury;
        fetch(URL).then((res) => res.json()).then((response) => {
            setPackageList(response.allpackagelist);
            setLoader(false)
        })
    }

    const handleFilter = (e) => {
        const searchVal = e.target.value
        setSearch(e.target.value)
        fetchPackageList(searchVal)
    }

    const DeletePackage = async (e) => {
        var removeid = e.target.getAttribute("packageid");
        const url = REACT_APP_LIVE_BACKEND_BASE_URL+"api/delete-package/"+removeid;
        setAlert(
            <SweetAlert
                warning
                showCancel
                confirmBtnText="Yes, delete it!"
                confirmBtnBsStyle="danger"
                cancelBtnText="No, cancel"
                cancelBtnBsStyle="success"
                title="Are you sure?"
                onConfirm={() => deleteItem(url)}
                onCancel={() => setAlert(null)}
            >
                You won't be able to revert this!
            </SweetAlert>
        );
    }

    const deleteItem = async (url) => {
        setAlert(null)
        await axios({
            method: "delete",
            url: url,
        }).then((response) => {   
            fetchPackageList();         
            setAlert((
                <SweetAlert success title="Success" onConfirm={() => { setAlert(null)}}>
                    {response.message}
                </SweetAlert>
            ))
        }).catch((error) => {
            const errors = error.response.data;
            setAlert((
                <SweetAlert danger title="Oops!" onConfirm={() => {setAlert(null)}}>
                    {errors.message}
                </SweetAlert>
            ))
        });
    }

    return (
        <div id="content" className="main-content">
            <div className="bg-custom">
                <div className="main-profile-sec">
                    <div className="profile-sec">
                        <div className="inner-guest-posts">
                            <div className="inner-profile-sec">
                                <div className="row align-items-center">
                                    <div className="col-md-6">
                                        <div className="heading-title c-text-small">
                                            <p>Package List</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group posts-title text-right p-0 m-0">
                                            <input type="text" name="search" placeholder="Search..." onKeyUp={(e) => handleFilter(e)} />
                                        </div>
                                    </div>
                                </div>

                                <div className="add-more-btn text-right custom-add-btn-space">
                                    <Link to="/add-package/"> <i className="fa-solid fa-plus"></i> Add Package</Link>
                                </div>

                                <div className="data-table-sec table-responsive admin-data-table">
                                    <table id="example" className="display custom-t-admin-table" cellspacing="0" width="100%">
                                        <thead>
                                            <tr>
                                                <th>S No.</th>
                                                <th>Title Name</th>
                                                <th>Price</th>
                                                <th>Button Text</th>
                                                <th>Description</th>
                                                <th>Status</th>
                                                <th className="text-right" style={{ width: "26%" }}>Actions</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {isLoader ? <DataTable /> : packageList !== null ? packageList.map((item, i) => {
                                                return (<tr>
                                                    <td> {i+1}. </td>
                                                    <td> {item.title} </td>
                                                    <td> {item.price ? `$${item.price}` : 'N/A'} </td>
                                                    <td> {item.button_text} </td>
                                                    <td> {item.description} </td>
                                                    <td> {item.status ? <span className='badge badge-success'>Active</span> : <span className='badge badge-danger'>Inactive</span>} </td>
                                                    <td className="action-btn text-right">
                                                        <Link to={"/edit-package/"+item.id+"/"} className="edit-btn" ><i className="fa-solid fa-edit"></i> Edit</Link>
                                                        <button className="edit-btn" packageid={item.id} onClick={DeletePackage}><i className="fa-solid fa-trash"></i> Delete</button>
                                                    </td>
                                                </tr>)
                                            }) : (<tr>
                                                <td colSpan="7">No Data Found.</td>
                                            </tr>)} 
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {Alert}
            <Admindashboardfooter />
        </div>
    )
}

export default PackageList
