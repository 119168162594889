import React, {useEffect} from "react";
import Admindashboardfooter from "../components/Admindashboardfooter"

function Admindashboard() {
    useEffect(() => {
        document.title = 'Admin Dashboard';
    }, [])

    return (
        <div id="content" className="main-content">
            <div className="bg-custom">
            </div>
            <Admindashboardfooter />
        </div>
    )
}

export default Admindashboard