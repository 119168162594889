import React, {useState, useEffect} from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useParams, Link } from 'react-router-dom';

function PracticeAreas() {
    let {state} = useParams();
    let {city} = useParams();
    const  REACT_APP_LIVE_BACKEND_BASE_URL  = process.env.REACT_APP_LIVE_BACKEND_BASE_URL;
    const [PracticeArea, setPracticeArea] = useState([]);
    const [searchCity, setSearchCity] = useState(null);

    useEffect(() => {
        if (!state || !city) {
            window.location.href = process.env.REACT_APP_BASE_URL+"/";
        }
        fetchPracticeArea();
        fetchCity();
        document.title = "Practice areas";
    }, [])

    const fetchPracticeArea = () => {
        fetch(REACT_APP_LIVE_BACKEND_BASE_URL+"api/get-practise-area-list").then((res) => res.json()).then((response) => {
            setPracticeArea(response);
        })
    }

    const fetchCity = () => {
        fetch(REACT_APP_LIVE_BACKEND_BASE_URL+"api/get-city/"+city).then((res) => res.json()).then((response) => {
            setSearchCity(response);
        })
    }

    return (
        <div>
            <Header />
            <section className="pages-breadcrumb mt-3">
                <div className="container">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href={process.env.REACT_APP_BASE_URL} rel="noopener noreferrer">Home</a></li>
                            {/* <li className="breadcrumb-item"><Link to="/flawyers">State</Link></li>
                            <li className="breadcrumb-item"><Link to="/c-city">City</Link></li> */}
                            <li className="breadcrumb-item" aria-current="page">
                                {state.replace(/-/g, " ")}
                                {/* <Link to={`/find-a-lawyer`}>{state.replace(/-/g, " ")}</Link> */}
                            </li>
                            <li className="breadcrumb-item" aria-current="page">
                                <Link to={`/practice-areas/${state}`}>{city.replace(/-/g, " ")}</Link>
                            </li>  
                            <li className="breadcrumb-item active" aria-current="page">Practice areas</li>
                        </ol>
                    </nav>
                </div>
            </section>

            <section className="f-lawyer-practice-areas pb-5">
                <div className="container">
                    <div className="inner-f-lawyer-state">
                        <div className="title mb-3">
                            <h1 className="text-center">Choose Your Practice Area To Find Lawyers In {searchCity ? searchCity.name : ""}</h1>
                        </div>

                        <div className="state-list">
                            <ul className="lawyer-state-list">
                                {PracticeArea !== '' ? (<>{PracticeArea.map((item, i) => {

                                return(

                                    <li><a href={process.env.REACT_APP_LIVE_BASE_URL+"practice-areas/"+state+"/"+city+"/"+item.slug} rel="noopener noreferrer">{item.name}</a></li>
                                    // <li><a href={process.env.REACT_APP_LIVE_BASE_URL+"practice-area/"+state+"/"+city+"/"+item.slug} rel="noopener noreferrer">{item.name}</a></li>
                                    // <li><Link to={"/practice-area/"+state+"/"+city+"/"+item.slug}> {item.name} </Link></li>
                                    // <li><Link to={"/"+state+"/"+city+"/"+item.slug}> {item.name} </Link></li>
                                )
                                })}</>) : ''}
                                
                            </ul>
                        </div>

                    </div>
                </div>
            </section>

            <Footer />
        </div>
    )
}

export { PracticeAreas } 