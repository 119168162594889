import React, {useEffect, useState} from "react"
import Admindashboardfooter from "../components/Admindashboardfooter"
import $ from 'jquery'
import axios from 'axios'
import SweetAlert from 'react-bootstrap-sweetalert'
import { useParams, useHistory } from "react-router-dom"
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import DevtoCard from "../../helper/DevtoCard"

function EditPracticeArea() {
    const DvInnerHTML = function (target, newText) {
        document.querySelectorAll(target).forEach(function (element) {
            element.textContent = newText
        })
    }
    let {id} = useParams()
    const [Alert, setAlert] = useState(null)
    let history = useHistory()
    const  REACT_APP_LIVE_BACKEND_BASE_URL  = process.env.REACT_APP_LIVE_BACKEND_BASE_URL
    const [practiceArea, setPracticeArea ] = useState('')
    const [isLoader, setLoader] = useState(true)
    const [editorElement, setEditorElement] = useState(null)
    const [longDescriptionEditorElement, setLongDescEditorElement] = useState(null)

    useEffect(() => {
        document.title = 'Edit Practice Area'; 
        EditPracticeArea();
    },[])

    const EditPracticeArea = () => {
        fetch(REACT_APP_LIVE_BACKEND_BASE_URL+"api/edit-practise-area/"+id).then((res) => res.json()).then((response) => {
            if(response !== ''){
                setPracticeArea(response);
                setLoader(false)
            }
        })
    }

    const UpdatePracticeArea = async (e) => {
        e.preventDefault();
        try {
            $(e.target).find('#area_description, #area_long_description').remove();
            let ckeditorElem = `<textarea name="area_description" id="area_description" style="display:none;">${editorElement?.getData()}</textarea></textarea><textarea name="area_long_description" id="area_long_description" style="display:none;">${longDescriptionEditorElement?.getData()}</textarea>`
            $(e.target).append(ckeditorElem);
            
            await axios({
                method: "post",
                url: REACT_APP_LIVE_BACKEND_BASE_URL+"api/update-practise-area/"+id,
                data: new FormData(e.target),
                headers: { "Content-Type": "multipart/form-data" },
            }).then((response) => {
                const errors = response.data.errors;
                if (response.data.status) {
                    DvInnerHTML('.text-danger', '')
                    setAlert((<SweetAlert success title="Done" onConfirm={() => {
                        setAlert(null)
                        history.push('./../../practice-area-list/')
                    }} >{response.data.msg}</SweetAlert>))
                } else {
                    DvInnerHTML('.text-danger', '');
                    if (parseInt(Object.keys(errors).length) > 0) {
                        for (var key in errors) {
                            if (errors.hasOwnProperty(key)) {
                                if (document.getElementById(key + '-error') === null) {
                                } else {
                                    document.getElementById(key + '-error').innerHTML = errors[key];
                                }
                            }
                        }
                    } else {
                        setAlert((<SweetAlert danger title="Ooops!" onConfirm={() => {
                            setAlert(null)
                        }} >{response.data.msg}</SweetAlert>))
                    }
                }
            });
        } catch (response) {
            console.log(response)
        }
    }

    $(document).on('change', ".newpreview", function () {
        if (typeof (FileReader) != "undefined") {
            var image_holder = $(this).attr('preimage')
            var reader = new FileReader();
            reader.onload = function (e) {
                $("#" + image_holder).prop('src', e.target.result)
            }
            reader.readAsDataURL($(this)[0].files[0]);
        } else {
            alert("This browser does not support FileReader.");
        }
    });


    return (
        <div id="content" className="main-content">
            <div className="bg-custom">
                <div className="main-profile-sec">
                    <form onSubmit={UpdatePracticeArea}>
                        <div className="profile-sec">
                            { isLoader ? <DevtoCard /> : (<div className="inner-guest-posts">
                                {practiceArea !== '' ? <div className="inner-profile-sec">
                                    <div className="heading-title c-text-small">
                                        <p>Update Practice Areas</p>
                                    </div>

                                    <div className="profile-edit-sec">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <input type="hidden" name="practiceareaid" defaultValue={id} />
                                                <div className="form-group">
                                                    <div className="row">
                                                        <label className="col-2">Title</label>
                                                        <div className="col-10">
                                                            <input type="text" name="area_name" id="area_name" className="form-control" defaultValue={practiceArea.name} /><p class="text-danger" id="area_name-error"></p>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label className="col-2">Meta Title</label>
                                                        <div className="col-10">
                                                            <input type="text" name="meta_title" id="meta_title" className="form-control" defaultValue={practiceArea.meta_title} /><p class="text-danger" id="meta_title-error"></p>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label className="col-2">Meta Description</label>
                                                        <div className="col-10">
                                                            <textarea name="meta_description" rows="5" cols="10" id="meta_description" className="form-control" defaultValue={practiceArea.meta_description}></textarea>
                                                            <p class="text-danger" id="meta_description-error"></p>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <label className="col-2">Practice Area Description</label>
                                                        <div className="col-10">
                                                            <CKEditor
                                                                editor = { ClassicEditor }
                                                                data = {practiceArea?.description}
                                                                onReady={ editor => {
                                                                    setEditorElement(editor);
                                                                } }
                                                            />
                                                        </div>                                                                
                                                    </div>
                                                    <div className="row">
                                                        <label className="col-2">Practice Area Long Description</label>
                                                        <div className="col-10">
                                                            <CKEditor
                                                                editor={ ClassicEditor }
                                                                data = {practiceArea?.long_description}
                                                                onReady={ editor => {
                                                                    setLongDescEditorElement(editor);
                                                                }}
                                                            />
                                                        </div>                                                                
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="save-btn text-right p-0">
                                            <button type="submit" className="mt-2">Save</button>
                                        </div>
                                    </div>
                                </div> : practiceArea}
                            </div>)}
                        </div>
                    </form>
                </div>
            </div>
            {Alert}
            <Admindashboardfooter />
        </div>
    )
}

export default EditPracticeArea