import React, {useState, useEffect} from "react"
import Admindashboardfooter from "../components/Admindashboardfooter"
import Multiselect from 'multiselect-react-dropdown'
import axios from 'axios'
import SweetAlert from 'react-bootstrap-sweetalert'
import {useHistory } from 'react-router-dom'
import $ from 'jquery'

function Adduserprofile() {
    useEffect(() => {
        document.title = 'Add User Profile';
    }, [])

    const [file, setFile] = useState()
    let history = useHistory()

    const options = [
        {
            name: 'Lawyer Lists',
            id: 1
        },
        {
            name: 'User Lists',
            id: 2
        },
        {
            name: 'Frontend',
            id: 3
        },
        {
            name: 'Badge',
            id: 4
        }, 
        {
            name: 'State City',
            id: 5
        }, 
        {
            name: 'Package',
            id: 6
        }, 
        {
            name: 'Lawyer Dashboard Content',
            id: 7
        }
    ];

    const [selectedValue, setSelectedValue] = useState([]);
    const REACT_APP_LIVE_BACKEND_BASE_URL = process.env.REACT_APP_LIVE_BACKEND_BASE_URL;

    const DvInnerHTML = function (target, newText) {
        document.querySelectorAll(target).forEach(function (element) {
            element.textContent = newText;
        });
    }

    const [Alert, setAlert] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = new FormData(e.target)
        const roles = selectedValue.map((value) => {
            return value.name
        })
        data.append('role', roles);

        try {
            await axios({
                method: "post",
                url: REACT_APP_LIVE_BACKEND_BASE_URL + "api/create-user",
                data: data,
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            }).then((response) => {
                const errors = response.data.errors;
                if (response.data.status) {
                    setAlert((
                        <SweetAlert success title="" onConfirm={() => {
                            setAlert(null)
                            history.push('./../user-list/')
                        }}>
                            {response.data.msg}
                        </SweetAlert>
                    ))
                } else {
                    DvInnerHTML('.text-danger', '');
                    if (parseInt(Object.keys(errors).length) > 0) {
                        for (var key in errors) {
                            if (errors.hasOwnProperty(key)) {
                                if (document.getElementById(key + '-error') === null) {
                                    console.log(key + '-error')
                                } else {
                                    document.getElementById(key + '-error').innerHTML = errors[key];
                                }
                            }
                        }
                    } else {
                        setAlert((
                            <SweetAlert danger title="Oops!" onConfirm={() => {
                                setAlert(null)
                            }}>
                                {response.data.msg}
                            </SweetAlert>
                        ))
                    }
                }
            })
        } catch (response) {
            console.log(response)
        }
    }

    $(document).on('change', "#profile_photo", function () {
        if (typeof(FileReader) != "undefined") {
            var image_holder = $(this).attr('previewimage')
            var reader = new FileReader()
            reader.onload = function (e) {
                $("#" + image_holder).prop('src', e.target.result)
            }
            reader.readAsDataURL($(this)[0].files[0]);
        } else {
            alert("This browser does not support FileReader.");
        }
    });

    $(document).on('click', '#delete-photos', function () {
        var deleteattr = $(this).attr('deleteprev');
        $("." + deleteattr).val('');
        $("." + deleteattr).prop('src', '');
    });

    return (
        <div id="content" className="main-content">
            <div className="bg-custom">
                <div className="main-profile-sec">
                    <div className="profile-sec">
                        <form onSubmit={handleSubmit}>
                            <div className="inner-guest-posts">
                                <div className="inner-profile-sec">
                                    <div className="heading-title c-text-small">
                                        <p>Create User</p>
                                    </div>

                                    <div className="profile-edit-sec">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Name</label>
                                                    <input type="text" name="name" id="name" className="form-control"/>
                                                    <p className="text-danger" id="name-error"></p>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Email</label>
                                                    <input type="text" name="email" id="email" className="form-control" autoComplete="new-email"/>
                                                    <p className="text-danger" id="email-error"></p>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Password</label>
                                                    <input type="password" name="password" id="password" className="form-control" autoComplete="new-password"/>
                                                    <p className="text-danger" id="password-error"></p>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Status</label>
                                                    <select name="status" id="status" className="form-control">
                                                        <option value="1">Active</option>
                                                        <option value="0">Inactive</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="profile-img-sec">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/dummy-image.jpg`} id="lawyerprofileimage" className="delete_prev_image" />
                                                    <div className="action-button-profile">
                                                        <div className="upload-img-btn">
                                                            <input type="file" filename={file} previewimage="lawyerprofileimage" name="profile_photo" id="profile_photo" className="delete_prev_image" />
                                                            <span>Upload Profile Image</span>
                                                        </div>
                                                        <div className="delete-img-btn">
                                                            <button type="button" deleteprev="delete_prev_image" id="delete-photos">Delete Image</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <p className="text-danger" id="profile_photo-error"></p>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Permission</label>
                                                    <Multiselect options={options} selectedValues={selectedValue} onSelect={(e) => setSelectedValue(e)} onRemove={(e) => setSelectedValue(e)} displayValue="name"/>
                                                    <p className="text-danger" id="city-error"></p>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="save-btn text-right p-0">
                                            <button type="submit" className="mt-2">Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <Admindashboardfooter/> 
                {Alert} 
            </div>
        </div>
    )
}


export default Adduserprofile
