import React, {useState,useEffect} from 'react';
import axios from 'axios';
import Lawyerfooter from '../component/Lawyerfooter';
import Lawyersidebar from '../component/Lawyersidebar';
import SweetAlert from 'react-bootstrap-sweetalert';

function LawyerContactUs() {
    useEffect(() => {
        document.title = 'Contact Us';
    }, []);
    const REACT_APP_LIVE_BACKEND_BASE_URL = process.env.REACT_APP_LIVE_BACKEND_BASE_URL;
    const URL = REACT_APP_LIVE_BACKEND_BASE_URL + 'api/lawyer/contact-us';
    const [Alert, setAlert] = useState(null);
    const [otherSuject, setOtherSubject] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [submitButtonText, setSubmitButtonText] = useState("Submit");

    const DvInnerHTML = function (target, newText) {
        document.querySelectorAll(target).forEach(function (element) {
            element.textContent = newText;
        });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsDisabled(true);
        setSubmitButtonText("Please wait ...");
        DvInnerHTML('.text-danger', '');
        await axios({
            method: "post",
            url: URL,
            data: new FormData(e.target),
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            setIsDisabled(false);
            setSubmitButtonText("Submit");
            e.target.reset();
            setAlert((
                <SweetAlert success title="Success" onConfirm={() => {
                    setAlert(null)
                }}>
                    {response.data.message}
                </SweetAlert>
            ))
        }).catch((error) => {
            setIsDisabled(false);
            setSubmitButtonText("Submit");
            const errors = error.response.data;

            if(errors.message === undefined){
                Object.entries(errors.errors).forEach(([key, value]) => {
                    const elementId = key+"-error";
                    document.getElementById(elementId).innerHTML = value;
                });
            }else{
                setAlert((
                    <SweetAlert danger title="Oops!"
                        onConfirm={
                            () => {
                                setAlert(null)
                            }
                    }>
                        {
                        errors.message
                    }</SweetAlert>
                ))
            }
        });
    }

    const changeSubject = async (e) => {
        const subject = e.target.value;
        if(subject === "Other"){
            setOtherSubject(true);
        }else{
            setOtherSubject(false);
        }
    }

    
    return (
        <div className="wrapper d-flex align-items-stretch">
            <Lawyersidebar/>
            <div id="content" className="main-content">

                <div className="bg-custom py-4">
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <h3 className='mb-3'>Contact Us</h3>
                                <p>Need some help? Please send us a message, and we’ll get back to you asap!</p>
                                <form className='mt-3' onSubmit={(e) => handleSubmit(e)}>
                                    <div className='row'>
                                        <div className="col-md-6">
                                            <div className="form-group mb-2">
                                                <label>Name</label>
                                                <input type="text" name="name" placeholder='Name' className="form-control" />
                                                <p className="text-danger py-2 px-2" id='name-error'></p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group mb-2">
                                                <label>Email Addrerss</label>
                                                <input type="text" name="email" placeholder='Email Addrerss' className="form-control" />
                                                <p className="text-danger py-2 px-2" id='email-error'></p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group mb-2">
                                                <label>Phone Number</label>
                                                <input type="text" name="phone" placeholder='Phone Number' className="form-control" />
                                                <p className="text-danger py-2 px-2" id='phone-error'></p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group mb-2">
                                                <label>Subject</label>
                                                <select name="subject" onChange={(e) => changeSubject(e)} className="form-control">
                                                    <option value="">-- Select --</option>
                                                    <option value="Injury Law SEO">Injury Law SEO</option>
                                                    <option value="Help With Profile">Help With Profile</option>
                                                    <option value="Publish An Article">Publish An Article</option>
                                                    <option value="Cancel Subscription">Cancel Subscription</option>
                                                    <option value="Other">Other</option>
                                                </select>
                                                {
                                                    otherSuject ? (
                                                        <>
                                                        <input type="text" name="subject_other" placeholder='Subject' className="form-control mt-1" />
                                                        <p className="text-danger py-2 px-2" id='subject_other-error'></p>
                                                        </>
                                                        
                                                    ) : (
                                                        <p className="text-danger py-2 px-2" id='subject-error'></p>
                                                    )
                                                }
                                                
                                                
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group mb-2">
                                                <label>Message</label>
                                                <textarea name='message' placeholder='Message' className='form-control' rows="5" cols="30" ></textarea>
                                                <p className="text-danger py-2 px-2" id='message-error'></p>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="save-btn py-0 mb-2">
                                                <button type="submit" className='btn-submit' disabled={isDisabled}>{submitButtonText}</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                {Alert}
                <Lawyerfooter/>
            </div>
        </div>
    )

}

export default LawyerContactUs
