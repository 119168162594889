import React, {useEffect, useState} from "react"
import Admindashboardfooter from "../components/Admindashboardfooter"
import $ from 'jquery'
import { Link } from 'react-router-dom'
import SweetAlert from 'react-bootstrap-sweetalert'
import axios from 'axios';
import { Pagination } from "../../helper/commonHelper"
import DataTable from "../../helper/DataTable"

function StateCityList() {

    const [Alert, setAlert] = useState(null);
    const [pageLinks, setPageLinks] = useState([]);
    const [allState, setAllState] = useState([]);
    const [allCity, setAllCity] = useState([]);
    const [query, setQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [lastPageNumber, setLastPage] = useState("");
    const  REACT_APP_LIVE_BACKEND_BASE_URL  = process.env.REACT_APP_LIVE_BACKEND_BASE_URL;
    var url = REACT_APP_LIVE_BACKEND_BASE_URL+"api/get-all-state-city";
    const [isLoader, setLoader] = useState(true)

    useEffect(() => {
        document.title = 'Cities list'; 
        GetAllStateCity(url);
    },[])

    const GetAllStateCity = (url) => {
        fetch(url).then((res) => res.json()).then((response) => {
            setCurrentPage(response.current_page);
            setCurrentPageNumber(response.current_page_number);
            setLastPage(response.last_page);
            setPageLinks(response.pagination);
            setAllState(response.statedata);
            setAllCity(response.city);
            setLoader(false)
        })
    }

    const  DeleteState = async (e) => {
        var removeid = e.target.getAttribute('deleteid');
        const url = REACT_APP_LIVE_BACKEND_BASE_URL+"api/delete-state-id/"+removeid;
        setAlert(
            <SweetAlert
                warning
                showCancel
                confirmBtnText="Yes, delete it!"
                confirmBtnBsStyle="danger"
                cancelBtnText="No, cancel"
                cancelBtnBsStyle="success"
                title="Are you sure?"
                onConfirm={() => deleteItem(url)}
                onCancel={() => setAlert(null)}
            >
                You won't be able to revert this!
            </SweetAlert>
        );
    }
    
    const handleClick = (e) => {
        e.preventDefault();
        var next = e.currentTarget.getAttribute('nextpage');
        if(next != null){
            next = next+"&search="+query;
            GetAllStateCity(next);
            $(window).scrollTop(0)
        }
    }

    const deleteItem = async (url) => {
        const new_url = REACT_APP_LIVE_BACKEND_BASE_URL+"api/get-all-state-city";
        setAlert(null);
        await axios({
            method: "delete",
            url: url,
        }).then((response) => {   
            GetAllStateCity(new_url);         
            setAlert((<SweetAlert success title="Success" onConfirm={() => { setAlert(null)}}>{response.message}</SweetAlert>))
        }).catch((error) => {
            const errors = error.response.data;
            setAlert((<SweetAlert danger title="Oops!" onConfirm={() => {setAlert(null)}}>{errors.message}</SweetAlert>))
        });
    }

    const handleSearch = async (e) => {
        const search = e.target.value;
        url = url+"?search="+search;
        setQuery(search);
        GetAllStateCity(url);
    }
        
    return (
        <div id="content" className="main-content">
            <div className="bg-custom">
                <div className="main-profile-sec">
                    <div className="profile-sec">
                        <div className="inner-guest-posts">
                            <div className="inner-profile-sec">
                                <div className="row align-items-center">
                                    <div className="col-md-6">
                                        <div className="heading-title c-text-small">
                                            <p> State & City List</p>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group posts-title text-right p-0 m-0">
                                            <input type="text" name="search" placeholder="Search state ..." onKeyUp={(e) => handleSearch(e)} />
                                        </div>
                                    </div>
                                </div>

                                <div className="add-more-btn text-right custom-add-btn-space    ">
                                    {/* <Link to="/add-state-city"> <i className="fa-solid fa-plus"></i> Add State & City</Link> */}
                                </div>

                                <div className="data-table-sec table-responsive admin-data-table">
                                    <table id="example" className="display t-custom-width" cellSpacing="0" width="100%">
                                        <thead>
                                            <tr>
                                                <th style={{width:"6%"}}>S No.</th>
                                                <th style={{width:"10%"}}>State Name</th>
                                                <th style={{width:"40%"}}>City Names</th>
                                                <th className="text-right" style={{width: "23%"}}>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody className="print-html">
                                            {isLoader ? <DataTable /> : allState !== null ? allState.map((item, i) => {
                                                return(
                                                    <tr>
                                                        <td>{currentPage+i}</td>
                                                        <td>{item.name}</td>
                                                        <td>{allCity !== null ? allCity.map((items, j) => {
                                                            return items.map((city, k) => {
                                                                return city.state_id == item.id ? city.name+', ' : ''
                                                            })
                                                        }) : ''}</td>
                                                        <td className="action-btn text-right">
                                                            <Link to={"/edit-state-city/"+item.id+"/"} className="edit-btn"> <i className="fa-regular fa-pen-to-square"></i> Edit</Link>
                                                            <button type="button" onClick={DeleteState}  className="delete-btn print-onclick-button" deleteid={item.id}> <i className="fa-solid fa-trash-can"></i> Delete </button>
                                                        </td>
                                                    </tr>
                                                )
                                            }) : ''}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="lawyer-profile-pagination pagination-custom mb-5">
                                    <Pagination page={pageLinks} lastPage={lastPageNumber} currentPage={currentPageNumber} handleClick={handleClick} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {Alert}
            <Admindashboardfooter />
        </div>
    )
}

export default StateCityList