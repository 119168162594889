import React from 'react';
import { Redirect } from 'react-router-dom';

const LowercaseRedirect = ({ children }) => {
  const { pathname } = window.location;
  const lowercasePath = pathname.toLowerCase()
  if (pathname !== lowercasePath) {
    return <Redirect to={lowercasePath} />
  }

  return children;
}

export default LowercaseRedirect;
