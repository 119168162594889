import React, {useEffect, useState} from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import $ from 'jquery';
import { Link } from 'react-router-dom';


function Welcome() {
    const DvInnerHTML = function (target, newText) {
        document.querySelectorAll(target).forEach(function (element) {
            element.textContent = newText;
        });
    }

    const  REACT_APP_LIVE_BACKEND_BASE_URL  = process.env.REACT_APP_LIVE_BACKEND_BASE_URL;
    const  stage  = process.env.REACT_APP_STAGE;
    const [PracticeArea, setPracticeArea] = useState([]);
    const [state, setState] = useState([]);
    const [city, setCity] = useState([]);
    const [Alert, setAlert] = useState(null);
    useEffect(() => {
        if(stage === "Production"){
            window.location.href = process.env.REACT_APP_BASE_URL+"/";
        }
        
        fetchPracticeArea();
        fetchState();
        fetchCity();
    }, [])

    const fetchPracticeArea = () => {
        fetch(REACT_APP_LIVE_BACKEND_BASE_URL+"api/get-practise-area-list")
        .then((res) => res.json()).then((response) => {
            setPracticeArea(response);
        })
    }

    const fetchState = () => {
        fetch(REACT_APP_LIVE_BACKEND_BASE_URL+"api/get-front-state-list")
        .then((res) => res.json()).then((response) => {
            setState(response);
        })
    }

    const fetchCity = () => {
        fetch(REACT_APP_LIVE_BACKEND_BASE_URL+"api/get-front-city-list")
        .then((res) => res.json()).then((response) => {
            setCity(response);
        })
    }

    const SearchData = async (e) => {
        e.preventDefault();
        DvInnerHTML('.text-danger', '');
        var practice_area = $("#practice_area").val();
        var lawyer_state = $("#lawyer_state").val();
        if(lawyer_state != '' && practice_area != ''){
            window.location.assign('/lawyer-profile/'+practice_area+'/'+lawyer_state);
        }else{
            $("#name_state-error").html('Please Fill State/City/Zipcode.').css('color', 'red');
        }
    }

    return (
        <div>
            <Header />
            <section className="hero-sec">
                <div className="container">

                    <div className="title text-center">
                        <h1>Find The Right Personal Injury Lawyer In Your City</h1>
                    </div>

                    <div className="hero-sec-form">
                        <form onSubmit={SearchData}>
                            <div className="row">

                                <div className="col-md-6">
                                    <div className="form-group">

                                        <select name="practice_area" id="practice_area">
                                            {PracticeArea !== '' ? (<>{PracticeArea.map((item, i) => {

                                                return(
                                                    <option value={item.id} key={i}>{item.name}</option>
                                                )
                                            })}</>) : ''}
                                            
                                            
                                            
                                        </select>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="form-group">
                                        <input type="text" name="lawyer_state" className="form-control" id="lawyer_state" placeholder="Zip Code, State, City" />
                                    </div>
                                </div>

                                <div className="col-md-2">
                                    <div className="form-btn">
                                        <button type="submit">Get Started</button>
                                    </div>
                                </div>

                            </div>
                            <p className="text-danger" id="name_state-error"></p>
                        </form>
                    </div>

                </div>
            </section>

            <section className="btf-sec">
                <div className="container">

                    <div className="title">
                        <h2>Lorem Ipsum</h2>
                    </div>

                    <div className="row">
                    {PracticeArea !== '' ? (<>{PracticeArea.map((item, i) => {

                        return(
                            <div className="col-md-6 col-lg-4 col-xl-3" key={i}>
                                <div className="card">
                                    <Link to="/car-accidents">
                                        <div className="card-img text-center">
                                            <img src={item.practice_photo ? REACT_APP_LIVE_BACKEND_BASE_URL+"public"+item.practice_photo : ''}  />
                                        </div>
                                        <div className="card-title">
                                            <h5>{item.name}</h5>
                                        </div>
                                        <div className="card-text">
                                            <p>{item.description}</p>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        )
                        })}</>) : ''}

                    </div>

                </div>
            </section>

            <section className="fl-state">
                <div className="container">

                    <div className="inner-fl-state-content">

                        <div className="title text-center">
                            <span className="small-title">Find Lawyers by</span>
                            <h2>State</h2>
                        </div>

                        <div className="row">
                            {state.map((item, i) => {
                                return(

                                <div className="col-4 col-sm-4 col-md-4" key={i}>
                                    <div className="states">
                                        <ul>
                                            {item.map((items, j) => {
                                                return(

                                                    <li key={j}> <Link to={"/c-city/"+items.slug}> {items.name} </Link> </li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                </div>
                                )
                            })}
                            

                            

                        </div>

                    </div>

                </div>
            </section>

            <section className="fl-city">
                <div className="container">

                    <div className="inner-fl-city-content">

                        <div className="title text-center">
                            <span className="small-title">Find Lawyers by</span>
                            <h2>City</h2>
                        </div>

                        <div className="row">
                            {city.map((item, i) => {
                                return(
                                    <div className="col-4 col-sm-4 col-md-4" key={i}>
                                        <div className="city">
                                            <ul>
                                                {item.map((items, j) =>{
                                                    return(
                                                       
    
                                                                <li key={j}> <Link to=""> {items.name} </Link> </li>
                                                    
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                )
                            })}
                            

                            

                        </div>

                    </div>
                    <div class="home-city">
                        <Link to="/c-city">See All</Link>
                    </div>

                </div>
            </section>

            <section className="blog">
                <div className="container">
                    <div className="title text-center">
                        <h2>Latest Blog</h2>
                    </div>

                    <div className="inner-blog">
                        <div className="row">

                            <div className="col-md-6 col-lg-4">
                                <div className="blog-content">

                                    <div className="blog-img">
                                        <Link to="/">
                                            <img src={`${process.env.PUBLIC_URL}/images/blog-img.jpg`} />
                                        </Link>
                                    </div>

                                    <div className="blog-c-info">
                                        <div className="blog-tag">
                                            <Link to="/"> OUTREACH </Link>
                                        </div>

                                        <div className="blog-title">
                                            <h4> <Link to=""> 8 Best Cold Email Tools of 2022 </Link> </h4>
                                        </div>

                                        <div className="blog-post-date">
                                            <Link to="/">Boris Mustapic</Link>
                                            <span>•</span>
                                            <time>10 September 2022</time>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="col-md-6 col-lg-4">
                                <div className="blog-content">

                                    <div className="blog-img">
                                        <Link to="/">
                                            <img src={`${process.env.PUBLIC_URL}/images/blog-img.jpg`} />
                                        </Link>
                                    </div>

                                    <div className="blog-c-info">
                                        <div className="blog-tag">
                                            <Link to="/"> OUTREACH </Link>
                                        </div>

                                        <div className="blog-title">
                                            <h4> <Link to=""> 8 Best Cold Email Tools of 2022 </Link> </h4>
                                        </div>

                                        <div className="blog-post-date">
                                            <Link to="/">Boris Mustapic</Link>
                                            <span>•</span>
                                            <time>10 September 2022</time>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="col-md-6 col-lg-4">
                                <div className="blog-content">

                                    <div className="blog-img">
                                        <Link to="/">
                                            <img src={`${process.env.PUBLIC_URL}/images/blog-img.jpg`} />
                                        </Link>
                                    </div>

                                    <div className="blog-c-info">
                                        <div className="blog-tag">
                                            <Link to="/"> OUTREACH </Link>
                                        </div>

                                        <div className="blog-title">
                                            <h4> <Link to=""> 8 Best Cold Email Tools of 2022 </Link> </h4>
                                        </div>

                                        <div className="blog-post-date">
                                            <Link to="/">Boris Mustapic</Link>
                                            <span>•</span>
                                            <time>10 September 2022</time>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="col-md-6 col-lg-4">
                                <div className="blog-content">

                                    <div className="blog-img">
                                        <Link to="/">
                                            <img src={`${process.env.PUBLIC_URL}/images/blog-img.jpg`} />
                                        </Link>
                                    </div>

                                    <div className="blog-c-info">
                                        <div className="blog-tag">
                                            <Link to="/"> OUTREACH </Link>
                                        </div>

                                        <div className="blog-title">
                                            <h4> <Link to=""> 8 Best Cold Email Tools of 2022 </Link> </h4>
                                        </div>

                                        <div className="blog-post-date">
                                            <Link to="/">Boris Mustapic</Link>
                                            <span>•</span>
                                            <time>10 September 2022</time>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="col-md-6 col-lg-4">
                                <div className="blog-content">

                                    <div className="blog-img">
                                        <Link to="/">
                                            <img src={`${process.env.PUBLIC_URL}/images/blog-img.jpg`} />
                                        </Link>
                                    </div>

                                    <div className="blog-c-info">
                                        <div className="blog-tag">
                                            <Link to="/"> OUTREACH </Link>
                                        </div>

                                        <div className="blog-title">
                                            <h4> <Link to=""> 8 Best Cold Email Tools of 2022 </Link> </h4>
                                        </div>

                                        <div className="blog-post-date">
                                            <Link to="/">Boris Mustapic</Link>
                                            <span>•</span>
                                            <time>10 September 2022</time>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="col-md-6 col-lg-4">
                                <div className="blog-content">

                                    <div className="blog-img">
                                        <Link to="/">
                                            <img src={`${process.env.PUBLIC_URL}/images/blog-img.jpg`} />
                                        </Link>
                                    </div>

                                    <div className="blog-c-info">
                                        <div className="blog-tag">
                                            <Link to="/"> OUTREACH </Link>
                                        </div>

                                        <div className="blog-title">
                                            <h4> <Link to=""> 8 Best Cold Email Tools of 2022 </Link> </h4>
                                        </div>

                                        <div className="blog-post-date">
                                            <Link to="/">Boris Mustapic</Link>
                                            <span>•</span>
                                            <time>10 September 2022</time>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            {Alert}
            <Footer />
        </div>
    )
}



function Findlawyer() {

    return (

        <div>

            <Header />
            <section className="lawyer-profile">
                <div className="container">
                    <div className="inner-lawyer-profile">
                        <div className="row">
                            <div className="col-md-2">
                                <div className="lawyer-profile-img">
                                    <img src="images/dummy-profile-pic.jpg" />
                                </div>
                                <div className="visit-btn">
                                    <Link to="/lawyerprofile"><i className="fa-solid fa-user"></i> View Profile</Link>
                                </div>
                            </div>

                            <div className="col-md-7">
                                <div className="lawyer-details">
                                    <div className="lawyer-name">
                                        <h5>Adam Rosengard</h5>
                                    </div>

                                    <div className="lawyer-type">
                                        <span>Rosengard Law Group</span>
                                    </div>

                                    <div className="lawyer-address">
                                        <p><i className="fa-solid fa-map-location-dot"></i>496 Kings Highway North, Suite 220B, Cherry Hill, NJ 08034</p>
                                    </div>

                                    <div className="lawyer-discription">
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                                    </div>
                                    <div className="read-more-btn">
                                        <Link to="/lawyerprofile">Read More<i className="fa-solid fa-angles-right"></i></Link>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="lawyer-contant">
                                    <div className="lawyer-badge-img">
                                        <img src="images/Lawyer-Image.png" />
                                    </div>

                                    <div className="lawyer-contact-number">
                                        <Link to=""><i className="fa-solid fa-phone"></i>(234) 555-1212</Link>
                                    </div>

                                    <div className="lawyer-email">
                                        <Link to=""><i className="fa-solid fa-envelope"></i>abc@gmail.com</Link>
                                    </div>

                                    <div className="lawyer-website">
                                        <Link to="" target="_blank"><i className="fa-solid fa-arrow-up-right-from-square"></i>lawyer.com</Link>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="inner-lawyer-profile">
                        <div className="row">
                            <div className="col-md-2">
                                <div className="lawyer-profile-img">
                                    <img src="images/dummy-profile-pic.jpg" />
                                </div>
                                <div className="visit-btn">
                                    <Link to="/lawyerprofile"><i className="fa-solid fa-user"></i> View Profile</Link>
                                </div>
                            </div>

                            <div className="col-md-7">
                                <div className="lawyer-details">
                                    <div className="lawyer-name">
                                        <h5>Adam Rosengard</h5>
                                    </div>

                                    <div className="lawyer-type">
                                        <span>Rosengard Law Group</span>
                                    </div>

                                    <div className="lawyer-address">
                                        <p><i className="fa-solid fa-map-location-dot"></i>496 Kings Highway North, Suite 220B, Cherry Hill, NJ 08034</p>
                                    </div>

                                    <div className="lawyer-discription">
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                                    </div>
                                    <div className="read-more-btn">
                                        <Link to="/lawyerprofile">Read More<i className="fa-solid fa-angles-right"></i></Link>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="lawyer-contant">
                                    <div className="lawyer-badge-img">
                                        <img src="images/Lawyer-Image.png" />
                                    </div>

                                    <div className="lawyer-contact-number">
                                        <Link to=""><i className="fa-solid fa-phone"></i>(234) 555-1212</Link>
                                    </div>

                                    <div className="lawyer-email">
                                        <Link to=""><i className="fa-solid fa-envelope"></i>abc@gmail.com</Link>
                                    </div>

                                    <div className="lawyer-website">
                                        <Link to="" target="_blank"><i className="fa-solid fa-arrow-up-right-from-square"></i>lawyer.com</Link>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="inner-lawyer-profile">
                        <div className="row">
                            <div className="col-md-2">
                                <div className="lawyer-profile-img">
                                    <img src="images/dummy-profile-pic.jpg" />
                                </div>
                                <div className="visit-btn">
                                    <Link to="/lawyerprofile"><i className="fa-solid fa-user"></i>View Profile</Link>
                                </div>
                            </div>

                            <div className="col-md-7">
                                <div className="lawyer-details">
                                    <div className="lawyer-name">
                                        <h5>Adam Rosengard</h5>
                                    </div>

                                    <div className="lawyer-type">
                                        <span>Rosengard Law Group</span>
                                    </div>

                                    <div className="lawyer-address">
                                        <p><i className="fa-solid fa-map-location-dot"></i>496 Kings Highway North, Suite 220B, Cherry Hill, NJ 08034</p>
                                    </div>

                                    <div className="lawyer-discription">
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                                    </div>
                                    <div className="read-more-btn">
                                        <Link to="/lawyerprofile">Read More<i className="fa-solid fa-angles-right"></i></Link>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="lawyer-contant">
                                    <div className="lawyer-badge-img">
                                        <img src="images/Lawyer-Image.png" />
                                    </div>

                                    <div className="lawyer-contact-number">
                                        <Link to=""><i className="fa-solid fa-phone"></i>(234) 555-1212</Link>
                                    </div>

                                    <div className="lawyer-email">
                                        <Link to=""><i className="fa-solid fa-envelope"></i>abc@gmail.com</Link>
                                    </div>

                                    <div className="lawyer-website">
                                        <Link to="" target="_blank"><i className="fa-solid fa-arrow-up-right-from-square"></i>lawyer.com</Link>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="inner-lawyer-profile">
                        <div className="row">
                            <div className="col-md-2">
                                <div className="lawyer-profile-img">
                                    <img src="images/dummy-profile-pic.jpg" />
                                </div>
                                <div className="visit-btn">
                                    <Link to="/lawyerprofile"><i className="fa-solid fa-user"></i> View Profile</Link>
                                </div>
                            </div>

                            <div className="col-md-7">
                                <div className="lawyer-details">
                                    <div className="lawyer-name">
                                        <h5>Adam Rosengard</h5>
                                    </div>

                                    <div className="lawyer-type">
                                        <span>Rosengard Law Group</span>
                                    </div>

                                    <div className="lawyer-address">
                                        <p><i className="fa-solid fa-map-location-dot"></i>496 Kings Highway North, Suite 220B, Cherry Hill, NJ 08034</p>
                                    </div>

                                    <div className="lawyer-discription">
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                                    </div>
                                    <div className="read-more-btn">
                                        <Link to="/lawyerprofile">Read More<i className="fa-solid fa-angles-right"></i></Link>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="lawyer-contant">
                                    <div className="lawyer-badge-img">
                                        <img src="images/Lawyer-Image.png" />
                                    </div>

                                    <div className="lawyer-contact-number">
                                        <Link to=""><i className="fa-solid fa-phone"></i>(234) 555-1212</Link>
                                    </div>

                                    <div className="lawyer-email">
                                        <Link to=""><i className="fa-solid fa-envelope"></i>abc@gmail.com</Link>
                                    </div>

                                    <div className="lawyer-website">
                                        <Link to="" target="_blank"><i className="fa-solid fa-arrow-up-right-from-square"></i>lawyer.com</Link>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="lawyer-profile-pagination">
                        <Link to="" className="prev">prev</Link>
                        <Link to="" className="count active">1</Link>
                        <Link to="" className="count">2</Link>
                        <Link to="" className="count">...</Link>
                        <Link to="" className="next">next</Link>
                    </div>
                </div>

            </section>
            <Footer />

        </div>

    )
}


function Lawyerprofile() {

    return (


        <div>
            <Header />
            <section className="single-lawyer-profile">
                <div className="container">
                    <div className="inner-lawyer-profile">
                        <div className="row">
                            <div className="col-md-2">
                                <div className="lawyer-profile-img">
                                    <img src="images/dummy-profile-pic.jpg" />
                                </div>
                                <div className="visit-btn">
                                    <Link to=""><i className="fa-solid fa-user"></i> View Profile</Link>
                                </div>
                            </div>

                            <div className="col-md-7">
                                <div className="lawyer-details">
                                    <div className="lawyer-name">
                                        <h5>Adam Rosengard</h5>
                                    </div>

                                    <div className="lawyer-type">
                                        <span>Rosengard Law Group</span>
                                    </div>

                                    <div className="lawyer-address">
                                        <p><i className="fa-solid fa-map-location-dot"></i>496 Kings Highway North, Suite 220B, Cherry Hill, NJ 08034</p>
                                    </div>

                                    <div className="lawyer-discription">
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                                    </div>

                                    <div className="read-more-btn">
                                        <Link to="">Read More<i className="fa-solid fa-angles-right"></i></Link>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="lawyer-contant">
                                    <div className="lawyer-badge-img">
                                        <img src="images/Lawyer-Image.png" />
                                    </div>

                                    <div className="lawyer-contact-number">
                                        <Link to=""><i className="fa-solid fa-phone"></i>(234) 555-1212</Link>
                                    </div>

                                    <div className="lawyer-email">
                                        <Link to=""><i className="fa-solid fa-envelope"></i>abc@gmail.com</Link>
                                    </div>

                                    <div className="lawyer-website">
                                        <Link to="" target="_blank"><i
                                            className="fa-solid fa-arrow-up-right-from-square"></i>lawyer.com</Link>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="lawyer-profile-content">

                        <div className="lawyer-biography mt-5">
                            <div className="title">
                                <h4 className="text-start mb-4">Biography</h4>
                            </div>
                            <div className="text">
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                            </div>
                        </div>

                        <div className="lawyer-p-area mt-5">
                            <div className="title">
                                <h4 className="text-start mb-4">Practice Areas</h4>
                            </div>
                            <div className="text">
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                            </div>
                        </div>

                        <div className="lawyer-license mt-5">
                            <div className="title">
                                <h4 className="text-start mb-4">License</h4>
                            </div>

                            <div className="row">
                                <div className="col-md-4">
                                    <div className="l-state s-both">
                                        <p> <strong>State:</strong> <span>California</span> </p>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="l-status s-both">
                                        <p> <strong>Status:</strong> <span>Active</span> </p>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="l-year s-both">
                                        <p> <strong>Year:</strong> <span>1997</span> </p>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="lawyer-review mt-5">
                            <div className="title">
                                <h4 className="text-start mb-4">Reviews</h4>
                            </div>
                            <div id="testimonials">
                                <div className="testimonial-box-container">

                                    <div className="testimonial-box">
                                        <div className="box-top">
                                            <div className="profile">
                                                <div className="profile-img">
                                                    <img src="images/dummy-profile-pic.jpg" />
                                                </div>
                                                <div className="name-user">
                                                    <p>Lorem ipsum</p>
                                                    <span>@loremipsum</span>
                                                </div>
                                            </div>
                                            <div className="reviews">
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                                <i className="far fa-star"></i>
                                            </div>
                                        </div>
                                        <div className="client-comment">
                                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem, quaerat quis? Provident temporibus architecto asperiores nobis maiores nisi a. Quae doloribus ipsum aliquam tenetur voluptates incidunt blanditiis sed atque cumque.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="testimonial-box">
                                        <div className="box-top">
                                            <div className="profile">
                                                <div className="profile-img">
                                                    <img src="images/dummy-profile-pic.jpg" />
                                                </div>
                                                <div className="name-user">
                                                    <p>Lorem ipsum</p>
                                                    <span>@loremipsum</span>
                                                </div>
                                            </div>
                                            <div className="reviews">
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                                <i className="far fa-star"></i>
                                            </div>
                                        </div>
                                        <div className="client-comment">
                                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem, quaerat quis? Provident temporibus architecto asperiores nobis maiores nisi a. Quae doloribus ipsum aliquam tenetur voluptates incidunt blanditiis sed atque cumque.
                                            </p>
                                        </div>
                                    </div>

                                </div>
                                <div className="review-btn">
                                    <Link to="">Write a Review</Link>
                                </div>
                            </div>
                        </div>

                        <div className="lawyer-pv mt-5">
                            <div className="title">
                                <h4 className="text-start mb-4">Photos and Videos</h4>
                            </div>
                            <div className="pv-content">
                                <div className="row">

                                    <div className="col-md-3">
                                        <div className="lawyer-photo">
                                            <img src="images/lawyer.jpg" />
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="lawyer-photo">
                                            <img src="images/lawyer.jpg" />
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="lawyer-photo">
                                            <img src="images/lawyer.jpg" />
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="lawyer-photo">
                                            <img src="images/lawyer.jpg" />
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="lawyer-photo">
                                            <img src="images/lawyer.jpg" />
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="lawyer-photo">
                                            <img src="images/lawyer.jpg" />
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="lawyer-photo">
                                            <img src="images/lawyer.jpg" />
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="lawyer-photo">
                                            <img src="images/lawyer.jpg" />
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="lawyer-video">
                                            <iframe width="306" height="230" src="https://www.youtube.com/embed/K4TOrB7at0Y" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="lawyer-video">
                                            <iframe width="306" height="230" src="https://www.youtube.com/embed/K4TOrB7at0Y" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="lawyer-video">
                                            <iframe width="306" height="230" src="https://www.youtube.com/embed/K4TOrB7at0Y" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="lawyer-video">
                                            <iframe width="306" height="230" src="https://www.youtube.com/embed/K4TOrB7at0Y" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="lawyer-awards mt-5">
                            <div className="title">
                                <h4 className="text-start mb-4">Awards</h4>
                            </div>

                            <div className="row">
                                <div className="col-md-4">
                                    <div className="l-awards s-both">
                                        <p> <strong>Awards Name:</strong> <span>Lorem Ipsum</span> </p>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="l-grantor s-both">
                                        <p> <strong>Grantor:</strong> <span>Lorem Ipsum</span> </p>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="l-grantor-date s-both">
                                        <p> <strong>Date Granted:</strong> <span>1997</span> </p>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="lawyer-experience mt-5">
                            <div className="title">
                                <h4 className="text-start mb-4">Professional Experience</h4>
                            </div>

                            <div className="row">
                                <div className="col-md-4">
                                    <div className="l-title s-both">
                                        <p> <strong>Title:</strong> <span>Lorem Ipsum</span> </p>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="l-company s-both">
                                        <p> <strong>Company:</strong> <span>Lorem Ipsum</span> </p>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="l-date s-both">
                                        <p> <strong>Date:</strong> <span>24-04-2000</span> </p>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="lawyer-professional-associations mt-5">
                            <div className="title">
                                <h4 className="text-start mb-4">Professional Associations</h4>
                            </div>

                            <div className="row">
                                <div className="col-md-4">
                                    <div className="l-association s-both">
                                        <p> <strong>Association Name:</strong> <span>Lorem Ipsum</span> </p>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="l-position s-both">
                                        <p> <strong>Position:</strong> <span>Lorem Ipsum</span> </p>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="l-date s-both">
                                        <p> <strong>Dates:</strong> <span>24-04-2022</span> </p>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="lawyer-education mt-5">
                            <div className="title">
                                <h4 className="text-start mb-4">Education</h4>
                            </div>

                            <div className="row">
                                <div className="col-md-4">
                                    <div className="l-school s-both">
                                        <p> <strong>School Name:</strong> <span>Lorem Ipsum</span> </p>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="l-degree s-both">
                                        <p> <strong>Degree:</strong> <span>Lorem Ipsum</span> </p>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="l-graduated s-both">
                                        <p> <strong>Graduated:</strong> <span>24-04-2022</span> </p>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="lawyer-further mt-5">
                            <div className="title">
                                <h4 className="text-start mb-4">Further Reading</h4>
                            </div>

                            <div className="further-reading">
                                <p>
                                    <Link to="">New Jersey Car Accident Lawyer 
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-up-right" viewBox="0 0 16 16"> 
                                            <path fill-rule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z" />
                                            <path fill-rule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z" />
                                        </svg>
                                    </Link>
                                </p>

                                <p>
                                    <Link to="">New Jersey Car Accident Lawyer 
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-up-right" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z" />
                                            <path fill-rule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z" />
                                        </svg>
                                    </Link>
                                </p>

                                <p>
                                    <Link to="">New Jersey Car Accident Lawyer
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-up-right" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z" />
                                            <path fill-rule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z" />
                                        </svg>
                                    </Link>
                                </p>

                                <p>
                                    <Link to="">New Jersey Car Accident Lawyer 
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-up-right" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z" />
                                            <path fill-rule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z" />
                                        </svg>
                                    </Link>
                                </p>
                            </div>
                        </div>


                        <div className="lawer-attroney mt-5">
                            <div className="title">
                                <h4 className="text-start mb-4">Attorneys at This Firm</h4>
                            </div>

                            <div className="row attroney-list">
                                <div className="col-md-3">
                                    <div className="row">
                                        <div className="attroney-img col-md-4">
                                            <img src="images/Linkttroney-lawyer.jpg" alt="" />
                                        </div>
                                        <div className="col-md-8">
                                            <Link to="">Mark A. Snyder</Link>
                                            <p>Member</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="row">
                                        <div className="attroney-img col-md-4">
                                            <img src="images/Linkttroney-lawyer.jpg" alt="" />
                                        </div>
                                        <div className="col-md-8">
                                            <Link to="">Mark A. Snyder</Link>
                                            <p>Member</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="row">
                                        <div className="attroney-img col-md-4">
                                            <img src="images/Linkttroney-lawyer.jpg" alt="" />
                                        </div>
                                        <div className="col-md-8">
                                            <Link to="">Mark A. Snyder</Link>
                                            <p>Member</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="row">
                                        <div className="attroney-img col-md-4">
                                            <img src="images/Linkttroney-lawyer.jpg" alt="" />
                                        </div>
                                        <div className="col-md-8">
                                            <Link to="">Mark A. Snyder</Link>
                                            <p>Member</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="row">
                                        <div className="attroney-img col-md-4">
                                            <img src="images/Linkttroney-lawyer.jpg" alt="" />
                                        </div>
                                        <div className="col-md-8">
                                            <Link to="">Mark A. Snyder</Link>
                                            <p>Member</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="row">
                                        <div className="attroney-img col-md-4">
                                            <img src="images/Linkttroney-lawyer.jpg" alt="" />
                                        </div>
                                        <div className="col-md-8">
                                            <Link to="">Mark A. Snyder</Link>
                                            <p>Member</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="row">
                                        <div className="attroney-img col-md-4">
                                            <img src="images/Linkttroney-lawyer.jpg" alt="" />
                                        </div>
                                        <div className="col-md-8">
                                            <Link to="">Mark A. Snyder</Link>
                                            <p>Member</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="row">
                                        <div className="attroney-img col-md-4">
                                            <img src="images/Linkttroney-lawyer.jpg" alt="" />
                                        </div>
                                        <div className="col-md-8">
                                            <Link to="">Mark A. Snyder</Link>
                                            <p>Member</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="row">
                                        <div className="attroney-img col-md-4">
                                            <img src="images/Linkttroney-lawyer.jpg" alt="" />
                                        </div>
                                        <div className="col-md-8">
                                            <Link to="">Mark A. Snyder</Link>
                                            <p>Member</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="row">
                                        <div className="attroney-img col-md-4">
                                            <img src="images/Linkttroney-lawyer.jpg" alt="" />
                                        </div>
                                        <div className="col-md-8">
                                            <Link to="">Mark A. Snyder</Link>
                                            <p>Member</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="row">
                                        <div className="attroney-img col-md-4">
                                            <img src="images/Linkttroney-lawyer.jpg" alt="" />
                                        </div>
                                        <div className="col-md-8">
                                            <Link to="">Mark A. Snyder</Link>
                                            <p>Member</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>

            </section>
            <Footer />
        </div>

    )
}

export { Welcome, Findlawyer, Lawyerprofile } 