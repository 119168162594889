import React, {useEffect} from "react";
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Admindashboardheader from "../components/Admindashboardheader"
import Admindashboardsidebar from "../components/Admindashboardsidebar"
import Admindashboardfooter from "../components/Admindashboardfooter"

function Bloglist() {
    useEffect(() => {
        document.title = 'Blog List';
    }, []);
    const [modalShow, setModalShow] = React.useState(false);

    return (
        <>
            <Admindashboardheader />
            <div className="wrapper d-flex align-items-stretch">
                <Admindashboardsidebar />
                <div id="content" className="main-content">

                    <div className="bg-custom">
                        <div className="main-profile-sec">
                            <div className="profile-sec">
                                <div className="inner-guest-posts">

                                    <div className="inner-profile-sec">

                                        <div className="row align-items-center">

                                            <div className="col-md-6">
                                                <div className="heading-title c-text-small">
                                                    <p>Blog List</p>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group posts-title text-right p-0">
                                                    <input type="text" name="search" placeholder="Search..." className="" id="" />
                                                </div>
                                            </div>

                                        </div>

                                        <div className="add-more-btn text-right">
                                            <Link to="add-blog.html"> <i className="fa-solid fa-plus"></i> Add Blog </Link>
                                        </div>



                                        <div className="data-table-sec table-responsive admin-data-table">

                                            <table id="example" className="display" cellspacing="0" width="100%">
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: "12%" }}>Blog Image</th>
                                                        <th style={{ width: "13%" }}>Date</th>
                                                        <th>Title</th>
                                                        <th>Description</th>
                                                        <th>categories</th>
                                                        <th className="text-right" style={{ width: "16%" }}>Actions</th>

                                                    </tr>
                                                </thead>

                                                <tbody>

                                                    <tr>
                                                        <td className="list-img"> <img src="dashboard/images/bloglistimg.webp" /></td>
                                                        <td> 01 September 2022 </td>
                                                        <td> The Ultimate Guide to Guest Posting in 2022 </td>
                                                        <td> Read this guide to learn how to use guest posting to build links, drive traffic, and develop valuable relationships. </td>
                                                        <td> Dog Bites </td>
                                                        <td className="action-btn text-right">
                                                            <Button className="edit-btn" onClick={() => setModalShow(true)}> <i className="fa-solid fa-eye"></i> View
                                                            </Button>
                                                        </td>
                                                    </tr>

                                                </tbody>

                                            </table>
                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>
                    <Admindashboardfooter />

                </div>
            </div>

            <ApprovedModal show={modalShow} onHide={() => setModalShow(false)} />
        </>
    )
}

export default Bloglist

function ApprovedModal(props) {
    return (
        <div className="profile-list-approve-modal">
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centeredl
            >
                <Modal.Header className="modal-header" closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h5 className="modal-title" id="exampleModalLabel">Approve</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="approve-list-content">
                        <div className="row">

                            <div className="col-md-6">
                                <div className="al-content">
                                    <p> <span>Name :</span> Adam Rosengard </p>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="al-content">
                                    <p> <span>Email :</span> abc@gmail.com </p>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="al-content">
                                    <p> <span>Payment Status :</span> Paid </p>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="al-content">
                                    <p> <span>Payment ID :</span> 123456 </p>
                                </div>
                            </div>

                        </div>

                        <div className="approve-switch-brn">
                            <Button type="button" className="btn btn-toggle" data-toggle="button" aria-pressed="false"
                                autocomplete="off">
                                <div className="handle"></div>
                            </Button>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button>Save</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}