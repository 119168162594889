import React, {useState, useEffect} from "react"
import Admindashboardfooter from "../components/Admindashboardfooter"
import axios from 'axios'
import SweetAlert from 'react-bootstrap-sweetalert'
import Cookies from 'universal-cookie'
import {useHistory} from 'react-router-dom'

function Changepassword() {
    useEffect(() => {
        document.title = 'Change Password'
    }, [])
    const REACT_APP_LIVE_BACKEND_BASE_URL = process.env.REACT_APP_LIVE_BACKEND_BASE_URL
    const [Alert, setAlert] = useState(null)
    const cookies = new Cookies()
    var tokenid = cookies.get('ADMIN')
    let history = useHistory()

    const DvInnerHTML = function (target, newText) {
        document.querySelectorAll(target).forEach(function (element) {
            element.textContent = newText
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = new FormData(e.target);

        try {
            await axios({
                method: "post",
                url: REACT_APP_LIVE_BACKEND_BASE_URL + "api/change-user-password",
                data: data,
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            }).then((response) => {
                const errors = response.data.errors;
                if (response.data.status) {
                    setAlert((
                        <SweetAlert success title="Done" onConfirm={() => {
                            setAlert(null)
                            history.push('./../logout/')
                        }}>
                            {response.data.message}
                        </SweetAlert>
                    ))
                } else {
                    DvInnerHTML('.text-danger', '');
                    if (parseInt(Object.keys(errors).length) > 0) {
                        for (var key in errors) {
                            if (errors.hasOwnProperty(key)) {
                                if (document.getElementById(key + '_error') === null) {
                                    console.log(key + '-error')
                                } else {
                                    document.getElementById(key + '_error').innerHTML = errors[key];
                                }
                            }
                        }
                    } else {
                        setAlert((
                            <SweetAlert danger title="Ooops!" onConfirm={() => {
                                setAlert(null)
                            }}>
                                {response.data.message}
                            </SweetAlert>
                        ))
                    }
                }
            });
        } catch (response) {
            console.log(response)
        }
    }

    return (
        <div id="content" className="main-content">
            <div className="bg-custom">
                <div className="main-profile-sec">
                    <div className="profile-sec">
                        <form onSubmit={handleSubmit}>
                            <div className="inner-guest-posts">
                                <div className="inner-profile-sec">
                                    <div className="heading-title c-text-small">
                                        <p>Change Password</p>
                                    </div>

                                    <div className="profile-edit-sec">
                                        <div className="row">

                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>Old Password</label>
                                                    <input type="password" name="old_password" className="form-control"/>
                                                    <input type="hidden" name="token" value={tokenid} />
                                                    <p className="text-danger" id="old_password_error"></p>
                                                </div>
                                                <div className="form-group">
                                                    <label>New Password</label>
                                                    <input type="password" name="new_password" className="form-control"/>
                                                    <p className="text-danger" id="new_password_error"></p>
                                                </div>
                                                <div className="form-group">
                                                    <label>Confirm Password</label>
                                                    <input type="password" name="confirm_password" className="form-control"/>
                                                    <p className="text-danger" id="confirm_password_error"></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="save-btn text-right p-0">
                                            <button type="submit" className="mt-2">Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Admindashboardfooter/> 
            {Alert}
        </div>
    )
}


export default Changepassword
