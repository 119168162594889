import React, {useState, useEffect} from "react"
import Cookies from 'universal-cookie'
import axios from 'axios'
import SweetAlert from 'react-bootstrap-sweetalert'
function LogOut(){
    const cookies = new Cookies();  
    const [Alert, setAlert] = useState(null);
    const  REACT_APP_LIVE_BACKEND_BASE_URL  = process.env.REACT_APP_LIVE_BACKEND_BASE_URL;

    useEffect(() => {
        document.title = "Logout";
        if(typeof cookies.get('LAWYER') !== "undefined"){
            var lawyertoken = cookies.get('LAWYER');
            cookies.remove("LAWYER", {path: '/'});
            cookies.remove('LAWYER', { path: '/', domain: '.servetheinjured.com'}); 
            cookies.remove("LAWYERID", {path: '/'});
            cookies.remove('LAWYERID', { path: '/', domain: '.servetheinjured.com'}); 

            deleteToken(lawyertoken);
        }else if(typeof cookies.get('ADMIN') !== "undefined"){
            var admintoken = cookies.get('ADMIN');
            cookies.remove("ADMIN", {path: '/'});
            cookies.remove('ADMIN', { path: '/', domain: '.servetheinjured.com'}); 
            deleteToken(admintoken);
        }
    }, []);

    const deleteToken = async(token) => {
        try {
          const response = await axios({
            method: "post",
            url: REACT_APP_LIVE_BACKEND_BASE_URL+"api/delete-token",
            data: {
                token:token,
            },
            headers: { "Content-Type": "multipart/form-data" },
          }).then((response) => {
            const errors = response.data.errors;
            if(response.data.status){
                if(process.env.REACT_APP_STAGE === "Production"){
                    window.location.assign(process.env.REACT_APP_BASE_URL);
                }else{
                    window.location.assign(process.env.REACT_APP_LIVE_BASE_URL);
                }
            }else{
                if (parseInt(Object.keys(errors).length) > 0) {
                    for (var key in errors) {
                        if (errors.hasOwnProperty(key)) {
                            if(document.getElementById(key + '-error') === null)
                            {
                                console.log(key + '-error')
                            }else
                            {
                                document.getElementById(key + '-error').innerHTML = errors[key];
                            }
                        }
                    }
                }else{
                    setAlert((<SweetAlert danger title="" onConfirm={() => {
                        setAlert(null)
                    }}>{response.data.msg}</SweetAlert>))
                }

                if(process.env.REACT_APP_STAGE === "Production"){
                    window.location.assign(process.env.REACT_APP_BASE_URL);
                }else{
                    window.location.assign(process.env.REACT_APP_LIVE_BASE_URL);
                }
            }
        });
        } catch(response) {
            console.log(response)
        }
    }
      
    return(
        <div>
            <section className="logout-section py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="text-center">Please wait...</h2>
                        </div>
                    </div>
                </div>
            </section>
            {Alert}
        </div>
    )
}

export default LogOut