import React, {useEffect, useState} from 'react'
import axios from 'axios'
import SweetAlert from 'react-bootstrap-sweetalert'  
import Cookies from 'universal-cookie'
import { useParams, Link } from 'react-router-dom'
import { Loader } from '../helper/commonHelper'
import { Helmet } from "react-helmet"

function Signin() {
    const [isLoader, setLoader] = useState(false);
    const  REACT_APP_LIVE_BACKEND_BASE_URL  = process.env.REACT_APP_LIVE_BACKEND_BASE_URL;
    const DvInnerHTML = function(target,newText) {
        document.querySelectorAll(target).forEach(function(element) {
          element.textContent = newText
        })
    }  
    const queryParameters = new URLSearchParams(window.location.search)
    const cookies = new Cookies()
    const [Alert, setAlert] = useState(null)
    let {token} = useParams()
    useEffect(() => {
        document.title = 'Signin'
        if(typeof token !== "undefined" && token.length){
            fetchLoginToken()
        }

        if(typeof cookies.get('LAWYER') !== "undefined"){
            window.location.href = `${process.env.REACT_APP_LIVE_BASE_URL}dashboard/`
        }else if(typeof cookies.get('ADMIN') !== "undefined"){
            window.location.assign('/')
        }else{
            setLoader(false)
        }

    }, [])

    useEffect(() => {
        const message = queryParameters.get("msg")
        if(message !== null){
            setAlert((<SweetAlert success title="" onConfirm={() => {
                setAlert(null)
                window.location.assign('/sign-in/')
            }} >{message}</SweetAlert>))
        }
    }, [])
    
    const fetchLoginToken = () => {
        fetch(REACT_APP_LIVE_BACKEND_BASE_URL+"api/verify-token/"+token)
        .then((res) => res.json()).then((response) => {
            if(response.status){
                setAlert((<SweetAlert success title="" onConfirm={() => {
                    setAlert(null)
                    cookies.set('LAWYER', response.token, {path: '/', maxAge: 2592000, secure: true, sameSite: 'None' })
                    cookies.set('LAWYER', response.token, { path: '/', maxAge: 2592000, domain: '.servetheinjured.com', secure: true, sameSite: 'Lax'})
                    window.location.assign('/dashboard/')
                }} >{response.msg}</SweetAlert>))
            }else{
                setAlert((<SweetAlert danger title="" onConfirm={() => {
                    setAlert(null);
                }} >{response.msg}</SweetAlert>))
            }
        })
    }

    const handleSubmit = async(e) => {
        e.preventDefault();

        try {
          await axios({
            method: "post",
            url: REACT_APP_LIVE_BACKEND_BASE_URL+"api/signin-lawyer",
            data: new FormData(e.target),
            headers: { "Content-Type": "multipart/form-data" },
          }).then((response) => {
            const errors = response.data.errors;
            if(response.data.status){
                DvInnerHTML('.text-danger', '')
                setAlert(null)
                cookies.set('LAWYER', response.data.token, { path: '/', maxAge: 2592000 })
                cookies.set('LAWYERID', response.data.lawyerid, { path: '/', maxAge: 2592000 })
                cookies.set('LAWYER', response.data.token, { path: '/', maxAge: 2592000, domain: '.servetheinjured.com', secure: true, sameSite: 'Lax'})
                cookies.set('LAWYERID', response.data.lawyerid, { path: '/', maxAge: 2592000, domain: '.servetheinjured.com', secure: true, sameSite: 'Lax'})
                window.location.assign('/dashboard/')
            }else{
                DvInnerHTML('.text-danger', '')
                if (parseInt(Object.keys(errors).length) > 0) {
                    for (var key in errors) {
                        if (errors.hasOwnProperty(key)) {
                            if(document.getElementById(key + '-error') === null)
                            {
                                console.log(key + '-error')
                            }else
                            {
                                document.getElementById(key + '-error').innerHTML = errors[key]
                            }
                        }
                    }
                }else{
                    setAlert((<SweetAlert   danger title=""  onConfirm={() => {
                        setAlert(null)
                    }} >{response.data.clickevent  ? (<>{response.data.msg} <button id="resend-email-button" sendurl={response.data.clickevent} useremail={response.data.useremail} onClick={ResendEmail}>Resend Email</button></>) : response.data.msg}</SweetAlert>))
                }
            }
        })} catch(response) {
            console.log(response)
        }
    }

    const ResendEmail = async(e) => {
        e.preventDefault()
        const getemailurl = e.target.getAttribute("sendurl")
        const useremail = e.target.getAttribute("useremail")

        try {
          await axios({
            method: "post",
            url: getemailurl,
            data: useremail,
            headers: { "Content-Type": "multipart/form-data" },
          }).then((response) =>{
            const errors = response.data.errors
            if(response.data.status){
                DvInnerHTML('.text-danger', '')
                setAlert((<SweetAlert  success  title="" onConfirm={() => {
                    setAlert(null)
                }} >{response.data.msg}</SweetAlert>))
            }else{
                DvInnerHTML('.text-danger', '');
                if (parseInt(Object.keys(errors).length) > 0) {
                    for (var key in errors) {
                        if (errors.hasOwnProperty(key)) {
                            if(document.getElementById(key + '-error') === null)
                            {
                                console.log(key + '-error')
                            }else
                            {
                                document.getElementById(key + '-error').innerHTML = errors[key]
                            }
                        }
                    }
                }else{
                    setAlert((<SweetAlert   danger title=""  onConfirm={() => {
                        setAlert(null)
                    }} >{response.data.msg}</SweetAlert>))
                }
            }
        })} catch(response) {
          console.log(response)
        }
    }

    return (
        <Loader loader={isLoader}>
            <Helmet>
                <title>Sign In</title>
                <link rel="canonical" href={window.location.href.toLowerCase()} />
            </Helmet>
            <section className="signin">
                <div className="container">
                    <div className="inner-signin">
                        <Link to="/" className="previous-btn">
                            <i className="fa-solid fa-angle-left"></i>
                        </Link>

                        <div className="signin-content">
                            <div className="signin-title text-center">
                                <h4>Sign in</h4>
                            </div>
                        </div>

                        <div className="email signin-form-sec mt-4">
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label for="email" className="mb-1">Email Address <span className="red">*</span></label>
                                    <input type="text" name="email"  className="form-control" id="email_address"/>
                                </div>
                                <p className="text-danger" id="email-error"></p>

                                <div className="form-group">
                                    <label for="email" className="mb-1"> Password <span className="red">*</span></label>
                                    <input type="password" name="password" className="form-control" id="password"/>
                                </div>
                                <p className="text-danger" id="password-error"></p>

                                <div className="remember mt-4">
                                    <label><input type="checkbox" name="" id="" />Remember me</label>
                                </div>

                                <div className="next-btn">
                                    <button type="submit" className="mt-4 mb-2">Sign in</button>
                                </div>
                            </form>

                            <div className="or-line text-center">
                                <p>or</p>
                            </div>

                            <div className="account text-center">
                                <p className="m-0 text-muted">Don't have an account yet? <Link to="/signup/" rel="nofollow"> Sign up </Link></p>
                            </div>
                        </div>

                        <div className="password signin-form-sec mt-4">
                            <div className="forgot text-center mt-3">
                                <Link to="/forgot-password/" rel="nofollow">Forgot your password?</Link>
                            </div>
                            <div className="terms-text text-center mt-2">
                                <p className="m-0 text-muted">By signing in, you agree to STI <a href={`${process.env.REACT_APP_BASE_URL}/terms-of-service/`}>terms of use</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {Alert}
        </Loader>
    )
}

export { Signin } 