import React, {useState, useEffect} from "react";
import { useParams, useHistory } from "react-router-dom";
import Admindashboardfooter from "../components/Admindashboardfooter"
import { Editor } from "react-draft-wysiwyg"
import { EditorState, convertToRaw, ContentState } from 'draft-js'
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import axios from 'axios';
import SweetAlert from 'react-bootstrap-sweetalert';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

function EditDashboardContent() {
    let {id} = useParams();
    const REACT_APP_LIVE_BACKEND_BASE_URL = process.env.REACT_APP_LIVE_BACKEND_BASE_URL
    const [Alert, setAlert] = useState(null)
    let history = useHistory()
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const [dashboardContent, setDashboardContent] = useState([])
    const DvInnerHTML = function (target, newText) {
        document.querySelectorAll(target).forEach(function (element) {
            element.textContent = newText
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = new FormData(e.target);
        DvInnerHTML('.text-danger', '');

        try {
            await axios({
                method: "post",
                url: REACT_APP_LIVE_BACKEND_BASE_URL + "api/lawyer-profile-content-update",
                data: data,
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            }).then((response) => {
                const errors = response.data.errors;
                if (response.data.status) {
                    setAlert((
                        <SweetAlert success title="Done" onConfirm={() => {
                            setAlert(null)
                            history.push('./../../lawyer-dashboard-content/')
                        }}>
                            {response.data.message}
                        </SweetAlert>
                    ))
                } else {
                    if (parseInt(Object.keys(errors).length) > 0) {
                        for (var key in errors) {
                            if (errors.hasOwnProperty(key)) {
                                if (document.getElementById(key + '-error') === null) {
                                    console.log(key + '-error')
                                } else {
                                    document.getElementById(key + '-error').innerHTML = errors[key];
                                }
                            }
                        }
                    } else {
                        setAlert((
                            <SweetAlert danger title="Oops!" onConfirm={() => { setAlert(null)}}>
                                {response.data.msg}
                            </SweetAlert>
                        ))
                    }
                }
            });
        } catch (response) {
            console.log(response)
        }

    }

    const getLawyerDashboardData = async () => {
        URL = REACT_APP_LIVE_BACKEND_BASE_URL+'api/lawyer-profile-content/'+id;
        await axios({
            method: "get",
            url: URL,
            headers: {
                "Content-Type": "application/json"
            }
        }).then((response) => {
            const data = response.data.data;
            setDashboardContent(data);
            const contentBlock = htmlToDraft(data.content);
            const contentStateData = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorStateData = EditorState.createWithContent(contentStateData);
            setEditorState(editorStateData);
        }).catch((error) => {
            console.log(error)
        });
    }

    const onEditorStateChange = (editorState) => {
        DvInnerHTML('.text-danger', '');
        setEditorState(editorState);
    }

    useEffect(() => {
        document.title = 'Edit Dashboard Content';
        getLawyerDashboardData();
    }, []);

    return (
        <div id="content" className="main-content">
            <div className="bg-custom">
                <div className="main-profile-sec">
                    <div className="profile-sec">
                        <form onSubmit={handleSubmit}>
                            <div className="inner-guest-posts">
                                <div className="inner-profile-sec">
                                    <div className="heading-title c-text-small">
                                        <p>Update Lawyer Dashboard Content</p>
                                    </div>

                                    <div className="profile-edit-section">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Title</label>
                                                    <input type="text" name="title" id="title" defaultValue={dashboardContent.title} className="form-control"/>
                                                    <input type="hidden" name="id" value={dashboardContent.id} />
                                                    <p className="text-danger" id="title-error"></p>
                                                    <p className="text-danger" id="id-error"></p>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Youtube Video Url</label>
                                                    <input type="text" name="youtube_video" id="youtube_video" defaultValue={dashboardContent.youtube_video} className="form-control" />
                                                    <p className="text-danger" id="youtube_video-error"></p>
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>Description</label>
                                                    <textarea name="discription" rows="5" cols="30" id="discription" className="form-control">{dashboardContent.discription}</textarea>
                                                    <p className="text-danger" id="discription-error"></p>
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>Page Content</label>
                                                    <Editor
                                                        editorState={editorState}
                                                        toolbarClassName="toolbarClassName"
                                                        wrapperClassName="wrapperClassName"
                                                        editorClassName="inner-guest-posts"
                                                        onEditorStateChange={onEditorStateChange}
                                                        toolbar={{
                                                            options: ['inline', 'textAlign', 'colorPicker', 'link', 'remove', 'history']
                                                        }}
                                                    />
                                                    <input type="hidden" name="content" value={draftToHtml(convertToRaw(editorState.getCurrentContent()))} />
                                                    <p className="text-danger" id="content-error"></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="save-btn text-right p-0">
                                            <button type="submit" className="mt-2">Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <Admindashboardfooter /> 
                {Alert}
            </div>
        </div>
    )
}


export default EditDashboardContent
