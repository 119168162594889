import React, {useState, useEffect} from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Admindashboardheader from "../admin_dashboard/components/Admindashboardheader"
import Admindashboardsidebar from "../admin_dashboard/components/Admindashboardsidebar"
import LowercaseRedirect from '../components/LowercaseRedirect'

export default function AdminLayout({children}) {
    const [isDashboard, setIsDashboard] = useState(true)
    const dashboardUrls = ['admin-dashboard', 'lawyer-list', 'accountmanagementlist', 'add-badge', 'add-categories', 'add-blog', 'add-package', 'add-practice-area', 'add-webinars', 'badge', 'blog-categories-list', 'lawyer-list', 'user-list', 'lawyer-dashboard-content', 'blog-list', 'admin-lawyer-profile', 'practice-area-list', 'webinars-list', 'add-lawyer', 'add-user', 'edit-user-profile', 'edit-lawyer-profile', 'edit-dashboard-content', 'package-list', 'edit-practice-area', 'edit-state-city', 'state-city-list', 'edit-city', 'edit-package', 'add-logo', 'change-admin-password', 'logout', 'add-front-social', 'front-social-list', 'edit-front-social', 'add-popular-location', 'popular-location-list', 'edit-popular-location', 'add-for-the-injured', 'for-the-injured-list', 'edit-for-the-injured', 'for-attorney-list', 'add-for-attorney', 'edit-for-attorney', 'copyright', 'add-sidebar-banner', 'sidebar-banner', 'get-published', 'home-page']

    const checkCurrentPage = () => {
        const liveUrl = window.location.href
        let isUrlExists = false
        for (let i = 0; i < dashboardUrls.length; i++) {
            if (liveUrl.includes(dashboardUrls[i])) {
                isUrlExists = true
            }
        }
        setIsDashboard(isUrlExists)
    }

    useEffect(() => {
        checkCurrentPage()
    }, [])

    return isDashboard ? (<>
        <Admindashboardheader />
        <LowercaseRedirect />
        <div className="wrapper d-flex align-items-stretch">
            <Admindashboardsidebar />
            { children }
        </div>
    </>) : (<>
        <Header isLogin={true} loginType="Admin" />
        { children }
        <Footer />
    </>)
}